import { TrialPreviewTree } from './tree-walkers';
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { PlayCircle } from '@mui/icons-material';
import { BlockTrialStimuliTable } from './BlockTrialStimuliTable';
import React from 'react';
import { TestType } from '../../../../types';

export function BlockTreeTrialList({
  block,
  testType,
}: {
  block: TrialPreviewTree['blocks'][number];
  testType: TestType;
}) {
  return (
    <List dense sx={{ borderLeft: '1px solid black' }}>
      {block.trials.map((trial, ti) => (
        <>
          <ListItem key={block.name + 'trials'}>
            <ListItemIcon sx={{ alignSelf: 'flex-start' }}>
              <PlayCircle />
            </ListItemIcon>
            <ListItemText
              sx={{ alignSelf: 'flex-start' }}
              primary={trial.trialName}
            />
            <Box sx={{ alignSelf: 'flex-start' }}>
              <BlockTrialStimuliTable
                testType={testType}
                trial={trial}
                withHeaders={ti === 0}
              />
            </Box>
          </ListItem>
        </>
      ))}
    </List>
  );
}
