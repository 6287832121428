import React, { FC } from 'react';
import { shuffle } from 'lodash';
import {
  Box,
  Button,
  Checkbox,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { ArrowDownward } from '@mui/icons-material';

type AmpBlockConfiguration = {
  amountTrials: number;
  probabilityWithoutReplacement: boolean;
};
type MockAmpStimulus =
  | {
      type: 'text';
      text: string;
    }
  | { type: 'svg'; svg: FC<{ length?: number; crossOut?: boolean }> };

const colorPalette = [
  '#FF0000',
  '#00FF00',
  '#0000FF',
  '#EE8800',
  '#00FFFF',
  '#FF00FF',
];
function createMockStimuli(
  conf: { type: 'target' } | { type: 'prime'; form: 'rect' | 'circle' },
): MockAmpStimulus[] {
  if (conf.type === 'target') {
    const variables = [
      ['x', 'y'],
      ['y', 'x'],
    ];
    const operators = ['+', '-', '*', '/'];
    return variables.flatMap((v) => {
      return operators.map((o) => {
        return { type: 'text', text: `${v[0]}${o}${v[1]}` };
      });
    });
  }
  if (conf.type === 'prime') {
    const colors = ['green', 'orange', 'red', 'blue'];
    return colors.map((c, i) => {
      return {
        type: 'svg',
        svg: ({ length = 100, crossOut = false }) => {
          return (
            <svg
              key={i}
              width={length}
              height={length}
              viewBox={`0 0 ${length} ${length}`}
              xmlns="http://www.w3.org/2000/svg"
            >
              {conf.form === 'rect' && (
                <rect
                  x={`${Math.round(length / 20)}`}
                  y={`${Math.round(length / 20)}`}
                  width={`${Math.round((9 * length) / 10)}`}
                  height={`${Math.round((9 * length) / 10)}`}
                  fill={colorPalette[i % colorPalette.length]}
                />
              )}
              {conf.form === 'circle' && (
                <circle
                  cx={`${Math.round(length / 2)}`}
                  cy={`${Math.round(length / 2)}`}
                  r={`${Math.round((9 * length) / 20)}`}
                  fill={colorPalette[i % colorPalette.length]}
                />
              )}
              {crossOut && (
                <line
                  x1="0"
                  y1="0"
                  x2={length}
                  y2={length}
                  stroke="red"
                  strokeWidth="2"
                />
              )}
            </svg>
          );
        },
      };
    });
  }
  throw new Error('Invalid type');
}

const mockAmpStimuli = {
  target: createMockStimuli({ type: 'target' }),
  primes: [
    {
      name: 'rectangles',
      stimuli: createMockStimuli({ type: 'prime', form: 'rect' }),
    },
    {
      name: 'circles',
      stimuli: createMockStimuli({ type: 'prime', form: 'circle' }),
    },
  ],
} satisfies {
  target: MockAmpStimulus[];
  primes: { name: string; stimuli: MockAmpStimulus[] }[];
};

type AmpPickingUrn = {
  name: string;
  probabilityWithoutReplacement: boolean;
} & (
  | {
      type: 'stimuli';
      stimuli: {
        stimulus: MockAmpStimulus;
        picked: boolean;
        hovered: 'dash' | 'none';
      }[];
    }
  | {
      type: 'urn';
      urns: {
        urn: Extract<AmpPickingUrn, { type: 'stimuli' }>;
        picked: boolean;
        hovered: 'dash' | 'none';
      }[];
    }
);
function copyAmpUrn(urn: AmpPickingUrn): AmpPickingUrn {
  return {
    name: urn.name,
    probabilityWithoutReplacement: urn.probabilityWithoutReplacement,
    ...(urn.type === 'stimuli'
      ? {
          type: 'stimuli',
          stimuli: urn.stimuli.map((stimulus) => {
            return {
              ...stimulus,
            };
          }),
        }
      : {
          type: 'urn',
          urns: urn.urns.map((urn) => {
            return {
              ...urn,
              urn: copyAmpUrn(urn.urn) as Extract<
                AmpPickingUrn,
                { type: 'stimuli' }
              >,
            };
          }),
        }),
  };
}
type AmpBlockPickingStep = {
  target: {
    urn: Extract<AmpPickingUrn, { type: 'stimuli' }>;
    pickedStimulus: MockAmpStimulus;
  };
  primes: {
    urn: Extract<AmpPickingUrn, { type: 'urn' }>;
    pickedCategory: Extract<AmpPickingUrn, { type: 'stimuli' }>;
    pickedStimulus: MockAmpStimulus;
  };
  pickedList: [MockAmpStimulus, MockAmpStimulus][];
};
type AmpBlockPicking = {
  steps: AmpBlockPickingStep[];
  result: [MockAmpStimulus, MockAmpStimulus][];
};

function createAmpBlockPicking(conf: AmpBlockConfiguration): AmpBlockPicking {
  let currentTargetUrn: Extract<AmpPickingUrn, { type: 'stimuli' }> = {
    name: 'urn_target',
    probabilityWithoutReplacement: conf.probabilityWithoutReplacement,
    type: 'stimuli',
    stimuli: mockAmpStimuli.target.map((stimulus) => {
      return { stimulus, picked: false, hovered: 'none' };
    }),
  };
  let currentPrimeUrn: Extract<AmpPickingUrn, { type: 'urn' }> = {
    name: 'urn_primes',
    probabilityWithoutReplacement: true,
    type: 'urn',
    urns: mockAmpStimuli.primes.map((prime) => {
      return {
        urn: {
          name: prime.name,
          probabilityWithoutReplacement: conf.probabilityWithoutReplacement,
          type: 'stimuli',
          stimuli: prime.stimuli.map((stimulus) => {
            return { stimulus, picked: false, hovered: 'none' };
          }),
        },
        hovered: 'none',
        picked: false,
      };
    }),
  };
  let result: [MockAmpStimulus, MockAmpStimulus][] = [];
  const steps: AmpBlockPickingStep[] = Array.from({
    length: conf.amountTrials,
  }).map((_, i) => {
    currentTargetUrn = copyAmpUrn(currentTargetUrn) as Extract<
      AmpPickingUrn,
      { type: 'stimuli' }
    >;
    currentTargetUrn.stimuli.forEach((s) => {
      if (s.hovered === 'dash') {
        s.picked = currentTargetUrn.probabilityWithoutReplacement;
      }
      s.hovered = 'none';
    });
    if (
      currentTargetUrn.probabilityWithoutReplacement &&
      currentTargetUrn.stimuli.every((s) => s.picked)
    ) {
      currentTargetUrn.stimuli.forEach((s) => (s.picked = false));
    }
    const shuffledTarget = shuffle([
      ...currentTargetUrn.stimuli.filter((s) => !s.picked),
    ]);
    const pickedTarget = shuffledTarget[0];
    pickedTarget.hovered = 'dash';

    currentPrimeUrn = copyAmpUrn(currentPrimeUrn) as Extract<
      AmpPickingUrn,
      { type: 'urn' }
    >;
    currentPrimeUrn.urns.forEach((s) => {
      if (s.hovered === 'dash') {
        s.picked = currentPrimeUrn.probabilityWithoutReplacement;
      }
      s.hovered = 'none';
    });
    if (
      currentPrimeUrn.probabilityWithoutReplacement &&
      currentPrimeUrn.urns.every((s) => s.picked)
    ) {
      currentPrimeUrn.urns.forEach((s) => (s.picked = false));
    }
    const shuffledPrimeCategory = shuffle([
      ...currentPrimeUrn.urns.filter((s) => !s.picked),
    ]);
    const pickedPrimeCategory = shuffledPrimeCategory[0];
    pickedPrimeCategory.hovered = 'dash';

    currentPrimeUrn.urns
      .flatMap((u) => u.urn.stimuli)
      .forEach((s) => {
        if (s.hovered === 'dash') {
          s.picked = pickedPrimeCategory.urn.probabilityWithoutReplacement;
        }
        s.hovered = 'none';
      });
    if (
      pickedPrimeCategory.urn.probabilityWithoutReplacement &&
      pickedPrimeCategory.urn.stimuli.every((s) => s.picked)
    ) {
      pickedPrimeCategory.urn.stimuli.forEach((s) => (s.picked = false));
    }
    const shuffledPrime = shuffle([
      ...pickedPrimeCategory.urn.stimuli.filter((s) => !s.picked),
    ]);
    const pickedPrimeStimulus = shuffledPrime[0];
    pickedPrimeStimulus.hovered = 'dash';
    result = [...result, [pickedTarget.stimulus, pickedPrimeStimulus.stimulus]];
    return {
      target: {
        pickedStimulus: pickedTarget.stimulus,
        urn: currentTargetUrn,
      },
      primes: {
        urn: currentPrimeUrn,
        pickedCategory: pickedPrimeCategory.urn,
        pickedStimulus: pickedPrimeStimulus.stimulus,
      },
      pickedList: result,
    };
  });
  return {
    steps,
    result,
  };
}

function AmpStimuliDisplay({
  length,
  stimulus,
  active = true,
}: {
  length: number;
  active?: boolean;
  stimulus: {
    stimulus: MockAmpStimulus;
    picked: boolean;
    hovered: 'dash' | 'none';
  };
}) {
  return (
    <>
      {stimulus.stimulus.type === 'text' && (
        <Typography
          sx={{
            display: 'inline',
            whiteSpace: 'nowrap',
            color: stimulus.picked ? 'lightgrey' : 'black',
            padding: '0.25em',
            textDecorationColor: '#FF0000',
            textDecoration: stimulus.picked ? 'line-through' : 'none',
            border:
              stimulus.hovered === 'dash' && active
                ? '4px dotted orange'
                : undefined,
          }}
        >
          {stimulus.stimulus.text}
        </Typography>
      )}
      {stimulus.stimulus.type === 'svg' && (
        <Box
          sx={{
            display: 'inline-block',
            padding: '0.25em',
            border:
              stimulus.hovered === 'dash' && active
                ? '4px dotted orange'
                : undefined,
          }}
        >
          <Box
            sx={{
              display: 'inline-block',
              // opacity: stimulus.picked ? 0.05 : 1,
            }}
          >
            {stimulus.stimulus.svg({ length, crossOut: stimulus.picked })}
          </Box>
        </Box>
      )}
    </>
  );
}

function AmpBlockConfigurationPanel({
  conf,
  setConf,
  visualize,
}: {
  conf: AmpBlockConfiguration;
  setConf: (conf: AmpBlockConfiguration) => void;
  visualize: () => void;
}) {
  return (
    <Stack
      direction="row"
      sx={{ padding: '1em', width: '100%' }}
      justifyContent="center"
      spacing={2}
    >
      <Box>
        <TextField
          type="number"
          onChange={(e) =>
            setConf({
              ...conf,
              amountTrials: (e.target as HTMLInputElement).valueAsNumber,
            })
          }
          value={conf.amountTrials}
        />{' '}
      </Box>
      <Box>
        <Checkbox
          checked={conf.probabilityWithoutReplacement}
          onChange={(_, checked) =>
            setConf({ ...conf, probabilityWithoutReplacement: checked })
          }
        />
        Probability without replacement
      </Box>
      <Box>
        <Button onClick={visualize}>Visualize</Button>
      </Box>
    </Stack>
  );
}
export function TestAmpPickingVisualizer() {
  const [conf, setConf] = React.useState<AmpBlockConfiguration>({
    amountTrials: 8,
    probabilityWithoutReplacement: true,
  });
  const picking = conf ? createAmpBlockPicking(conf) : undefined;

  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <AmpBlockConfigurationPanel
        visualize={() => {}}
        conf={conf}
        setConf={setConf}
      />
      {picking && (
        <Stack spacing={1} sx={{ width: '100%', padding: '0 5%' }}>
          {picking.steps.map((step, i) => {
            return (
              <Stack key={i} spacing={1} sx={{ width: '100%' }}>
                <Typography variant="h6">Trial {i + 1}</Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    width: '100%',
                    paddingLeft: '0.75em',
                    maxHeight: '7em',
                  }}
                >
                  <Box flex={1}>
                    <Typography variant="h6">Target</Typography>
                    <Stack
                      spacing={1}
                      sx={{ height: '100%', paddingRight: '1em' }}
                    >
                      <Stack
                        spacing={1}
                        direction="row"
                        sx={{
                          border: '2px solid black',
                          height: '100%',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {step.target.urn.stimuli.map((stimulus, j) => {
                          return (
                            <AmpStimuliDisplay
                              length={40}
                              stimulus={stimulus}
                              key={j}
                            />
                          );
                        })}
                      </Stack>
                    </Stack>
                  </Box>
                  <Box flex={1}>
                    <Typography variant="h6">Primes</Typography>
                    <Stack spacing={1} sx={{ height: '100%' }}>
                      <Stack
                        spacing={1}
                        direction="row"
                        sx={{ border: '2px solid black', height: '100%' }}
                      >
                        {step.primes.urn.urns.map((urn, j) => {
                          return (
                            <Box
                              key={j}
                              flex={1}
                              sx={{
                                padding: '0.5em',
                                border:
                                  urn.hovered === 'dash'
                                    ? '3px dashed blue'
                                    : `1px solid ${urn.picked ? 'red' : 'grey'}`,
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{
                                  textDecoration: urn.picked
                                    ? 'line-through'
                                    : undefined,
                                }}
                              >
                                {urn.urn.name}
                              </Typography>
                              <Stack spacing={1} direction="row">
                                {urn.urn.stimuli.map((stimulus, k) => {
                                  return (
                                    <AmpStimuliDisplay
                                      length={40}
                                      active={
                                        step.primes.pickedCategory === urn.urn
                                      }
                                      stimulus={stimulus}
                                      key={k}
                                    />
                                  );
                                })}
                              </Stack>
                            </Box>
                          );
                        })}
                      </Stack>
                    </Stack>
                  </Box>
                </Stack>
                <Stack
                  spacing={1}
                  sx={{
                    paddingTop: '2em',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ArrowDownward />
                  <Stack
                    spacing={1}
                    direction="row"
                    sx={{
                      flex: 0,
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid black',
                    }}
                  >
                    <AmpStimuliDisplay
                      length={40}
                      stimulus={{
                        stimulus: step.target.pickedStimulus,
                        picked: false,
                        hovered: 'none',
                      }}
                    />
                    <Divider color="black" orientation="vertical" flexItem />
                    <AmpStimuliDisplay
                      length={40}
                      stimulus={{
                        stimulus: step.primes.pickedStimulus,
                        picked: false,
                        hovered: 'none',
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            );
          })}
          <Stack spacing={1} sx={{ width: '100%', paddingBlock: '1em' }}>
            <Typography variant="h6">Result</Typography>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {picking.result.map(([target, prime], i) => {
                return (
                  <Stack
                    key={i}
                    direction="row"
                    spacing={1}
                    sx={{ border: '1px solid black' }}
                  >
                    <AmpStimuliDisplay
                      length={40}
                      stimulus={{
                        stimulus: target,
                        picked: false,
                        hovered: 'none',
                      }}
                    />
                    <Divider color="black" orientation="vertical" flexItem />
                    <AmpStimuliDisplay
                      length={40}
                      stimulus={{
                        stimulus: prime,
                        picked: false,
                        hovered: 'none',
                      }}
                    />
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
