import { UserRegistration, UserRegistrationStatus } from '../../API';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  useConfirmUserRegistrationMutation,
  useDenyUserRegistrationMutation,
} from './registrationsApi';
import { roles, UserRole } from '../../auth/user-role';
import React from 'react';
import { format } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';
import { enqueueSnackbar } from 'notistack';

export function RegistrationRequestDialog({
  request,
  closeDialog,
}: {
  request: UserRegistration;
  closeDialog: (reload?: boolean) => void;
}) {
  const [denyRegistration, { isLoading: isDenyLoading }] =
    useDenyUserRegistrationMutation();
  const [confirmRegistration, { isLoading: isConfirmLoading }] =
    useConfirmUserRegistrationMutation();
  const [confirmationData, setConfirmationData] = React.useState<{
    choosenRole: UserRole;
    institution: string;
    additionalInformation: string;
    researchGoal: string;
  }>();
  return (
    <Dialog
      open
      onClose={() => {
        closeDialog(false);
      }}
      maxWidth={'lg'}
      fullWidth
    >
      <DialogTitle>User Registration</DialogTitle>
      <DialogContent sx={{ width: '100%' }}>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ width: '25%' }}>
                  Date of Registration
                </TableCell>
                <TableCell>
                  {format(new Date(request.createdAt), 'dd.MM.yyyy')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>{request.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>E-Mail</TableCell>
                <TableCell>{request.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Institution</TableCell>
                <TableCell>
                  {confirmationData ? (
                    <TextField
                      fullWidth
                      value={confirmationData.institution}
                      onChange={(evt) =>
                        setConfirmationData({
                          ...confirmationData,
                          institution: evt.target.value,
                        })
                      }
                    />
                  ) : (
                    request.institution
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Research Goal</TableCell>
                <TableCell>
                  {confirmationData ? (
                    <TextField
                      fullWidth
                      value={confirmationData.researchGoal}
                      rows={5}
                      multiline
                      onChange={(evt) =>
                        setConfirmationData({
                          ...confirmationData,
                          researchGoal: evt.target.value,
                        })
                      }
                    />
                  ) : (
                    request.researchGoal
                      .split('\n')
                      .map((l) => (
                        <Typography sx={{ display: 'block' }}>{l}</Typography>
                      ))
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Additional Information</TableCell>
                <TableCell>
                  {confirmationData ? (
                    <TextField
                      fullWidth
                      value={confirmationData.additionalInformation}
                      rows={5}
                      multiline
                      onChange={(evt) =>
                        setConfirmationData({
                          ...confirmationData,
                          additionalInformation: evt.target.value,
                        })
                      }
                    />
                  ) : (
                    (request.additionalInformation
                      ?.split('\n')
                      .map((l) => (
                        <Typography sx={{ display: 'block' }}>{l}</Typography>
                      )) ?? '')
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          {confirmationData && (
            <Autocomplete
              disableClearable
              renderInput={(params) => <TextField {...params} label="Groups" />}
              options={roles}
              value={confirmationData.choosenRole}
              onChange={(_, newGroup) =>
                setConfirmationData({
                  ...confirmationData,
                  choosenRole: newGroup as UserRole,
                })
              }
            />
          )}
        </Stack>
      </DialogContent>
      {isDenyLoading || isConfirmLoading ? (
        <LinearProgress />
      ) : (
        <DialogActions>
          <Button
            onClick={() => {
              closeDialog(false);
            }}
            variant="contained"
            color="inherit"
          >
            Cancel
          </Button>
          {!confirmationData &&
            request.status === UserRegistrationStatus.PENDING && (
              <Button
                disabled={isDenyLoading || isConfirmLoading}
                startIcon={
                  isDenyLoading ? <CircularProgress size="1em" /> : undefined
                }
                onClick={() => {
                  denyRegistration({ registrationId: request.id })
                    .unwrap()
                    .then((res) => {
                      console.log('denied registration', res);
                      enqueueSnackbar('Registration denied successfully', {
                        variant: 'success',
                      });
                      closeDialog(true);
                    })
                    .catch((err) => {
                      enqueueSnackbar('Failed to deny registration', {
                        variant: 'error',
                        persist: true,
                      });
                      console.error('Failed to deny registration', err);
                    });
                }}
                variant="contained"
                color="warning"
              >
                Deny
              </Button>
            )}
          {request.status !== UserRegistrationStatus.CONFIRMED && (
            <Button
              variant="contained"
              disabled={isDenyLoading || isConfirmLoading}
              startIcon={
                isConfirmLoading ? <CircularProgress size="1em" /> : undefined
              }
              onClick={() => {
                if (!confirmationData) {
                  setConfirmationData({
                    choosenRole: 'extern',
                    institution: request.institution,
                    additionalInformation: request.additionalInformation ?? '',
                    researchGoal: request.researchGoal,
                  });
                } else {
                  confirmRegistration({
                    registrationId: request.id,
                    groups: [confirmationData.choosenRole],
                    institution: confirmationData.institution,
                    additionalInformation:
                      confirmationData.additionalInformation,
                    researchGoal: confirmationData.researchGoal,
                  })
                    .unwrap()
                    .then((res) => {
                      console.log('confirmed registration', res);
                      enqueueSnackbar('Registration confirmed successfully', {
                        variant: 'success',
                      });
                      closeDialog(true);
                    })
                    .catch((err) => {
                      let error = null as string | null;
                      try {
                        const stack = err.stack ? JSON.parse(err.stack) : err;
                        if (stack.errors) {
                          error = stack.errors[0].message;
                        }
                      } catch (err) {
                        console.warn('failed to parse error stack', err);
                      }
                      enqueueSnackbar(
                        `Failed to confirm registration${error ? `: ${error}` : ''}`,
                        {
                          variant: 'error',
                          persist: true,
                        },
                      );
                      console.error('failed to confirm registration', err);
                    });
                }
              }}
            >
              {request.status === UserRegistrationStatus.DECLINED
                ? 'Edit & Confirm'
                : 'Accept'}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}
