import { BaseTest } from '../../../../../tests/types';
import { TestType } from '../../../../types';
import { TreeSequence } from '../../../graph/tree-sequence/tree-sequence';
import { SortedTree } from '../../../graph/sorted-tree/sorted-tree';
import { TestResourceAccessor } from '../../../media/test-resource-loader';
import { DrawableMediaInstance } from '../../../media/MediaData';
import { ampTreeWalker } from './amp-tree-walker';
import { iatTreeWalker } from './iat-tree-walker';

export type TrialPreviewTree = {
  structureInfo: Record<string, unknown>;
  blocks: {
    name: string;
    blockInfo: Record<string, unknown>;
    trials: {
      trialName: string;
      presentedStimuli: {
        stimulusType: string;
        stimulusInfo: Record<string, unknown>;
        stimulusData: DrawableMediaInstance;
      }[];
    }[];
  }[];
};
export type TestTreeWalker<T extends BaseTest = BaseTest> = {
  testType: TestType;
  transformTreeSequence: (
    treeSequence: TreeSequence,
    sortedTree: SortedTree,
    testAccessor: TestResourceAccessor<T>,
  ) => TrialPreviewTree;
};
export const treeWalkers: TestTreeWalker<any>[] = [
  ampTreeWalker,
  iatTreeWalker,
];
