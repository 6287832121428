import { ResultPersisterQueue } from '../../events/result-persister';
import { TestBaseContext } from './state/baseTestContext/context/ContextTypes';
import { NewGQL } from '../../../GQL';
import { TestFinalizationQueries } from '../../tests/shared-queries';
import { TestStatus } from '../../../API';

const DefaultResultPersistenceConfig = {
  retryInterval: 1000,
  shutdownTimeout: 60000,
};

export function createResultPersister(
  testContext: TestBaseContext,
  testStatus: TestStatus,
  finalizationEntryId: string,
  gql: NewGQL,
) {
  return new ResultPersisterQueue(
    finalizationEntryId,
    async (resultQuery) => {
      if (
        (testContext as TestBaseContext).providerContext.mode === 'execution'
      ) {
        return gql.execute(resultQuery);
      } else {
        return Promise.resolve();
      }
    },
    DefaultResultPersistenceConfig,
    (persister) => {
      return persister(
        TestFinalizationQueries.Update.create({
          input: {
            id: finalizationEntryId,
            status: testStatus,
            testFinalizedAt: new Date().toISOString(),
          },
        }),
      );
    },
  );
}
