import { TrialPreviewTree } from './tree-walkers';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { TestType } from '../../../../types';

export function TestInfoTable({
  blockTree,
  testType,
}: {
  blockTree: TrialPreviewTree;
  testType: TestType;
}) {
  if (testType === TestType.IAT) {
    return (
      <Stack spacing={1}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Attribute Categories</TableCell>
              <TableCell>Target Categories</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>A</TableCell>
              <TableCell>{`${(blockTree.structureInfo.attributeCategories as any)[0].label}`}</TableCell>
              <TableCell>{`${(blockTree.structureInfo.targetCategories as any)[0].label}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>B</TableCell>
              <TableCell>{`${(blockTree.structureInfo.attributeCategories as any)[1].label}`}</TableCell>
              <TableCell>{`${(blockTree.structureInfo.targetCategories as any)[1].label}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell># Stimuli</TableCell>
              <TableCell>{`${(blockTree.structureInfo.attributeCategories as any)[0].stimuliSize}`}</TableCell>
              <TableCell>{`${(blockTree.structureInfo.targetCategories as any)[0].stimuliSize}`}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
    );
  }

  return (
    <Stack spacing={1}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell># Practice Pool (per category)</TableCell>
            <TableCell># Test Pool (per category)</TableCell>
            <TableCell>Categories</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Primes</TableCell>
            <TableCell>{`${(blockTree.structureInfo.primeCategoryStimuliSize as any).practicePool}`}</TableCell>
            <TableCell>{`${(blockTree.structureInfo.primeCategoryStimuliSize as any).testPool}`}</TableCell>
            <TableCell>
              <ul>
                {(blockTree.structureInfo.primeCategories as any).map(
                  ({ label }: { label: string }) => (
                    <li>{label}</li>
                  ),
                )}
              </ul>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Targets</TableCell>
            <TableCell>{`${(blockTree.structureInfo.targetCategoryStimuliSize as any).practicePool}`}</TableCell>
            <TableCell>{`${(blockTree.structureInfo.targetCategoryStimuliSize as any).testPool}`}</TableCell>
            <TableCell>-</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  );
}
