import { RequireAuth } from '../../auth/RequiredAuth';
import { Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { DsgvoTable } from '../../components/SetupInstructions/DsgvoTable';
import { DataProtectionInfoTable } from './DataProtectionInfoTable';

export function DataProtectionPage() {
  return (
    <RequireAuth>
      <Stack sx={{ pt: 3, pb: '1em' }} spacing={2}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Datenschutz-Übersicht
        </Typography>
        <Paper sx={{ padding: '1rem' }}>
          <Typography variant="h4">
            Welche Daten von Probanden werden verarbeitet:
          </Typography>
          <DsgvoTable />
        </Paper>
        <Paper sx={{ padding: '1rem' }}>
          <Typography variant="h4">Wo werden Daten gespeichert:</Typography>
          {/*    <Typography variant="body2" color="text.secondary">

          </Typography>*/}
          <DataProtectionInfoTable
            tableDescriptor={{
              columns: {
                dataType: { label: 'Art der Daten', flex: 1 },
                storageService: { label: 'Dienst', flex: 2 },
                storageRegion: { label: 'Region/Standort', flex: 1 },
              },
              data: [
                {
                  dataType: 'Testkonfiguration',
                  storageService: 'AWS DynamoDB',
                  storageRegion: 'eu-central-1 (Frankfurt)',
                },
                {
                  dataType: 'Öffentlicher Media Pool (Bilder)',
                  storageService: 'AWS S3 + AWS DynamoDB (Metadaten)',
                  storageRegion: 'eu-central-1 (Frankfurt)',
                },
                {
                  dataType: 'Vom User hochgeladene Bilder',
                  storageService: 'AWS S3 + AWS DynamoDB (Metadaten)',
                  storageRegion: 'eu-central-1 (Frankfurt)',
                },
                {
                  dataType: 'Ergebnisaufzeichnung',
                  storageService: 'AWS DynamoDB',
                  storageRegion: 'eu-central-1 (Frankfurt)',
                },
                {
                  dataType: 'Verarbeitete Ergebnisse (CSV-Datei)',
                  storageService: 'AWS S3',
                  storageRegion: 'eu-central-1 (Frankfurt)',
                },
                {
                  dataType: 'Logdaten',
                  storageService: (
                    <>
                      AWS Cloudwatch
                      <br />
                      DCB Monitoring (Hetzner/Strato)
                    </>
                  ),
                  storageRegion: (
                    <>
                      eu-central-1 (Frankfurt)
                      <br />
                      Berlin/Karlsruhe/Nuremberg
                    </>
                  ),
                },
              ],
            }}
          />
        </Paper>
        <Paper sx={{ padding: '1rem' }}>
          <Typography variant="h4">
            Von welchen Diensten werden die Daten verarbeitet:
          </Typography>
          {/*    <Typography variant="body2" color="text.secondary">

          </Typography>*/}
          <DataProtectionInfoTable
            tableDescriptor={{
              columns: {
                dataType: { label: 'Art der Daten', flex: 1 },
                storageService: { label: 'Dienst', flex: 2 },
                storageRegion: { label: 'Region/Standort', flex: 1 },
              },
              data: [
                {
                  dataType: 'Testkonfiguration',
                  storageService: 'AWS AppSync/AWS Lambda',
                  storageRegion: 'eu-central-1 (Frankfurt)',
                },
                {
                  dataType: 'Öffentlicher Media Pool (Bilder)',
                  storageService: 'AWS S3 API, AWS App Sync, AWS Lambda',
                  storageRegion: 'eu-central-1 (Frankfurt)',
                },
                {
                  dataType: 'Vom User hochgeladene Bilder',
                  storageService: 'AWS S3 API, AWS App Sync, AWS Lambda',
                  storageRegion: 'eu-central-1 (Frankfurt)',
                },
                {
                  dataType: 'Ergebnisaufzeichnung',
                  storageService: 'AWS App Sync, AWS Lambda',
                  storageRegion: 'eu-central-1 (Frankfurt)',
                },
                {
                  dataType: 'Ergebnis Verarbeitung',
                  storageService: 'DeZIM Server',
                  storageRegion: 'Berlin',
                },
              ],
            }}
          />
        </Paper>
        <Paper sx={{ padding: '1rem' }}>
          <Typography variant="h4">
            Wie lange werden die Daten gespeichert
          </Typography>
          {/*    <Typography variant="body2" color="text.secondary">

          </Typography>*/}
          <DataProtectionInfoTable
            tableDescriptor={{
              columns: {
                dataType: { label: 'Art der Daten', flex: 1 },
                storageDuration: { label: 'Dienst', flex: 2 },
              },
              data: [
                {
                  dataType: 'Testkonfiguration',
                  storageDuration: 'Unbegrenzt',
                },
                {
                  dataType: 'Öffentlicher Media Pool (Bilder)',
                  storageDuration: 'Unbegrenzt',
                },
                {
                  dataType: 'Vom User hochgeladene Bilder',
                  storageDuration: 'Unbegrenzt',
                },
                {
                  dataType: 'Ergebnisaufzeichnung',
                  storageDuration: 'derzeit unbegrenzt, Sollwert 5 Jahre',
                },
                {
                  dataType: 'Verarbeitete Ergebnisse (CSV-Datei)',
                  storageDuration: 'derzeit unbegrenzt, Sollwert 5 Jahre',
                },
                {
                  dataType: 'Log Daten CloudWatch',
                  storageDuration: 'derzeit unbegrenzt, Sollwert 2 Jahre',
                },
                {
                  dataType: 'Log Daten LogStash (DCB Monitoring)',
                  storageDuration: 'derzeit unbegrenzt, Sollwert 2 Jahre',
                },
              ],
            }}
          />
        </Paper>
        <Paper sx={{ padding: '1rem' }}>
          <Typography variant="h4">
            Zu welchem Zweck werden die Daten verarbeitet
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Hier müsste DeZIM was liefern.
          </Typography>
        </Paper>
      </Stack>
    </RequireAuth>
  );
}
