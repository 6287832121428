import { IatOptionsType } from '../../features/tests/IAT/loader/structure/iat-options-type';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { FC } from 'react';
import { OptionsSide } from '../../features/tests/types';
import { shuffle, zip } from 'lodash';
import {
  AddCircle,
  ArrowDownward,
  ArrowForward,
  ArrowUpward,
  Merge,
  Shuffle,
} from '@mui/icons-material';
import Divider from '@mui/material/Divider';

type MockIatStimulus =
  | {
      type: 'text';
      text: string;
    }
  | { type: 'svg'; svg: FC<{ length?: number }> };

const iatBlockTypeMap = {
  'attribute-target': {
    type: ['attribute', 'target'],
    label: 'Combined block',
  },
  target: {
    type: 'target',
    label: 'Target block',
  },
  attribute: {
    type: 'attribute',
    label: 'Attribute block',
  },
} satisfies Record<
  `${IatOptionsType}` | 'attribute-target',
  {
    type: IatOptionsType | [IatOptionsType, IatOptionsType];
    label: string;
  }
>;

type IatStimuliUrn = {
  name: string;
  probabilityWithoutReplacement: boolean;
  stimuli: {
    stimulis: MockIatStimulus;
    picked: boolean;
    hovered: 'dash' | 'none';
  }[];
};

function IatStimuliUrnDisplay({
  urn,
  showHeader = true,
}: {
  urn: IatStimuliUrn;
  showHeader?: boolean;
}) {
  return (
    <Stack>
      {showHeader && <Typography>{urn.name}</Typography>}
      <div
        style={{
          border: '1px solid black',
          // borderTop: '1px dashed black',
          padding: '0.5em',
        }}
      >
        <Grid container>
          {urn.stimuli.map((stimulus, i) => {
            return (
              <Grid
                item
                xs={6}
                sx={{
                  paddingLeft: i % 4 > 1 ? '2em' : 0,
                }}
              >
                {stimulus.stimulis.type === 'text' && (
                  <Typography
                    sx={{
                      display: 'inline',
                      color: stimulus.picked ? 'lightgrey' : 'black',
                      padding: '0.25em',
                      border:
                        stimulus.hovered === 'dash'
                          ? '4px dotted orange'
                          : undefined,
                    }}
                  >
                    {stimulus.stimulis.text}
                  </Typography>
                )}
                {stimulus.stimulis.type === 'svg' && (
                  <Box
                    sx={{
                      display: 'inline-block',
                      padding: '0.25em',
                      border:
                        stimulus.hovered === 'dash'
                          ? '4px dotted orange'
                          : undefined,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'inline-block',
                        opacity: stimulus.picked ? 0.05 : 1,
                      }}
                    >
                      {stimulus.stimulis.svg({ length: 40 })}
                    </Box>
                  </Box>
                )}
              </Grid>
            );
          })}
        </Grid>
      </div>
    </Stack>
  );
}
const colorPalette = [
  '#FF0000',
  '#00FF00',
  '#0000FF',
  '#EE8800',
  '#00FFFF',
  '#FF00FF',
];
const iatStimuliWords: Record<OptionsSide, string[]> = {
  0: ['happy', 'love', 'joy', 'fun'],
  1: ['agony', 'hurt', 'evil', 'panic'],
};
function createMockIatStimuli(
  type: IatOptionsType,
  variant: OptionsSide,
  amount: number,
): MockIatStimulus[] {
  return Array.from({ length: amount }).map((_, i) => {
    if (type === 'attribute') {
      return {
        type: 'text',
        text: iatStimuliWords[variant][i % iatStimuliWords[variant].length],
      };
    }
    return {
      type: 'svg',
      svg: ({ length = 100 }) => (
        <svg
          key={i}
          width={length}
          height={length}
          viewBox={`0 0 ${length} ${length}`}
          xmlns="http://www.w3.org/2000/svg"
        >
          {variant === 0 && (
            <rect
              x={`${Math.round(length / 20)}`}
              y={`${Math.round(length / 20)}`}
              width={`${Math.round((9 * length) / 10)}`}
              height={`${Math.round((9 * length) / 10)}`}
              fill={colorPalette[i % colorPalette.length]}
            />
          )}
          {variant === 1 && (
            <circle
              cx={`${Math.round(length / 2)}`}
              cy={`${Math.round(length / 2)}`}
              r={`${Math.round((9 * length) / 20)}`}
              fill={colorPalette[i % colorPalette.length]}
            />
          )}
        </svg>
      ),
    };
  });
}

type MockIatVariant = {
  variant: OptionsSide;
  stimuli: MockIatStimulus[];
};
type MockIatCategory = {
  type: IatOptionsType;
  variants: Record<OptionsSide, MockIatVariant>;
};
type MockIatBlock = {
  type: keyof typeof iatBlockTypeMap;
  categories: MockIatCategory[];
  amountTrials: number;
};

function createMockIatBlock(conf: IatBlockConfiguration): MockIatBlock {
  return {
    type: conf.type,
    amountTrials: conf.amountTrials,
    categories:
      conf.type === 'attribute-target'
        ? [
            {
              type: 'attribute',
              variants: {
                0: {
                  variant: 0 as const,
                  stimuli: createMockIatStimuli(
                    'attribute',
                    0,
                    conf.stimuliCount,
                  ),
                },
                1: {
                  variant: 1 as const,
                  stimuli: createMockIatStimuli(
                    'attribute',
                    1,
                    conf.stimuliCount,
                  ),
                },
              },
            },
            {
              type: 'target',
              variants: {
                0: {
                  variant: 0 as const,
                  stimuli: createMockIatStimuli('target', 0, conf.stimuliCount),
                },
                1: {
                  variant: 1 as const,
                  stimuli: createMockIatStimuli('target', 1, conf.stimuliCount),
                },
              },
            },
          ]
        : [
            {
              type: conf.type,
              variants: {
                0: {
                  variant: 0 as const,
                  stimuli: createMockIatStimuli(
                    conf.type,
                    0,
                    conf.stimuliCount,
                  ),
                },
                1: {
                  variant: 1 as const,
                  stimuli: createMockIatStimuli(
                    conf.type,
                    1,
                    conf.stimuliCount,
                  ),
                },
              },
            },
          ],
  };
}

type IatBlockConfiguration = {
  type: keyof typeof iatBlockTypeMap;
  amountTrials: number;
  probabilityWithoutReplacement: boolean;
  stimuliCount: number;
};
function IatBlockConfigurationPanel({
  conf,
  setConf,
  visualize,
}: {
  conf: IatBlockConfiguration;
  setConf: (conf: IatBlockConfiguration) => void;
  visualize: () => void;
}) {
  return (
    <Stack
      direction="row"
      sx={{ padding: '1em', width: '100%' }}
      justifyContent="center"
      spacing={2}
    >
      <Box>
        <Select
          value={conf.type}
          onChange={(e) => {
            setConf({
              ...conf,
              type: e.target.value as keyof typeof iatBlockTypeMap,
            });
          }}
        >
          {Object.entries(iatBlockTypeMap).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {value.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box>
        <TextField
          type="number"
          onChange={(e) =>
            setConf({
              ...conf,
              amountTrials: (e.target as HTMLInputElement).valueAsNumber,
            })
          }
          value={conf.amountTrials}
        />{' '}
      </Box>
      <Box>
        <Checkbox
          checked={conf.probabilityWithoutReplacement}
          onChange={(_, checked) =>
            setConf({ ...conf, probabilityWithoutReplacement: checked })
          }
        />
        Probability without replacement
      </Box>
      <Box>
        <Button onClick={visualize}>Visualize</Button>
      </Box>
    </Stack>
  );
}
const iatPoolMetaMap: Record<
  IatOptionsType,
  { name: string; variants: Record<OptionsSide, { name: string }> }
> = {
  target: {
    name: 'Formen',
    variants: {
      0: {
        name: 'Rechtecke',
      },
      1: {
        name: 'Kreise',
      },
    },
  },
  attribute: {
    name: 'Worte',
    variants: {
      0: {
        name: 'Positiv',
      },
      1: {
        name: 'Negativ',
      },
    },
  },
};

type IatCategoryVariantPickingStep = {
  urn: IatStimuliUrn;
  pickedList: MockIatStimulus[];
};
type IatCategoryVariantPicking = {
  category: IatOptionsType;
  variants: {
    variant: OptionsSide;
    steps: IatCategoryVariantPickingStep[];
    result: MockIatStimulus[];
  }[];
}[];
function copyIatUrn(urn: IatStimuliUrn): IatStimuliUrn {
  return {
    name: urn.name,
    probabilityWithoutReplacement: urn.probabilityWithoutReplacement,
    stimuli: urn.stimuli.map((stimulus) => {
      return {
        ...stimulus,
      };
    }),
  };
}
function createCategoryIatVariantPicking(
  conf: IatBlockConfiguration,
  categories: MockIatCategory[],
): IatCategoryVariantPicking {
  return categories.map((category) => {
    return {
      category: category.type,
      variants: Object.entries(category.variants).map(([key, variant]) => {
        const initialUrn: IatStimuliUrn = {
          name: iatPoolMetaMap[category.type].variants[variant.variant].name,
          probabilityWithoutReplacement: conf.probabilityWithoutReplacement,
          stimuli: variant.stimuli.map((stimulus) => {
            return {
              stimulis: stimulus,
              picked: false,
              hovered: 'none',
            };
          }),
        };
        let pickedList = [] as MockIatStimulus[];
        let currentUrn = initialUrn;
        const steps = [
          {
            urn: initialUrn,
            pickedList: [],
          },
          ...Array.from({
            length: conf.amountTrials / (2 * categories.length),
          }).map(() => {
            currentUrn = copyIatUrn(currentUrn);
            currentUrn.stimuli.forEach((s) => {
              if (s.hovered === 'dash') {
                s.picked = conf.probabilityWithoutReplacement;
              }
              s.hovered = 'none';
            });
            if (
              conf.probabilityWithoutReplacement &&
              currentUrn.stimuli.every((s) => s.picked)
            ) {
              currentUrn.stimuli.forEach((s) => (s.picked = false));
            }
            const shuffled = shuffle([
              ...currentUrn.stimuli.filter((s) => !s.picked),
            ]);
            const picked = shuffled[0];
            picked.hovered = 'dash';
            pickedList = [...pickedList, picked.stimulis];
            return {
              urn: currentUrn,
              pickedList,
            };
          }),
        ];
        return {
          variant: variant.variant,
          steps: steps,
          result: pickedList,
        };
      }),
    };
  });
}

function CategoryIatVariantPickList({
  conf,
  pickedList,
  block,
  pools = 1,
  splitPosition,
}: {
  conf: IatBlockConfiguration;
  block: MockIatBlock;
  pickedList: MockIatStimulus[];
  pools?: number;
  splitPosition?: 'left' | 'right';
}) {
  const amount = (pools * conf.amountTrials) / (2 * block.categories.length);

  return (
    <Stack
      direction="row"
      sx={{
        width: '100%',
        height: '100%',
        border: '3px dashed #BEBEBE',
        borderRadius: '2em',
      }}
      spacing={0}
    >
      {Array.from({ length: amount }).map((_, i) => {
        const stimulus = pickedList[i];
        return (
          <Stack
            sx={{
              flex: 1,
              position: 'relative',
              borderLeft: i === 0 ? undefined : '3px dashed #BEBEBE',
              // backgroundColor: stimulus ? '#BEBEBE' : 'white',
              padding: '0.25em',
              alignItems: 'center',
              justifyContent: 'center',
              // color: stimulus ? 'black' : '#BEBEBE',
            }}
          >
            <Typography
              sx={{
                position: 'absolute',
                left: splitPosition === 'right' ? '50%' : '0.75em',
                top: '0.75em',
                fontSize: '1.1em',
              }}
            >
              {i + 1}
            </Typography>
            {stimulus?.type === 'text' && (
              <Typography>{stimulus.text}</Typography>
            )}
            {stimulus?.type === 'svg' && (
              <Box>{stimulus.svg({ length: 35 })}</Box>
            )}
            {stimulus === undefined && <span></span>}
          </Stack>
        );
      })}
    </Stack>
  );
}

function IatCategoryVariantPickingDisplay({
  picking,
  conf,
  block,
}: {
  picking: IatCategoryVariantPicking[number]['variants'][number];
  conf: IatBlockConfiguration;
  block: MockIatBlock;
}) {
  return (
    <Stack spacing={2}>
      {/*  <Box sx={{ maxHeight: '200px', height: '100px' }}>
        <CategoryIatVariantPickList conf={conf} block={block} pickedList={[]} />
      </Box>*/}
      <Stack direction="row" spacing={1}>
        {picking.steps.slice(1).map((step, i) => {
          return (
            <>
              {i > 0 && (
                <ArrowForward
                  sx={{
                    alignSelf: 'center',
                    fontSize: '1.5em',
                  }}
                />
              )}
              <Stack spacing={2}>
                <IatStimuliUrnDisplay showHeader={false} urn={step.urn} />
                <ArrowDownward sx={{ alignSelf: 'center' }} />
              </Stack>
            </>
          );
        })}
      </Stack>
      <Box sx={{ maxHeight: '200px', height: '100px' }}>
        <CategoryIatVariantPickList
          conf={conf}
          block={block}
          pickedList={picking.result}
        />
      </Box>
    </Stack>
  );
}

function createIatBlockPicking(
  conf: IatBlockConfiguration,
  block: MockIatBlock,
) {
  const categoryVariantPicking = createCategoryIatVariantPicking(
    conf,
    block.categories,
  );
  const categoryPicking = categoryVariantPicking.map((category) => {
    return {
      category: category.category,
      variants: category.variants,
      concatList: category.variants.map((v) => v.result).flat(),
      shuffledList: shuffle(category.variants.map((v) => v.result).flat()),
    };
  });
  const zippedPicking = {
    categoryPicking,
    zippedResult: zip(
      ...categoryPicking.map((p) => p.shuffledList),
    ).flat() as MockIatStimulus[],
  };
  return zippedPicking;
}

export function TestIatPickingVisualizer() {
  const [conf, setConf] = React.useState<IatBlockConfiguration>({
    type: 'attribute-target',
    amountTrials: 10,
    stimuliCount: 4,
    probabilityWithoutReplacement: true,
  });
  const [mockBlock, setMockBlock] = React.useState<MockIatBlock>();
  /*  const picking = mockBlock
    ? createCategoryIatVariantPicking(conf, mockBlock.categories)
    : undefined;*/
  const blockPicking = mockBlock
    ? createIatBlockPicking(conf, mockBlock)
    : undefined;

  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <IatBlockConfigurationPanel
        visualize={() => {
          setMockBlock(createMockIatBlock(conf));
        }}
        conf={conf}
        setConf={setConf}
      />
      {mockBlock && (
        <Stack spacing={1} sx={{ width: '100%', padding: '0 5%' }}>
          {mockBlock.categories.map((category) => {
            return (
              <Stack sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ alignSelf: 'center' }}>
                  {iatPoolMetaMap[category.type].name}
                </Typography>
                <Stack
                  sx={{ width: '100%' }}
                  justifyContent="space-between"
                  direction="row"
                  spacing={1}
                >
                  {Object.entries(category.variants).map(([key, variant]) => {
                    return (
                      <Stack key={key}>
                        <Typography variant="h6">
                          {
                            iatPoolMetaMap[category.type].variants[
                              variant.variant
                            ].name
                          }
                        </Typography>
                        <Stack direction="row">
                          {variant.stimuli.map((stimulus) => {
                            return (
                              <Stack>
                                {stimulus.type === 'text' && (
                                  <Typography marginLeft={'1em'}>
                                    {stimulus.text}
                                  </Typography>
                                )}
                                {stimulus.type === 'svg' && (
                                  <Box>{stimulus.svg({ length: 100 })}</Box>
                                )}
                              </Stack>
                            );
                          })}
                        </Stack>
                      </Stack>
                    );
                  })}
                </Stack>
              </Stack>
            );
          })}
          <Divider />
          <Typography variant={'h2'}>1. Build category-type lists</Typography>
          <Stack sx={{ width: '100%', paddingLeft: '1em' }} spacing={2}>
            {blockPicking?.categoryPicking?.map((category, ci) => {
              return (
                <Stack spacing={2} sx={{ paddingTop: '1em' }}>
                  <Typography variant="h3">
                    {`1.${ci} Build ${category.category} categories list`}
                  </Typography>
                  <Stack sx={{ width: '100%' }} direction="row">
                    {category.variants.map((variant, vi) => {
                      return (
                        <Stack
                          spacing={1}
                          sx={{
                            flex: 1,
                            padding: '0.5em 1em',
                            borderLeft:
                              vi === 0 ? undefined : '1px solid black',
                          }}
                        >
                          <Typography variant="h4">
                            {
                              iatPoolMetaMap[category.category].variants[
                                variant.variant
                              ].name
                            }
                          </Typography>
                          <IatCategoryVariantPickingDisplay
                            picking={variant}
                            conf={conf}
                            block={mockBlock!}
                          />
                          {/* {variant.steps.map((step) => {
                          return (
                            <CategoryVariantPickingStepDisplay
                              step={step}
                              conf={conf}
                              block={mockBlock!}
                            />
                          );
                        })}*/}
                          <ArrowDownward sx={{ alignSelf: 'center' }} />
                        </Stack>
                      );
                    })}
                  </Stack>
                  <Box
                    sx={{
                      display: 'flex',
                      flex: 1,
                      width: '100%',
                      padding: '0 20%',
                    }}
                  >
                    <Chip
                      sx={{ flex: 1 }}
                      icon={<AddCircle />}
                      label="Concat lists"
                    />
                  </Box>
                  <ArrowDownward sx={{ alignSelf: 'center' }} />
                  <Box sx={{ maxHeight: '200px', height: '100px' }}>
                    <CategoryIatVariantPickList
                      conf={conf}
                      block={mockBlock!}
                      pools={2}
                      pickedList={category.concatList}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flex: 1,
                      width: '100%',
                      padding: '0 20%',
                    }}
                  >
                    <Chip
                      sx={{ flex: 1 }}
                      icon={<Shuffle />}
                      label="Shuffle list"
                    />
                  </Box>
                  <ArrowDownward sx={{ alignSelf: 'center' }} />
                  <Box sx={{ maxHeight: '200px', height: '100px' }}>
                    <CategoryIatVariantPickList
                      conf={conf}
                      block={mockBlock!}
                      pools={2}
                      pickedList={category.shuffledList}
                    />
                  </Box>
                </Stack>
              );
            })}
            {conf.type === 'attribute-target' && blockPicking && (
              <>
                <Typography variant="h2">2. Combine categories</Typography>
                <Stack spacing={2} sx={{ paddingLeft: '1em' }}>
                  <Box sx={{ maxHeight: '200px', height: '100px' }}>
                    <CategoryIatVariantPickList
                      conf={conf}
                      block={mockBlock!}
                      pools={2}
                      pickedList={blockPicking.categoryPicking[0].shuffledList}
                    />
                  </Box>
                  <ArrowDownward sx={{ alignSelf: 'center' }} />
                  <Box
                    sx={{
                      display: 'flex',
                      flex: 1,
                      width: '100%',
                      padding: '0 20%',
                    }}
                  >
                    <Chip sx={{ flex: 1 }} icon={<Merge />} label="Zip lists" />
                  </Box>
                  <Stack sx={{ width: '100%' }} direction="row">
                    {blockPicking.categoryPicking[0].shuffledList.map(() => {
                      return (
                        <Box
                          sx={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'left',
                            paddingLeft: '0.5em',
                          }}
                        >
                          <ArrowDownward />
                        </Box>
                      );
                    })}
                  </Stack>
                  <Box sx={{ maxHeight: '200px', height: '100px' }}>
                    <CategoryIatVariantPickList
                      conf={conf}
                      block={mockBlock!}
                      pools={4}
                      pickedList={blockPicking.zippedResult}
                    />
                  </Box>

                  <Stack sx={{ width: '100%' }} direction="row">
                    {blockPicking.categoryPicking[0].shuffledList.map(() => {
                      return (
                        <Box
                          sx={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'right',
                            paddingRight: '0.5em',
                          }}
                        >
                          <ArrowUpward />
                        </Box>
                      );
                    })}
                  </Stack>
                  <Box
                    sx={{
                      display: 'flex',
                      flex: 1,
                      width: '100%',
                      padding: '0 20%',
                    }}
                  >
                    <Chip sx={{ flex: 1 }} icon={<Merge />} label="Zip lists" />
                  </Box>
                  <ArrowUpward sx={{ alignSelf: 'center' }} />
                  <Box sx={{ maxHeight: '200px', height: '100px' }}>
                    <CategoryIatVariantPickList
                      conf={conf}
                      block={mockBlock!}
                      pools={2}
                      pickedList={blockPicking.categoryPicking[1].shuffledList}
                    />
                  </Box>
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
