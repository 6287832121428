import { ResultTableColumn } from '../../../../tests/IAT/runner/result-preview-table';
import { BasicDimension, SizedArea } from '../../media/drawable-dimensions';
import { Subject } from 'rxjs';
import {
  TestDirector,
  TestFlowSnapshot,
} from '../../graph-state/test-director';
import { ControlInputMonitor } from '../../controls/control-monitor';
import { ControlHandlerManager } from '../../controls/control-handlers';
import React from 'react';
import { GQLQueryInstance, NewGQL } from '../../../../../GQL';
import { ControlDisplay } from './ControlDisplay';
import { ResultView } from './ResultView';
import { TreeSequence } from '../../graph/tree-sequence/tree-sequence';
import { NodePathEntry } from '../../graph/tree-sequence/node-path-entry';
import { ScreenTreeNode } from '../../graph/nodes/screen-tree-node';
import { TestScreenInstanceController } from '../../screens/composer/screen-instance/test-screen-instance-controller';

export function StageRendererTest({
  subject,
  screenSize,
  controller,
  screenNodes,
  treeSequence,
  persistResults,
  controllerHandlerFactory,
  stateChangeSubject,
  resultPreviewTable,
}: {
  resultPreviewTable?: ResultTableColumn<any>[];
  persistResults: boolean;
  treeSequence: TreeSequence;
  screenSize: BasicDimension;
  stateChangeSubject: Subject<NodePathEntry>;
  subject: Subject<TestFlowSnapshot>;
  controller: TestScreenInstanceController;
  screenNodes: ScreenTreeNode[];
  controllerHandlerFactory: (
    inputMonitor: ControlInputMonitor,
  ) => ControlHandlerManager;
}) {
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const resultSubject = React.useMemo(
    () => new Subject<GQLQueryInstance<any, any>>(),
    [],
  );
  React.useEffect(() => {
    const inputMonitor = new ControlInputMonitor(containerRef.current!);
    const manager = controllerHandlerFactory(inputMonitor);
    const subjectSub = subject.subscribe({
      next: (value) => {
        manager
          .raceRequests(
            value.screenNode.screenData.controlTransitions
              .filter(
                (ct) =>
                  ct.predicate === undefined ||
                  ct.predicate(director.getStateHolder()),
              )
              .map((ctt) => ctt.controlRequest),
            value.timeProcessor,
          )
          .subscribe({
            next: (value1) => {
              director.processControl({
                type: 'tree-transition',
                controlOccurrence: value1,
              });
            },
          });
      },
    });

    const director = new TestDirector(treeSequence, subject, {
      enqueueResult(queryInstance: GQLQueryInstance<any, any>) {
        if (persistResults) {
          NewGQL.NO_AUTH_CLIENT.execute(queryInstance);
        }
        resultSubject.next(queryInstance);
      },
    });
    const stateSub = stateChangeSubject.subscribe({
      next: (val) => {
        // director.externStateChange(val);
        director.processControl({
          type: 'intercepting-transition',
          interceptor: () => ({
            treeTransition: { in: [], out: [], screen: val.node },
          }),
        });
      },
    });
    return () => {
      stateSub.unsubscribe();
      subjectSub.unsubscribe();
    };
  }, [
    controllerHandlerFactory,
    persistResults,
    resultSubject,
    screenNodes,
    stateChangeSubject,
    subject,
    treeSequence,
  ]);
  const RootComponent = controller.mainViewController.element;
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div
        ref={containerRef}
        style={{
          ...SizedArea.px(
            screenSize.width + 5,
            screenSize.height + 5,
          ).toSizeModeStyle(),
          border: '3px dashed black',
        }}
      >
        <RootComponent />
      </div>
      <ControlDisplay subject={subject} />
      <ResultView
        resultPreviewTable={resultPreviewTable}
        resultSubject={resultSubject}
      />
    </div>
  );
}
