import { GQLQueryInstance } from '../../GQL';
import { delay } from '../subject/utils';

export interface QueueTiming {
  retryInterval: number;
  shutdownTimeout: number;
}

export class ResultPersisterQueue {
  private currentAmount = 0;
  private totalAmount = 0;
  private listeners = [] as ((current: number, total: number) => void)[];
  private active = true;

  constructor(
    public testFinalizationId: string,
    protected persister: (query: GQLQueryInstance<any, any>) => Promise<any>,
    protected timing: QueueTiming,
    protected shutdownHook?: (
      persister: (query: GQLQueryInstance<any, any>) => Promise<any>,
    ) => Promise<any> | any,
  ) {}

  enqueueResult(result: GQLQueryInstance<any, any>) {
    this.currentAmount = this.currentAmount + 1;
    this.totalAmount = this.totalAmount + 1;
    this.publishState();
    return this.tryPersist(result);
  }

  protected publishState() {
    try {
      this.listeners.forEach((l) => l(this.currentAmount, this.totalAmount));
    } catch (e) {
      console.error('failed to inform persistence listeners', e);
    }
  }

  shutdownMonitor(listener: (current: number, total: number) => void) {
    const shutdownPromise = new Promise<void>((resolve, reject) => {
      this.active = false;
      if (this.currentAmount === 0) {
        if (this.shutdownHook) {
          this.shutdownHook(this.persister);
        }
        resolve();
      }
      this.listeners.push((current, total) => {
        listener(current, total);
        if (current === 0) {
          if (this.shutdownHook) {
            this.shutdownHook(this.persister);
          }
          resolve();
        }
      });
      listener(this.currentAmount, this.totalAmount);
    });
    return Promise.race([delay(this.timing.shutdownTimeout), shutdownPromise]);
  }

  protected async tryPersist(result: GQLQueryInstance<any, any>) {
    let persisted = false;
    while (!persisted && this.active) {
      try {
        await this.persister(result);
        persisted = true;
        this.currentAmount = this.currentAmount - 1;
        this.publishState();
      } catch (e) {
        await delay(this.timing.retryInterval);
      }
    }
    return persisted;
  }
}
