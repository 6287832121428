import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import {
  CreateDocumentFileMutation,
  DocumentFile,
  ListDocumentFilesQuery,
} from '../../API';
import { API, Storage } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import { listDocumentFiles } from '../../graphql/queries';
import { createDocumentFile } from '../../graphql/mutations';
import { v4 } from 'uuid';

export type DocumentUploadRequest = {
  file: File;
  version: string;
  validSince: Date | null;
  path: string[];
  name: string;
  description: string;
  documentId: string | null;
  replaceCurrentVersion: boolean;
};

export type DocumentFileDescriptor = {
  path: string[];
  name: string;
  format: string;
  description: string;
};

type DocumentFileVersion = {
  file: File;
  version: string;
  validSince: Date | null;
};
export type DocumentFileRequest = {
  documentFileDescriptor: DocumentFileDescriptor;
  version: DocumentFileVersion;
};

export const documentFilesApi = createApi({
  reducerPath: 'document-files',
  baseQuery: graphqlRequestBaseQuery({
    url: '/graphql',
  }),
  tagTypes: ['DocumentFile'],
  endpoints: (builder) => ({
    listDocumentFiles: builder.query<DocumentFile[], void>({
      queryFn: async () => {
        const result = await API.graphql<GraphQLQuery<ListDocumentFilesQuery>>({
          query: listDocumentFiles,
        });
        return {
          data: (result.data?.listDocumentFiles?.items ?? []) as DocumentFile[],
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'DocumentFile' as const,
                id,
              })),
              { type: 'DocumentFile', id: 'LIST' },
            ]
          : [{ type: 'DocumentFile', id: 'LIST' }],
    }),
    createDocumentFile: builder.mutation<
      CreateDocumentFileMutation['createDocumentFile'],
      DocumentFileRequest
    >({
      queryFn: async (input) => {
        const s3Result = await Storage.put(
          `files/${v4()}.${input.documentFileDescriptor.format}`,
          input.version.file,
          {
            contentType: input.version.file.type,
          },
        );

        const apiResult = await API.graphql<
          GraphQLQuery<CreateDocumentFileMutation>
        >({
          query: createDocumentFile,
          variables: {
            input: {
              path: input.documentFileDescriptor.path,
              name: input.documentFileDescriptor.name,
              description: input.documentFileDescriptor.description,
              format: input.documentFileDescriptor.format,
              currentVersion: {
                createdAt: new Date().toISOString(),
                version: input.version.version,
                validSince: input.version.validSince?.toISOString() ?? null,
                s3Key: s3Result.key,
              },
              history: [],
            },
          },
        });
        return {
          data: apiResult.data?.createDocumentFile,
        };
      },
      invalidatesTags: [{ type: 'DocumentFile', id: 'LIST' }],
    }),
    uploadDocumentFile: builder.mutation<
      CreateDocumentFileMutation['createDocumentFile'],
      DocumentUploadRequest
    >({
      queryFn: async (input) => {
        const s3Result = await Storage.put(
          `files/${v4()}.${input.file.type}`,
          input.file,
          {
            contentType: input.file.type,
          },
        );

        const apiResult = await API.graphql<
          GraphQLQuery<CreateDocumentFileMutation>
        >({
          query: createDocumentFile,
          variables: {
            input: {
              path: input.path,
              name: input.name,
              description: input.description,
              currentVersion: {
                createdAt: new Date().toISOString(),
                version: input.version,
                validSince: input.validSince?.toISOString() ?? null,
                s3Key: s3Result.key,
              },
              history: [],
            },
          },
        });
        return {
          data: apiResult.data?.createDocumentFile,
        };
      },
    }),
  }),
});

export const {
  useListDocumentFilesQuery,
  useUploadDocumentFileMutation,
  useCreateDocumentFileMutation,
} = documentFilesApi;
