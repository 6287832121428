import React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';

type ColumnDescriptor = { label: string; flex: number };
type ColumnDescriptorMap = Record<string, ColumnDescriptor>;
export type DataProtectionInfoTableDescriptor<T extends ColumnDescriptorMap> = {
  columns: T;
  data: Record<keyof T, React.ReactNode>[];
};

export function DataProtectionInfoTable<T extends ColumnDescriptorMap>({
  tableDescriptor,
}: {
  tableDescriptor: DataProtectionInfoTableDescriptor<T>;
}) {
  return (
    <DataGridPro<Record<keyof T, React.ReactNode> & { id: string }>
      sx={{ minHeight: 400 }}
      columns={Object.entries(tableDescriptor.columns).map(
        ([field, { label, flex }]) => ({
          field,
          headerName: label,
          flex,
          renderCell: (params) => params.value,
        }),
      )}
      rows={tableDescriptor.data.map((row, i) => ({ ...row, id: String(i) }))}
    />
  );
}
