import { DefaultTestTree } from '../../../../subject/testRunner/graph/test-tree';
import { OptionsStereotypes } from '../../../../subject/testRunner/controls/control-stereotypes';
import { DefaultTransitionAction } from '../../../../subject/testRunner/graph-state/graph-action';
import { createCategoryPreviewElement } from '../../views/iat-preview';
import { InstructionsContent } from '../../../../subject/testRunner/screens/basic-views/test-instructions-view';
import React from 'react';
import { IatStructure } from '../structure/iat-structure';
import { IatInstructionsData } from '../view-data/iat-instructions-data';
import { IATModifiers } from './iat-modifiers';
import { createIatBlockPhaseTrees } from './iat-block-tree';
import { MapScreenTreeNode } from '../../../../subject/testRunner/graph/nodes/map-screen-tree-node';
import { MapPhaseTreeNode } from '../../../../subject/testRunner/graph/nodes/map-phase-tree-node';
import { finalScreeNodeTag } from '../../../../subject/testRunner/graph/nodes/tree-node-tag';

export function createIatTree(iatStructure: IatStructure) {
  const root = new MapPhaseTreeNode('iat', null);

  const start = createIatStartPhaseTree(iatStructure, root);

  const blocks = createIatBlockPhaseTrees(iatStructure, root).transitionFrom(
    IATModifiers.default,
    start,
  );

  createIatEndPhaseTree(iatStructure, root).transitionFrom(
    IATModifiers.default,
    blocks,
  );

  return new DefaultTestTree(IATModifiers, root);
}

function createIatStartPhaseTree(
  iatStructure: IatStructure,
  root: MapPhaseTreeNode,
) {
  const start = new MapPhaseTreeNode('start', root)
    .withDisplayName('Introduction')
    .connectParent(IATModifiers.default);

  const CategoryPreviewElement = createCategoryPreviewElement(
    iatStructure.pool,
    iatStructure.accessor.modelData.counterbalancing,
  );

  const welcome = new MapScreenTreeNode(
    'welcome',
    start,
    new IatInstructionsData({
      content: iatStructure.welcomePhase.content.content,
      nextButton: iatStructure.welcomePhase.content.nextButton,
      additionalContent:
        iatStructure.welcomePhase.categoryPreview === 'on-welcome'
          ? CategoryPreviewElement
          : undefined,
    }),
  )
    .connectParent(IATModifiers.default)
    .withDisplayName(`Welcome Screen`)
    .controlRequest(
      OptionsStereotypes.Confirm.UI.defineTransition(undefined, () =>
        DefaultTransitionAction.next(IATModifiers.default),
      ),
    );

  if (iatStructure.welcomePhase.categoryPreview === 'single') {
    // const CategoryPreviewElement = createCategoryPreviewElement(iatStructure.pool);
    new MapScreenTreeNode(
      'category-preview',
      start,
      new IatInstructionsData(
        new InstructionsContent(
          React.createElement(CategoryPreviewElement, {}),
          iatStructure.accessor.modelData.nextLabel ?? 'Next',
        ),
      ),
    )
      .connectParent()
      .withDisplayName(`Category Preview`)
      .controlRequest(
        OptionsStereotypes.Confirm.UI.defineTransition(undefined, () =>
          DefaultTransitionAction.next(IATModifiers.default),
        ),
      )
      .transitionFrom(IATModifiers.default, welcome);
  }
  return start;
}

function createIatEndPhaseTree(
  iatStructure: IatStructure,
  root: MapPhaseTreeNode,
) {
  return new MapScreenTreeNode(
    'end-screen',
    root,
    new IatInstructionsData({
      content: iatStructure.endPhase.content.content,
      finish: true,
      nextButton: iatStructure.endPhase.content.nextButton,
    }),
  )
    .withTag(finalScreeNodeTag)
    .withDisplayName(`End Screen`)
    .controlRequest(
      OptionsStereotypes.Confirm.UI.defineTransition(undefined, () =>
        DefaultTransitionAction.next(IATModifiers.default),
      ),
    )
    .connectParent();
}
