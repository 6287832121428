import {
  AmpLabeledStimuliPoolInput,
  BlockType,
  CategoryVariant,
  CreateAmpTestInput,
  MediaItemSnapshotScope,
  OptionsPosition,
  TestStatus,
  TextAlignment,
} from '../../../../API';
import {
  createDefaultFontStyle,
  createDefaultStyle,
  createEmptyKeyboardSettings,
  createEmptyMediaItemSnapshot,
} from '../../testAdmin/testEditor/initialEditorStates';
import { Amp } from '../types';

const createEmptyCategories = (): AmpLabeledStimuliPoolInput[] => [
  {
    label: '',
    practiceStimuliPool: [],
    testStimuliPool: [],
  },
];

export const ampTemplates: Amp[] = [
  {
    id: '4ec6a680-ddef-47b2-9e33-6e800d518eeb',
    name: 'AMP Standard Template',
    description:
      'The AMP Standard Template uses the categories Arab and White as primes and Chinese pictographs as neutral targets.',
    language: null,
    instructions: {
      desktop:
        '<p style="text-align:center;"><br></p>\n<p style="text-align:center;"><span style="font-size: 24px;font-family: Mulish;"><strong>We would like to ask you to take part in a cognitive test.</strong></span></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Your task is to indicate whether various Chinese characters appear <strong>unpleasant</strong> or <strong>pleasant</strong> to you.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">The cognitive test consists of a short practice block and a longer test block. </span></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">To begin, please click <strong>Start</strong>.</span></p>\n<p></p>\n',
      mobile:
        '<p style="text-align:center;"><span style="font-family: Mulish;"><br> </span><span style="color: rgba(30,95,70,0.87);font-size: 14px;font-family: Mulish;"><strong>We would like to ask you to take part in a cognitive test.</strong></span></p>\n<p style="text-align:center;"><span style="color: rgba(30,95,70,0.87);font-size: 14px;font-family: Mulish;">Your task is to indicate whether various Chinese characters appear <strong>unpleasant</strong> or <strong>pleasant</strong> to you.</span><span style="font-family: Mulish;"><br></span><span style="color: rgba(30,95,70,0.87);font-size: 14px;font-family: Mulish;">The cognitive test consists of a short practice block and a longer test block. </span></p>\n<p style="text-align:center;"><span style="color: rgba(30,95,70,0.87);font-size: 14px;font-family: Mulish;">To begin, please click <strong>Start</strong>.</span><span style="font-family: Mulish;"><br></span></p>\n',
      fontStyle: null,
      __typename: 'StyleableTextTemplate',
    },
    keyboard: {
      left: 'KeyA',
      right: 'KeyL',
      confirm: 'Space',
      __typename: 'KeyboardSettings',
    },
    counterbalancing: false,
    probabilityWithoutReplacement: true,
    interTrialInterval: 500,
    style: {
      maxWidth: 85,
      backgroundColour: 'white',
      stimulusTextStyle: {
        fontSize: 14,
        color: 'black',
        alignment: 'CENTER',
        fontFace: 'ARIAL',
        __typename: 'FontStyle',
      },
      mobileStimulusTextStyle: null,
      optionsPosition: 'MIDDLE',
      optionsPositionMobile: 'MIDDLE',
      __typename: 'TestStyle',
    },
    status: 'DRAFT',
    endScreen: {
      desktop:
        '<p></p>\n<p style="text-align:center;"><span style="color: rgba(30,95,70,0.87);font-size: 24px;font-family: Mulish;"><strong>Thank you! </strong></span></p>\n<p></p>\n<p style="text-align:center;"><span style="color: rgba(30,95,70,0.87);font-size: 18px;font-family: Mulish;">The cognitive test is now complete.</span></p>\n<p></p>\n<p style="text-align:center;"><span style="color: rgba(30,95,70,0.87);font-size: 18px;font-family: Mulish;">Please click <strong>Next</strong>.</span></p>\n',
      mobile:
        '<p style="text-align:center;"><span style="font-size: 14px;font-family: Mulish;"><strong>Thank you!</strong></span></p>\n<p style="text-align:center;"><span style="color: rgba(30,95,70,0.87);background-color: white;font-size: 14px;font-family: Mulish;">The cognitive test is now complete. </span><span style="font-family: Mulish;"><br></span><span style="color: rgba(30,95,70,0.87);background-color: white;font-size: 14px;font-family: Mulish;">Please click <strong>Next</strong>. </span><span style="font-family: Mulish;"> </span></p>\n',
      fontStyle: null,
      __typename: 'StyleableTextTemplate',
    },
    attributeCategories: {
      fontStyle: {
        fontSize: 40,
        color: '#1e5f46',
        alignment: 'CENTER',
        fontFace: 'ARIAL',
        __typename: 'FontStyle',
      },
      categoryA: {
        id: 'e9e9c578-20a9-45f4-ab3d-7cc2514ad091',
        tags: [],
        scope: 'PRIVATE',
        text: 'unpleasant',
        image: null,
        originalFileName: null,
        __typename: 'MediaItemSnapshot',
      },
      categoryB: {
        id: '5b177b99-6d9e-470d-a1b0-bf723b8bc64c',
        tags: [],
        scope: 'PRIVATE',
        text: 'pleasant',
        image: null,
        originalFileName: null,
        __typename: 'MediaItemSnapshot',
      },
      __typename: 'AmpAttributeCategories',
    },
    fixation: null,
    primes: {
      interval: 75,
      categories: [
        {
          label: 'White',
          practiceStimuliPool: [
            {
              id: 'de38197f-971a-46da-b50f-03c2964a70ac',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/e0254b1c-c5a0-4e52-a6af-d2179936a44b.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/e0254b1c-c5a0-4e52-a6af-d2179936a44b.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_02_03.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '0a9821f2-020e-4e89-8c20-9cf18f0c5053',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/310ddf82-e33e-4bd2-a805-22e5c1ee4287.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/310ddf82-e33e-4bd2-a805-22e5c1ee4287.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_02_06.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '1fe1dc27-d0b7-4c2a-86f2-650a54a3ae51',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 623,
                  height: 800,
                  key: 'fullSize/b6dd36fd-8c21-419e-8bb0-39a3d6c97c8a.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/b6dd36fd-8c21-419e-8bb0-39a3d6c97c8a.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_02_09.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '56e0a0d6-9ff3-4737-8f96-ed8f589f2267',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/2ec6077a-865b-493e-ac89-b511f11ae87f.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/2ec6077a-865b-493e-ac89-b511f11ae87f.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_02_07.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '36e71c60-17d4-48bb-baa9-b6bffdbe078f',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/b030c8a5-d018-4bdb-bdbb-1ff145b8d6cd.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/b030c8a5-d018-4bdb-bdbb-1ff145b8d6cd.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_02_04.jpg',
              __typename: 'MediaItemSnapshot',
            },
          ],
          testStimuliPool: [
            {
              id: 'e709422c-c9e7-454f-b2d1-02d274f92860',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/15442f75-e76f-4ea4-844b-0450563d07e6.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/15442f75-e76f-4ea4-844b-0450563d07e6.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_02_24.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '04d1f47e-f464-4bba-9615-d6a9afa46db4',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/93895440-7845-4cc6-a657-b5450efdf9d7.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/93895440-7845-4cc6-a657-b5450efdf9d7.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_02_19.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'ae02e1e3-2ab1-4ad3-8a54-2f28d3e380ec',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/a5ca2d63-3815-4ebf-96ee-72e14a29a50c.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/a5ca2d63-3815-4ebf-96ee-72e14a29a50c.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_02_01.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'df0064d7-9b5b-4df5-8743-6dd48dadfa89',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 623,
                  height: 800,
                  key: 'fullSize/ebabf30b-1627-4bf1-8af7-7bd00c5e6dd1.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/ebabf30b-1627-4bf1-8af7-7bd00c5e6dd1.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_02_20.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'ee484c23-7a1a-41ca-9b07-00509b09fa1b',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 623,
                  height: 800,
                  key: 'fullSize/8488d79b-293e-4516-bafb-daa685b5b365.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/8488d79b-293e-4516-bafb-daa685b5b365.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_02_14.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '890837c5-5244-4e8e-b4b4-aa792cecbf3a',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/54a2dc6b-34ac-46e4-a54a-105f8f8989fe.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/54a2dc6b-34ac-46e4-a54a-105f8f8989fe.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_02_10.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '1e51d43c-c7c9-4fe7-bfae-1e5a1f3ddb27',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/1da8b434-b032-4c7a-ba46-8d8c835c0455.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/1da8b434-b032-4c7a-ba46-8d8c835c0455.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_02_11.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '16272730-0600-49e9-a278-328c16350a91',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 621,
                  height: 800,
                  key: 'fullSize/3a210908-8c10-4538-a766-dd34059fd242.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/3a210908-8c10-4538-a766-dd34059fd242.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_02_21.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '162ddc57-137c-4b7e-86e6-3e9e30495659',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/a2727a13-0f43-4e3e-944d-c4ffd92d5184.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/a2727a13-0f43-4e3e-944d-c4ffd92d5184.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_02_18.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '8394a289-aef4-40aa-9256-ad0d890f9af1',
              tags: [
                {
                  id: '1b24fa6f-22a6-4ec5-84d2-0e632b6fc98c',
                  name: 'Group 2',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 623,
                  height: 800,
                  key: 'fullSize/c06e08b3-93dc-4e68-8221-d7bde540acf1.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/c06e08b3-93dc-4e68-8221-d7bde540acf1.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_02_05.jpg',
              __typename: 'MediaItemSnapshot',
            },
          ],
          __typename: 'AmpLabeledStimuliPool',
        },
        {
          label: 'Arab',
          practiceStimuliPool: [
            {
              id: 'ca948182-4863-4e14-9867-860db6ec39e8',
              tags: [
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/5051b2ce-e18b-4c23-ab9e-945d91e835c4.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/5051b2ce-e18b-4c23-ab9e-945d91e835c4.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_01_01.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '239d914b-4a26-487d-8a63-0a9b3c863fea',
              tags: [
                {
                  id: '10de3796-033d-453e-b483-71b73c9987d5',
                  name: 'Arab',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '6eeedc53-d529-4f25-9c4c-48330bcaf414',
                  name: 'Turkish',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/dd1e5547-89b7-4d54-b59b-cd500fe4aff4.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/dd1e5547-89b7-4d54-b59b-cd500fe4aff4.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_01_05.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '845469f2-f1c6-4a36-b1a4-087c37ab1b89',
              tags: [
                {
                  id: '10de3796-033d-453e-b483-71b73c9987d5',
                  name: 'Arab',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '6eeedc53-d529-4f25-9c4c-48330bcaf414',
                  name: 'Turkish',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 623,
                  height: 800,
                  key: 'fullSize/7ad07274-d743-4e0a-b060-d86f4a1f27ad.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/7ad07274-d743-4e0a-b060-d86f4a1f27ad.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_01_02.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '28261ad8-92fa-4ce1-b18b-8c9351e40b7c',
              tags: [
                {
                  id: '10de3796-033d-453e-b483-71b73c9987d5',
                  name: 'Arab',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '6eeedc53-d529-4f25-9c4c-48330bcaf414',
                  name: 'Turkish',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 623,
                  height: 800,
                  key: 'fullSize/953b9a9f-4443-4bac-afd5-849ef144658e.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/953b9a9f-4443-4bac-afd5-849ef144658e.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_01_04.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'ae379a0d-e4eb-4ae1-ab06-f4c1e4e680ba',
              tags: [
                {
                  id: '10de3796-033d-453e-b483-71b73c9987d5',
                  name: 'Arab',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '2069f530-853b-452c-9396-3efd20ff5df7',
                  name: 'Set2',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '5a024ad1-36c7-4014-8822-3a8f65d3418f',
                  name: 'Group 1',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '6eeedc53-d529-4f25-9c4c-48330bcaf414',
                  name: 'Turkish',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 623,
                  height: 800,
                  key: 'fullSize/7dbd6734-e11b-429d-bbb6-f3b92b025e19.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/7dbd6734-e11b-429d-bbb6-f3b92b025e19.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_01_03.jpg',
              __typename: 'MediaItemSnapshot',
            },
          ],
          testStimuliPool: [
            {
              id: '59e396bf-90e3-4b3b-ab2e-6fae1adf607d',
              tags: [
                {
                  id: '10de3796-033d-453e-b483-71b73c9987d5',
                  name: 'Arab',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '6eeedc53-d529-4f25-9c4c-48330bcaf414',
                  name: 'Turkish',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/1c677154-54c4-4455-903d-7783dd09f856.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/1c677154-54c4-4455-903d-7783dd09f856.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_01_17.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'f1795356-1bd8-49df-8f9e-f074bdd24913',
              tags: [
                {
                  id: '10de3796-033d-453e-b483-71b73c9987d5',
                  name: 'Arab',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '6eeedc53-d529-4f25-9c4c-48330bcaf414',
                  name: 'Turkish',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/8a83115e-da51-4987-848e-386b1aa1c237.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/8a83115e-da51-4987-848e-386b1aa1c237.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_01_09.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '4494cfb7-a4a7-4dce-8b02-e77e1b7b03ce',
              tags: [
                {
                  id: '10de3796-033d-453e-b483-71b73c9987d5',
                  name: 'Arab',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '6eeedc53-d529-4f25-9c4c-48330bcaf414',
                  name: 'Turkish',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/14d472a2-d7c0-4ca0-a7f7-e646d76b0ded.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/14d472a2-d7c0-4ca0-a7f7-e646d76b0ded.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_01_08.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '2eb4158e-0dc8-4ff9-9625-9132007c3e9a',
              tags: [
                {
                  id: '10de3796-033d-453e-b483-71b73c9987d5',
                  name: 'Arab',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '6eeedc53-d529-4f25-9c4c-48330bcaf414',
                  name: 'Turkish',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/f5c7a5f6-bf77-4789-aedc-04eab6dd5b25.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/f5c7a5f6-bf77-4789-aedc-04eab6dd5b25.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_01_19.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '5055a286-aaaa-4a20-b24c-1ec02866d3f4',
              tags: [
                {
                  id: '10de3796-033d-453e-b483-71b73c9987d5',
                  name: 'Arab',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '6eeedc53-d529-4f25-9c4c-48330bcaf414',
                  name: 'Turkish',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 623,
                  height: 800,
                  key: 'fullSize/d3d77039-bfb2-4072-877d-0ecb846a0eac.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/d3d77039-bfb2-4072-877d-0ecb846a0eac.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_01_21.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '7472f23e-ee4f-4576-b2a0-b6efae47d4b2',
              tags: [
                {
                  id: '10de3796-033d-453e-b483-71b73c9987d5',
                  name: 'Arab',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '6eeedc53-d529-4f25-9c4c-48330bcaf414',
                  name: 'Turkish',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/96d0980f-ee75-4e29-99f0-83881688f592.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/96d0980f-ee75-4e29-99f0-83881688f592.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_01_20.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'cdc58a07-4a8e-44e2-b1c4-1e4d09789a8e',
              tags: [
                {
                  id: '10de3796-033d-453e-b483-71b73c9987d5',
                  name: 'Arab',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '6eeedc53-d529-4f25-9c4c-48330bcaf414',
                  name: 'Turkish',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 623,
                  height: 800,
                  key: 'fullSize/0f5649fa-95fd-42ee-b3ab-20d408e55a0d.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/0f5649fa-95fd-42ee-b3ab-20d408e55a0d.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_01_10.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '62fc16b8-3927-4f0c-adb9-28d7de39e279',
              tags: [
                {
                  id: '10de3796-033d-453e-b483-71b73c9987d5',
                  name: 'Arab',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '6eeedc53-d529-4f25-9c4c-48330bcaf414',
                  name: 'Turkish',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/f8221000-8e22-432b-a0da-bd3098e5bd02.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/f8221000-8e22-432b-a0da-bd3098e5bd02.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_01_16.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'e552a4de-2ff0-440b-aed9-2000faba01d4',
              tags: [
                {
                  id: '10de3796-033d-453e-b483-71b73c9987d5',
                  name: 'Arab',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '6eeedc53-d529-4f25-9c4c-48330bcaf414',
                  name: 'Turkish',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/527da73d-56a9-43af-b8ff-fd8157a0ae3b.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/527da73d-56a9-43af-b8ff-fd8157a0ae3b.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_01_18.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'ff5bbf5e-4441-4ec2-b403-df6d26351845',
              tags: [
                {
                  id: '10de3796-033d-453e-b483-71b73c9987d5',
                  name: 'Arab',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '502ee4e1-ed1b-4930-a8aa-a53e9682ae32',
                  name: 'Set1',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '606ad625-4e7c-4e63-bd19-a643248642fc',
                  name: 'Male',
                  __typename: 'TagSnapShot',
                },
                {
                  id: '6eeedc53-d529-4f25-9c4c-48330bcaf414',
                  name: 'Turkish',
                  __typename: 'TagSnapShot',
                },
                {
                  id: 'f267255b-145a-43bb-ae94-38b6bb3841b2',
                  name: 'Face',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 800,
                  key: 'fullSize/d7f2b27f-5b84-4137-9c4c-fc189f4634f3.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 62,
                  height: 80,
                  key: 'thumbNail/d7f2b27f-5b84-4137-9c4c-fc189f4634f3.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'dpd_fa_ma_01_12.jpg',
              __typename: 'MediaItemSnapshot',
            },
          ],
          __typename: 'AmpLabeledStimuliPool',
        },
      ],
      __typename: 'TimedAmpCategories',
    },
    targets: {
      interval: 100,
      categories: [
        {
          label: 'Chinese pictographs',
          practiceStimuliPool: [
            {
              id: 'd97c9762-14d4-481f-846e-5e6e95f950dd',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/3214a794-ce0b-4663-960c-6c20a159ab5b.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/3214a794-ce0b-4663-960c-6c20a159ab5b.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic165.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'd606ef70-d2fe-45b7-9bdd-41d17d56cefd',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/da2cb653-9d9c-4de0-b7e1-56799660e6f9.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/da2cb653-9d9c-4de0-b7e1-56799660e6f9.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic107.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'cb9f5816-5519-48dc-af5a-332d61832b8c',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/322a5560-ffcc-4bcd-9bb7-31bec6b1bde0.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/322a5560-ffcc-4bcd-9bb7-31bec6b1bde0.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic161.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'd357cfb5-31cc-4226-90a0-189a80894d3d',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/e8cd78da-485e-4813-9b2a-7abbc1dec589.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/e8cd78da-485e-4813-9b2a-7abbc1dec589.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic142.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'dfe81fad-255a-485e-a1c9-84a05cf711ff',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/70f290e3-f90a-415a-8373-187a89cc403e.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/70f290e3-f90a-415a-8373-187a89cc403e.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic160.jpg',
              __typename: 'MediaItemSnapshot',
            },
          ],
          testStimuliPool: [
            {
              id: '91539a0e-d2e5-4bb6-a22e-f801a19d5a10',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/c93da18a-3e28-4265-a52e-339b201e60dd.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/c93da18a-3e28-4265-a52e-339b201e60dd.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic186.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'f8dc9b4d-3c60-4cb7-a6fb-7e9acbab49ad',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/9bd1ecd8-4756-42c4-bb1b-68009470174a.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/9bd1ecd8-4756-42c4-bb1b-68009470174a.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic179.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '9c520b9f-e11d-4ca1-838e-78043e939313',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/a982a7a5-e8a3-4f56-89a3-844f5764e189.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/a982a7a5-e8a3-4f56-89a3-844f5764e189.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic182.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'a856bd1c-9d23-4095-a551-dc88b9f90b49',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/3d65f1ee-ca81-4d1c-955d-76f3e53dfa01.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/3d65f1ee-ca81-4d1c-955d-76f3e53dfa01.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic108.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'd606ef70-d2fe-45b7-9bdd-41d17d56cefd',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/da2cb653-9d9c-4de0-b7e1-56799660e6f9.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/da2cb653-9d9c-4de0-b7e1-56799660e6f9.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic107.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '5479df20-6294-46b4-8ed4-2fc5eb279089',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/a7e17e54-d094-4a35-867c-9b2da16bbfc3.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/a7e17e54-d094-4a35-867c-9b2da16bbfc3.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic172.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '922b0043-e7bb-4b01-bb57-bfef4b5d4019',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/8ea1c5b2-bd8b-440a-872d-93bf84d06563.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/8ea1c5b2-bd8b-440a-872d-93bf84d06563.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic162.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'c8e4023d-00f7-412c-aa6a-021dff80c4c3',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/500cca21-fe42-44d1-bb30-f030cf7b6d3b.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/500cca21-fe42-44d1-bb30-f030cf7b6d3b.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic121.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '24b5a57e-4e46-4075-b785-5c77a57e1f8a',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/3c797eca-ab3f-4846-9f1d-f0fb03d6b9ae.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/3c797eca-ab3f-4846-9f1d-f0fb03d6b9ae.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic147.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '2cd15122-264c-4598-89fd-7a07da7043ff',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/44ff4d24-57ee-48dc-86cd-e48fc2da7fbd.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/44ff4d24-57ee-48dc-86cd-e48fc2da7fbd.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic129.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '184082df-39be-4c47-acfd-783afd424e6b',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/18f4cd46-e2dd-46df-a9f8-878200dc3859.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/18f4cd46-e2dd-46df-a9f8-878200dc3859.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic123.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '905217d8-758d-4ba4-b819-1f1ed95d4921',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/b5df5843-58e7-4bcb-bb00-d29cbd02184b.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/b5df5843-58e7-4bcb-bb00-d29cbd02184b.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic164.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '99945b87-4fdb-4a1c-87dc-dbb5d9342b5c',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/a2f200b6-9a78-478f-8712-43e4cbb9fd30.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/a2f200b6-9a78-478f-8712-43e4cbb9fd30.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic117.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'f636b51a-5f3c-4371-ac08-5746cebc765b',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/8781b171-19b2-4200-a929-a7c40cc472da.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/8781b171-19b2-4200-a929-a7c40cc472da.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic118.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '810196e8-d59a-4f5b-ae43-05550841fc90',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/52df7348-edb6-4548-b0ee-be7712e4a1a8.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/52df7348-edb6-4548-b0ee-be7712e4a1a8.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic112.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '574e41e1-17c0-46cc-8b59-9b66be56798d',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/6dfec9aa-64c3-4f35-9149-bd051649d0be.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/6dfec9aa-64c3-4f35-9149-bd051649d0be.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic130.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '1bc1030d-b569-40c0-87c4-958d031c742c',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/7dc8e30d-1acd-46d7-bc7b-85476edda1d4.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/7dc8e30d-1acd-46d7-bc7b-85476edda1d4.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic178.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'a975564e-690d-4cf3-8d47-ccfe62d234d4',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/3865178c-6f4d-4c6e-b1d3-f660cac2666f.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/3865178c-6f4d-4c6e-b1d3-f660cac2666f.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic9.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'c86f99fb-5219-4545-90b7-ac33f881cff2',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/11202545-00f8-42da-83f6-ed4f32f9ae65.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/11202545-00f8-42da-83f6-ed4f32f9ae65.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic6.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '2fbe957b-efd3-498c-a5bf-5950eae6acfe',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/01066e00-24d8-40ee-aafc-fda2e2196e91.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/01066e00-24d8-40ee-aafc-fda2e2196e91.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic2.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '0b087be4-8e98-4296-9c28-dd99bb58d36f',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/1685f5a9-e0bc-4b2c-9ddb-d1582970aed4.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/1685f5a9-e0bc-4b2c-9ddb-d1582970aed4.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic12.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '4c187168-75d7-4fa6-bda5-1fd5c565c1d8',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/de3e773b-cd5c-4940-b7e2-28f3e517b9d7.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/de3e773b-cd5c-4940-b7e2-28f3e517b9d7.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic151.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '047ec44f-9d0e-417a-8a2c-8a307fc7e73c',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/ccefd8c6-35e6-461e-aab2-657c33de1284.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/ccefd8c6-35e6-461e-aab2-657c33de1284.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic113.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '21c6cbca-2838-4c67-8196-2aeda462bbd2',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/cedde918-1d28-4240-96a9-addb26639401.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/cedde918-1d28-4240-96a9-addb26639401.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic124.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'c29e882b-d90d-4e00-a93f-e74c0e81d7e0',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/c2421cde-3ff6-4b65-81e6-809776dcd786.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/c2421cde-3ff6-4b65-81e6-809776dcd786.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic158.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '2cddc9d0-32ac-4fb9-89ab-46a8df871abf',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/af9ddfbb-c08d-42e9-810b-9fdf68a39dc1.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/af9ddfbb-c08d-42e9-810b-9fdf68a39dc1.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic180.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'ae2f2f25-d3d9-4242-b73b-71fb42e86071',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/5869ad01-0883-4127-8a97-06e320ba5567.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/5869ad01-0883-4127-8a97-06e320ba5567.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic132.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '9fe562ec-0362-478d-a64f-11f6d1d52013',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/7a5ea833-b763-49c3-bdc9-c09f9636bc4a.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/7a5ea833-b763-49c3-bdc9-c09f9636bc4a.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic137.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'cc5fb155-c7ea-4e05-9d70-077f24e802b3',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/81e94529-b149-47b6-8884-ba1c54a456c5.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/81e94529-b149-47b6-8884-ba1c54a456c5.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic126.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'fa73e1fd-2247-4b79-8bb9-8f765aa05be0',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/7e18d183-0389-4bb6-b952-bde71355fda0.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/7e18d183-0389-4bb6-b952-bde71355fda0.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic20.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'a24cf38f-e814-4301-b5fc-088cf521569d',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/08afecd1-35f4-41d5-a2b6-4dcab8a85a8c.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/08afecd1-35f4-41d5-a2b6-4dcab8a85a8c.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic176.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '1f0660c5-b0d4-4cb2-b855-56145559f113',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/5e819a09-ca61-4322-b2b2-58f424336963.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/5e819a09-ca61-4322-b2b2-58f424336963.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic184.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'ff2443eb-ecef-41da-bb79-344b41860614',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/be1db74a-2e5f-4d41-b10f-fef67980de95.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/be1db74a-2e5f-4d41-b10f-fef67980de95.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic139.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '940ec215-147d-4cbc-be98-e8b4494e9b75',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/a0531658-9050-450f-b2b6-f0d7a21923f8.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/a0531658-9050-450f-b2b6-f0d7a21923f8.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic152.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '3144283d-a5c8-424f-8960-5e6ed9972287',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/3b8e6df1-39f7-459c-bda4-10ccd1555a1c.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/3b8e6df1-39f7-459c-bda4-10ccd1555a1c.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic114.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '9f1b522d-d625-48c8-ad0b-033cc3b98e50',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/c65e99cc-c7be-48c8-8a00-fa9b2abe7395.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/c65e99cc-c7be-48c8-8a00-fa9b2abe7395.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic171.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '91baf37c-bf61-4251-ad62-7612c877c588',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/8f4c0773-fd97-41ed-893d-135aa88c7165.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/8f4c0773-fd97-41ed-893d-135aa88c7165.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic163.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '512dfd13-cc06-49f5-b088-66cd0d23a75c',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/13a5910d-ba11-48d9-8882-e1babfabb94e.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/13a5910d-ba11-48d9-8882-e1babfabb94e.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic177.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'e92aa2ea-81ec-4238-9e0a-e54849ab13e7',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/42313016-7b82-47be-b64f-15cdad93471e.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/42313016-7b82-47be-b64f-15cdad93471e.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic168.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '4e1d9168-65f3-4196-9ba2-b01ef8cf5ff2',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/3218b7ac-91bc-4a17-855f-b52f8b433244.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/3218b7ac-91bc-4a17-855f-b52f8b433244.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic133.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'b35eb9da-77a3-4491-9bea-440744d7298c',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/1e63376b-d268-4a83-bb5f-83f8f5aa997a.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/1e63376b-d268-4a83-bb5f-83f8f5aa997a.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic110.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '2369184d-bbd7-4159-bb1b-dbff63e83db0',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/2c334a28-c8af-4e18-ae38-b9f3697727d7.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/2c334a28-c8af-4e18-ae38-b9f3697727d7.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic116.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '48610940-5d0b-4a24-acb2-13b6b610d575',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/b3e56d92-b575-4f24-b567-f687f6fc014a.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/b3e56d92-b575-4f24-b567-f687f6fc014a.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic101.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '82184ce1-8a09-49a1-bc03-3101ebebc369',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/5fa00036-f02f-472e-97ae-6f8a34d04076.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/5fa00036-f02f-472e-97ae-6f8a34d04076.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic104.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '5fcd4358-a0f7-4f39-9331-2291f7a46399',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/285ce826-e1f1-4678-b768-d3efd0829878.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/285ce826-e1f1-4678-b768-d3efd0829878.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic157.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'dc758e5f-1e70-4bf5-833d-e47acfacb4a8',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/7b9231fe-8521-4500-804c-f0fd5f32c5bb.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/7b9231fe-8521-4500-804c-f0fd5f32c5bb.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic145.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '4a0e2780-5844-4b34-8555-a4e31ddc8c13',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/d40efc4c-0349-4166-aba6-525a108118f3.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/d40efc4c-0349-4166-aba6-525a108118f3.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic136.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '6856ff94-dcfa-4347-b804-32b6fd8298f6',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/384c7732-d7b6-441c-a4a4-daef572ebf8d.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/384c7732-d7b6-441c-a4a4-daef572ebf8d.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic183.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '82a2992d-0ca1-4ccc-addd-e9329132208c',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/8c9fed28-c980-4516-962f-ab75d0eb9f08.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/8c9fed28-c980-4516-962f-ab75d0eb9f08.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic111.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '0c94fa52-dd58-4c16-9084-e0e50afc9664',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/567c0fc8-d31b-440d-b3bb-85d3d19b45ea.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/567c0fc8-d31b-440d-b3bb-85d3d19b45ea.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic11.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'a9959a99-a8ca-43a4-ba48-6ff75594f286',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/7ee85f44-f7c0-424b-9c71-9aa39f7fe5c8.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/7ee85f44-f7c0-424b-9c71-9aa39f7fe5c8.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic149.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '1665b920-7884-47d5-8477-ed8995a96521',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/0da7130b-b51f-4bac-9dfc-79f6bb2531be.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/0da7130b-b51f-4bac-9dfc-79f6bb2531be.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic10.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '56129d94-3358-401b-b3eb-beda7e324704',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/9c55fbe6-2831-498d-90d7-6fe2d900760e.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/9c55fbe6-2831-498d-90d7-6fe2d900760e.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic141.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '028a9b2e-bf32-47ee-b1cf-13dab7cc7d74',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/d744d8ac-fc5f-4991-ba9e-b77ae17f689a.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/d744d8ac-fc5f-4991-ba9e-b77ae17f689a.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic128.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '0a0e54d0-15b4-410b-9a03-3b92d321e8c3',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/a7dffd0e-75c7-4f2e-88ca-ecbcdd1150d7.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/a7dffd0e-75c7-4f2e-88ca-ecbcdd1150d7.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic167.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'b373691b-c232-4178-8007-81a0bd8f123f',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/b062cfae-dc99-4a18-9ad5-7bb55154171a.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/b062cfae-dc99-4a18-9ad5-7bb55154171a.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic185.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'cd3b5fca-d07a-413c-98c0-33443c97b6a4',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/6ff5b02d-0a16-479a-94db-b253a0da5dc3.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/6ff5b02d-0a16-479a-94db-b253a0da5dc3.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic5.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '9452a0c0-d143-4dba-9ea9-09f52193d91f',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/16daf18f-563a-4452-b9c0-439ac63ce466.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/16daf18f-563a-4452-b9c0-439ac63ce466.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic109.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '7f55ccfd-813f-4689-87c6-c51b940accbe',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/757630a3-2cfa-4e80-aeed-ee17583bebb8.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/757630a3-2cfa-4e80-aeed-ee17583bebb8.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic159.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '457f660d-1c7d-4732-8b58-6800578ec1ad',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/7295fde8-cef4-4121-bf59-cd11c4c397d0.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/7295fde8-cef4-4121-bf59-cd11c4c397d0.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic153.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '16d6f227-4339-487e-bb44-9dbd5f07fbfa',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/dcdd012d-2408-41ad-a899-3d399e9ef6d9.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/dcdd012d-2408-41ad-a899-3d399e9ef6d9.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic170.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'ff4bbb73-d448-43e2-a31f-505c214cb156',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/eeba06bf-1e92-4ac6-8e28-e86243479bca.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/eeba06bf-1e92-4ac6-8e28-e86243479bca.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic14.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '898c8702-aa56-4e1d-9005-0a4f22ae9bc8',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/35e6d4ef-e549-4aa8-92cc-5b34d5f52ddb.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/35e6d4ef-e549-4aa8-92cc-5b34d5f52ddb.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic174.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'd97c9762-14d4-481f-846e-5e6e95f950dd',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/3214a794-ce0b-4663-960c-6c20a159ab5b.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/3214a794-ce0b-4663-960c-6c20a159ab5b.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic165.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'e945ba30-9269-461a-b9cf-4816723995c2',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/77ca5722-3d15-40db-93b1-c87d98e38a8e.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/77ca5722-3d15-40db-93b1-c87d98e38a8e.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic146.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '0b37b21a-f49a-4c9b-841e-cdf91591ad29',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/dc323348-e414-4292-bcb9-8ff02920b685.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/dc323348-e414-4292-bcb9-8ff02920b685.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic135.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'db3ad3c8-60bb-44c7-8546-ecdf4820605f',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/b08396c4-9890-42cf-b696-146ce3a64206.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/b08396c4-9890-42cf-b696-146ce3a64206.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic7.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '0d78c732-49f8-4738-afc7-b100e910cc85',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/f4c348fd-4638-4258-a761-cd3cbacb87b4.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/f4c348fd-4638-4258-a761-cd3cbacb87b4.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic105.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '274650a7-11d3-471c-9c52-e7fe0709a228',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/93dea3a4-8f46-4164-b787-ceff8527c5d2.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/93dea3a4-8f46-4164-b787-ceff8527c5d2.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic155.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '27bee7df-e6e1-4108-a966-088ca675d954',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/010ed1af-1988-4aff-a0a0-b570428d4370.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/010ed1af-1988-4aff-a0a0-b570428d4370.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic13.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'd1d059a9-b0b2-42d8-a7ce-8b9a6d67ea8f',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/76102771-8ea6-4bb3-b4a0-c3f16b596b6e.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/76102771-8ea6-4bb3-b4a0-c3f16b596b6e.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic156.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '65c36fd2-e674-4fa5-b9e1-c154ec3faa06',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/21c76f85-7385-4f17-98be-e3c865350cee.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/21c76f85-7385-4f17-98be-e3c865350cee.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic175.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'd147c203-3656-4572-a7ff-4f769b56444d',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/6781f006-d91b-4794-ba76-7aeca94328ca.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/6781f006-d91b-4794-ba76-7aeca94328ca.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic120.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '1d4e0fc2-a3e6-4cf2-8a3f-9fd54e46cd62',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/88a270ed-53ba-4c00-bd97-fec288865969.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/88a270ed-53ba-4c00-bd97-fec288865969.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic189.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'bd070341-d377-4d2e-941e-899bea0b9254',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/0f89d312-ccea-4373-8f66-26f460f3685f.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/0f89d312-ccea-4373-8f66-26f460f3685f.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic131.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '7d177227-a6f7-41fd-9863-a889e6371c7d',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/bae76dd8-2457-4780-86c7-a01dec87be72.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/bae76dd8-2457-4780-86c7-a01dec87be72.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic4.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '41e5e2c5-b11f-40f3-8c26-c6755ec68bef',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/17b26328-bd41-4d61-b407-7c88217a8f8c.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/17b26328-bd41-4d61-b407-7c88217a8f8c.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic102.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '5a932a14-4ea1-413e-872e-aaf6a4a2ee9b',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/904bdee7-0788-4011-b39d-0729d1916801.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/904bdee7-0788-4011-b39d-0729d1916801.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic154.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '1437f0e5-4c7e-4a87-87a1-382b0facaf0d',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/54ac9bf7-131a-4b40-b059-70647b6867fc.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/54ac9bf7-131a-4b40-b059-70647b6867fc.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic122.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'e8ccbe4f-93f4-4ddc-8d6b-f7d51bc2db95',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/70ab80b6-04cc-42f8-be20-63c48c14d388.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/70ab80b6-04cc-42f8-be20-63c48c14d388.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic190.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'e0caff94-c470-44f8-b4e8-6fe14ee1811c',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/5ba1489b-0a15-4177-bd4c-5d51bfeead3d.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/5ba1489b-0a15-4177-bd4c-5d51bfeead3d.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic150.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '3c3ffdb4-4b32-4e5f-86ef-799186ae6f27',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/cc8dc917-0b0e-4a0e-a6a5-54cf89510fd9.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/cc8dc917-0b0e-4a0e-a6a5-54cf89510fd9.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic106.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '3614a858-3f87-4c8b-abc3-4ee8eedb00f3',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/79fdfeff-4172-429d-a977-5ae383005529.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/79fdfeff-4172-429d-a977-5ae383005529.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic143.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '3966d515-dca8-4329-b01b-b030e368d918',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/6014c7d2-2859-42ba-9fd7-e08e736cd2fd.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/6014c7d2-2859-42ba-9fd7-e08e736cd2fd.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic134.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'e42b921e-544d-4a6f-adf2-ccd191c8de66',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/990b1b69-98f4-4c91-96b3-cbc2197d48c4.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/990b1b69-98f4-4c91-96b3-cbc2197d48c4.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic15.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '6d989285-9d56-4cb0-bcb2-6ee0e7e513b3',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/81416eed-1aba-4b08-bccf-6073bc39f501.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/81416eed-1aba-4b08-bccf-6073bc39f501.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic19.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '527c4f20-3f4f-4add-8549-55bf01e9277d',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/65a7b5bd-26fa-4db4-86e2-7bc61c5a572a.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/65a7b5bd-26fa-4db4-86e2-7bc61c5a572a.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic8.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'c44ce4ca-cf00-4b74-8f63-ee307b9ac1a3',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/0da35952-bf3e-4b3b-a154-2033c9095518.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/0da35952-bf3e-4b3b-a154-2033c9095518.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic148.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'ca2d962e-3605-48f4-b5f9-d007f062b67c',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/638f392b-e38f-47da-b655-6755d9e0e477.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/638f392b-e38f-47da-b655-6755d9e0e477.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic127.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'e881e667-273d-4094-b0de-d96bbba95912',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/04f8127b-d246-4bb3-a085-c06ed4fd2902.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/04f8127b-d246-4bb3-a085-c06ed4fd2902.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic166.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '15cd5d88-4d21-449c-8649-a3d604cd2b7a',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/26332fd0-32c9-43a3-a0f7-36955ca20837.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/26332fd0-32c9-43a3-a0f7-36955ca20837.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic115.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'ea0999f5-7648-4fe7-adba-e2e039ef37d4',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/6302e2e7-8f5a-4fe4-8fe8-62fd347e726c.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/6302e2e7-8f5a-4fe4-8fe8-62fd347e726c.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic191.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '90380779-67f6-45a5-9f64-2d78a73ae9e2',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/34e8f8d5-20b1-47e9-a7d0-826741606715.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/34e8f8d5-20b1-47e9-a7d0-826741606715.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic169.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '80081464-88cf-4c65-a29c-42e275e0c4e6',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/d2f53902-7e20-4f42-b6c0-0053c683b4c2.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/d2f53902-7e20-4f42-b6c0-0053c683b4c2.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic188.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'fcfbb071-36e5-4760-a453-176433fab15c',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/cb5a4a59-7ca1-454d-a494-850a2e384de5.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/cb5a4a59-7ca1-454d-a494-850a2e384de5.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic1.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'b4560f37-32f4-46eb-bc92-0f88b08af54e',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/168bd7d8-47a2-4e57-bb90-f77c8b0162f1.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/168bd7d8-47a2-4e57-bb90-f77c8b0162f1.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic18.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '43869dd5-3dca-4ecd-822b-b82537255fd7',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/477aa23b-1bce-457e-a04f-909aea18e8e4.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/477aa23b-1bce-457e-a04f-909aea18e8e4.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic140.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '07142091-a85b-4f65-841d-323bd013b54f',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/fca78059-4dfa-46bc-8075-6aa1bbce6b1c.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/fca78059-4dfa-46bc-8075-6aa1bbce6b1c.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic16.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: 'ba52e26c-53fe-423b-8e4f-195887442469',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/5800f61f-3634-4064-b700-a432d3dd4f95.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/5800f61f-3634-4064-b700-a432d3dd4f95.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic3.jpg',
              __typename: 'MediaItemSnapshot',
            },
            {
              id: '514a525e-456c-46ec-abdc-1930d91cd40f',
              tags: [
                {
                  id: 'c11309ff-0fbe-4d61-b2f3-fdc470a9b7c9',
                  name: 'Pictograph',
                  __typename: 'TagSnapShot',
                },
              ],
              scope: 'PUBLIC',
              text: null,
              image: {
                fullSize: {
                  width: 622,
                  height: 622,
                  key: 'fullSize/b6fc70a5-cb73-400c-8fa1-796ccb283c29.jpg',
                  __typename: 'ImageInstance',
                },
                thumbNail: {
                  width: 80,
                  height: 80,
                  key: 'thumbNail/b6fc70a5-cb73-400c-8fa1-796ccb283c29.jpg',
                  __typename: 'ImageInstance',
                },
                __typename: 'Image',
              },
              originalFileName: 'pic138.jpg',
              __typename: 'MediaItemSnapshot',
            },
          ],
          __typename: 'AmpLabeledStimuliPool',
        },
      ],
      __typename: 'TimedAmpCategories',
    },
    mask: {
      id: '4f86eb8d-2551-4d81-8b25-3abf6b5b7a9a',
      tags: [
        {
          id: '486901f4-d438-41c3-8a84-88e834a136d4',
          name: 'mask',
          __typename: 'TagSnapShot',
        },
        {
          id: 'a38ed254-afc8-4d87-9847-2e055d32f0f3',
          name: 'white_noise',
          __typename: 'TagSnapShot',
        },
      ],
      scope: 'PUBLIC',
      text: null,
      image: {
        fullSize: {
          width: 622,
          height: 800,
          key: 'fullSize/9bc8f351-2935-477b-8422-472540df4366.jpg',
          __typename: 'ImageInstance',
        },
        thumbNail: {
          width: 62,
          height: 80,
          key: 'thumbNail/9bc8f351-2935-477b-8422-472540df4366.jpg',
          __typename: 'ImageInstance',
        },
        __typename: 'Image',
      },
      originalFileName: 'mask2b.jpg',
      __typename: 'MediaItemSnapshot',
    },
    blankInterval: 125,
    blocks: [
      {
        index: 0,
        name: 'Practice Block',
        type: 'PRACTICE',
        instructions: {
          desktop:
            '<p style="text-align:center;"></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;"><strong>The procedure is as follows: </strong></span></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">To indicate that things are about to start, a person is first displayed for a few milliseconds. </span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Immediately afterwards, a Chinese character appears for a few milliseconds, and will then be hidden.</span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Please <strong>ignore the picture of the person</strong> and judge whether the Chinese character appears to you threatening or non-threatening.</span></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Rate the <strong>Chinese character</strong> by pressing </span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;"><strong>A</strong> for <strong>#ATTRIBUTE_LEFT#</strong> or<strong> L</strong> for <strong>#ATTRIBUTE_RIGHT#.</strong></span>&nbsp;</p>\n<p style="text-align:center;"></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Be careful, because the Chinese characters can only be seen for a very short time. </span></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Decide spontaneously and as quickly as possible! </span></p>\n<p></p>\n<p style="text-align:center;"><span style="font-size: 18px;font-family: Mulish;">Now place your fingers on the A and L keys and press the <strong>space bar</strong> to start the practice block. </span></p>\n',
          mobile:
            '<p style="text-align:center;"><span style="color: rgba(30,95,70,0.87);font-size: 14px;font-family: Mulish;"><strong>The procedure is as follows: </strong></span></p>\n<p style="text-align:center;"><span style="color: rgba(30,95,70,0.87);font-size: 14px;font-family: Mulish;">To indicate that things are about to start, a person is first displayed for a few milliseconds. </span><br><span style="color: rgba(30,95,70,0.87);font-size: 14px;font-family: Mulish;">Immediately afterwards, a Chinese character appears for a few milliseconds, and will then be hidden.</span><br><span style="color: rgba(30,95,70,0.87);font-size: 14px;font-family: Mulish;">Please <strong>ignore the picture of the person</strong> and judge whether the Chinese character appears to you threatening or non-threatening.</span></p>\n<p style="text-align:center;"><span style="color: rgba(30,95,70,0.87);font-size: 14px;font-family: Mulish;">Rate the <strong>Chinese character</strong> by tapping</span><br> <span style="color: rgba(30,95,70,0.87);font-size: 14px;font-family: Mulish;">the <strong>left side </strong>of the screen for <strong>#ATTRIBUTE_LEFT#</strong> or the<strong> right side</strong> of the screen for  <strong>#ATTRIBUTE_RIGHT#.</strong></span></p>\n<p style="text-align:center;"><span style="color: rgba(30,95,70,0.87);font-size: 14px;font-family: Mulish;">Be careful, because the Chinese characters can only be seen for a very short time. </span><br><span style="color: rgba(30,95,70,0.87);font-size: 14px;font-family: Mulish;">Decide spontaneously and as quickly as possible! </span></p>\n<p style="text-align:center;"><span style="color: rgba(30,95,70,0.87);font-size: 14px;font-family: Mulish;">Now press <strong>Next </strong>to start the first practice block.</span>&nbsp;</p>\n',
          fontStyle: null,
          __typename: 'StyleableTextTemplate',
        },
        amountTrials: 10,
        attributeVariant: 'DEFAULT',
        tips: {
          desktop:
            '<p><span style="color: rgba(30,95,70,0.87);background-color: rgb(255,255,255);font-size: 16px;font-family: Roboto, Helvetica, Arial, sans-serif;">Use the A and L keys to answer.</span></p>\n',
          mobile: '<p>Tap the left or right side of the screen.&nbsp;</p>\n',
          fontStyle: null,
          __typename: 'StyleableTextTemplate',
        },
        __typename: 'AmpBlock',
      },
      {
        index: 2,
        name: 'Test Block',
        type: 'NON_PRACTICE',
        instructions: {
          desktop:
            '<p style="text-align:center;"></p>\n<p style="text-align:center;"><br><span style="color: rgba(30,95,70,0.87);font-size: 18px;font-family: Mulish;">The practice block is now finished and the actual task begins. </span></p>\n<p style="text-align:center;"><span style="color: rgba(30,95,70,0.87);font-size: 18px;font-family: Mulish;">This time there will be a lot of rounds.</span></p>\n<p style="text-align:center;"></p>\n<p style="text-align:center;"><span style="color: rgba(30,95,70,0.87);font-size: 18px;font-family: Mulish;">The task remains the same:</span></p>\n<p style="text-align:center;"><span style="color: rgba(30,95,70,0.87);font-size: 18px;font-family: Mulish;">Ignore the pictures of the people.</span></p>\n<p style="text-align:center;"><br></p>\n<p style="text-align:center;"><span style="color: rgba(30,95,70,0.87);font-size: 18px;font-family: Mulish;">Rate the <strong>Chinese character </strong>by pressing </span></p>\n<p style="text-align:center;"><span style="color: rgba(30,95,70,0.87);font-size: 18px;font-family: Mulish;"><strong>A</strong> for <strong>#ATTRIBUTE_LEFT#</strong> or<strong> L</strong> for <strong>#ATTRIBUTE_RIGHT#.</strong></span>&nbsp;</p>\n<p style="text-align:center;"></p>\n<p style="text-align:center;">&nbsp;<span style="color: rgba(30,95,70,0.87);font-size: 18px;font-family: Mulish;">Place your fingers on the A and L keys and press the <strong>space bar</strong> to start the test bock.</span></p>\n',
          mobile:
            '<p style="text-align:center;"><span style="color: rgba(30,95,70,0.87);font-size: 14px;font-family: Mulish;">The practice block is now finished and the actual task begins.</span><br><span style="color: rgba(30,95,70,0.87);font-size: 14px;font-family: Mulish;">This time there will be a lot of rounds.</span></p>\n<p style="text-align:center;"><span style="color: rgba(30,95,70,0.87);font-size: 14px;font-family: Mulish;">The task remains the same:</span><br><span style="color: rgba(30,95,70,0.87);font-size: 14px;font-family: Mulish;">Ignore the pictures of the people.</span>&nbsp;</p>\n<p style="text-align:center;"><span style="color: rgba(30,95,70,0.87);font-size: 14px;font-family: Mulish;">Rate the <strong>Chinese character</strong> by tapping </span><br><span style="color: rgba(30,95,70,0.87);font-size: 14px;font-family: Mulish;">the <strong>left side </strong>of the screen for <strong>#ATTRIBUTE_LEFT#</strong> or the<strong> right side</strong> of the screen for  <strong>#ATTRIBUTE_RIGHT#.</strong></span>&nbsp;</p>\n<p style="text-align:center;"><span style="color: rgba(30,95,70,0.87);font-size: 14px;font-family: Mulish;">Now press <strong>Next </strong>to start the test block.</span>&nbsp;</p>\n',
          fontStyle: null,
          __typename: 'StyleableTextTemplate',
        },
        amountTrials: 100,
        attributeVariant: 'DEFAULT',
        tips: {
          desktop:
            '<p><span style="color: rgba(30,95,70,0.87);background-color: rgb(255,255,255);font-size: 16px;font-family: Mulish;">Use the A and L keys to answer.</span>&nbsp;</p>\n',
          mobile:
            '<p><span style="color: rgba(30,95,70,0.87);background-color: rgb(255,255,255);font-size: 16px;font-family: Mulish;">Tap the left or right side of the screen. </span>&nbsp;</p>\n',
          fontStyle: null,
          __typename: 'StyleableTextTemplate',
        },
        __typename: 'AmpBlock',
      },
    ],
    startLabel: 'Start',
    nextLabel: 'Next',
    finishLabel: 'Next',
    owner: 'ead161f7-5cae-4508-8ded-9e7130083652',
    editors: [
      '29d7ea4e-e606-45e2-b2ae-58ee2a2908d1',
      '7d53cdab-115d-4356-a3fe-b772a0c5be89',
      'c3b8a87d-f9c5-4d93-a0e5-3ae195186e58',
    ],
    viewers: [
      '29d7ea4e-e606-45e2-b2ae-58ee2a2908d1',
      '7d53cdab-115d-4356-a3fe-b772a0c5be89',
      'c3b8a87d-f9c5-4d93-a0e5-3ae195186e58',
    ],
    resultViewers: [
      '29d7ea4e-e606-45e2-b2ae-58ee2a2908d1',
      '7d53cdab-115d-4356-a3fe-b772a0c5be89',
      'c3b8a87d-f9c5-4d93-a0e5-3ae195186e58',
    ],
    createdAt: '2024-01-24T19:37:41.953Z',
    updatedAt: '2024-09-11T08:38:46.975Z',
    __typename: 'AmpTest',
  } as Amp,
];

const InitialAmpEditorState: CreateAmpTestInput = {
  startLabel: 'Start',
  nextLabel: 'Next',
  finishLabel: 'Finish',
  name: '',
  description: '',
  instructions: {
    desktop: '',
  },
  fixation: {
    interval: 500,
    mediaItem: {
      id: '',
      scope: MediaItemSnapshotScope.PRIVATE,
      tags: [],
    },
  },
  primes: {
    interval: 75,
    categories: createEmptyCategories(),
  },
  targets: {
    interval: 200,
    categories: createEmptyCategories(),
  },
  attributeCategories: {
    fontStyle: createDefaultFontStyle(TextAlignment.CENTER),
    categoryA: createEmptyMediaItemSnapshot(),
    categoryB: createEmptyMediaItemSnapshot(),
  },
  mask: createEmptyMediaItemSnapshot(),
  blocks: [
    {
      index: 0,
      name: 'Practice Block',
      amountTrials: 20,
      attributeVariant: CategoryVariant.DEFAULT,
      instructions: {
        desktop: '',
      },
      type: BlockType.PRACTICE,
    },
    {
      index: 1,
      name: 'Test Block',
      amountTrials: 40,
      attributeVariant: CategoryVariant.DEFAULT,
      instructions: {
        desktop: '',
      },
      type: BlockType.NON_PRACTICE,
    },
  ],
  keyboard: createEmptyKeyboardSettings(),
  style: { ...createDefaultStyle(), optionsPosition: OptionsPosition.BOTTOM },
  blankInterval: 100,
  interTrialInterval: 500,
  counterbalancing: false,
  probabilityWithoutReplacement: true,
  status: TestStatus.DRAFT,
};

export default InitialAmpEditorState;
