import React, { FC } from 'react';
import { Amp } from '../../types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import MediaItemComponent from '../../../../media/MediaItemComponent';
import { TextDisplay } from '../../../../../components/TextDisplay';
import { ExpandMore } from '@mui/icons-material';
import MediaItemList from '../../../../media/MediaItemList';

export const AmpStimuliSettingsView: FC<{ test: Amp }> = ({ test }) => {
  return (
    <Stack spacing={3}>
      <TextDisplay
        label="Probability Without Replacement"
        value={test.probabilityWithoutReplacement ? 'Yes' : 'No'}
      />
      <TextDisplay
        label="Inter Trial Interval"
        value={test.interTrialInterval}
        suffix="ms"
      />
      <TextDisplay
        label="Blank Interval"
        value={test.blankInterval}
        suffix="ms"
      />
      <Stack spacing={3} direction="row">
        <MediaItemComponent label="Mask" mediaItem={test.mask} />
        {test.fixation ? (
          <MediaItemComponent
            label={`Fixation (${test.fixation.interval}ms)`}
            mediaItem={test.fixation.mediaItem}
          />
        ) : (
          <Typography color="textSecondary" variant="body2">
            No Fixation
          </Typography>
        )}
      </Stack>
      <Stack direction="row" spacing={3}>
        <TextDisplay
          label="Target Label"
          value={test.targets.categories[0].label}
        />
        <TextDisplay
          label="Target Interval"
          value={`${test.targets.interval} ms`}
        />
      </Stack>
      <Box>
        <Typography color="textSecondary">Target Practice Stimuli</Typography>
        <MediaItemList items={test.targets.categories[0].practiceStimuliPool} />
      </Box>
      <Box>
        <Typography color="textSecondary">Target Test Stimuli</Typography>
        <MediaItemList items={test.targets.categories[0].testStimuliPool} />
      </Box>
      <TextDisplay
        label="Primes Interval"
        value={`${test.primes.interval} ms`}
      />
      {test.primes.categories.map((category, i) => (
        <Box key={`category${i}`}>
          <Accordion key={`category${i}`}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography color="textSecondary">{`Prime ${
                  i + 1
                }`}</Typography>
                <Typography variant="h5">{category.label}</Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8} style={{ position: 'relative' }}>
                  <Typography color="textSecondary">
                    Practice Stimuli
                  </Typography>
                  <MediaItemList items={category.practiceStimuliPool} />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography color="textSecondary">Test Stimuli</Typography>
                  <MediaItemList items={category.testStimuliPool} />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}
    </Stack>
  );
};
