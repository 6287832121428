import React, { FC, FunctionComponent } from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useField } from 'formik';
import { UiInput } from '../../../../components/input/types';
import CommaSeparatedTextStimuliPicker from '../../../../components/input/CommaSeperatedTextStimliPicker';
import { MediaItemSnapshotInput } from '../../../../API';
import MediaItemList from '../../MediaItemList';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import MediaItemPickerPreview from '../MediaItemPickerPreview';
import { NativeImageUploadButton } from '../../uploader/NativeImageUploadButton';
import { useUploadPrivateImage } from '../../uploader/uploadPrivateImage.hook';
import { PickerTable } from '../PickerTable';
import { useGetMediaItemsQuery } from '../../redux/mediaApi';
import { MediaItem } from '../../types';
import { ChevronLeft, ChevronRight, Upload } from '@mui/icons-material';
import { mediaItemToMediaItemSnapshotInput } from '../pickerUtils';
import { LoadingIcon } from '../../../../components/utils/LoadingIcon';
import { TextPickerDialog } from '../TextPickerDialog';

const useStyles = makeStyles((theme) => ({
  stimulusRoot: {
    flex: 1,
    position: 'relative',
    "& .MuiChip-deletable[datatype='image']:nth-child(3)": {
      marginRight: 20,
    },
  },
  textPicker: {
    marginTop: '-14px!important',
    '& + div': {
      paddingTop: theme.spacing(1),
    },
    '& .MuiInputLabel-shrink': {
      marginLeft: -5,
      padding: '2px 5px',
      background: 'white',
    },
  },
}));

interface MediaItemsPickerProps extends UiInput<MediaItemSnapshotInput[]> {
  color?: string | null;
  bgColor?: string | null;
  imageMode?: boolean;
}

function MediaItemsSelectionList({
  mediaItems,
  updateItems,
  loading,
  onUploadFiles,
}: {
  mediaItems: MediaItemSnapshotInput[];
  updateItems: (newList: MediaItemSnapshotInput[]) => void;
  loading: boolean;
  onUploadFiles: (files: File[]) => void;
}) {
  const [selection, setSelection] = React.useState(
    [] as MediaItemSnapshotInput[],
  );
  const realSelection = selection.filter((m) => mediaItems.includes(m));
  return (
    <Stack sx={{ flex: 1 }} spacing={2}>
      {realSelection.length > 0 ? (
        <Button
          color="error"
          variant="outlined"
          sx={{ textTransform: 'none' }}
          onClick={() =>
            updateItems(mediaItems.filter((s) => !selection.includes(s)))
          }
        >
          Remove {realSelection.length} from test
        </Button>
      ) : (
        <NativeImageUploadButton
          onChange={onUploadFiles}
          label="Upload Images"
          accept="image/*"
          buttonProps={{
            disabled: loading,
            variant: 'contained',
            sx: { textTransform: 'none' },
            fullWidth: true,
            size: 'medium',
            startIcon: loading ? <LoadingIcon /> : <Upload />,
          }}
        />
      )}
      <Typography variant="h6" color="text.secondary">
        Selected Images
      </Typography>
      <Divider />
      {mediaItems.length === 0 && (
        <Alert severity="warning">No Images selected yet</Alert>
      )}
      <List dense sx={{ width: '100%' }} disablePadding>
        {mediaItems.map((mediaItem) => {
          return (
            <>
              <ListItem>
                <ListItemIcon>
                  <Checkbox
                    checked={selection.includes(mediaItem)}
                    onChange={(_, c) => {
                      setSelection(
                        c
                          ? [...realSelection, mediaItem]
                          : realSelection.filter((m) => m !== mediaItem),
                      );
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={<MediaItemPickerPreview mediaItem={mediaItem} />}
                  secondary={mediaItem.originalFileName}
                />
              </ListItem>
              <Divider />
            </>
          );
        })}
      </List>
    </Stack>
  );
}

export const MediaItemSelectionListDialog: FC<{
  title: string;
  onConfirm: (mediaItems: MediaItemSnapshotInput[]) => void;
  onClose: () => void;
  open: boolean;
  value?: MediaItemSnapshotInput[];
}> = ({ title, onConfirm, open, onClose, value }) => {
  const [chosenMediaItems, setChosenMediaItems] = React.useState<
    MediaItemSnapshotInput[]
  >(value ?? []);

  const handleConfirm = () => {
    onConfirm(chosenMediaItems);
    onClose();
  };

  const { uploadState, uploadImages } = useUploadPrivateImage();

  const uploadSelectedFiles = (files: File[]) => {
    uploadImages(files).then((res) => {
      setChosenMediaItems([...chosenMediaItems, ...res]);
    });
  };

  const [showMediaPoolTransfer, setShowMediaPoolTransfer] =
    React.useState(false);

  return (
    <Dialog disableEnforceFocus open={open} maxWidth="xl" onClose={onClose}>
      <DialogTitle style={{ position: 'relative' }}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ width: '100%' }}
        >
          <Typography variant="inherit">{title}</Typography>
          <Box sx={{ position: 'absolute', right: 24 }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={handleConfirm}
            >
              Save
            </Button>
          </Box>
        </Stack>
      </DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          alignItems: 'center',
          overflow: 'visible',
          minWidth: 500,
        }}
      >
        <Stack sx={{ flex: 1 }} spacing={2}>
          <Collapse in={!showMediaPoolTransfer}>
            <Button
              variant="outlined"
              fullWidth
              sx={{ textTransform: 'none' }}
              endIcon={<ChevronRight />}
              onClick={() => setShowMediaPoolTransfer(!showMediaPoolTransfer)}
            >
              Show public MIND.set Pool
            </Button>
          </Collapse>
          <Stack direction="row" spacing={showMediaPoolTransfer ? 2 : 0}>
            <Collapse orientation="horizontal" in={showMediaPoolTransfer}>
              <MediaPoolTransferList
                onTogglePublicPicker={() =>
                  setShowMediaPoolTransfer(!showMediaPoolTransfer)
                }
                chosenMediaItems={chosenMediaItems}
                onSelectPoolItems={(items: MediaItemSnapshotInput[]) =>
                  setChosenMediaItems([
                    ...chosenMediaItems,
                    ...items.filter(
                      (i) => !chosenMediaItems.find((m) => m.id === i.id),
                    ),
                  ])
                }
              />
            </Collapse>
            <MediaItemsSelectionList
              loading={uploadState === 'loading'}
              onUploadFiles={uploadSelectedFiles}
              mediaItems={chosenMediaItems}
              updateItems={(newList) => setChosenMediaItems(newList)}
            />
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

function MediaPoolTransferList({
  chosenMediaItems,
  onSelectPoolItems,
  onTogglePublicPicker,
}: {
  chosenMediaItems: MediaItemSnapshotInput[];
  onSelectPoolItems: (items: MediaItemSnapshotInput[]) => void;
  onTogglePublicPicker: () => void;
}) {
  const { data, isLoading } = useGetMediaItemsQuery();

  const mediaPool: MediaItem[] = data || [];
  const [selection, setSelection] = React.useState([] as MediaItem[]);
  return (
    <div>
      <Button
        variant="outlined"
        fullWidth
        sx={{
          textTransform: 'none',
          mb: 2,
          color: 'text.secondary',
          borderColor: 'text.secondary',
        }}
        startIcon={<ChevronLeft />}
        onClick={onTogglePublicPicker}
      >
        Hide public MIND.set MediaPool
      </Button>
      <Typography variant="h6" color="text.secondary" sx={{ mb: 2 }}>
        Public MIND.set Pool
      </Typography>
      <PickerTable
        mediaItems={mediaPool.filter(
          (m) => !chosenMediaItems.find((m2) => m2.id === m.id),
        )}
        selectedMediaItems={selection}
        withoutUpload
        onSelectionChange={(mediaItems) => setSelection(mediaItems)}
        isLoading={isLoading}
        toolbarExtras={
          selection.length > 0 ? (
            <Button
              variant="contained"
              color="success"
              size="small"
              sx={{ ml: 'auto' }}
              endIcon={<ChevronRight />}
              onClick={() => {
                onSelectPoolItems(
                  selection.map(mediaItemToMediaItemSnapshotInput),
                );
                setSelection([]);
              }}
            >
              {`Add ${selection.length} to selection`}
            </Button>
          ) : undefined
        }
      />
    </div>
  );
}

const MediaItemsPicker: FunctionComponent<MediaItemsPickerProps> = ({
  bgColor,
  color,
  imageMode = true,
  value,
  name,
  label,
}) => {
  const classes = useStyles();

  const [showTransferList, setShowTransferList] = React.useState(false);

  const [, meta, helpers] = useField<typeof value>(name);

  const hasValue =
    meta.value && meta.value.length !== 0 && meta.value[0].text !== '';
  const isText = meta?.value?.length && meta.value[0].text;

  const saveSelection = (mediaItems: MediaItemSnapshotInput[]) => {
    helpers.setValue(mediaItems);
  };

  const removeStimuliFromList = (stimulusId: string) => {
    if (meta.value) {
      helpers.setValue(meta.value.filter((s) => s.id !== stimulusId));
    }
  };

  return (
    <div
      className={classes.stimulusRoot}
      style={{
        borderColor: `${
          meta.touched && meta.error ? 'red' : 'rgba(0,0,0,0.23)'
        }`,
      }}
    >
      <Typography variant="body1" color="textSecondary">
        {label ?? 'Stimuli'}
        <IconButton onClick={() => setShowTransferList(true)} size="small">
          <EditIcon fontSize="small" />
        </IconButton>
      </Typography>
      {(isText || !hasValue) && (
        <Box sx={{ mt: 2 }}>
          <CommaSeparatedTextStimuliPicker
            variant="standard"
            name={name}
            label={''}
            className={classes.textPicker}
          />
        </Box>
      )}
      {hasValue && (
        <MediaItemList
          items={meta.value || []}
          onDelete={isText ? undefined : removeStimuliFromList}
          datatype={isText ? 'text' : 'image'}
          bgColor={bgColor ?? undefined}
          fontColor={color ?? undefined}
        />
      )}
      {imageMode && !isText ? (
        <MediaItemSelectionListDialog
          value={meta.value}
          open={showTransferList}
          onClose={() => setShowTransferList(false)}
          title={'Images'}
          onConfirm={(mediaItems) => saveSelection(mediaItems)}
        />
      ) : (
        <TextPickerDialog
          fieldName={name}
          dialogOpen={showTransferList}
          onClose={() => setShowTransferList(false)}
        />
      )}
    </div>
  );
};
export default MediaItemsPicker;
