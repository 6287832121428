import React, { useEffect, useState } from 'react';
import { GQLQuery, NewGQL } from '../../GQL';
import { adminListUsers } from '../../graphql/mutations';
import {
  AdminListUsersMutationVariables,
  AdminListUsersResponse,
  UserListElement,
} from '../../API';
import { IconButton, Paper } from '@mui/material';
import { Cached } from '@mui/icons-material';
import {
  DataGridPro,
  GridColDef,
  GridEnrichedColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { formatDate } from '../../dateUtils';
import { UserRole } from '../../auth/user-role';
import { UserRoleChip } from './UserRoleChip';
import { UserStatusChip } from './UserStatusChip';
import { EditUserDialog } from './EditUserDialog';
import { DeleteUserDialog } from './DeleteUserDialog';
import { CreateUserDialog } from './CreateUserDialog';
import { RequireAuth } from '../../auth/RequiredAuth';
import { ResendUserInvitationDialog } from './ResendUserInvitationDialog';

async function listUsers() {
  return NewGQL.DEFAULT_CLIENT.execute(
    GQLQuery.Mutate<AdminListUsersMutationVariables, AdminListUsersResponse>(
      adminListUsers,
    ).create({}),
  );
}

function useListUsers() {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<AdminListUsersResponse['users']>([]);

  const listUsersFunction = () => {
    setLoading(true);
    listUsers().then((res) => {
      setUsers(res.users);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (users.length === 0) {
      listUsersFunction();
    }
  }, [users]);

  return { users, loading, reload: listUsersFunction };
}

const columns: GridColDef<UserListElement>[] = [
  {
    headerName: 'E-Mail',
    field: 'email',
    flex: 3,
  },
  { headerName: 'ID', field: 'sub', flex: 1 },
  {
    headerName: 'Group',
    field: 'groups',
    flex: 2,
    renderCell: (params) => (
      <>
        {params.row.groups.map((r) => (
          <UserRoleChip key={r} role={r as UserRole} size="small" />
        ))}
      </>
    ),
  },
  {
    headerName: 'Status',
    field: 'userStatus',
    flex: 2,
    renderCell: (params) => (
      <UserStatusChip status={params.value} size="small" />
    ),
  },
  {
    field: 'userCreateDate',
    headerName: 'Created at',
    flex: 2,
    type: 'dateTime',
    renderCell: (params) => formatDate(params.value as string),
  },
  {
    field: 'userLastModifiedDate',
    headerName: 'Updated at',
    flex: 2,
    type: 'dateTime',
    renderCell: (params) => formatDate(params.value as string),
  },
];

export const UsersScreen = () => {
  const { users, loading, reload } = useListUsers();

  const enrichedColumns: GridEnrichedColDef<UserListElement>[] = [
    ...columns,
    {
      field: 'actions',
      type: 'actions',
      getActions: (params) => [
        <ResendUserInvitationDialog user={params.row} onSuccess={reload} />,
        <EditUserDialog user={params.row} onSuccess={reload} />,
        <DeleteUserDialog user={params.row} onSuccess={reload} />,
      ],
    },
  ];

  return (
    <RequireAuth>
      <Paper sx={{ minHeight: 600 }}>
        <DataGridPro
          disableSelectionOnClick
          loading={loading}
          getRowId={(row) => row.sub}
          columns={enrichedColumns}
          initialState={{
            columns: {
              columnVisibilityModel: { sub: false, userCreateDate: false },
            },
          }}
          rows={users}
          components={{
            Toolbar: () => (
              <GridToolbarContainer>
                <CreateUserDialog onSuccess={reload} />
                <GridToolbarFilterButton
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                  placeholder=""
                  sx={{ m: 1 }}
                />
                <GridToolbarColumnsButton
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                  placeholder=""
                />
                <GridToolbarExport
                  csvOptions={{
                    fileName: 'MINDset Users.csv',
                    utf8WithBom: true,
                  }}
                />
                <IconButton onClick={reload} sx={{ ml: 'auto' }}>
                  <Cached />
                </IconButton>
              </GridToolbarContainer>
            ),
          }}
        />
      </Paper>
    </RequireAuth>
  );
};
