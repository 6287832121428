import logo from '../features/tests/testAdmin/testList/navigation/logo.png';
import {
  AlertTitle,
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  LinearProgress,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import React, { FC } from 'react';
import { RegisterUserMutationVariables } from '../API';
import { RegistrationDataForm } from './registration/RegistrationDataForm';
import { RegistrationInstructions } from './registration/RegistrationInstructions';
import { RegistrationSubmission } from './registration/RegistrationSubmission';
import { getGraphQLAuth } from '../features/logger';
import { publicRegisterUser } from '../graphql/customMutations';
import { API } from 'aws-amplify';
import { Alert } from '@mui/lab';

const registrationSteps: {
  name: string;
  content: FC<{
    name: string;
    currentData: Partial<RegisterUserMutationVariables>;
    back?: (updateData?: Partial<RegisterUserMutationVariables>) => void;
    next: (
      updateData: Partial<RegisterUserMutationVariables>,
      done: boolean,
    ) => void;
  }>;
}[] = [
  {
    name: 'Introduction',
    content: RegistrationInstructions,
  },
  {
    name: 'Data',
    content: RegistrationDataForm,
  },
  {
    name: 'Confirm Policy',
    content: RegistrationSubmission,
  },
];

export function RegistrationScreen() {
  const [activeStep, setActiveStep] = React.useState<
    | {
        type: 'step';
        activeStep: number;
        registrationData: Partial<RegisterUserMutationVariables>;
      }
    | {
        type: 'result';
        registrationData: Partial<RegisterUserMutationVariables>;
        result:
          | { phase: 'pending' }
          | { phase: 'error'; error: string; type: 'unknown' | 'duplicate' }
          | { phase: 'success' };
      }
  >({ type: 'step', activeStep: 0, registrationData: {} });

  const executeRegistration = async (
    registrationData: Partial<RegisterUserMutationVariables>,
  ) => {
    setActiveStep({
      type: 'result',
      registrationData,
      result: { phase: 'pending' },
    });
    getGraphQLAuth()
      .then((authMode) => {
        return API.graphql({
          query: publicRegisterUser,
          variables: {
            ...registrationData,
          },
          authMode: authMode as any,
        }) as Promise<any>;
      })
      .then((mRes) => {
        console.log('register', mRes);
        setActiveStep({
          type: 'result',
          registrationData,
          result: { phase: 'success' },
        });
      })
      .catch((err) => {
        console.error('error', err);
        setActiveStep({
          type: 'result',
          registrationData,
          result: {
            phase: 'error',
            error: `${err}`,
            type:
              err?.errors?.[0]?.message ===
              'Registration for email already exists'
                ? 'duplicate'
                : 'unknown',
          },
        });
      });
  };
  const registrationStep =
    activeStep.type === 'step'
      ? {
          step: registrationSteps[activeStep.activeStep],
          data: activeStep.registrationData,
          StepContent: registrationSteps[activeStep.activeStep].content,
        }
      : null;

  return (
    <Container
      sx={{
        pt: 8,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <img src={logo} alt="logo" />
      <Typography variant="h4" sx={{ my: 3 }}>
        Welcome to MIND.set
      </Typography>
      <Card sx={{ width: '100%', mb: '2em' }}>
        <CardHeader
          title={
            <Stepper
              activeStep={
                activeStep.type === 'step'
                  ? activeStep.activeStep
                  : registrationSteps.length
              }
              sx={{ width: '100%' }}
            >
              {registrationSteps.map((step, stepIndex) => (
                <Step key={step.name}>
                  <StepButton
                    disabled={activeStep.type !== 'step'}
                    onClick={() => {
                      setActiveStep({
                        type: 'step',
                        activeStep: stepIndex,
                        registrationData:
                          activeStep.type === 'step'
                            ? activeStep.registrationData
                            : {},
                      });
                    }}
                  >
                    {step.name}
                  </StepButton>
                </Step>
              ))}
              <Step key={`Done`}>
                <StepLabel>Done</StepLabel>
              </Step>
            </Stepper>
          }
        />
        <Box sx={{ width: '100%', padding: '1.5em', marginTop: '1em' }}>
          {registrationStep && (
            <registrationStep.StepContent
              name={registrationStep.step.name}
              currentData={registrationStep.data}
              back={
                activeStep.type === 'step' && activeStep.activeStep > 0
                  ? (pd) => {
                      setActiveStep({
                        type: 'step',
                        activeStep: activeStep.activeStep - 1,
                        registrationData: {
                          ...activeStep.registrationData,
                          ...pd,
                        },
                      });
                    }
                  : undefined
              }
              next={(data, done) => {
                const registrationData = {
                  ...(activeStep.type === 'step' &&
                    activeStep.registrationData),
                  ...data,
                };
                if (!done) {
                  setActiveStep({
                    type: 'step',
                    activeStep:
                      activeStep.type === 'step'
                        ? activeStep.activeStep + 1
                        : 0,
                    registrationData,
                  });
                } else {
                  executeRegistration(registrationData);
                }
              }}
            />
          )}
          {activeStep.type === 'result' && (
            <>
              {activeStep.result.phase === 'pending' && <LinearProgress />}
              {activeStep.result.phase === 'success' && (
                <Alert color="success" title={`Successfully registered`}>
                  <AlertTitle>{`Successfully registered`}</AlertTitle>
                  <Typography>
                    Registration is complete. As soon as it has been checked by
                    our staff, you will receive an e-mail
                  </Typography>
                </Alert>
              )}
              {activeStep.result.phase === 'error' && (
                <>
                  <Alert
                    color="error"
                    title={`An error occurred`}
                    action={
                      activeStep.result.type === 'duplicate' || (
                        <>
                          <Button
                            onClick={() => {
                              executeRegistration(activeStep.registrationData);
                            }}
                          >
                            Retry
                          </Button>
                        </>
                      )
                    }
                  >
                    <AlertTitle>{`An error occurred`}</AlertTitle>
                    {activeStep.result.type === 'duplicate' && (
                      <Typography>
                        This email address has already been used to request a
                        MIND.set account
                      </Typography>
                    )}
                  </Alert>
                </>
              )}
            </>
          )}
        </Box>
      </Card>
    </Container>
  );
}
