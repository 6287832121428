/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getDocumentationFile = /* GraphQL */ `query GetDocumentationFile($id: ID!) {
  getDocumentationFile(id: $id) {
    id
    name
    description
    s3Key
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDocumentationFileQueryVariables,
  APITypes.GetDocumentationFileQuery
>;
export const listDocumentationFiles = /* GraphQL */ `query ListDocumentationFiles(
  $filter: ModelDocumentationFileFilterInput
  $limit: Int
  $nextToken: String
) {
  listDocumentationFiles(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      s3Key
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDocumentationFilesQueryVariables,
  APITypes.ListDocumentationFilesQuery
>;
export const getDocumentFile = /* GraphQL */ `query GetDocumentFile($id: ID!) {
  getDocumentFile(id: $id) {
    id
    path
    name
    description
    format
    currentVersion {
      createdAt
      version
      validSince
      s3Key
      __typename
    }
    history {
      createdAt
      version
      validSince
      s3Key
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDocumentFileQueryVariables,
  APITypes.GetDocumentFileQuery
>;
export const listDocumentFiles = /* GraphQL */ `query ListDocumentFiles(
  $filter: ModelDocumentFileFilterInput
  $limit: Int
  $nextToken: String
) {
  listDocumentFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      path
      name
      description
      format
      currentVersion {
        createdAt
        version
        validSince
        s3Key
        __typename
      }
      history {
        createdAt
        version
        validSince
        s3Key
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDocumentFilesQueryVariables,
  APITypes.ListDocumentFilesQuery
>;
export const getTag = /* GraphQL */ `query GetTag($id: ID!) {
  getTag(id: $id) {
    id
    name
    mediaItems {
      items {
        id
        tagId
        mediaItemId
        tag {
          id
          name
          mediaItems {
            items {
              id
              tagId
              mediaItemId
              tag {
                id
                name
                mediaItems {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              mediaItem {
                id
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                source
                originalFileName
                tags {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                tagsString
                tagsIds
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        mediaItem {
          id
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          source
          originalFileName
          tags {
            items {
              id
              tagId
              mediaItemId
              tag {
                id
                name
                mediaItems {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              mediaItem {
                id
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                source
                originalFileName
                tags {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                tagsString
                tagsIds
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tagsString
          tagsIds
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTagQueryVariables, APITypes.GetTagQuery>;
export const listTags = /* GraphQL */ `query ListTags($filter: ModelTagFilterInput, $limit: Int, $nextToken: String) {
  listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      mediaItems {
        items {
          id
          tagId
          mediaItemId
          tag {
            id
            name
            mediaItems {
              items {
                id
                tagId
                mediaItemId
                tag {
                  id
                  name
                  mediaItems {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                mediaItem {
                  id
                  image {
                    fullSize {
                      width
                      height
                      key
                      __typename
                    }
                    thumbNail {
                      width
                      height
                      key
                      __typename
                    }
                    __typename
                  }
                  source
                  originalFileName
                  tags {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  tagsString
                  tagsIds
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          mediaItem {
            id
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            source
            originalFileName
            tags {
              items {
                id
                tagId
                mediaItemId
                tag {
                  id
                  name
                  mediaItems {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                mediaItem {
                  id
                  image {
                    fullSize {
                      width
                      height
                      key
                      __typename
                    }
                    thumbNail {
                      width
                      height
                      key
                      __typename
                    }
                    __typename
                  }
                  source
                  originalFileName
                  tags {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  tagsString
                  tagsIds
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tagsString
            tagsIds
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTagsQueryVariables, APITypes.ListTagsQuery>;
export const getMediaItem = /* GraphQL */ `query GetMediaItem($id: ID!) {
  getMediaItem(id: $id) {
    id
    image {
      fullSize {
        width
        height
        key
        __typename
      }
      thumbNail {
        width
        height
        key
        __typename
      }
      __typename
    }
    source
    originalFileName
    tags {
      items {
        id
        tagId
        mediaItemId
        tag {
          id
          name
          mediaItems {
            items {
              id
              tagId
              mediaItemId
              tag {
                id
                name
                mediaItems {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              mediaItem {
                id
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                source
                originalFileName
                tags {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                tagsString
                tagsIds
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        mediaItem {
          id
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          source
          originalFileName
          tags {
            items {
              id
              tagId
              mediaItemId
              tag {
                id
                name
                mediaItems {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              mediaItem {
                id
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                source
                originalFileName
                tags {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                tagsString
                tagsIds
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tagsString
          tagsIds
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    tagsString
    tagsIds
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMediaItemQueryVariables,
  APITypes.GetMediaItemQuery
>;
export const listMediaItems = /* GraphQL */ `query ListMediaItems(
  $filter: ModelMediaItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listMediaItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      source
      originalFileName
      tags {
        items {
          id
          tagId
          mediaItemId
          tag {
            id
            name
            mediaItems {
              items {
                id
                tagId
                mediaItemId
                tag {
                  id
                  name
                  mediaItems {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                mediaItem {
                  id
                  image {
                    fullSize {
                      width
                      height
                      key
                      __typename
                    }
                    thumbNail {
                      width
                      height
                      key
                      __typename
                    }
                    __typename
                  }
                  source
                  originalFileName
                  tags {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  tagsString
                  tagsIds
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          mediaItem {
            id
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            source
            originalFileName
            tags {
              items {
                id
                tagId
                mediaItemId
                tag {
                  id
                  name
                  mediaItems {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                mediaItem {
                  id
                  image {
                    fullSize {
                      width
                      height
                      key
                      __typename
                    }
                    thumbNail {
                      width
                      height
                      key
                      __typename
                    }
                    __typename
                  }
                  source
                  originalFileName
                  tags {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  tagsString
                  tagsIds
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tagsString
            tagsIds
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tagsString
      tagsIds
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMediaItemsQueryVariables,
  APITypes.ListMediaItemsQuery
>;
export const mediaItemsByTagsString = /* GraphQL */ `query MediaItemsByTagsString(
  $tagsString: String!
  $sortDirection: ModelSortDirection
  $filter: ModelMediaItemFilterInput
  $limit: Int
  $nextToken: String
) {
  mediaItemsByTagsString(
    tagsString: $tagsString
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      source
      originalFileName
      tags {
        items {
          id
          tagId
          mediaItemId
          tag {
            id
            name
            mediaItems {
              items {
                id
                tagId
                mediaItemId
                tag {
                  id
                  name
                  mediaItems {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                mediaItem {
                  id
                  image {
                    fullSize {
                      width
                      height
                      key
                      __typename
                    }
                    thumbNail {
                      width
                      height
                      key
                      __typename
                    }
                    __typename
                  }
                  source
                  originalFileName
                  tags {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  tagsString
                  tagsIds
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          mediaItem {
            id
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            source
            originalFileName
            tags {
              items {
                id
                tagId
                mediaItemId
                tag {
                  id
                  name
                  mediaItems {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                mediaItem {
                  id
                  image {
                    fullSize {
                      width
                      height
                      key
                      __typename
                    }
                    thumbNail {
                      width
                      height
                      key
                      __typename
                    }
                    __typename
                  }
                  source
                  originalFileName
                  tags {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  tagsString
                  tagsIds
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tagsString
            tagsIds
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tagsString
      tagsIds
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MediaItemsByTagsStringQueryVariables,
  APITypes.MediaItemsByTagsStringQuery
>;
export const getTestFinalization = /* GraphQL */ `query GetTestFinalization($id: ID!) {
  getTestFinalization(id: $id) {
    id
    testType
    testId
    probandId
    loadingStartedAt
    testStartedAt
    testFinalizedAt
    testAbortedAt
    owner
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTestFinalizationQueryVariables,
  APITypes.GetTestFinalizationQuery
>;
export const listTestFinalizations = /* GraphQL */ `query ListTestFinalizations(
  $filter: ModelTestFinalizationFilterInput
  $limit: Int
  $nextToken: String
) {
  listTestFinalizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      testType
      testId
      probandId
      loadingStartedAt
      testStartedAt
      testFinalizedAt
      testAbortedAt
      owner
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTestFinalizationsQueryVariables,
  APITypes.ListTestFinalizationsQuery
>;
export const testFinalizationsByTestId = /* GraphQL */ `query TestFinalizationsByTestId(
  $testId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTestFinalizationFilterInput
  $limit: Int
  $nextToken: String
) {
  testFinalizationsByTestId(
    testId: $testId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      testType
      testId
      probandId
      loadingStartedAt
      testStartedAt
      testFinalizedAt
      testAbortedAt
      owner
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TestFinalizationsByTestIdQueryVariables,
  APITypes.TestFinalizationsByTestIdQuery
>;
export const getTestResultsStatus = /* GraphQL */ `query GetTestResultsStatus($id: ID!) {
  getTestResultsStatus(id: $id) {
    id
    status
    testType
    error
    statistics {
      processedResultRows
      exportedResults
      processedProbandAmount
      finishedProbandAmount
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTestResultsStatusQueryVariables,
  APITypes.GetTestResultsStatusQuery
>;
export const listTestResultsStatuses = /* GraphQL */ `query ListTestResultsStatuses(
  $filter: ModelTestResultsStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  listTestResultsStatuses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      status
      testType
      error
      statistics {
        processedResultRows
        exportedResults
        processedProbandAmount
        finishedProbandAmount
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTestResultsStatusesQueryVariables,
  APITypes.ListTestResultsStatusesQuery
>;
export const getIatTest = /* GraphQL */ `query GetIatTest($id: ID!) {
  getIatTest(id: $id) {
    id
    name
    description
    language
    instructions {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    keyboard {
      left
      right
      confirm
      __typename
    }
    interTrialInterval
    counterbalancing
    probabilityWithoutReplacement
    style {
      maxWidth
      backgroundColour
      stimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      mobileStimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      optionsPosition
      optionsPositionMobile
      __typename
    }
    status
    previewCategories
    blocks {
      index
      type
      name
      amountTrials
      instructions {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      categoryLabelsInInstructions
      attributeCategoryVariant
      targetCategoryVariant
      tips {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      __typename
    }
    targetCategories {
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      categoryA {
        label {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      categoryB {
        label {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    categoryDivider {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    attributeCategories {
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      categoryA {
        label {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      categoryB {
        label {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    endScreen {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    startLabel
    nextLabel
    finishLabel
    feedback
    feedbackInterval
    owner
    editors
    viewers
    resultViewers
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetIatTestQueryVariables,
  APITypes.GetIatTestQuery
>;
export const listIatTests = /* GraphQL */ `query ListIatTests(
  $filter: ModelIatTestFilterInput
  $limit: Int
  $nextToken: String
) {
  listIatTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      language
      instructions {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      keyboard {
        left
        right
        confirm
        __typename
      }
      interTrialInterval
      counterbalancing
      probabilityWithoutReplacement
      style {
        maxWidth
        backgroundColour
        stimulusTextStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        mobileStimulusTextStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        optionsPosition
        optionsPositionMobile
        __typename
      }
      status
      previewCategories
      blocks {
        index
        type
        name
        amountTrials
        instructions {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        categoryLabelsInInstructions
        attributeCategoryVariant
        targetCategoryVariant
        tips {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        __typename
      }
      targetCategories {
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        categoryA {
          label {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          practiceStimuliPool {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          testStimuliPool {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          __typename
        }
        categoryB {
          label {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          practiceStimuliPool {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          testStimuliPool {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          __typename
        }
        __typename
      }
      categoryDivider {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      attributeCategories {
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        categoryA {
          label {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          practiceStimuliPool {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          testStimuliPool {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          __typename
        }
        categoryB {
          label {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          practiceStimuliPool {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          testStimuliPool {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          __typename
        }
        __typename
      }
      endScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      startLabel
      nextLabel
      finishLabel
      feedback
      feedbackInterval
      owner
      editors
      viewers
      resultViewers
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIatTestsQueryVariables,
  APITypes.ListIatTestsQuery
>;
export const getIatResult = /* GraphQL */ `query GetIatResult($id: ID!) {
  getIatResult(id: $id) {
    id
    testId
    clientId
    survey {
      probandId
      surveyId
      surveyProbandId
      testProviderName
      __typename
    }
    timing {
      renderStartDelay
      renderEndDelay
      startTriggerDelay
      endTriggerDelay
      controlRaceDelay
      __typename
    }
    device {
      ua
      browser {
        name
        version
        major
        __typename
      }
      device {
        model
        type
        vendor
        __typename
      }
      engine {
        name
        version
        __typename
      }
      os {
        name
        version
        __typename
      }
      cpu {
        architecture
        __typename
      }
      __typename
    }
    blockIndex
    blockName
    blockType
    trialIndex
    actionResultName
    actionTarget {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    actionAttribute {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    counterBalancingGroup
    stimulus {
      categoryLabel {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      categoryType
      stimulus {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    pressedButton
    triggeredControl
    attributeLeft {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    attributeRight {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    targetLeft {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    targetRight {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetIatResultQueryVariables,
  APITypes.GetIatResultQuery
>;
export const listIatResults = /* GraphQL */ `query ListIatResults(
  $filter: ModelIatResultFilterInput
  $limit: Int
  $nextToken: String
) {
  listIatResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      testId
      clientId
      survey {
        probandId
        surveyId
        surveyProbandId
        testProviderName
        __typename
      }
      timing {
        renderStartDelay
        renderEndDelay
        startTriggerDelay
        endTriggerDelay
        controlRaceDelay
        __typename
      }
      device {
        ua
        browser {
          name
          version
          major
          __typename
        }
        device {
          model
          type
          vendor
          __typename
        }
        engine {
          name
          version
          __typename
        }
        os {
          name
          version
          __typename
        }
        cpu {
          architecture
          __typename
        }
        __typename
      }
      blockIndex
      blockName
      blockType
      trialIndex
      actionResultName
      actionTarget {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      actionAttribute {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      counterBalancingGroup
      stimulus {
        categoryLabel {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        categoryType
        stimulus {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      pressedButton
      triggeredControl
      attributeLeft {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      attributeRight {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      targetLeft {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      targetRight {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIatResultsQueryVariables,
  APITypes.ListIatResultsQuery
>;
export const iatResultsByTestId = /* GraphQL */ `query IatResultsByTestId(
  $testId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelIatResultFilterInput
  $limit: Int
  $nextToken: String
) {
  iatResultsByTestId(
    testId: $testId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      testId
      clientId
      survey {
        probandId
        surveyId
        surveyProbandId
        testProviderName
        __typename
      }
      timing {
        renderStartDelay
        renderEndDelay
        startTriggerDelay
        endTriggerDelay
        controlRaceDelay
        __typename
      }
      device {
        ua
        browser {
          name
          version
          major
          __typename
        }
        device {
          model
          type
          vendor
          __typename
        }
        engine {
          name
          version
          __typename
        }
        os {
          name
          version
          __typename
        }
        cpu {
          architecture
          __typename
        }
        __typename
      }
      blockIndex
      blockName
      blockType
      trialIndex
      actionResultName
      actionTarget {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      actionAttribute {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      counterBalancingGroup
      stimulus {
        categoryLabel {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        categoryType
        stimulus {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      pressedButton
      triggeredControl
      attributeLeft {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      attributeRight {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      targetLeft {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      targetRight {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.IatResultsByTestIdQueryVariables,
  APITypes.IatResultsByTestIdQuery
>;
export const getAmpTest = /* GraphQL */ `query GetAmpTest($id: ID!) {
  getAmpTest(id: $id) {
    id
    name
    description
    language
    instructions {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    keyboard {
      left
      right
      confirm
      __typename
    }
    counterbalancing
    probabilityWithoutReplacement
    interTrialInterval
    style {
      maxWidth
      backgroundColour
      stimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      mobileStimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      optionsPosition
      optionsPositionMobile
      __typename
    }
    status
    endScreen {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    attributeCategories {
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      categoryA {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      categoryB {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    fixation {
      interval
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    primes {
      interval
      categories {
        label
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    targets {
      interval
      categories {
        label
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    mask {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    blankInterval
    blocks {
      index
      name
      type
      instructions {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      amountTrials
      attributeVariant
      tips {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      __typename
    }
    startLabel
    nextLabel
    finishLabel
    owner
    editors
    viewers
    resultViewers
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAmpTestQueryVariables,
  APITypes.GetAmpTestQuery
>;
export const listAmpTests = /* GraphQL */ `query ListAmpTests(
  $filter: ModelAmpTestFilterInput
  $limit: Int
  $nextToken: String
) {
  listAmpTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      language
      instructions {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      keyboard {
        left
        right
        confirm
        __typename
      }
      counterbalancing
      probabilityWithoutReplacement
      interTrialInterval
      style {
        maxWidth
        backgroundColour
        stimulusTextStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        mobileStimulusTextStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        optionsPosition
        optionsPositionMobile
        __typename
      }
      status
      endScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      attributeCategories {
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        categoryA {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        categoryB {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      fixation {
        interval
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      primes {
        interval
        categories {
          label
          practiceStimuliPool {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          testStimuliPool {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          __typename
        }
        __typename
      }
      targets {
        interval
        categories {
          label
          practiceStimuliPool {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          testStimuliPool {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          __typename
        }
        __typename
      }
      mask {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      blankInterval
      blocks {
        index
        name
        type
        instructions {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        amountTrials
        attributeVariant
        tips {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        __typename
      }
      startLabel
      nextLabel
      finishLabel
      owner
      editors
      viewers
      resultViewers
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAmpTestsQueryVariables,
  APITypes.ListAmpTestsQuery
>;
export const getAmpResult = /* GraphQL */ `query GetAmpResult($id: ID!) {
  getAmpResult(id: $id) {
    id
    testId
    clientId
    survey {
      probandId
      surveyId
      surveyProbandId
      testProviderName
      __typename
    }
    timing {
      renderStartDelay
      renderEndDelay
      startTriggerDelay
      endTriggerDelay
      controlRaceDelay
      __typename
    }
    device {
      ua
      browser {
        name
        version
        major
        __typename
      }
      device {
        model
        type
        vendor
        __typename
      }
      engine {
        name
        version
        __typename
      }
      os {
        name
        version
        __typename
      }
      cpu {
        architecture
        __typename
      }
      __typename
    }
    blockIndex
    blockName
    blockType
    trialIndex
    actionResultName
    counterBalancingGroup
    pressedButton
    triggeredControl
    attributeLeftStimulus {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    attributeRightStimulus {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    actionResultStimulus {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    attributeLeft
    attributeRight
    prime {
      label
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    target {
      label
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAmpResultQueryVariables,
  APITypes.GetAmpResultQuery
>;
export const listAmpResults = /* GraphQL */ `query ListAmpResults(
  $filter: ModelAmpResultFilterInput
  $limit: Int
  $nextToken: String
) {
  listAmpResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      testId
      clientId
      survey {
        probandId
        surveyId
        surveyProbandId
        testProviderName
        __typename
      }
      timing {
        renderStartDelay
        renderEndDelay
        startTriggerDelay
        endTriggerDelay
        controlRaceDelay
        __typename
      }
      device {
        ua
        browser {
          name
          version
          major
          __typename
        }
        device {
          model
          type
          vendor
          __typename
        }
        engine {
          name
          version
          __typename
        }
        os {
          name
          version
          __typename
        }
        cpu {
          architecture
          __typename
        }
        __typename
      }
      blockIndex
      blockName
      blockType
      trialIndex
      actionResultName
      counterBalancingGroup
      pressedButton
      triggeredControl
      attributeLeftStimulus {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      attributeRightStimulus {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      actionResultStimulus {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      attributeLeft
      attributeRight
      prime {
        label
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      target {
        label
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAmpResultsQueryVariables,
  APITypes.ListAmpResultsQuery
>;
export const ampResultsByTestId = /* GraphQL */ `query AmpResultsByTestId(
  $testId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAmpResultFilterInput
  $limit: Int
  $nextToken: String
) {
  ampResultsByTestId(
    testId: $testId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      testId
      clientId
      survey {
        probandId
        surveyId
        surveyProbandId
        testProviderName
        __typename
      }
      timing {
        renderStartDelay
        renderEndDelay
        startTriggerDelay
        endTriggerDelay
        controlRaceDelay
        __typename
      }
      device {
        ua
        browser {
          name
          version
          major
          __typename
        }
        device {
          model
          type
          vendor
          __typename
        }
        engine {
          name
          version
          __typename
        }
        os {
          name
          version
          __typename
        }
        cpu {
          architecture
          __typename
        }
        __typename
      }
      blockIndex
      blockName
      blockType
      trialIndex
      actionResultName
      counterBalancingGroup
      pressedButton
      triggeredControl
      attributeLeftStimulus {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      attributeRightStimulus {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      actionResultStimulus {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      attributeLeft
      attributeRight
      prime {
        label
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      target {
        label
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AmpResultsByTestIdQueryVariables,
  APITypes.AmpResultsByTestIdQuery
>;
export const getPodtTest = /* GraphQL */ `query GetPodtTest($id: ID!) {
  getPodtTest(id: $id) {
    id
    name
    description
    language
    instructions {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    keyboard {
      left
      right
      confirm
      __typename
    }
    counterbalancing
    probabilityWithoutReplacement
    interTrialInterval
    style {
      maxWidth
      backgroundColour
      stimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      mobileStimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      optionsPosition
      optionsPositionMobile
      __typename
    }
    status
    endScreen {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    response {
      timeout
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      active {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      passive {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    scenes {
      maxAmountScenesPerTrial
      scenes {
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        boxes {
          top
          left
          width
          height
          __typename
        }
        __typename
      }
      intervalConfig {
        min
        max
        stepSize
        __typename
      }
      __typename
    }
    targets {
      label
      practiceStimuliPool {
        dangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        nonDangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      testStimuliPool {
        dangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        nonDangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    fixation {
      interval
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    trialFeedback {
      correctRejectionText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      hitText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      missText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      falseAlarmText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      noResponseText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      pointsFontsize
      __typename
    }
    blocks {
      index
      name
      type
      amountTrials
      instructions {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      preparationScreen {
        interval
        screen {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        __typename
      }
      tips {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      __typename
    }
    showCategoriesAfterInstructions
    startLabel
    nextLabel
    finishLabel
    owner
    editors
    viewers
    resultViewers
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPodtTestQueryVariables,
  APITypes.GetPodtTestQuery
>;
export const listPodtTests = /* GraphQL */ `query ListPodtTests(
  $filter: ModelPodtTestFilterInput
  $limit: Int
  $nextToken: String
) {
  listPodtTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      language
      instructions {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      keyboard {
        left
        right
        confirm
        __typename
      }
      counterbalancing
      probabilityWithoutReplacement
      interTrialInterval
      style {
        maxWidth
        backgroundColour
        stimulusTextStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        mobileStimulusTextStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        optionsPosition
        optionsPositionMobile
        __typename
      }
      status
      endScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      response {
        timeout
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        active {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        passive {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      scenes {
        maxAmountScenesPerTrial
        scenes {
          mediaItem {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          boxes {
            top
            left
            width
            height
            __typename
          }
          __typename
        }
        intervalConfig {
          min
          max
          stepSize
          __typename
        }
        __typename
      }
      targets {
        label
        practiceStimuliPool {
          dangerousStimuli {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          nonDangerousStimuli {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          __typename
        }
        testStimuliPool {
          dangerousStimuli {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          nonDangerousStimuli {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          __typename
        }
        __typename
      }
      fixation {
        interval
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      trialFeedback {
        correctRejectionText {
          value
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          weight
          __typename
        }
        hitText {
          value
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          weight
          __typename
        }
        missText {
          value
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          weight
          __typename
        }
        falseAlarmText {
          value
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          weight
          __typename
        }
        noResponseText {
          value
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          weight
          __typename
        }
        pointsFontsize
        __typename
      }
      blocks {
        index
        name
        type
        amountTrials
        instructions {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        preparationScreen {
          interval
          screen {
            desktop
            mobile
            fontStyle {
              fontSize
              color
              alignment
              fontFace
              __typename
            }
            __typename
          }
          __typename
        }
        tips {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        __typename
      }
      showCategoriesAfterInstructions
      startLabel
      nextLabel
      finishLabel
      owner
      editors
      viewers
      resultViewers
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPodtTestsQueryVariables,
  APITypes.ListPodtTestsQuery
>;
export const getPodtResult = /* GraphQL */ `query GetPodtResult($id: ID!) {
  getPodtResult(id: $id) {
    id
    testId
    clientId
    survey {
      probandId
      surveyId
      surveyProbandId
      testProviderName
      __typename
    }
    timing {
      renderStartDelay
      renderEndDelay
      startTriggerDelay
      endTriggerDelay
      controlRaceDelay
      __typename
    }
    device {
      ua
      browser {
        name
        version
        major
        __typename
      }
      device {
        model
        type
        vendor
        __typename
      }
      engine {
        name
        version
        __typename
      }
      os {
        name
        version
        __typename
      }
      cpu {
        architecture
        __typename
      }
      __typename
    }
    blockIndex
    blockName
    blockType
    trialIndex
    actionResultName
    counterBalancingGroup
    earnedPoints
    reactionLeft
    reactionRight
    scenes {
      scene {
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        boxes {
          top
          left
          width
          height
          __typename
        }
        __typename
      }
      interval
      __typename
    }
    target {
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      category
      dangerous
      __typename
    }
    accumulatedPoints
    pressedButton
    triggeredControl
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPodtResultQueryVariables,
  APITypes.GetPodtResultQuery
>;
export const listPodtResults = /* GraphQL */ `query ListPodtResults(
  $filter: ModelPodtResultFilterInput
  $limit: Int
  $nextToken: String
) {
  listPodtResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      testId
      clientId
      survey {
        probandId
        surveyId
        surveyProbandId
        testProviderName
        __typename
      }
      timing {
        renderStartDelay
        renderEndDelay
        startTriggerDelay
        endTriggerDelay
        controlRaceDelay
        __typename
      }
      device {
        ua
        browser {
          name
          version
          major
          __typename
        }
        device {
          model
          type
          vendor
          __typename
        }
        engine {
          name
          version
          __typename
        }
        os {
          name
          version
          __typename
        }
        cpu {
          architecture
          __typename
        }
        __typename
      }
      blockIndex
      blockName
      blockType
      trialIndex
      actionResultName
      counterBalancingGroup
      earnedPoints
      reactionLeft
      reactionRight
      scenes {
        scene {
          mediaItem {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          boxes {
            top
            left
            width
            height
            __typename
          }
          __typename
        }
        interval
        __typename
      }
      target {
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        category
        dangerous
        __typename
      }
      accumulatedPoints
      pressedButton
      triggeredControl
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPodtResultsQueryVariables,
  APITypes.ListPodtResultsQuery
>;
export const podtResultsByTestId = /* GraphQL */ `query PodtResultsByTestId(
  $testId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPodtResultFilterInput
  $limit: Int
  $nextToken: String
) {
  podtResultsByTestId(
    testId: $testId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      testId
      clientId
      survey {
        probandId
        surveyId
        surveyProbandId
        testProviderName
        __typename
      }
      timing {
        renderStartDelay
        renderEndDelay
        startTriggerDelay
        endTriggerDelay
        controlRaceDelay
        __typename
      }
      device {
        ua
        browser {
          name
          version
          major
          __typename
        }
        device {
          model
          type
          vendor
          __typename
        }
        engine {
          name
          version
          __typename
        }
        os {
          name
          version
          __typename
        }
        cpu {
          architecture
          __typename
        }
        __typename
      }
      blockIndex
      blockName
      blockType
      trialIndex
      actionResultName
      counterBalancingGroup
      earnedPoints
      reactionLeft
      reactionRight
      scenes {
        scene {
          mediaItem {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          boxes {
            top
            left
            width
            height
            __typename
          }
          __typename
        }
        interval
        __typename
      }
      target {
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        category
        dangerous
        __typename
      }
      accumulatedPoints
      pressedButton
      triggeredControl
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PodtResultsByTestIdQueryVariables,
  APITypes.PodtResultsByTestIdQuery
>;
export const getPodtaTest = /* GraphQL */ `query GetPodtaTest($id: ID!) {
  getPodtaTest(id: $id) {
    id
    name
    description
    language
    instructions {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    keyboard {
      left
      right
      confirm
      __typename
    }
    counterbalancing
    probabilityWithoutReplacement
    interTrialInterval
    style {
      maxWidth
      backgroundColour
      stimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      mobileStimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      optionsPosition
      optionsPositionMobile
      __typename
    }
    status
    endScreen {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    response {
      timeout
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      active {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      passive {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    scenes {
      maxAmountScenesPerTrial
      scenes {
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        boxes {
          top
          left
          width
          height
          position
          __typename
        }
        __typename
      }
      intervalConfig {
        min
        max
        stepSize
        __typename
      }
      __typename
    }
    targets {
      label
      practiceStimuliPool {
        dangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        nonDangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      testStimuliPool {
        dangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        nonDangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    fixation {
      interval
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    trialFeedback {
      correctRejectionText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      hitText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      missText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      falseAlarmText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      noResponseText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      pointsFontsize
      __typename
    }
    blocks {
      index
      name
      type
      amountTrials
      instructions {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      preparationScreen {
        interval
        screen {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        __typename
      }
      tips {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      __typename
    }
    showCategoriesAfterInstructions
    startLabel
    nextLabel
    finishLabel
    owner
    editors
    viewers
    resultViewers
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPodtaTestQueryVariables,
  APITypes.GetPodtaTestQuery
>;
export const listPodtaTests = /* GraphQL */ `query ListPodtaTests(
  $filter: ModelPodtaTestFilterInput
  $limit: Int
  $nextToken: String
) {
  listPodtaTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      language
      instructions {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      keyboard {
        left
        right
        confirm
        __typename
      }
      counterbalancing
      probabilityWithoutReplacement
      interTrialInterval
      style {
        maxWidth
        backgroundColour
        stimulusTextStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        mobileStimulusTextStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        optionsPosition
        optionsPositionMobile
        __typename
      }
      status
      endScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      response {
        timeout
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        active {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        passive {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      scenes {
        maxAmountScenesPerTrial
        scenes {
          mediaItem {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          boxes {
            top
            left
            width
            height
            position
            __typename
          }
          __typename
        }
        intervalConfig {
          min
          max
          stepSize
          __typename
        }
        __typename
      }
      targets {
        label
        practiceStimuliPool {
          dangerousStimuli {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          nonDangerousStimuli {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          __typename
        }
        testStimuliPool {
          dangerousStimuli {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          nonDangerousStimuli {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          __typename
        }
        __typename
      }
      fixation {
        interval
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      trialFeedback {
        correctRejectionText {
          value
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          weight
          __typename
        }
        hitText {
          value
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          weight
          __typename
        }
        missText {
          value
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          weight
          __typename
        }
        falseAlarmText {
          value
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          weight
          __typename
        }
        noResponseText {
          value
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          weight
          __typename
        }
        pointsFontsize
        __typename
      }
      blocks {
        index
        name
        type
        amountTrials
        instructions {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        preparationScreen {
          interval
          screen {
            desktop
            mobile
            fontStyle {
              fontSize
              color
              alignment
              fontFace
              __typename
            }
            __typename
          }
          __typename
        }
        tips {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        __typename
      }
      showCategoriesAfterInstructions
      startLabel
      nextLabel
      finishLabel
      owner
      editors
      viewers
      resultViewers
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPodtaTestsQueryVariables,
  APITypes.ListPodtaTestsQuery
>;
export const getPodtaResult = /* GraphQL */ `query GetPodtaResult($id: ID!) {
  getPodtaResult(id: $id) {
    id
    testId
    clientId
    survey {
      probandId
      surveyId
      surveyProbandId
      testProviderName
      __typename
    }
    timing {
      renderStartDelay
      renderEndDelay
      startTriggerDelay
      endTriggerDelay
      controlRaceDelay
      __typename
    }
    device {
      ua
      browser {
        name
        version
        major
        __typename
      }
      device {
        model
        type
        vendor
        __typename
      }
      engine {
        name
        version
        __typename
      }
      os {
        name
        version
        __typename
      }
      cpu {
        architecture
        __typename
      }
      __typename
    }
    blockIndex
    blockName
    blockType
    trialIndex
    actionResultName
    counterBalancingGroup
    earnedPoints
    reactionLeft
    reactionRight
    scenes {
      scene {
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        boxes {
          top
          left
          width
          height
          position
          __typename
        }
        __typename
      }
      interval
      __typename
    }
    target {
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      category
      dangerous
      __typename
    }
    accumulatedPoints
    pressedButton
    triggeredControl
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPodtaResultQueryVariables,
  APITypes.GetPodtaResultQuery
>;
export const listPodtaResults = /* GraphQL */ `query ListPodtaResults(
  $filter: ModelPodtaResultFilterInput
  $limit: Int
  $nextToken: String
) {
  listPodtaResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      testId
      clientId
      survey {
        probandId
        surveyId
        surveyProbandId
        testProviderName
        __typename
      }
      timing {
        renderStartDelay
        renderEndDelay
        startTriggerDelay
        endTriggerDelay
        controlRaceDelay
        __typename
      }
      device {
        ua
        browser {
          name
          version
          major
          __typename
        }
        device {
          model
          type
          vendor
          __typename
        }
        engine {
          name
          version
          __typename
        }
        os {
          name
          version
          __typename
        }
        cpu {
          architecture
          __typename
        }
        __typename
      }
      blockIndex
      blockName
      blockType
      trialIndex
      actionResultName
      counterBalancingGroup
      earnedPoints
      reactionLeft
      reactionRight
      scenes {
        scene {
          mediaItem {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          boxes {
            top
            left
            width
            height
            position
            __typename
          }
          __typename
        }
        interval
        __typename
      }
      target {
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        category
        dangerous
        __typename
      }
      accumulatedPoints
      pressedButton
      triggeredControl
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPodtaResultsQueryVariables,
  APITypes.ListPodtaResultsQuery
>;
export const podtaResultsByTestId = /* GraphQL */ `query PodtaResultsByTestId(
  $testId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPodtaResultFilterInput
  $limit: Int
  $nextToken: String
) {
  podtaResultsByTestId(
    testId: $testId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      testId
      clientId
      survey {
        probandId
        surveyId
        surveyProbandId
        testProviderName
        __typename
      }
      timing {
        renderStartDelay
        renderEndDelay
        startTriggerDelay
        endTriggerDelay
        controlRaceDelay
        __typename
      }
      device {
        ua
        browser {
          name
          version
          major
          __typename
        }
        device {
          model
          type
          vendor
          __typename
        }
        engine {
          name
          version
          __typename
        }
        os {
          name
          version
          __typename
        }
        cpu {
          architecture
          __typename
        }
        __typename
      }
      blockIndex
      blockName
      blockType
      trialIndex
      actionResultName
      counterBalancingGroup
      earnedPoints
      reactionLeft
      reactionRight
      scenes {
        scene {
          mediaItem {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          boxes {
            top
            left
            width
            height
            position
            __typename
          }
          __typename
        }
        interval
        __typename
      }
      target {
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        category
        dangerous
        __typename
      }
      accumulatedPoints
      pressedButton
      triggeredControl
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PodtaResultsByTestIdQueryVariables,
  APITypes.PodtaResultsByTestIdQuery
>;
export const getWSWTest = /* GraphQL */ `query GetWSWTest($id: ID!) {
  getWSWTest(id: $id) {
    id
    name
    language
    description
    instructions {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    style {
      maxWidth
      backgroundColour
      stimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      mobileStimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      optionsPosition
      optionsPositionMobile
      __typename
    }
    status
    endScreen {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    attributeCombinations {
      mainCategory
      additionalCategory
      criteriaCategory
      amount
      multiplicator
      __typename
    }
    mainAttribute {
      stimuliVariant
      type
      pickingStrategy
      categories {
        name
        mediaSnaps {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    criteriaAttribute {
      stimuliVariant
      type
      pickingStrategy
      categories {
        name
        mediaSnaps {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    additionalAttribute {
      stimuliVariant
      type
      pickingStrategy
      categories {
        name
        mediaSnaps {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    groupConstruction {
      instructionsScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      feedbackScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      fakeQuestions {
        randomizeSequence
        conditionalQuestion {
          questionText
          confirmText
          declineText
          answerType
          __typename
        }
        questionsAfter {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        questionsBefore {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        __typename
      }
      conditions {
        condition1
        condition2
        condition3
        __typename
      }
      groups {
        name
        color
        questionSet {
          randomizeSequence
          conditionalQuestion {
            questionText
            confirmText
            declineText
            answerType
            __typename
          }
          questionsAfter {
            noAnswer
            type
            questionText
            multiple {
              randomized
              options
              __typename
            }
            scale {
              invert
              min {
                name
                value
                __typename
              }
              max {
                name
                value
                __typename
              }
              __typename
            }
            mediaItemSnapshot {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            imageMultipleChoice {
              randomized
              options {
                id
                tags {
                  id
                  name
                  __typename
                }
                scope
                text
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                originalFileName
                __typename
              }
              __typename
            }
            stimulusTimeout
            __typename
          }
          questionsBefore {
            noAnswer
            type
            questionText
            multiple {
              randomized
              options
              __typename
            }
            scale {
              invert
              min {
                name
                value
                __typename
              }
              max {
                name
                value
                __typename
              }
              __typename
            }
            mediaItemSnapshot {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            imageMultipleChoice {
              randomized
              options {
                id
                tags {
                  id
                  name
                  __typename
                }
                scope
                text
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                originalFileName
                __typename
              }
              __typename
            }
            stimulusTimeout
            __typename
          }
          __typename
        }
        probability
        feedbackScreen {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        __typename
      }
      randomizeGroupColors
      __typename
    }
    learningPhase {
      instructionsScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      feedbackScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      questionsSet {
        randomizeSequence
        conditionalQuestion {
          questionText
          confirmText
          declineText
          answerType
          __typename
        }
        questionsAfter {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        questionsBefore {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        __typename
      }
      questionOffsetTimeout
      __typename
    }
    testPhase {
      instructionsScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      feedbackScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      questionsSet {
        randomizeSequence
        conditionalQuestion {
          questionText
          confirmText
          declineText
          answerType
          __typename
        }
        questionsAfter {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        questionsBefore {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        __typename
      }
      questionOffsetTimeout
      __typename
    }
    distractionPhase {
      instructionsScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      feedbackScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      questionsSet {
        randomizeSequence
        conditionalQuestion {
          questionText
          confirmText
          declineText
          answerType
          __typename
        }
        questionsAfter {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        questionsBefore {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        __typename
      }
      __typename
    }
    startLabel
    nextLabel
    finishLabel
    owner
    editors
    viewers
    resultViewers
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWSWTestQueryVariables,
  APITypes.GetWSWTestQuery
>;
export const listWSWTests = /* GraphQL */ `query ListWSWTests(
  $filter: ModelWSWTestFilterInput
  $limit: Int
  $nextToken: String
) {
  listWSWTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      language
      description
      instructions {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      style {
        maxWidth
        backgroundColour
        stimulusTextStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        mobileStimulusTextStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        optionsPosition
        optionsPositionMobile
        __typename
      }
      status
      endScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      attributeCombinations {
        mainCategory
        additionalCategory
        criteriaCategory
        amount
        multiplicator
        __typename
      }
      mainAttribute {
        stimuliVariant
        type
        pickingStrategy
        categories {
          name
          mediaSnaps {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          __typename
        }
        __typename
      }
      criteriaAttribute {
        stimuliVariant
        type
        pickingStrategy
        categories {
          name
          mediaSnaps {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          __typename
        }
        __typename
      }
      additionalAttribute {
        stimuliVariant
        type
        pickingStrategy
        categories {
          name
          mediaSnaps {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          __typename
        }
        __typename
      }
      groupConstruction {
        instructionsScreen {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        feedbackScreen {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        fakeQuestions {
          randomizeSequence
          conditionalQuestion {
            questionText
            confirmText
            declineText
            answerType
            __typename
          }
          questionsAfter {
            noAnswer
            type
            questionText
            multiple {
              randomized
              options
              __typename
            }
            scale {
              invert
              min {
                name
                value
                __typename
              }
              max {
                name
                value
                __typename
              }
              __typename
            }
            mediaItemSnapshot {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            imageMultipleChoice {
              randomized
              options {
                id
                tags {
                  id
                  name
                  __typename
                }
                scope
                text
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                originalFileName
                __typename
              }
              __typename
            }
            stimulusTimeout
            __typename
          }
          questionsBefore {
            noAnswer
            type
            questionText
            multiple {
              randomized
              options
              __typename
            }
            scale {
              invert
              min {
                name
                value
                __typename
              }
              max {
                name
                value
                __typename
              }
              __typename
            }
            mediaItemSnapshot {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            imageMultipleChoice {
              randomized
              options {
                id
                tags {
                  id
                  name
                  __typename
                }
                scope
                text
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                originalFileName
                __typename
              }
              __typename
            }
            stimulusTimeout
            __typename
          }
          __typename
        }
        conditions {
          condition1
          condition2
          condition3
          __typename
        }
        groups {
          name
          color
          questionSet {
            randomizeSequence
            conditionalQuestion {
              questionText
              confirmText
              declineText
              answerType
              __typename
            }
            questionsAfter {
              noAnswer
              type
              questionText
              multiple {
                randomized
                options
                __typename
              }
              scale {
                invert
                min {
                  name
                  value
                  __typename
                }
                max {
                  name
                  value
                  __typename
                }
                __typename
              }
              mediaItemSnapshot {
                id
                tags {
                  id
                  name
                  __typename
                }
                scope
                text
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                originalFileName
                __typename
              }
              imageMultipleChoice {
                randomized
                options {
                  id
                  tags {
                    id
                    name
                    __typename
                  }
                  scope
                  text
                  image {
                    fullSize {
                      width
                      height
                      key
                      __typename
                    }
                    thumbNail {
                      width
                      height
                      key
                      __typename
                    }
                    __typename
                  }
                  originalFileName
                  __typename
                }
                __typename
              }
              stimulusTimeout
              __typename
            }
            questionsBefore {
              noAnswer
              type
              questionText
              multiple {
                randomized
                options
                __typename
              }
              scale {
                invert
                min {
                  name
                  value
                  __typename
                }
                max {
                  name
                  value
                  __typename
                }
                __typename
              }
              mediaItemSnapshot {
                id
                tags {
                  id
                  name
                  __typename
                }
                scope
                text
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                originalFileName
                __typename
              }
              imageMultipleChoice {
                randomized
                options {
                  id
                  tags {
                    id
                    name
                    __typename
                  }
                  scope
                  text
                  image {
                    fullSize {
                      width
                      height
                      key
                      __typename
                    }
                    thumbNail {
                      width
                      height
                      key
                      __typename
                    }
                    __typename
                  }
                  originalFileName
                  __typename
                }
                __typename
              }
              stimulusTimeout
              __typename
            }
            __typename
          }
          probability
          feedbackScreen {
            desktop
            mobile
            fontStyle {
              fontSize
              color
              alignment
              fontFace
              __typename
            }
            __typename
          }
          __typename
        }
        randomizeGroupColors
        __typename
      }
      learningPhase {
        instructionsScreen {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        feedbackScreen {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        questionsSet {
          randomizeSequence
          conditionalQuestion {
            questionText
            confirmText
            declineText
            answerType
            __typename
          }
          questionsAfter {
            noAnswer
            type
            questionText
            multiple {
              randomized
              options
              __typename
            }
            scale {
              invert
              min {
                name
                value
                __typename
              }
              max {
                name
                value
                __typename
              }
              __typename
            }
            mediaItemSnapshot {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            imageMultipleChoice {
              randomized
              options {
                id
                tags {
                  id
                  name
                  __typename
                }
                scope
                text
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                originalFileName
                __typename
              }
              __typename
            }
            stimulusTimeout
            __typename
          }
          questionsBefore {
            noAnswer
            type
            questionText
            multiple {
              randomized
              options
              __typename
            }
            scale {
              invert
              min {
                name
                value
                __typename
              }
              max {
                name
                value
                __typename
              }
              __typename
            }
            mediaItemSnapshot {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            imageMultipleChoice {
              randomized
              options {
                id
                tags {
                  id
                  name
                  __typename
                }
                scope
                text
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                originalFileName
                __typename
              }
              __typename
            }
            stimulusTimeout
            __typename
          }
          __typename
        }
        questionOffsetTimeout
        __typename
      }
      testPhase {
        instructionsScreen {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        feedbackScreen {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        questionsSet {
          randomizeSequence
          conditionalQuestion {
            questionText
            confirmText
            declineText
            answerType
            __typename
          }
          questionsAfter {
            noAnswer
            type
            questionText
            multiple {
              randomized
              options
              __typename
            }
            scale {
              invert
              min {
                name
                value
                __typename
              }
              max {
                name
                value
                __typename
              }
              __typename
            }
            mediaItemSnapshot {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            imageMultipleChoice {
              randomized
              options {
                id
                tags {
                  id
                  name
                  __typename
                }
                scope
                text
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                originalFileName
                __typename
              }
              __typename
            }
            stimulusTimeout
            __typename
          }
          questionsBefore {
            noAnswer
            type
            questionText
            multiple {
              randomized
              options
              __typename
            }
            scale {
              invert
              min {
                name
                value
                __typename
              }
              max {
                name
                value
                __typename
              }
              __typename
            }
            mediaItemSnapshot {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            imageMultipleChoice {
              randomized
              options {
                id
                tags {
                  id
                  name
                  __typename
                }
                scope
                text
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                originalFileName
                __typename
              }
              __typename
            }
            stimulusTimeout
            __typename
          }
          __typename
        }
        questionOffsetTimeout
        __typename
      }
      distractionPhase {
        instructionsScreen {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        feedbackScreen {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        questionsSet {
          randomizeSequence
          conditionalQuestion {
            questionText
            confirmText
            declineText
            answerType
            __typename
          }
          questionsAfter {
            noAnswer
            type
            questionText
            multiple {
              randomized
              options
              __typename
            }
            scale {
              invert
              min {
                name
                value
                __typename
              }
              max {
                name
                value
                __typename
              }
              __typename
            }
            mediaItemSnapshot {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            imageMultipleChoice {
              randomized
              options {
                id
                tags {
                  id
                  name
                  __typename
                }
                scope
                text
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                originalFileName
                __typename
              }
              __typename
            }
            stimulusTimeout
            __typename
          }
          questionsBefore {
            noAnswer
            type
            questionText
            multiple {
              randomized
              options
              __typename
            }
            scale {
              invert
              min {
                name
                value
                __typename
              }
              max {
                name
                value
                __typename
              }
              __typename
            }
            mediaItemSnapshot {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            imageMultipleChoice {
              randomized
              options {
                id
                tags {
                  id
                  name
                  __typename
                }
                scope
                text
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                originalFileName
                __typename
              }
              __typename
            }
            stimulusTimeout
            __typename
          }
          __typename
        }
        __typename
      }
      startLabel
      nextLabel
      finishLabel
      owner
      editors
      viewers
      resultViewers
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWSWTestsQueryVariables,
  APITypes.ListWSWTestsQuery
>;
export const getWswResult = /* GraphQL */ `query GetWswResult($id: ID!) {
  getWswResult(id: $id) {
    id
    testId
    clientId
    survey {
      probandId
      surveyId
      surveyProbandId
      testProviderName
      __typename
    }
    timing {
      renderStartDelay
      renderEndDelay
      startTriggerDelay
      endTriggerDelay
      controlRaceDelay
      __typename
    }
    device {
      ua
      browser {
        name
        version
        major
        __typename
      }
      device {
        model
        type
        vendor
        __typename
      }
      engine {
        name
        version
        __typename
      }
      os {
        name
        version
        __typename
      }
      cpu {
        architecture
        __typename
      }
      __typename
    }
    phase
    position
    trialIndex
    groupIndication {
      groupIndicatorShow
      groupIndicationCategory
      groupIndicationIndex
      groupIndicationColor
      __typename
    }
    questionType
    questionAnswer
    questionImageAnswerOriginalFileName
    noAnswer
    answerOptionIndex
    questionPosition
    questionPoolName
    questionSnapshot
    contentType
    resultStimulus {
      stimulusType
      stimulusId
      stimulusTextValue
      stimulusTags
      stimulusOriginalFilename
      __typename
    }
    attributeCombination {
      isLearningStimulus
      mainStimulus {
        stimulusType
        stimulusPoolCategory
        stimulusPoolIndex
        stimulusPoolPosition
        stimulusOriginalFilename
        type
        stimulusId
        stimulusTextValue
        stimulusTags
        __typename
      }
      criteriaStimulus {
        stimulusType
        stimulusPoolCategory
        stimulusPoolIndex
        stimulusPoolPosition
        stimulusOriginalFilename
        type
        stimulusId
        stimulusTextValue
        stimulusTags
        __typename
      }
      additionalStimulus {
        stimulusType
        stimulusPoolCategory
        stimulusPoolIndex
        stimulusPoolPosition
        stimulusOriginalFilename
        type
        stimulusId
        stimulusTextValue
        stimulusTags
        __typename
      }
      __typename
    }
    owner
    groupCondition
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWswResultQueryVariables,
  APITypes.GetWswResultQuery
>;
export const listWswResults = /* GraphQL */ `query ListWswResults(
  $filter: ModelWswResultFilterInput
  $limit: Int
  $nextToken: String
) {
  listWswResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      testId
      clientId
      survey {
        probandId
        surveyId
        surveyProbandId
        testProviderName
        __typename
      }
      timing {
        renderStartDelay
        renderEndDelay
        startTriggerDelay
        endTriggerDelay
        controlRaceDelay
        __typename
      }
      device {
        ua
        browser {
          name
          version
          major
          __typename
        }
        device {
          model
          type
          vendor
          __typename
        }
        engine {
          name
          version
          __typename
        }
        os {
          name
          version
          __typename
        }
        cpu {
          architecture
          __typename
        }
        __typename
      }
      phase
      position
      trialIndex
      groupIndication {
        groupIndicatorShow
        groupIndicationCategory
        groupIndicationIndex
        groupIndicationColor
        __typename
      }
      questionType
      questionAnswer
      questionImageAnswerOriginalFileName
      noAnswer
      answerOptionIndex
      questionPosition
      questionPoolName
      questionSnapshot
      contentType
      resultStimulus {
        stimulusType
        stimulusId
        stimulusTextValue
        stimulusTags
        stimulusOriginalFilename
        __typename
      }
      attributeCombination {
        isLearningStimulus
        mainStimulus {
          stimulusType
          stimulusPoolCategory
          stimulusPoolIndex
          stimulusPoolPosition
          stimulusOriginalFilename
          type
          stimulusId
          stimulusTextValue
          stimulusTags
          __typename
        }
        criteriaStimulus {
          stimulusType
          stimulusPoolCategory
          stimulusPoolIndex
          stimulusPoolPosition
          stimulusOriginalFilename
          type
          stimulusId
          stimulusTextValue
          stimulusTags
          __typename
        }
        additionalStimulus {
          stimulusType
          stimulusPoolCategory
          stimulusPoolIndex
          stimulusPoolPosition
          stimulusOriginalFilename
          type
          stimulusId
          stimulusTextValue
          stimulusTags
          __typename
        }
        __typename
      }
      owner
      groupCondition
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWswResultsQueryVariables,
  APITypes.ListWswResultsQuery
>;
export const wswResultsByTestId = /* GraphQL */ `query WswResultsByTestId(
  $testId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelWswResultFilterInput
  $limit: Int
  $nextToken: String
) {
  wswResultsByTestId(
    testId: $testId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      testId
      clientId
      survey {
        probandId
        surveyId
        surveyProbandId
        testProviderName
        __typename
      }
      timing {
        renderStartDelay
        renderEndDelay
        startTriggerDelay
        endTriggerDelay
        controlRaceDelay
        __typename
      }
      device {
        ua
        browser {
          name
          version
          major
          __typename
        }
        device {
          model
          type
          vendor
          __typename
        }
        engine {
          name
          version
          __typename
        }
        os {
          name
          version
          __typename
        }
        cpu {
          architecture
          __typename
        }
        __typename
      }
      phase
      position
      trialIndex
      groupIndication {
        groupIndicatorShow
        groupIndicationCategory
        groupIndicationIndex
        groupIndicationColor
        __typename
      }
      questionType
      questionAnswer
      questionImageAnswerOriginalFileName
      noAnswer
      answerOptionIndex
      questionPosition
      questionPoolName
      questionSnapshot
      contentType
      resultStimulus {
        stimulusType
        stimulusId
        stimulusTextValue
        stimulusTags
        stimulusOriginalFilename
        __typename
      }
      attributeCombination {
        isLearningStimulus
        mainStimulus {
          stimulusType
          stimulusPoolCategory
          stimulusPoolIndex
          stimulusPoolPosition
          stimulusOriginalFilename
          type
          stimulusId
          stimulusTextValue
          stimulusTags
          __typename
        }
        criteriaStimulus {
          stimulusType
          stimulusPoolCategory
          stimulusPoolIndex
          stimulusPoolPosition
          stimulusOriginalFilename
          type
          stimulusId
          stimulusTextValue
          stimulusTags
          __typename
        }
        additionalStimulus {
          stimulusType
          stimulusPoolCategory
          stimulusPoolIndex
          stimulusPoolPosition
          stimulusOriginalFilename
          type
          stimulusId
          stimulusTextValue
          stimulusTags
          __typename
        }
        __typename
      }
      owner
      groupCondition
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.WswResultsByTestIdQueryVariables,
  APITypes.WswResultsByTestIdQuery
>;
export const getSurveyProvider = /* GraphQL */ `query GetSurveyProvider($id: ID!) {
  getSurveyProvider(id: $id) {
    id
    slug
    name
    description
    snippetConfiguration {
      snippetType
      surveyIdReplacer
      probandIdReplacer
      __typename
    }
    setupDocumentation {
      steps {
        type
        textBeforeContent
        textAfterContent
        image {
          width
          height
          key
          __typename
        }
        __typename
      }
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSurveyProviderQueryVariables,
  APITypes.GetSurveyProviderQuery
>;
export const listSurveyProviders = /* GraphQL */ `query ListSurveyProviders(
  $filter: ModelSurveyProviderFilterInput
  $limit: Int
  $nextToken: String
) {
  listSurveyProviders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      slug
      name
      description
      snippetConfiguration {
        snippetType
        surveyIdReplacer
        probandIdReplacer
        __typename
      }
      setupDocumentation {
        steps {
          type
          textBeforeContent
          textAfterContent
          image {
            width
            height
            key
            __typename
          }
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSurveyProvidersQueryVariables,
  APITypes.ListSurveyProvidersQuery
>;
export const getUserRegistration = /* GraphQL */ `query GetUserRegistration($id: ID!) {
  getUserRegistration(id: $id) {
    id
    name
    email
    institution
    researchGoal
    additionalInformation
    confirmedUsagePolicy
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserRegistrationQueryVariables,
  APITypes.GetUserRegistrationQuery
>;
export const listUserRegistrations = /* GraphQL */ `query ListUserRegistrations(
  $filter: ModelUserRegistrationFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserRegistrations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      email
      institution
      researchGoal
      additionalInformation
      confirmedUsagePolicy
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserRegistrationsQueryVariables,
  APITypes.ListUserRegistrationsQuery
>;
export const getBroadcastMail = /* GraphQL */ `query GetBroadcastMail($id: ID!) {
  getBroadcastMail(id: $id) {
    id
    subject
    sender
    content
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBroadcastMailQueryVariables,
  APITypes.GetBroadcastMailQuery
>;
export const listBroadcastMails = /* GraphQL */ `query ListBroadcastMails(
  $filter: ModelBroadcastMailFilterInput
  $limit: Int
  $nextToken: String
) {
  listBroadcastMails(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      subject
      sender
      content
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBroadcastMailsQueryVariables,
  APITypes.ListBroadcastMailsQuery
>;
export const getMediaItemTags = /* GraphQL */ `query GetMediaItemTags($id: ID!) {
  getMediaItemTags(id: $id) {
    id
    tagId
    mediaItemId
    tag {
      id
      name
      mediaItems {
        items {
          id
          tagId
          mediaItemId
          tag {
            id
            name
            mediaItems {
              items {
                id
                tagId
                mediaItemId
                tag {
                  id
                  name
                  mediaItems {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                mediaItem {
                  id
                  image {
                    fullSize {
                      width
                      height
                      key
                      __typename
                    }
                    thumbNail {
                      width
                      height
                      key
                      __typename
                    }
                    __typename
                  }
                  source
                  originalFileName
                  tags {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  tagsString
                  tagsIds
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          mediaItem {
            id
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            source
            originalFileName
            tags {
              items {
                id
                tagId
                mediaItemId
                tag {
                  id
                  name
                  mediaItems {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                mediaItem {
                  id
                  image {
                    fullSize {
                      width
                      height
                      key
                      __typename
                    }
                    thumbNail {
                      width
                      height
                      key
                      __typename
                    }
                    __typename
                  }
                  source
                  originalFileName
                  tags {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  tagsString
                  tagsIds
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tagsString
            tagsIds
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    mediaItem {
      id
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      source
      originalFileName
      tags {
        items {
          id
          tagId
          mediaItemId
          tag {
            id
            name
            mediaItems {
              items {
                id
                tagId
                mediaItemId
                tag {
                  id
                  name
                  mediaItems {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                mediaItem {
                  id
                  image {
                    fullSize {
                      width
                      height
                      key
                      __typename
                    }
                    thumbNail {
                      width
                      height
                      key
                      __typename
                    }
                    __typename
                  }
                  source
                  originalFileName
                  tags {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  tagsString
                  tagsIds
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          mediaItem {
            id
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            source
            originalFileName
            tags {
              items {
                id
                tagId
                mediaItemId
                tag {
                  id
                  name
                  mediaItems {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                mediaItem {
                  id
                  image {
                    fullSize {
                      width
                      height
                      key
                      __typename
                    }
                    thumbNail {
                      width
                      height
                      key
                      __typename
                    }
                    __typename
                  }
                  source
                  originalFileName
                  tags {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  tagsString
                  tagsIds
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tagsString
            tagsIds
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tagsString
      tagsIds
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMediaItemTagsQueryVariables,
  APITypes.GetMediaItemTagsQuery
>;
export const listMediaItemTags = /* GraphQL */ `query ListMediaItemTags(
  $filter: ModelMediaItemTagsFilterInput
  $limit: Int
  $nextToken: String
) {
  listMediaItemTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tagId
      mediaItemId
      tag {
        id
        name
        mediaItems {
          items {
            id
            tagId
            mediaItemId
            tag {
              id
              name
              mediaItems {
                items {
                  id
                  tagId
                  mediaItemId
                  tag {
                    id
                    name
                    mediaItems {
                      items {
                        id
                        tagId
                        mediaItemId
                        tag {
                          id
                          name
                          mediaItems {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        mediaItem {
                          id
                          image {
                            fullSize {
                              width
                              height
                              key
                              __typename
                            }
                            thumbNail {
                              width
                              height
                              key
                              __typename
                            }
                            __typename
                          }
                          source
                          originalFileName
                          tags {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          tagsString
                          tagsIds
                          createdAt
                          updatedAt
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  mediaItem {
                    id
                    image {
                      fullSize {
                        width
                        height
                        key
                        __typename
                      }
                      thumbNail {
                        width
                        height
                        key
                        __typename
                      }
                      __typename
                    }
                    source
                    originalFileName
                    tags {
                      items {
                        id
                        tagId
                        mediaItemId
                        tag {
                          id
                          name
                          mediaItems {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        mediaItem {
                          id
                          image {
                            fullSize {
                              width
                              height
                              key
                              __typename
                            }
                            thumbNail {
                              width
                              height
                              key
                              __typename
                            }
                            __typename
                          }
                          source
                          originalFileName
                          tags {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          tagsString
                          tagsIds
                          createdAt
                          updatedAt
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    tagsString
                    tagsIds
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            mediaItem {
              id
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              source
              originalFileName
              tags {
                items {
                  id
                  tagId
                  mediaItemId
                  tag {
                    id
                    name
                    mediaItems {
                      items {
                        id
                        tagId
                        mediaItemId
                        tag {
                          id
                          name
                          mediaItems {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        mediaItem {
                          id
                          image {
                            fullSize {
                              width
                              height
                              key
                              __typename
                            }
                            thumbNail {
                              width
                              height
                              key
                              __typename
                            }
                            __typename
                          }
                          source
                          originalFileName
                          tags {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          tagsString
                          tagsIds
                          createdAt
                          updatedAt
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  mediaItem {
                    id
                    image {
                      fullSize {
                        width
                        height
                        key
                        __typename
                      }
                      thumbNail {
                        width
                        height
                        key
                        __typename
                      }
                      __typename
                    }
                    source
                    originalFileName
                    tags {
                      items {
                        id
                        tagId
                        mediaItemId
                        tag {
                          id
                          name
                          mediaItems {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        mediaItem {
                          id
                          image {
                            fullSize {
                              width
                              height
                              key
                              __typename
                            }
                            thumbNail {
                              width
                              height
                              key
                              __typename
                            }
                            __typename
                          }
                          source
                          originalFileName
                          tags {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          tagsString
                          tagsIds
                          createdAt
                          updatedAt
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    tagsString
                    tagsIds
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              tagsString
              tagsIds
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      mediaItem {
        id
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        source
        originalFileName
        tags {
          items {
            id
            tagId
            mediaItemId
            tag {
              id
              name
              mediaItems {
                items {
                  id
                  tagId
                  mediaItemId
                  tag {
                    id
                    name
                    mediaItems {
                      items {
                        id
                        tagId
                        mediaItemId
                        tag {
                          id
                          name
                          mediaItems {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        mediaItem {
                          id
                          image {
                            fullSize {
                              width
                              height
                              key
                              __typename
                            }
                            thumbNail {
                              width
                              height
                              key
                              __typename
                            }
                            __typename
                          }
                          source
                          originalFileName
                          tags {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          tagsString
                          tagsIds
                          createdAt
                          updatedAt
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  mediaItem {
                    id
                    image {
                      fullSize {
                        width
                        height
                        key
                        __typename
                      }
                      thumbNail {
                        width
                        height
                        key
                        __typename
                      }
                      __typename
                    }
                    source
                    originalFileName
                    tags {
                      items {
                        id
                        tagId
                        mediaItemId
                        tag {
                          id
                          name
                          mediaItems {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        mediaItem {
                          id
                          image {
                            fullSize {
                              width
                              height
                              key
                              __typename
                            }
                            thumbNail {
                              width
                              height
                              key
                              __typename
                            }
                            __typename
                          }
                          source
                          originalFileName
                          tags {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          tagsString
                          tagsIds
                          createdAt
                          updatedAt
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    tagsString
                    tagsIds
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            mediaItem {
              id
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              source
              originalFileName
              tags {
                items {
                  id
                  tagId
                  mediaItemId
                  tag {
                    id
                    name
                    mediaItems {
                      items {
                        id
                        tagId
                        mediaItemId
                        tag {
                          id
                          name
                          mediaItems {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        mediaItem {
                          id
                          image {
                            fullSize {
                              width
                              height
                              key
                              __typename
                            }
                            thumbNail {
                              width
                              height
                              key
                              __typename
                            }
                            __typename
                          }
                          source
                          originalFileName
                          tags {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          tagsString
                          tagsIds
                          createdAt
                          updatedAt
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  mediaItem {
                    id
                    image {
                      fullSize {
                        width
                        height
                        key
                        __typename
                      }
                      thumbNail {
                        width
                        height
                        key
                        __typename
                      }
                      __typename
                    }
                    source
                    originalFileName
                    tags {
                      items {
                        id
                        tagId
                        mediaItemId
                        tag {
                          id
                          name
                          mediaItems {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        mediaItem {
                          id
                          image {
                            fullSize {
                              width
                              height
                              key
                              __typename
                            }
                            thumbNail {
                              width
                              height
                              key
                              __typename
                            }
                            __typename
                          }
                          source
                          originalFileName
                          tags {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          tagsString
                          tagsIds
                          createdAt
                          updatedAt
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    tagsString
                    tagsIds
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              tagsString
              tagsIds
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tagsString
        tagsIds
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMediaItemTagsQueryVariables,
  APITypes.ListMediaItemTagsQuery
>;
export const mediaItemTagsByTagId = /* GraphQL */ `query MediaItemTagsByTagId(
  $tagId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMediaItemTagsFilterInput
  $limit: Int
  $nextToken: String
) {
  mediaItemTagsByTagId(
    tagId: $tagId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tagId
      mediaItemId
      tag {
        id
        name
        mediaItems {
          items {
            id
            tagId
            mediaItemId
            tag {
              id
              name
              mediaItems {
                items {
                  id
                  tagId
                  mediaItemId
                  tag {
                    id
                    name
                    mediaItems {
                      items {
                        id
                        tagId
                        mediaItemId
                        tag {
                          id
                          name
                          mediaItems {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        mediaItem {
                          id
                          image {
                            fullSize {
                              width
                              height
                              key
                              __typename
                            }
                            thumbNail {
                              width
                              height
                              key
                              __typename
                            }
                            __typename
                          }
                          source
                          originalFileName
                          tags {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          tagsString
                          tagsIds
                          createdAt
                          updatedAt
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  mediaItem {
                    id
                    image {
                      fullSize {
                        width
                        height
                        key
                        __typename
                      }
                      thumbNail {
                        width
                        height
                        key
                        __typename
                      }
                      __typename
                    }
                    source
                    originalFileName
                    tags {
                      items {
                        id
                        tagId
                        mediaItemId
                        tag {
                          id
                          name
                          mediaItems {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        mediaItem {
                          id
                          image {
                            fullSize {
                              width
                              height
                              key
                              __typename
                            }
                            thumbNail {
                              width
                              height
                              key
                              __typename
                            }
                            __typename
                          }
                          source
                          originalFileName
                          tags {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          tagsString
                          tagsIds
                          createdAt
                          updatedAt
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    tagsString
                    tagsIds
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            mediaItem {
              id
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              source
              originalFileName
              tags {
                items {
                  id
                  tagId
                  mediaItemId
                  tag {
                    id
                    name
                    mediaItems {
                      items {
                        id
                        tagId
                        mediaItemId
                        tag {
                          id
                          name
                          mediaItems {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        mediaItem {
                          id
                          image {
                            fullSize {
                              width
                              height
                              key
                              __typename
                            }
                            thumbNail {
                              width
                              height
                              key
                              __typename
                            }
                            __typename
                          }
                          source
                          originalFileName
                          tags {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          tagsString
                          tagsIds
                          createdAt
                          updatedAt
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  mediaItem {
                    id
                    image {
                      fullSize {
                        width
                        height
                        key
                        __typename
                      }
                      thumbNail {
                        width
                        height
                        key
                        __typename
                      }
                      __typename
                    }
                    source
                    originalFileName
                    tags {
                      items {
                        id
                        tagId
                        mediaItemId
                        tag {
                          id
                          name
                          mediaItems {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        mediaItem {
                          id
                          image {
                            fullSize {
                              width
                              height
                              key
                              __typename
                            }
                            thumbNail {
                              width
                              height
                              key
                              __typename
                            }
                            __typename
                          }
                          source
                          originalFileName
                          tags {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          tagsString
                          tagsIds
                          createdAt
                          updatedAt
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    tagsString
                    tagsIds
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              tagsString
              tagsIds
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      mediaItem {
        id
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        source
        originalFileName
        tags {
          items {
            id
            tagId
            mediaItemId
            tag {
              id
              name
              mediaItems {
                items {
                  id
                  tagId
                  mediaItemId
                  tag {
                    id
                    name
                    mediaItems {
                      items {
                        id
                        tagId
                        mediaItemId
                        tag {
                          id
                          name
                          mediaItems {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        mediaItem {
                          id
                          image {
                            fullSize {
                              width
                              height
                              key
                              __typename
                            }
                            thumbNail {
                              width
                              height
                              key
                              __typename
                            }
                            __typename
                          }
                          source
                          originalFileName
                          tags {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          tagsString
                          tagsIds
                          createdAt
                          updatedAt
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  mediaItem {
                    id
                    image {
                      fullSize {
                        width
                        height
                        key
                        __typename
                      }
                      thumbNail {
                        width
                        height
                        key
                        __typename
                      }
                      __typename
                    }
                    source
                    originalFileName
                    tags {
                      items {
                        id
                        tagId
                        mediaItemId
                        tag {
                          id
                          name
                          mediaItems {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        mediaItem {
                          id
                          image {
                            fullSize {
                              width
                              height
                              key
                              __typename
                            }
                            thumbNail {
                              width
                              height
                              key
                              __typename
                            }
                            __typename
                          }
                          source
                          originalFileName
                          tags {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          tagsString
                          tagsIds
                          createdAt
                          updatedAt
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    tagsString
                    tagsIds
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            mediaItem {
              id
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              source
              originalFileName
              tags {
                items {
                  id
                  tagId
                  mediaItemId
                  tag {
                    id
                    name
                    mediaItems {
                      items {
                        id
                        tagId
                        mediaItemId
                        tag {
                          id
                          name
                          mediaItems {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        mediaItem {
                          id
                          image {
                            fullSize {
                              width
                              height
                              key
                              __typename
                            }
                            thumbNail {
                              width
                              height
                              key
                              __typename
                            }
                            __typename
                          }
                          source
                          originalFileName
                          tags {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          tagsString
                          tagsIds
                          createdAt
                          updatedAt
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  mediaItem {
                    id
                    image {
                      fullSize {
                        width
                        height
                        key
                        __typename
                      }
                      thumbNail {
                        width
                        height
                        key
                        __typename
                      }
                      __typename
                    }
                    source
                    originalFileName
                    tags {
                      items {
                        id
                        tagId
                        mediaItemId
                        tag {
                          id
                          name
                          mediaItems {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        mediaItem {
                          id
                          image {
                            fullSize {
                              width
                              height
                              key
                              __typename
                            }
                            thumbNail {
                              width
                              height
                              key
                              __typename
                            }
                            __typename
                          }
                          source
                          originalFileName
                          tags {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          tagsString
                          tagsIds
                          createdAt
                          updatedAt
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    tagsString
                    tagsIds
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              tagsString
              tagsIds
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tagsString
        tagsIds
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MediaItemTagsByTagIdQueryVariables,
  APITypes.MediaItemTagsByTagIdQuery
>;
export const mediaItemTagsByMediaItemId = /* GraphQL */ `query MediaItemTagsByMediaItemId(
  $mediaItemId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMediaItemTagsFilterInput
  $limit: Int
  $nextToken: String
) {
  mediaItemTagsByMediaItemId(
    mediaItemId: $mediaItemId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tagId
      mediaItemId
      tag {
        id
        name
        mediaItems {
          items {
            id
            tagId
            mediaItemId
            tag {
              id
              name
              mediaItems {
                items {
                  id
                  tagId
                  mediaItemId
                  tag {
                    id
                    name
                    mediaItems {
                      items {
                        id
                        tagId
                        mediaItemId
                        tag {
                          id
                          name
                          mediaItems {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        mediaItem {
                          id
                          image {
                            fullSize {
                              width
                              height
                              key
                              __typename
                            }
                            thumbNail {
                              width
                              height
                              key
                              __typename
                            }
                            __typename
                          }
                          source
                          originalFileName
                          tags {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          tagsString
                          tagsIds
                          createdAt
                          updatedAt
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  mediaItem {
                    id
                    image {
                      fullSize {
                        width
                        height
                        key
                        __typename
                      }
                      thumbNail {
                        width
                        height
                        key
                        __typename
                      }
                      __typename
                    }
                    source
                    originalFileName
                    tags {
                      items {
                        id
                        tagId
                        mediaItemId
                        tag {
                          id
                          name
                          mediaItems {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        mediaItem {
                          id
                          image {
                            fullSize {
                              width
                              height
                              key
                              __typename
                            }
                            thumbNail {
                              width
                              height
                              key
                              __typename
                            }
                            __typename
                          }
                          source
                          originalFileName
                          tags {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          tagsString
                          tagsIds
                          createdAt
                          updatedAt
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    tagsString
                    tagsIds
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            mediaItem {
              id
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              source
              originalFileName
              tags {
                items {
                  id
                  tagId
                  mediaItemId
                  tag {
                    id
                    name
                    mediaItems {
                      items {
                        id
                        tagId
                        mediaItemId
                        tag {
                          id
                          name
                          mediaItems {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        mediaItem {
                          id
                          image {
                            fullSize {
                              width
                              height
                              key
                              __typename
                            }
                            thumbNail {
                              width
                              height
                              key
                              __typename
                            }
                            __typename
                          }
                          source
                          originalFileName
                          tags {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          tagsString
                          tagsIds
                          createdAt
                          updatedAt
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  mediaItem {
                    id
                    image {
                      fullSize {
                        width
                        height
                        key
                        __typename
                      }
                      thumbNail {
                        width
                        height
                        key
                        __typename
                      }
                      __typename
                    }
                    source
                    originalFileName
                    tags {
                      items {
                        id
                        tagId
                        mediaItemId
                        tag {
                          id
                          name
                          mediaItems {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        mediaItem {
                          id
                          image {
                            fullSize {
                              width
                              height
                              key
                              __typename
                            }
                            thumbNail {
                              width
                              height
                              key
                              __typename
                            }
                            __typename
                          }
                          source
                          originalFileName
                          tags {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          tagsString
                          tagsIds
                          createdAt
                          updatedAt
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    tagsString
                    tagsIds
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              tagsString
              tagsIds
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      mediaItem {
        id
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        source
        originalFileName
        tags {
          items {
            id
            tagId
            mediaItemId
            tag {
              id
              name
              mediaItems {
                items {
                  id
                  tagId
                  mediaItemId
                  tag {
                    id
                    name
                    mediaItems {
                      items {
                        id
                        tagId
                        mediaItemId
                        tag {
                          id
                          name
                          mediaItems {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        mediaItem {
                          id
                          image {
                            fullSize {
                              width
                              height
                              key
                              __typename
                            }
                            thumbNail {
                              width
                              height
                              key
                              __typename
                            }
                            __typename
                          }
                          source
                          originalFileName
                          tags {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          tagsString
                          tagsIds
                          createdAt
                          updatedAt
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  mediaItem {
                    id
                    image {
                      fullSize {
                        width
                        height
                        key
                        __typename
                      }
                      thumbNail {
                        width
                        height
                        key
                        __typename
                      }
                      __typename
                    }
                    source
                    originalFileName
                    tags {
                      items {
                        id
                        tagId
                        mediaItemId
                        tag {
                          id
                          name
                          mediaItems {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        mediaItem {
                          id
                          image {
                            fullSize {
                              width
                              height
                              key
                              __typename
                            }
                            thumbNail {
                              width
                              height
                              key
                              __typename
                            }
                            __typename
                          }
                          source
                          originalFileName
                          tags {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          tagsString
                          tagsIds
                          createdAt
                          updatedAt
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    tagsString
                    tagsIds
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            mediaItem {
              id
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              source
              originalFileName
              tags {
                items {
                  id
                  tagId
                  mediaItemId
                  tag {
                    id
                    name
                    mediaItems {
                      items {
                        id
                        tagId
                        mediaItemId
                        tag {
                          id
                          name
                          mediaItems {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        mediaItem {
                          id
                          image {
                            fullSize {
                              width
                              height
                              key
                              __typename
                            }
                            thumbNail {
                              width
                              height
                              key
                              __typename
                            }
                            __typename
                          }
                          source
                          originalFileName
                          tags {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          tagsString
                          tagsIds
                          createdAt
                          updatedAt
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  mediaItem {
                    id
                    image {
                      fullSize {
                        width
                        height
                        key
                        __typename
                      }
                      thumbNail {
                        width
                        height
                        key
                        __typename
                      }
                      __typename
                    }
                    source
                    originalFileName
                    tags {
                      items {
                        id
                        tagId
                        mediaItemId
                        tag {
                          id
                          name
                          mediaItems {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        mediaItem {
                          id
                          image {
                            fullSize {
                              width
                              height
                              key
                              __typename
                            }
                            thumbNail {
                              width
                              height
                              key
                              __typename
                            }
                            __typename
                          }
                          source
                          originalFileName
                          tags {
                            items {
                              id
                              tagId
                              mediaItemId
                              tag {
                                id
                                name
                                mediaItems {
                                  nextToken
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              mediaItem {
                                id
                                image {
                                  __typename
                                }
                                source
                                originalFileName
                                tags {
                                  nextToken
                                  __typename
                                }
                                tagsString
                                tagsIds
                                createdAt
                                updatedAt
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            nextToken
                            __typename
                          }
                          tagsString
                          tagsIds
                          createdAt
                          updatedAt
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    tagsString
                    tagsIds
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              tagsString
              tagsIds
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tagsString
        tagsIds
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MediaItemTagsByMediaItemIdQueryVariables,
  APITypes.MediaItemTagsByMediaItemIdQuery
>;
