import { TrialPreviewTree } from './tree-walkers';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { StimuliRenderer } from './StimuliDisplayRenderer';
import React from 'react';
import { TestType } from '../../../../types';

export function BlockTrialStimuliTable({
  trial,
  testType,
  withHeaders = false,
}: {
  withHeaders?: boolean;
  testType: TestType;
  trial: TrialPreviewTree['blocks'][number]['trials'][number];
}) {
  return (
    <Table size="small" sx={{ marginLeft: '2em', textAlign: 'center' }}>
      {withHeaders && (
        <TableHead>
          <TableRow sx={{ fontWeight: 'bold' }}>
            <TableCell align="center">
              {testType === TestType.IAT ? 'Stimuli' : 'Prime'}
            </TableCell>
            <TableCell align="center">
              {testType === TestType.IAT ? 'Group' : 'Target'}
            </TableCell>
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        <TableRow>
          <TableCell align="center">
            <StimuliRenderer
              mediaItemSnapshot={trial.presentedStimuli[0].stimulusData.data.toMediaItemSnapshot()}
              descriptor={
                testType === TestType.IAT
                  ? undefined
                  : `${(trial.presentedStimuli[0].stimulusInfo.primeCategory as string).substring(0, 1).toUpperCase()} ${trial.presentedStimuli[0].stimulusInfo.listIndex as number}`
              }
            />
          </TableCell>
          <TableCell align="center">
            {testType === TestType.IAT ? (
              <Typography>
                <>
                  {trial.presentedStimuli[0].stimulusInfo.categoryTypeName}{' '}
                  {trial.presentedStimuli[0].stimulusInfo.categoryLabel}{' '}
                  {trial.presentedStimuli[0].stimulusInfo.listIndex}
                </>
              </Typography>
            ) : (
              <StimuliRenderer
                mediaItemSnapshot={trial.presentedStimuli[1].stimulusData.data.toMediaItemSnapshot()}
                descriptor={`TA ${trial.presentedStimuli[1].stimulusInfo.listIndex as number}`}
              />
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
