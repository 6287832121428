import { TestTree } from '../../graph/test-tree';
import { Subject } from 'rxjs';
import { TestFlowSnapshot } from '../../graph-state/test-director';
import React from 'react';
import { ControlInputMonitor } from '../../controls/control-monitor';
import { ControlHandlerManager } from '../../controls/control-handlers';
import { NewGQLClient } from '../../../../../GQL';
import {
  standAloneProvider,
  useSurveyProviderLookupMap,
} from '../../state/TestProviderMap';
import { TestsMetaData, TestType } from '../../../types';
import {
  MediaResourceLoadingManager,
  S3TestLoadingManager,
  TestMediaResourceMap,
} from '../../media/media-resource-loader';
import { BaseTest } from '../../../../tests/types';
import { TestBaseContext } from '../../state/baseTestContext/context/ContextTypes';
import { TestResourceAccessor } from '../../media/test-resource-loader';
import { SelectorTree } from '../../screens/selectors/selector-tree';
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  CircularProgress,
  Stack,
  Tab,
  Tabs,
} from '@mui/material';
import { ResultTableColumn } from '../../../../tests/IAT/runner/result-preview-table';
import { TreeSequenceNavigatorContainer } from './TreeSequenceItem';
import { StageRendererTest } from './StageRendererTest';
import { PreviewConfig, PreviewConfigType } from './PreviewConfig';
import { getGraphQL } from '../../../../utils';
import { TreeSequence } from '../../graph/tree-sequence/tree-sequence';
import { NodePathEntry } from '../../graph/tree-sequence/node-path-entry';
import { TestScreenComposer } from '../../screens/composer/test-screen-composer';
import { TestScreenInstanceController } from '../../screens/composer/screen-instance/test-screen-instance-controller';
import { PreviewTrialSummary } from './trial-summary/TrialSummary';

export function StagePreviewTest<T extends BaseTest>({
  test,
  testLoader,
  resultPreviewTable,
}: {
  resultPreviewTable?: ResultTableColumn<any>[];
  test: T;
  testLoader: {
    testType: TestType;
    controlFactory: (
      structure: any,
    ) => (inputMonitor: ControlInputMonitor) => ControlHandlerManager;
    selectorTree: SelectorTree<any>;
    createViews: (structure: any) => TestScreenComposer;
    load: (
      testContext: TestBaseContext,
      gql: NewGQLClient,
      mediaManager: MediaResourceLoadingManager<TestMediaResourceMap>,
    ) => Promise<{
      tree: TestTree;
      sequence: TreeSequence;
      structure: { accessor: TestResourceAccessor<T> };
    }>;
  };
}) {
  const surveyProviderLookupMap = useSurveyProviderLookupMap();
  const [previewConfig, setPreviewConfig] = React.useState<PreviewConfigType>();
  const [loadedTest, setLoadedTest] = React.useState<{
    persistResults: boolean;
    treeSequence: TreeSequence;
    structure: any;
    screenController: TestScreenInstanceController;
  }>();
  const [contentType, setContentType] = React.useState<
    'preview-stage' | 'trial-summary'
  >('preview-stage');
  const fakeSubject = React.useMemo(() => new Subject<TestFlowSnapshot>(), []);
  const stateChangeSubject = React.useMemo(
    () => new Subject<NodePathEntry>(),
    [],
  );

  return (
    <div style={{ width: '90vw', height: '90vh', display: 'flex' }}>
      <div style={{ flexGrow: 0, width: '20%' }}>
        {/*        {loadedTest && (
          <TreeSequenceNavigator
            stateChangeSubject={stateChangeSubject}
            subject={fakeSubject}
            treeSequence={loadedTest.treeSequence}
          />
        )}*/}
        {loadedTest && (
          <Stack spacing={1} height="100%">
            <Box sx={{ overflow: 'auto', flex: 1 }}>
              <TreeSequenceNavigatorContainer
                stateChangeSubject={stateChangeSubject}
                subject={fakeSubject}
                treeSequence={loadedTest.treeSequence}
              />
            </Box>
            <Box sx={{ padding: '1em' }}>
              <BottomNavigation
                showLabels
                value={contentType}
                onChange={(_, newValue) => {
                  setContentType(newValue as any);
                }}
              >
                <BottomNavigationAction
                  label="Test Preview"
                  value="preview-stage"
                />
                <BottomNavigationAction
                  label="Trial Summary"
                  value="trial-summary"
                />
              </BottomNavigation>
            </Box>
          </Stack>
        )}
        {/* {treeSequence && <TreeNavigationTree treeSequence={treeSequence} subject={fakeSubject} />}*/}
      </div>
      <div style={{ flexGrow: 1 }}>
        {loadedTest && contentType === 'preview-stage' && (
          <StageRendererTest
            resultPreviewTable={resultPreviewTable}
            screenSize={previewConfig!.screenSize}
            stateChangeSubject={stateChangeSubject}
            persistResults={loadedTest.persistResults}
            controllerHandlerFactory={testLoader.controlFactory(
              loadedTest.structure,
            )}
            treeSequence={loadedTest.treeSequence}
            subject={fakeSubject}
            controller={loadedTest.screenController}
            screenNodes={Array.from(
              loadedTest.treeSequence.nodeMap.values(),
            ).map((v) => v.node)}
          />
        )}
        {loadedTest && contentType === 'trial-summary' && (
          <PreviewTrialSummary
            treeSequence={loadedTest.treeSequence}
            testType={
              loadedTest.structure.accessor.testContext.testIdentity.testType
            }
            testAccessor={loadedTest.structure.accessor}
          />
        )}
        {!loadedTest && previewConfig && (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </div>
        )}
        {!loadedTest && !previewConfig && !!surveyProviderLookupMap && (
          <PreviewConfig
            configure={(config: PreviewConfigType) => {
              setPreviewConfig(config);
              const contextHack = standAloneProvider.createContext(
                {
                  pathParams: {
                    testId: test.id,
                    testType: TestsMetaData[testLoader.testType].identifier,
                  },
                  configurationParams: new URLSearchParams({
                    provider: 'standAlone',
                    mode: 'preview',
                    debug: 'true',
                  }),
                },
                surveyProviderLookupMap,
                true,
              );
              const hackedContext = {
                ...contextHack,
                deviceInfo: {
                  ...contextHack.deviceInfo,
                  mode: config.mode,
                },
              };
              getGraphQL().then((gql) => {
                testLoader
                  .load(hackedContext, gql, S3TestLoadingManager())
                  .then((res) => {
                    const views = testLoader.createViews(res.structure);
                    const selectorMap = testLoader.selectorTree.processNodes(
                      Array.from(res.sequence.nodeMap.values()).map(
                        (v) => v.node,
                      ),
                    );
                    console.log(selectorMap);
                    const scaler = views.createScreenScaler(selectorMap);
                    scaler.scaleTestScreen(config.screenSize).then((res1) => {
                      setLoadedTest({
                        persistResults: config.persistResults,
                        treeSequence: res.sequence,
                        structure: res.structure,
                        screenController:
                          res1.createScreenController(fakeSubject),
                      });
                    });
                  });
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
