/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type DeleteMediaItemTagsInput = {
  id: string,
};

export type ModelMediaItemTagsConditionInput = {
  tagId?: ModelIDInput | null,
  mediaItemId?: ModelIDInput | null,
  and?: Array< ModelMediaItemTagsConditionInput | null > | null,
  or?: Array< ModelMediaItemTagsConditionInput | null > | null,
  not?: ModelMediaItemTagsConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type MediaItemTags = {
  __typename: "MediaItemTags",
  id: string,
  tagId: string,
  mediaItemId: string,
  tag: Tag,
  mediaItem: MediaItem,
  createdAt: string,
  updatedAt: string,
};

export type Tag = {
  __typename: "Tag",
  id: string,
  name: string,
  mediaItems?: ModelMediaItemTagsConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type TagInterface = {
  __typename: "TagInterface",
  id: string,
  name: string,
};

export type TagSnapShot = {
  __typename: "TagSnapShot",
  id: string,
  name: string,
};

export type ModelMediaItemTagsConnection = {
  __typename: "ModelMediaItemTagsConnection",
  items:  Array<MediaItemTags | null >,
  nextToken?: string | null,
};

export type MediaItem = {
  __typename: "MediaItem",
  id: string,
  image: Image,
  source?: string | null,
  originalFileName: string,
  tags?: ModelMediaItemTagsConnection | null,
  tagsString?: string | null,
  tagsIds: Array< string >,
  createdAt: string,
  updatedAt: string,
};

export type Image = {
  __typename: "Image",
  fullSize: ImageInstance,
  thumbNail: ImageInstance,
};

export type ImageInstance = {
  __typename: "ImageInstance",
  width: number,
  height: number,
  key: string,
};

export type CreateMediaItemTagsInput = {
  id?: string | null,
  tagId: string,
  mediaItemId: string,
};

export type UpdateMediaItemInput = {
  id: string,
  image?: ImageInput | null,
  source?: string | null,
  originalFileName?: string | null,
  tagsString?: string | null,
  tagsIds?: Array< string > | null,
};

export type ImageInput = {
  fullSize: ImageInstanceInput,
  thumbNail: ImageInstanceInput,
};

export type ImageInstanceInput = {
  width: number,
  height: number,
  key: string,
};

export type ModelMediaItemConditionInput = {
  source?: ModelStringInput | null,
  originalFileName?: ModelStringInput | null,
  tagsString?: ModelStringInput | null,
  tagsIds?: ModelStringInput | null,
  and?: Array< ModelMediaItemConditionInput | null > | null,
  or?: Array< ModelMediaItemConditionInput | null > | null,
  not?: ModelMediaItemConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type CreateMediaItemInput = {
  id?: string | null,
  image: ImageInput,
  source?: string | null,
  originalFileName: string,
  tagsString?: string | null,
  tagsIds: Array< string >,
};

export type FunctionBodyResponse = {
  __typename: "FunctionBodyResponse",
  body?: string | null,
};

export type ModelMediaItemFilterInput = {
  id?: ModelIDInput | null,
  source?: ModelStringInput | null,
  originalFileName?: ModelStringInput | null,
  tagsString?: ModelStringInput | null,
  tagsIds?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMediaItemFilterInput | null > | null,
  or?: Array< ModelMediaItemFilterInput | null > | null,
  not?: ModelMediaItemFilterInput | null,
};

export type ModelMediaItemConnection = {
  __typename: "ModelMediaItemConnection",
  items:  Array<MediaItem | null >,
  nextToken?: string | null,
};

export type ModelMediaItemTagsFilterInput = {
  id?: ModelIDInput | null,
  tagId?: ModelIDInput | null,
  mediaItemId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMediaItemTagsFilterInput | null > | null,
  or?: Array< ModelMediaItemTagsFilterInput | null > | null,
  not?: ModelMediaItemTagsFilterInput | null,
};

export type ModelTagFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTagFilterInput | null > | null,
  or?: Array< ModelTagFilterInput | null > | null,
  not?: ModelTagFilterInput | null,
};

export type ModelTagConnection = {
  __typename: "ModelTagConnection",
  items:  Array<Tag | null >,
  nextToken?: string | null,
};

export type IatTest = {
  __typename: "IatTest",
  id: string,
  name: string,
  description: string,
  language?: string | null,
  instructions: StyleableTextTemplate,
  keyboard: KeyboardSettings,
  interTrialInterval: number,
  counterbalancing: boolean,
  probabilityWithoutReplacement: boolean,
  style: TestStyle,
  status: TestStatus,
  previewCategories: CategoryPreviewDisplay,
  blocks:  Array<IatBlock >,
  targetCategories: IatCategories,
  categoryDivider?: StyleableTextTemplate | null,
  attributeCategories: IatCategories,
  endScreen?: StyleableTextTemplate | null,
  startLabel: string,
  nextLabel: string,
  finishLabel: string,
  feedback: IatFeedbackType,
  feedbackInterval?: number | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  viewers?: Array< string | null > | null,
  resultViewers?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type TestInterface = {
  __typename: "TestInterface",
  id: string,
  name: string,
  description: string,
  language?: string | null,
  instructions: StyleableTextTemplate,
  keyboard: KeyboardSettings,
  counterbalancing: boolean,
  probabilityWithoutReplacement: boolean,
  interTrialInterval: number,
  style: TestStyle,
  status: TestStatus,
  endScreen?: StyleableTextTemplate | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  viewers?: Array< string | null > | null,
  resultViewers?: Array< string | null > | null,
};

export type AmpTest = {
  __typename: "AmpTest",
  id: string,
  name: string,
  description: string,
  language?: string | null,
  instructions: StyleableTextTemplate,
  keyboard: KeyboardSettings,
  counterbalancing: boolean,
  probabilityWithoutReplacement: boolean,
  interTrialInterval: number,
  style: TestStyle,
  status: TestStatus,
  endScreen?: StyleableTextTemplate | null,
  attributeCategories: AmpAttributeCategories,
  fixation?: TimedMediaItem | null,
  primes: TimedAmpCategories,
  targets: TimedAmpCategories,
  mask: MediaItemSnapshot,
  blankInterval: number,
  blocks:  Array<AmpBlock >,
  startLabel: string,
  nextLabel: string,
  finishLabel: string,
  owner?: string | null,
  editors?: Array< string | null > | null,
  viewers?: Array< string | null > | null,
  resultViewers?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type StyleableTextTemplate = {
  __typename: "StyleableTextTemplate",
  desktop: string,
  mobile?: string | null,
  fontStyle?: FontStyle | null,
};

export type FontStyle = {
  __typename: "FontStyle",
  fontSize: number,
  color: string,
  alignment: TextAlignment,
  fontFace: FontFace,
};

export enum TextAlignment {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  CENTER = "CENTER",
}


export enum FontFace {
  ARIAL = "ARIAL",
  VERDANA = "VERDANA",
  HELVETICA = "HELVETICA",
  TAHOMA = "TAHOMA",
  TREBUCHET_MS = "TREBUCHET_MS",
  TIMES_NEW_ROMAN = "TIMES_NEW_ROMAN",
  GEORGIA = "GEORGIA",
  GARAMOND = "GARAMOND",
  COURIER_NEW = "COURIER_NEW",
  BRUSH_SCRIPT_MT = "BRUSH_SCRIPT_MT",
}


export type KeyboardSettings = {
  __typename: "KeyboardSettings",
  left: string,
  right: string,
  confirm: string,
};

export type TestStyle = {
  __typename: "TestStyle",
  maxWidth: number,
  backgroundColour?: string | null,
  stimulusTextStyle: FontStyle,
  mobileStimulusTextStyle?: FontStyle | null,
  optionsPosition: OptionsPosition,
  optionsPositionMobile?: OptionsPosition | null,
};

export enum OptionsPosition {
  TOP = "TOP",
  MIDDLE = "MIDDLE",
  BOTTOM = "BOTTOM",
}


export enum TestStatus {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  CLOSED = "CLOSED",
  LIVE = "LIVE",
}


export type AmpAttributeCategories = {
  __typename: "AmpAttributeCategories",
  fontStyle: FontStyle,
  categoryA: MediaItemSnapshot,
  categoryB: MediaItemSnapshot,
};

export type MediaItemSnapshot = {
  __typename: "MediaItemSnapshot",
  id: string,
  tags:  Array<TagSnapShot >,
  scope: MediaItemSnapshotScope,
  text?: string | null,
  image?: Image | null,
  originalFileName?: string | null,
};

export enum MediaItemSnapshotScope {
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
}


export type TimedMediaItem = {
  __typename: "TimedMediaItem",
  interval: number,
  mediaItem: MediaItemSnapshot,
};

export type TimedAmpCategories = {
  __typename: "TimedAmpCategories",
  interval: number,
  categories:  Array<AmpLabeledStimuliPool >,
};

export type AmpLabeledStimuliPool = {
  __typename: "AmpLabeledStimuliPool",
  label: string,
  practiceStimuliPool:  Array<MediaItemSnapshot >,
  testStimuliPool:  Array<MediaItemSnapshot >,
};

export type AmpBlock = {
  __typename: "AmpBlock",
  index: number,
  name: string,
  type: BlockType,
  instructions: StyleableTextTemplate,
  amountTrials: number,
  attributeVariant: CategoryVariant,
  tips?: StyleableTextTemplate | null,
};

export type BlockInterface = {
  __typename: "BlockInterface",
  index: number,
  name: string,
  type: BlockType,
  amountTrials: number,
  instructions: StyleableTextTemplate,
  tips?: StyleableTextTemplate | null,
};

export type IatBlock = {
  __typename: "IatBlock",
  index: number,
  type: BlockType,
  name: string,
  amountTrials: number,
  instructions: StyleableTextTemplate,
  categoryLabelsInInstructions: boolean,
  attributeCategoryVariant?: CategoryVariant | null,
  targetCategoryVariant?: CategoryVariant | null,
  tips?: StyleableTextTemplate | null,
};

export enum BlockType {
  PRACTICE = "PRACTICE",
  NON_PRACTICE = "NON_PRACTICE",
}


export enum CategoryVariant {
  DEFAULT = "DEFAULT",
  REVERSED = "REVERSED",
}


export type PodtBlock = {
  __typename: "PodtBlock",
  index: number,
  name: string,
  type: BlockType,
  amountTrials: number,
  instructions: StyleableTextTemplate,
  preparationScreen?: PodtPreparationScreen | null,
  tips?: StyleableTextTemplate | null,
};

export type PodtPreparationScreen = {
  __typename: "PodtPreparationScreen",
  interval: number,
  screen: StyleableTextTemplate,
};

export type PodtTest = {
  __typename: "PodtTest",
  id: string,
  name: string,
  description: string,
  language?: string | null,
  instructions: StyleableTextTemplate,
  keyboard: KeyboardSettings,
  counterbalancing: boolean,
  probabilityWithoutReplacement: boolean,
  interTrialInterval: number,
  style: TestStyle,
  status: TestStatus,
  endScreen?: StyleableTextTemplate | null,
  response: PodtResponse,
  scenes: PodtScenes,
  targets:  Array<PodtTargetCategory >,
  fixation?: TimedMediaItem | null,
  trialFeedback?: PodtTrialFeedback | null,
  blocks:  Array<PodtBlock >,
  showCategoriesAfterInstructions?: boolean | null,
  startLabel: string,
  nextLabel: string,
  finishLabel: string,
  owner?: string | null,
  editors?: Array< string | null > | null,
  viewers?: Array< string | null > | null,
  resultViewers?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type PodtResponse = {
  __typename: "PodtResponse",
  timeout: number,
  fontStyle: FontStyle,
  active: MediaItemSnapshot,
  passive: MediaItemSnapshot,
};

export type PodtScenes = {
  __typename: "PodtScenes",
  maxAmountScenesPerTrial: number,
  scenes:  Array<PodtScene >,
  intervalConfig: PodtSceneIntervalConfig,
};

export type PodtScene = {
  __typename: "PodtScene",
  mediaItem: MediaItemSnapshot,
  boxes:  Array<PodtBox >,
};

export type PodtBox = {
  __typename: "PodtBox",
  top: number,
  left: number,
  width: number,
  height: number,
};

export type PodtSceneIntervalConfig = {
  __typename: "PodtSceneIntervalConfig",
  min: number,
  max: number,
  stepSize: number,
};

export type PodtTargetCategory = {
  __typename: "PodtTargetCategory",
  label: string,
  practiceStimuliPool: PodtCategoryPool,
  testStimuliPool: PodtCategoryPool,
};

export type PodtCategoryPool = {
  __typename: "PodtCategoryPool",
  dangerousStimuli:  Array<MediaItemSnapshot >,
  nonDangerousStimuli:  Array<MediaItemSnapshot >,
};

export type PodtTrialFeedback = {
  __typename: "PodtTrialFeedback",
  correctRejectionText: WeightedStyledText,
  hitText: WeightedStyledText,
  missText: WeightedStyledText,
  falseAlarmText: WeightedStyledText,
  noResponseText: WeightedStyledText,
  pointsFontsize?: number | null,
};

export type WeightedStyledText = {
  __typename: "WeightedStyledText",
  value: string,
  fontStyle: FontStyle,
  weight: number,
};

export type PodtaTest = {
  __typename: "PodtaTest",
  id: string,
  name: string,
  description: string,
  language?: string | null,
  instructions: StyleableTextTemplate,
  keyboard: KeyboardSettings,
  counterbalancing: boolean,
  probabilityWithoutReplacement: boolean,
  interTrialInterval: number,
  style: TestStyle,
  status: TestStatus,
  endScreen?: StyleableTextTemplate | null,
  response: PodtResponse,
  scenes: PodtaScenes,
  targets:  Array<PodtTargetCategory >,
  fixation?: TimedMediaItem | null,
  trialFeedback?: PodtTrialFeedback | null,
  blocks:  Array<PodtBlock >,
  showCategoriesAfterInstructions?: boolean | null,
  startLabel: string,
  nextLabel: string,
  finishLabel: string,
  owner?: string | null,
  editors?: Array< string | null > | null,
  viewers?: Array< string | null > | null,
  resultViewers?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type PodtaScenes = {
  __typename: "PodtaScenes",
  maxAmountScenesPerTrial: number,
  scenes:  Array<PodtaScene >,
  intervalConfig: PodtSceneIntervalConfig,
};

export type PodtaScene = {
  __typename: "PodtaScene",
  mediaItem: MediaItemSnapshot,
  boxes:  Array<PodtaBox >,
};

export type PodtaBox = {
  __typename: "PodtaBox",
  top: number,
  left: number,
  width: number,
  height: number,
  position: PodtaBoxPosition,
};

export enum PodtaBoxPosition {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}


export enum CategoryPreviewDisplay {
  NONE = "NONE",
  INSTRUCTIONS = "INSTRUCTIONS",
  STAND_ALONE = "STAND_ALONE",
}


export type IatCategories = {
  __typename: "IatCategories",
  fontStyle: FontStyle,
  categoryA: LabeledStimuliPool,
  categoryB: LabeledStimuliPool,
};

export type LabeledStimuliPool = {
  __typename: "LabeledStimuliPool",
  label: MediaItemSnapshot,
  practiceStimuliPool:  Array<MediaItemSnapshot >,
  testStimuliPool:  Array<MediaItemSnapshot >,
};

export enum IatFeedbackType {
  BLOCKING = "BLOCKING",
  FEEDBACK_BLOCKING = "FEEDBACK_BLOCKING",
  NON_BLOCKING = "NON_BLOCKING",
  FEEDBACK_NON_BLOCKING = "FEEDBACK_NON_BLOCKING",
}


export type WSWTest = {
  __typename: "WSWTest",
  id: string,
  name: string,
  language?: string | null,
  description: string,
  instructions: StyleableTextTemplate,
  style: TestStyle,
  status: TestStatus,
  endScreen?: StyleableTextTemplate | null,
  attributeCombinations:  Array<AttributeCombination >,
  mainAttribute: WSWAttribute,
  criteriaAttribute: WSWAttribute,
  additionalAttribute?: WSWAttribute | null,
  groupConstruction?: GroupConstruction | null,
  learningPhase: PresentationPhase,
  testPhase: PresentationPhase,
  distractionPhase?: DistractionPhase | null,
  startLabel: string,
  nextLabel: string,
  finishLabel: string,
  owner?: string | null,
  editors?: Array< string | null > | null,
  viewers?: Array< string | null > | null,
  resultViewers?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type NewTestInterface = {
  __typename: "NewTestInterface",
  id: string,
  language?: string | null,
  name: string,
  description: string,
  instructions: StyleableTextTemplate,
  style: TestStyle,
  status: TestStatus,
  endScreen?: StyleableTextTemplate | null,
};

export type AttributeCombination = {
  __typename: "AttributeCombination",
  mainCategory: number,
  additionalCategory?: number | null,
  criteriaCategory?: number | null,
  amount: number,
  multiplicator: number,
};

export type WSWAttribute = {
  __typename: "WSWAttribute",
  stimuliVariant?: WswStimulusAttributeVariant | null,
  type: WSWAttributeType,
  pickingStrategy: PickingStrategy,
  categories?:  Array<WSWAttributeCategory > | null,
};

export enum WswStimulusAttributeVariant {
  Image = "Image",
  Text = "Text",
}


export enum WSWAttributeType {
  STIMULI = "STIMULI",
  GROUPS = "GROUPS",
}


export enum PickingStrategy {
  WITHOUT_REPLACEMENT = "WITHOUT_REPLACEMENT",
  WITH_REPLACEMENT = "WITH_REPLACEMENT",
  REFILLING_WITHOUT_REPLACEMENT = "REFILLING_WITHOUT_REPLACEMENT",
  PERMUTATION = "PERMUTATION",
}


export type WSWAttributeCategory = {
  __typename: "WSWAttributeCategory",
  name: string,
  mediaSnaps:  Array<MediaItemSnapshot >,
};

export type GroupConstruction = {
  __typename: "GroupConstruction",
  instructionsScreen?: StyleableTextTemplate | null,
  feedbackScreen?: StyleableTextTemplate | null,
  fakeQuestions: ConditionalQuestionSet,
  conditions: GroupConstructionConditions,
  groups:  Array<WSWGroup >,
  randomizeGroupColors: boolean,
};

export type ConditionalQuestionSet = {
  __typename: "ConditionalQuestionSet",
  randomizeSequence: boolean,
  conditionalQuestion?: ConditionalQuestion | null,
  questionsAfter:  Array<TestQuestion >,
  questionsBefore:  Array<TestQuestion >,
};

export type ConditionalQuestion = {
  __typename: "ConditionalQuestion",
  questionText: string,
  confirmText: string,
  declineText: string,
  answerType: ConditionalAnswerType,
};

export enum ConditionalAnswerType {
  CONFIRM = "CONFIRM",
  DECLINE = "DECLINE",
}


export type TestQuestion = {
  __typename: "TestQuestion",
  noAnswer?: string | null,
  type: QuestionType,
  questionText: string,
  multiple?: MultipleChoiceQuestion | null,
  scale?: ScaleQuestion | null,
  mediaItemSnapshot?: MediaItemSnapshot | null,
  imageMultipleChoice?: ImageMultipleChoiceQuestion | null,
  stimulusTimeout?: number | null,
};

export enum QuestionType {
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
  IMAGE_MULTIPLE_CHOICE = "IMAGE_MULTIPLE_CHOICE",
  SCALE = "SCALE",
  TEXT = "TEXT",
}


export type MultipleChoiceQuestion = {
  __typename: "MultipleChoiceQuestion",
  randomized: boolean,
  options: Array< string >,
};

export type ScaleQuestion = {
  __typename: "ScaleQuestion",
  invert: boolean,
  min: Boundary,
  max: Boundary,
};

export type Boundary = {
  __typename: "Boundary",
  name: string,
  value: number,
};

export type ImageMultipleChoiceQuestion = {
  __typename: "ImageMultipleChoiceQuestion",
  randomized: boolean,
  options:  Array<MediaItemSnapshot >,
};

export type GroupConstructionConditions = {
  __typename: "GroupConstructionConditions",
  condition1: number,
  condition2: number,
  condition3: number,
};

export type WSWGroup = {
  __typename: "WSWGroup",
  name: string,
  color: string,
  questionSet?: ConditionalQuestionSet | null,
  probability: number,
  feedbackScreen?: StyleableTextTemplate | null,
};

export type PresentationPhase = {
  __typename: "PresentationPhase",
  instructionsScreen?: StyleableTextTemplate | null,
  feedbackScreen?: StyleableTextTemplate | null,
  questionsSet: ConditionalQuestionSet,
  questionOffsetTimeout?: number | null,
};

export type DistractionPhase = {
  __typename: "DistractionPhase",
  instructionsScreen?: StyleableTextTemplate | null,
  feedbackScreen?: StyleableTextTemplate | null,
  questionsSet: ConditionalQuestionSet,
};

export enum Severity {
  error = "error",
  warn = "warn",
  info = "info",
}


export type MediaItemStatusResponse = {
  __typename: "MediaItemStatusResponse",
  mediaItemId: string,
  usingTests:  Array<TestUsageInformation >,
};

export type TestUsageInformation = {
  __typename: "TestUsageInformation",
  testId: string,
  testName: string,
  testType: string,
  testOwnerMail: string,
  testStatus: TestStatus,
};

export type AdminListUsersResponse = {
  __typename: "AdminListUsersResponse",
  users:  Array<UserListElement >,
};

export type UserListElement = {
  __typename: "UserListElement",
  sub: string,
  email: string,
  groups: Array< string >,
  userCreateDate: string,
  userLastModifiedDate: string,
  userStatus: string,
};

export type UserTestStatusResponse = {
  __typename: "UserTestStatusResponse",
  userId: string,
  ownedTests:  Array<TestOwnerInformation >,
};

export type TestOwnerInformation = {
  __typename: "TestOwnerInformation",
  testId: string,
  testName: string,
  testType: string,
  testStatus: TestStatus,
};

export type TestResultDownloadLinkResponse = {
  __typename: "TestResultDownloadLinkResponse",
  downloadLink: string,
};

export type RemoveMediaItemTagResponse = {
  __typename: "RemoveMediaItemTagResponse",
  type: RemoveMediaItemTagResultType,
  associatedMediaItems: Array< string >,
};

export enum RemoveMediaItemTagResultType {
  DELETED = "DELETED",
  DRY_RUN = "DRY_RUN",
}


export type CreateDocumentationFileInput = {
  id?: string | null,
  name: string,
  description: string,
  s3Key: string,
};

export type ModelDocumentationFileConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  s3Key?: ModelStringInput | null,
  and?: Array< ModelDocumentationFileConditionInput | null > | null,
  or?: Array< ModelDocumentationFileConditionInput | null > | null,
  not?: ModelDocumentationFileConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type DocumentationFile = {
  __typename: "DocumentationFile",
  id: string,
  name: string,
  description: string,
  s3Key: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateDocumentationFileInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  s3Key?: string | null,
};

export type DeleteDocumentationFileInput = {
  id: string,
};

export type CreateDocumentFileInput = {
  id?: string | null,
  path: Array< string >,
  name: string,
  description: string,
  format: string,
  currentVersion: DocumentFileVersionInput,
  history: Array< DocumentFileVersionInput >,
};

export type DocumentFileVersionInput = {
  createdAt: string,
  version: string,
  validSince?: string | null,
  s3Key: string,
};

export type ModelDocumentFileConditionInput = {
  path?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  format?: ModelStringInput | null,
  and?: Array< ModelDocumentFileConditionInput | null > | null,
  or?: Array< ModelDocumentFileConditionInput | null > | null,
  not?: ModelDocumentFileConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type DocumentFile = {
  __typename: "DocumentFile",
  id: string,
  path: Array< string >,
  name: string,
  description: string,
  format: string,
  currentVersion: DocumentFileVersion,
  history:  Array<DocumentFileVersion >,
  createdAt: string,
  updatedAt: string,
};

export type DocumentFileVersion = {
  __typename: "DocumentFileVersion",
  createdAt: string,
  version: string,
  validSince?: string | null,
  s3Key: string,
};

export type UpdateDocumentFileInput = {
  id: string,
  path?: Array< string > | null,
  name?: string | null,
  description?: string | null,
  format?: string | null,
  currentVersion?: DocumentFileVersionInput | null,
  history?: Array< DocumentFileVersionInput > | null,
};

export type DeleteDocumentFileInput = {
  id: string,
};

export type CreateTagInput = {
  id?: string | null,
  name: string,
};

export type ModelTagConditionInput = {
  name?: ModelStringInput | null,
  and?: Array< ModelTagConditionInput | null > | null,
  or?: Array< ModelTagConditionInput | null > | null,
  not?: ModelTagConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateTagInput = {
  id: string,
  name?: string | null,
};

export type DeleteTagInput = {
  id: string,
};

export type DeleteMediaItemInput = {
  id: string,
};

export type CreateTestFinalizationInput = {
  id?: string | null,
  testType: string,
  testId: string,
  probandId: string,
  loadingStartedAt: string,
  testStartedAt?: string | null,
  testFinalizedAt?: string | null,
  testAbortedAt?: string | null,
  owner?: string | null,
  status?: TestStatus | null,
};

export type ModelTestFinalizationConditionInput = {
  testType?: ModelStringInput | null,
  testId?: ModelIDInput | null,
  probandId?: ModelStringInput | null,
  loadingStartedAt?: ModelStringInput | null,
  testStartedAt?: ModelStringInput | null,
  testFinalizedAt?: ModelStringInput | null,
  testAbortedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  status?: ModelTestStatusInput | null,
  and?: Array< ModelTestFinalizationConditionInput | null > | null,
  or?: Array< ModelTestFinalizationConditionInput | null > | null,
  not?: ModelTestFinalizationConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelTestStatusInput = {
  eq?: TestStatus | null,
  ne?: TestStatus | null,
};

export type TestFinalization = {
  __typename: "TestFinalization",
  id: string,
  testType: string,
  testId: string,
  probandId: string,
  loadingStartedAt: string,
  testStartedAt?: string | null,
  testFinalizedAt?: string | null,
  testAbortedAt?: string | null,
  owner?: string | null,
  status?: TestStatus | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateTestFinalizationInput = {
  id: string,
  testType?: string | null,
  testId?: string | null,
  probandId?: string | null,
  loadingStartedAt?: string | null,
  testStartedAt?: string | null,
  testFinalizedAt?: string | null,
  testAbortedAt?: string | null,
  owner?: string | null,
  status?: TestStatus | null,
};

export type DeleteTestFinalizationInput = {
  id: string,
};

export type CreateTestResultsStatusInput = {
  id?: string | null,
  status: TestResultsStatusType,
  testType: string,
  error?: string | null,
  statistics?: TestResultsStatisticsInput | null,
};

export enum TestResultsStatusType {
  LOADING = "LOADING",
  ERROR = "ERROR",
  READY = "READY",
}


export type TestResultsStatisticsInput = {
  processedResultRows: number,
  exportedResults: number,
  processedProbandAmount: number,
  finishedProbandAmount: number,
};

export type ModelTestResultsStatusConditionInput = {
  status?: ModelTestResultsStatusTypeInput | null,
  testType?: ModelStringInput | null,
  error?: ModelStringInput | null,
  and?: Array< ModelTestResultsStatusConditionInput | null > | null,
  or?: Array< ModelTestResultsStatusConditionInput | null > | null,
  not?: ModelTestResultsStatusConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelTestResultsStatusTypeInput = {
  eq?: TestResultsStatusType | null,
  ne?: TestResultsStatusType | null,
};

export type TestResultsStatus = {
  __typename: "TestResultsStatus",
  id: string,
  status: TestResultsStatusType,
  testType: string,
  error?: string | null,
  statistics?: TestResultsStatistics | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type TestResultsStatistics = {
  __typename: "TestResultsStatistics",
  processedResultRows: number,
  exportedResults: number,
  processedProbandAmount: number,
  finishedProbandAmount: number,
};

export type UpdateTestResultsStatusInput = {
  id: string,
  status?: TestResultsStatusType | null,
  testType?: string | null,
  error?: string | null,
  statistics?: TestResultsStatisticsInput | null,
};

export type DeleteTestResultsStatusInput = {
  id: string,
};

export type CreateIatTestInput = {
  id?: string | null,
  name: string,
  description: string,
  language?: string | null,
  instructions: StyleableTextTemplateInput,
  keyboard: KeyboardSettingsInput,
  interTrialInterval: number,
  counterbalancing: boolean,
  probabilityWithoutReplacement: boolean,
  style: TestStyleInput,
  status: TestStatus,
  previewCategories: CategoryPreviewDisplay,
  blocks: Array< IatBlockInput >,
  targetCategories: IatCategoriesInput,
  categoryDivider?: StyleableTextTemplateInput | null,
  attributeCategories: IatCategoriesInput,
  endScreen?: StyleableTextTemplateInput | null,
  startLabel: string,
  nextLabel: string,
  finishLabel: string,
  feedback: IatFeedbackType,
  feedbackInterval?: number | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  viewers?: Array< string | null > | null,
  resultViewers?: Array< string | null > | null,
};

export type StyleableTextTemplateInput = {
  desktop: string,
  mobile?: string | null,
  fontStyle?: FontStyleInput | null,
};

export type FontStyleInput = {
  fontSize: number,
  color: string,
  alignment: TextAlignment,
  fontFace: FontFace,
};

export type KeyboardSettingsInput = {
  left: string,
  right: string,
  confirm: string,
};

export type TestStyleInput = {
  maxWidth: number,
  backgroundColour?: string | null,
  stimulusTextStyle: FontStyleInput,
  mobileStimulusTextStyle?: FontStyleInput | null,
  optionsPosition: OptionsPosition,
  optionsPositionMobile?: OptionsPosition | null,
};

export type IatBlockInput = {
  index: number,
  type: BlockType,
  name: string,
  amountTrials: number,
  instructions: StyleableTextTemplateInput,
  categoryLabelsInInstructions: boolean,
  attributeCategoryVariant?: CategoryVariant | null,
  targetCategoryVariant?: CategoryVariant | null,
  tips?: StyleableTextTemplateInput | null,
};

export type IatCategoriesInput = {
  fontStyle: FontStyleInput,
  categoryA: LabeledStimuliPoolInput,
  categoryB: LabeledStimuliPoolInput,
};

export type LabeledStimuliPoolInput = {
  label: MediaItemSnapshotInput,
  practiceStimuliPool: Array< MediaItemSnapshotInput >,
  testStimuliPool: Array< MediaItemSnapshotInput >,
};

export type MediaItemSnapshotInput = {
  id: string,
  tags: Array< TagSnapShotInput >,
  scope: MediaItemSnapshotScope,
  text?: string | null,
  image?: ImageInput | null,
  originalFileName?: string | null,
};

export type TagSnapShotInput = {
  id: string,
  name: string,
};

export type ModelIatTestConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  language?: ModelStringInput | null,
  interTrialInterval?: ModelIntInput | null,
  counterbalancing?: ModelBooleanInput | null,
  probabilityWithoutReplacement?: ModelBooleanInput | null,
  status?: ModelTestStatusInput | null,
  previewCategories?: ModelCategoryPreviewDisplayInput | null,
  startLabel?: ModelStringInput | null,
  nextLabel?: ModelStringInput | null,
  finishLabel?: ModelStringInput | null,
  feedback?: ModelIatFeedbackTypeInput | null,
  feedbackInterval?: ModelIntInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  viewers?: ModelStringInput | null,
  resultViewers?: ModelStringInput | null,
  and?: Array< ModelIatTestConditionInput | null > | null,
  or?: Array< ModelIatTestConditionInput | null > | null,
  not?: ModelIatTestConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelCategoryPreviewDisplayInput = {
  eq?: CategoryPreviewDisplay | null,
  ne?: CategoryPreviewDisplay | null,
};

export type ModelIatFeedbackTypeInput = {
  eq?: IatFeedbackType | null,
  ne?: IatFeedbackType | null,
};

export type UpdateIatTestInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  language?: string | null,
  instructions?: StyleableTextTemplateInput | null,
  keyboard?: KeyboardSettingsInput | null,
  interTrialInterval?: number | null,
  counterbalancing?: boolean | null,
  probabilityWithoutReplacement?: boolean | null,
  style?: TestStyleInput | null,
  status?: TestStatus | null,
  previewCategories?: CategoryPreviewDisplay | null,
  blocks?: Array< IatBlockInput > | null,
  targetCategories?: IatCategoriesInput | null,
  categoryDivider?: StyleableTextTemplateInput | null,
  attributeCategories?: IatCategoriesInput | null,
  endScreen?: StyleableTextTemplateInput | null,
  startLabel?: string | null,
  nextLabel?: string | null,
  finishLabel?: string | null,
  feedback?: IatFeedbackType | null,
  feedbackInterval?: number | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  viewers?: Array< string | null > | null,
  resultViewers?: Array< string | null > | null,
};

export type DeleteIatTestInput = {
  id: string,
};

export type CreateIatResultInput = {
  id?: string | null,
  testId: string,
  clientId: string,
  survey: ResultSurveyIdentifierInput,
  timing: ResultTimingInput,
  device: ProbandDeviceInput,
  blockIndex: number,
  blockName: string,
  blockType?: BlockType | null,
  trialIndex: number,
  actionResultName: string,
  actionTarget?: MediaItemSnapshotInput | null,
  actionAttribute?: MediaItemSnapshotInput | null,
  counterBalancingGroup?: string | null,
  stimulus: ResultStimulusInput,
  pressedButton?: string | null,
  triggeredControl?: string | null,
  attributeLeft?: MediaItemSnapshotInput | null,
  attributeRight?: MediaItemSnapshotInput | null,
  targetLeft?: MediaItemSnapshotInput | null,
  targetRight?: MediaItemSnapshotInput | null,
  status?: TestStatus | null,
};

export type ResultSurveyIdentifierInput = {
  probandId: string,
  surveyId?: string | null,
  surveyProbandId?: string | null,
  testProviderName: string,
};

export type ResultTimingInput = {
  renderStartDelay?: number | null,
  renderEndDelay?: number | null,
  startTriggerDelay: number,
  endTriggerDelay?: number | null,
  controlRaceDelay?: number | null,
};

export type ProbandDeviceInput = {
  ua: string,
  browser?: BrowserInfoInput | null,
  device?: DeviceInfoInput | null,
  engine?: EngineInfoInput | null,
  os?: OsInfoInput | null,
  cpu?: CpuInfoInput | null,
};

export type BrowserInfoInput = {
  name?: string | null,
  version?: string | null,
  major?: string | null,
};

export type DeviceInfoInput = {
  model?: string | null,
  type?: string | null,
  vendor?: string | null,
};

export type EngineInfoInput = {
  name?: string | null,
  version?: string | null,
};

export type OsInfoInput = {
  name?: string | null,
  version?: string | null,
};

export type CpuInfoInput = {
  architecture?: string | null,
};

export type ResultStimulusInput = {
  categoryLabel: MediaItemSnapshotInput,
  categoryType: IatCategoryType,
  stimulus: MediaItemSnapshotInput,
};

export enum IatCategoryType {
  TARGET = "TARGET",
  ATTRIBUTE = "ATTRIBUTE",
}


export type ModelIatResultConditionInput = {
  testId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  blockIndex?: ModelIntInput | null,
  blockName?: ModelStringInput | null,
  blockType?: ModelBlockTypeInput | null,
  trialIndex?: ModelIntInput | null,
  actionResultName?: ModelStringInput | null,
  counterBalancingGroup?: ModelStringInput | null,
  pressedButton?: ModelStringInput | null,
  triggeredControl?: ModelStringInput | null,
  status?: ModelTestStatusInput | null,
  and?: Array< ModelIatResultConditionInput | null > | null,
  or?: Array< ModelIatResultConditionInput | null > | null,
  not?: ModelIatResultConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelBlockTypeInput = {
  eq?: BlockType | null,
  ne?: BlockType | null,
};

export type IatResult = {
  __typename: "IatResult",
  id: string,
  testId: string,
  clientId: string,
  survey: ResultSurveyIdentifier,
  timing: ResultTiming,
  device: ProbandDevice,
  blockIndex: number,
  blockName: string,
  blockType?: BlockType | null,
  trialIndex: number,
  actionResultName: string,
  actionTarget?: MediaItemSnapshot | null,
  actionAttribute?: MediaItemSnapshot | null,
  counterBalancingGroup?: string | null,
  stimulus: ResultStimulus,
  pressedButton?: string | null,
  triggeredControl?: string | null,
  attributeLeft?: MediaItemSnapshot | null,
  attributeRight?: MediaItemSnapshot | null,
  targetLeft?: MediaItemSnapshot | null,
  targetRight?: MediaItemSnapshot | null,
  status?: TestStatus | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ResultSurveyIdentifier = {
  __typename: "ResultSurveyIdentifier",
  probandId: string,
  surveyId?: string | null,
  surveyProbandId?: string | null,
  testProviderName: string,
};

export type ResultTiming = {
  __typename: "ResultTiming",
  renderStartDelay?: number | null,
  renderEndDelay?: number | null,
  startTriggerDelay: number,
  endTriggerDelay?: number | null,
  controlRaceDelay?: number | null,
};

export type ProbandDevice = {
  __typename: "ProbandDevice",
  ua: string,
  browser?: BrowserInfo | null,
  device?: DeviceInfo | null,
  engine?: EngineInfo | null,
  os?: OsInfo | null,
  cpu?: CpuInfo | null,
};

export type BrowserInfo = {
  __typename: "BrowserInfo",
  name?: string | null,
  version?: string | null,
  major?: string | null,
};

export type DeviceInfo = {
  __typename: "DeviceInfo",
  model?: string | null,
  type?: string | null,
  vendor?: string | null,
};

export type EngineInfo = {
  __typename: "EngineInfo",
  name?: string | null,
  version?: string | null,
};

export type OsInfo = {
  __typename: "OsInfo",
  name?: string | null,
  version?: string | null,
};

export type CpuInfo = {
  __typename: "CpuInfo",
  architecture?: string | null,
};

export type ResultStimulus = {
  __typename: "ResultStimulus",
  categoryLabel: MediaItemSnapshot,
  categoryType: IatCategoryType,
  stimulus: MediaItemSnapshot,
};

export type UpdateIatResultInput = {
  id: string,
  testId?: string | null,
  clientId?: string | null,
  survey?: ResultSurveyIdentifierInput | null,
  timing?: ResultTimingInput | null,
  device?: ProbandDeviceInput | null,
  blockIndex?: number | null,
  blockName?: string | null,
  blockType?: BlockType | null,
  trialIndex?: number | null,
  actionResultName?: string | null,
  actionTarget?: MediaItemSnapshotInput | null,
  actionAttribute?: MediaItemSnapshotInput | null,
  counterBalancingGroup?: string | null,
  stimulus?: ResultStimulusInput | null,
  pressedButton?: string | null,
  triggeredControl?: string | null,
  attributeLeft?: MediaItemSnapshotInput | null,
  attributeRight?: MediaItemSnapshotInput | null,
  targetLeft?: MediaItemSnapshotInput | null,
  targetRight?: MediaItemSnapshotInput | null,
  status?: TestStatus | null,
};

export type DeleteIatResultInput = {
  id: string,
};

export type CreateAmpTestInput = {
  id?: string | null,
  name: string,
  description: string,
  language?: string | null,
  instructions: StyleableTextTemplateInput,
  keyboard: KeyboardSettingsInput,
  counterbalancing: boolean,
  probabilityWithoutReplacement: boolean,
  interTrialInterval: number,
  style: TestStyleInput,
  status: TestStatus,
  endScreen?: StyleableTextTemplateInput | null,
  attributeCategories: AmpAttributeCategoriesInput,
  fixation?: TimedMediaItemInput | null,
  primes: TimedAmpCategoriesInput,
  targets: TimedAmpCategoriesInput,
  mask: MediaItemSnapshotInput,
  blankInterval: number,
  blocks: Array< AmpBlockInput >,
  startLabel: string,
  nextLabel: string,
  finishLabel: string,
  owner?: string | null,
  editors?: Array< string | null > | null,
  viewers?: Array< string | null > | null,
  resultViewers?: Array< string | null > | null,
};

export type AmpAttributeCategoriesInput = {
  fontStyle: FontStyleInput,
  categoryA: MediaItemSnapshotInput,
  categoryB: MediaItemSnapshotInput,
};

export type TimedMediaItemInput = {
  interval: number,
  mediaItem: MediaItemSnapshotInput,
};

export type TimedAmpCategoriesInput = {
  interval: number,
  categories: Array< AmpLabeledStimuliPoolInput >,
};

export type AmpLabeledStimuliPoolInput = {
  label: string,
  practiceStimuliPool: Array< MediaItemSnapshotInput >,
  testStimuliPool: Array< MediaItemSnapshotInput >,
};

export type AmpBlockInput = {
  index: number,
  name: string,
  type: BlockType,
  instructions: StyleableTextTemplateInput,
  amountTrials: number,
  attributeVariant: CategoryVariant,
  tips?: StyleableTextTemplateInput | null,
};

export type ModelAmpTestConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  language?: ModelStringInput | null,
  counterbalancing?: ModelBooleanInput | null,
  probabilityWithoutReplacement?: ModelBooleanInput | null,
  interTrialInterval?: ModelIntInput | null,
  status?: ModelTestStatusInput | null,
  blankInterval?: ModelIntInput | null,
  startLabel?: ModelStringInput | null,
  nextLabel?: ModelStringInput | null,
  finishLabel?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  viewers?: ModelStringInput | null,
  resultViewers?: ModelStringInput | null,
  and?: Array< ModelAmpTestConditionInput | null > | null,
  or?: Array< ModelAmpTestConditionInput | null > | null,
  not?: ModelAmpTestConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateAmpTestInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  language?: string | null,
  instructions?: StyleableTextTemplateInput | null,
  keyboard?: KeyboardSettingsInput | null,
  counterbalancing?: boolean | null,
  probabilityWithoutReplacement?: boolean | null,
  interTrialInterval?: number | null,
  style?: TestStyleInput | null,
  status?: TestStatus | null,
  endScreen?: StyleableTextTemplateInput | null,
  attributeCategories?: AmpAttributeCategoriesInput | null,
  fixation?: TimedMediaItemInput | null,
  primes?: TimedAmpCategoriesInput | null,
  targets?: TimedAmpCategoriesInput | null,
  mask?: MediaItemSnapshotInput | null,
  blankInterval?: number | null,
  blocks?: Array< AmpBlockInput > | null,
  startLabel?: string | null,
  nextLabel?: string | null,
  finishLabel?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  viewers?: Array< string | null > | null,
  resultViewers?: Array< string | null > | null,
};

export type DeleteAmpTestInput = {
  id: string,
};

export type CreateAmpResultInput = {
  id?: string | null,
  testId: string,
  clientId: string,
  survey: ResultSurveyIdentifierInput,
  timing: ResultTimingInput,
  device: ProbandDeviceInput,
  blockIndex: number,
  blockName: string,
  blockType?: BlockType | null,
  trialIndex: number,
  actionResultName: string,
  counterBalancingGroup?: string | null,
  pressedButton?: string | null,
  triggeredControl?: string | null,
  attributeLeftStimulus?: MediaItemSnapshotInput | null,
  attributeRightStimulus?: MediaItemSnapshotInput | null,
  actionResultStimulus?: MediaItemSnapshotInput | null,
  attributeLeft: string,
  attributeRight: string,
  prime: AmpResultCategoryMediaItemInput,
  target: AmpResultCategoryMediaItemInput,
  status?: TestStatus | null,
};

export type AmpResultCategoryMediaItemInput = {
  label: string,
  mediaItem: MediaItemSnapshotInput,
};

export type ModelAmpResultConditionInput = {
  testId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  blockIndex?: ModelIntInput | null,
  blockName?: ModelStringInput | null,
  blockType?: ModelBlockTypeInput | null,
  trialIndex?: ModelIntInput | null,
  actionResultName?: ModelStringInput | null,
  counterBalancingGroup?: ModelStringInput | null,
  pressedButton?: ModelStringInput | null,
  triggeredControl?: ModelStringInput | null,
  attributeLeft?: ModelStringInput | null,
  attributeRight?: ModelStringInput | null,
  status?: ModelTestStatusInput | null,
  and?: Array< ModelAmpResultConditionInput | null > | null,
  or?: Array< ModelAmpResultConditionInput | null > | null,
  not?: ModelAmpResultConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type AmpResult = {
  __typename: "AmpResult",
  id: string,
  testId: string,
  clientId: string,
  survey: ResultSurveyIdentifier,
  timing: ResultTiming,
  device: ProbandDevice,
  blockIndex: number,
  blockName: string,
  blockType?: BlockType | null,
  trialIndex: number,
  actionResultName: string,
  counterBalancingGroup?: string | null,
  pressedButton?: string | null,
  triggeredControl?: string | null,
  attributeLeftStimulus?: MediaItemSnapshot | null,
  attributeRightStimulus?: MediaItemSnapshot | null,
  actionResultStimulus?: MediaItemSnapshot | null,
  attributeLeft: string,
  attributeRight: string,
  prime: AmpResultCategoryMediaItem,
  target: AmpResultCategoryMediaItem,
  status?: TestStatus | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type TestResultInterface = {
  __typename: "TestResultInterface",
  id: string,
  clientId: string,
  survey: ResultSurveyIdentifier,
  timing: ResultTiming,
  device: ProbandDevice,
  blockIndex: number,
  blockName: string,
  trialIndex: number,
  actionResultName: string,
  counterBalancingGroup?: string | null,
  owner?: string | null,
};

export type PodtResult = {
  __typename: "PodtResult",
  id: string,
  testId: string,
  clientId: string,
  survey: ResultSurveyIdentifier,
  timing: ResultTiming,
  device: ProbandDevice,
  blockIndex: number,
  blockName: string,
  blockType?: BlockType | null,
  trialIndex: number,
  actionResultName: string,
  counterBalancingGroup?: string | null,
  earnedPoints?: number | null,
  reactionLeft: string,
  reactionRight: string,
  scenes:  Array<PodtResultScene >,
  target: PodtResultTarget,
  accumulatedPoints: number,
  pressedButton?: string | null,
  triggeredControl?: string | null,
  status?: TestStatus | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type PodtResultScene = {
  __typename: "PodtResultScene",
  scene: PodtScene,
  interval: number,
};

export type PodtResultTarget = {
  __typename: "PodtResultTarget",
  mediaItem: MediaItemSnapshot,
  category: string,
  dangerous: boolean,
};

export type PodtaResult = {
  __typename: "PodtaResult",
  id: string,
  testId: string,
  clientId: string,
  survey: ResultSurveyIdentifier,
  timing: ResultTiming,
  device: ProbandDevice,
  blockIndex: number,
  blockName: string,
  blockType?: BlockType | null,
  trialIndex: number,
  actionResultName: string,
  counterBalancingGroup?: string | null,
  earnedPoints?: number | null,
  reactionLeft: string,
  reactionRight: string,
  scenes:  Array<PodtaResultScene >,
  target: PodtResultTarget,
  accumulatedPoints: number,
  pressedButton?: string | null,
  triggeredControl?: string | null,
  status?: TestStatus | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type PodtaResultScene = {
  __typename: "PodtaResultScene",
  scene: PodtaScene,
  interval: number,
};

export type AmpResultCategoryMediaItem = {
  __typename: "AmpResultCategoryMediaItem",
  label: string,
  mediaItem: MediaItemSnapshot,
};

export type UpdateAmpResultInput = {
  id: string,
  testId?: string | null,
  clientId?: string | null,
  survey?: ResultSurveyIdentifierInput | null,
  timing?: ResultTimingInput | null,
  device?: ProbandDeviceInput | null,
  blockIndex?: number | null,
  blockName?: string | null,
  blockType?: BlockType | null,
  trialIndex?: number | null,
  actionResultName?: string | null,
  counterBalancingGroup?: string | null,
  pressedButton?: string | null,
  triggeredControl?: string | null,
  attributeLeftStimulus?: MediaItemSnapshotInput | null,
  attributeRightStimulus?: MediaItemSnapshotInput | null,
  actionResultStimulus?: MediaItemSnapshotInput | null,
  attributeLeft?: string | null,
  attributeRight?: string | null,
  prime?: AmpResultCategoryMediaItemInput | null,
  target?: AmpResultCategoryMediaItemInput | null,
  status?: TestStatus | null,
};

export type DeleteAmpResultInput = {
  id: string,
};

export type CreatePodtTestInput = {
  id?: string | null,
  name: string,
  description: string,
  language?: string | null,
  instructions: StyleableTextTemplateInput,
  keyboard: KeyboardSettingsInput,
  counterbalancing: boolean,
  probabilityWithoutReplacement: boolean,
  interTrialInterval: number,
  style: TestStyleInput,
  status: TestStatus,
  endScreen?: StyleableTextTemplateInput | null,
  response: PodtResponseInput,
  scenes: PodtScenesInput,
  targets: Array< PodtTargetCategoryInput >,
  fixation?: TimedMediaItemInput | null,
  trialFeedback?: PodtTrialFeedbackInput | null,
  blocks: Array< PodtBlockInput >,
  showCategoriesAfterInstructions?: boolean | null,
  startLabel: string,
  nextLabel: string,
  finishLabel: string,
  owner?: string | null,
  editors?: Array< string | null > | null,
  viewers?: Array< string | null > | null,
  resultViewers?: Array< string | null > | null,
};

export type PodtResponseInput = {
  timeout: number,
  fontStyle: FontStyleInput,
  active: MediaItemSnapshotInput,
  passive: MediaItemSnapshotInput,
};

export type PodtScenesInput = {
  maxAmountScenesPerTrial: number,
  scenes: Array< PodtSceneInput >,
  intervalConfig: PodtSceneIntervalConfigInput,
};

export type PodtSceneInput = {
  mediaItem: MediaItemSnapshotInput,
  boxes: Array< PodtBoxInput >,
};

export type PodtBoxInput = {
  top: number,
  left: number,
  width: number,
  height: number,
};

export type PodtSceneIntervalConfigInput = {
  min: number,
  max: number,
  stepSize: number,
};

export type PodtTargetCategoryInput = {
  label: string,
  practiceStimuliPool: PodtCategoryPoolInput,
  testStimuliPool: PodtCategoryPoolInput,
};

export type PodtCategoryPoolInput = {
  dangerousStimuli: Array< MediaItemSnapshotInput >,
  nonDangerousStimuli: Array< MediaItemSnapshotInput >,
};

export type PodtTrialFeedbackInput = {
  correctRejectionText: WeightedStyledTextInput,
  hitText: WeightedStyledTextInput,
  missText: WeightedStyledTextInput,
  falseAlarmText: WeightedStyledTextInput,
  noResponseText: WeightedStyledTextInput,
  pointsFontsize?: number | null,
};

export type WeightedStyledTextInput = {
  value: string,
  fontStyle: FontStyleInput,
  weight: number,
};

export type PodtBlockInput = {
  index: number,
  name: string,
  type: BlockType,
  amountTrials: number,
  instructions: StyleableTextTemplateInput,
  preparationScreen?: PodtPreparationScreenInput | null,
  tips?: StyleableTextTemplateInput | null,
};

export type PodtPreparationScreenInput = {
  interval: number,
  screen: StyleableTextTemplateInput,
};

export type ModelPodtTestConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  language?: ModelStringInput | null,
  counterbalancing?: ModelBooleanInput | null,
  probabilityWithoutReplacement?: ModelBooleanInput | null,
  interTrialInterval?: ModelIntInput | null,
  status?: ModelTestStatusInput | null,
  showCategoriesAfterInstructions?: ModelBooleanInput | null,
  startLabel?: ModelStringInput | null,
  nextLabel?: ModelStringInput | null,
  finishLabel?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  viewers?: ModelStringInput | null,
  resultViewers?: ModelStringInput | null,
  and?: Array< ModelPodtTestConditionInput | null > | null,
  or?: Array< ModelPodtTestConditionInput | null > | null,
  not?: ModelPodtTestConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdatePodtTestInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  language?: string | null,
  instructions?: StyleableTextTemplateInput | null,
  keyboard?: KeyboardSettingsInput | null,
  counterbalancing?: boolean | null,
  probabilityWithoutReplacement?: boolean | null,
  interTrialInterval?: number | null,
  style?: TestStyleInput | null,
  status?: TestStatus | null,
  endScreen?: StyleableTextTemplateInput | null,
  response?: PodtResponseInput | null,
  scenes?: PodtScenesInput | null,
  targets?: Array< PodtTargetCategoryInput > | null,
  fixation?: TimedMediaItemInput | null,
  trialFeedback?: PodtTrialFeedbackInput | null,
  blocks?: Array< PodtBlockInput > | null,
  showCategoriesAfterInstructions?: boolean | null,
  startLabel?: string | null,
  nextLabel?: string | null,
  finishLabel?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  viewers?: Array< string | null > | null,
  resultViewers?: Array< string | null > | null,
};

export type DeletePodtTestInput = {
  id: string,
};

export type CreatePodtResultInput = {
  id?: string | null,
  testId: string,
  clientId: string,
  survey: ResultSurveyIdentifierInput,
  timing: ResultTimingInput,
  device: ProbandDeviceInput,
  blockIndex: number,
  blockName: string,
  blockType?: BlockType | null,
  trialIndex: number,
  actionResultName: string,
  counterBalancingGroup?: string | null,
  earnedPoints?: number | null,
  reactionLeft: string,
  reactionRight: string,
  scenes: Array< PodtResultSceneInput >,
  target: PodtResultTargetInput,
  accumulatedPoints: number,
  pressedButton?: string | null,
  triggeredControl?: string | null,
  status?: TestStatus | null,
};

export type PodtResultSceneInput = {
  scene: PodtSceneInput,
  interval: number,
};

export type PodtResultTargetInput = {
  mediaItem: MediaItemSnapshotInput,
  category: string,
  dangerous: boolean,
};

export type ModelPodtResultConditionInput = {
  testId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  blockIndex?: ModelIntInput | null,
  blockName?: ModelStringInput | null,
  blockType?: ModelBlockTypeInput | null,
  trialIndex?: ModelIntInput | null,
  actionResultName?: ModelStringInput | null,
  counterBalancingGroup?: ModelStringInput | null,
  earnedPoints?: ModelIntInput | null,
  reactionLeft?: ModelStringInput | null,
  reactionRight?: ModelStringInput | null,
  accumulatedPoints?: ModelIntInput | null,
  pressedButton?: ModelStringInput | null,
  triggeredControl?: ModelStringInput | null,
  status?: ModelTestStatusInput | null,
  and?: Array< ModelPodtResultConditionInput | null > | null,
  or?: Array< ModelPodtResultConditionInput | null > | null,
  not?: ModelPodtResultConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UpdatePodtResultInput = {
  id: string,
  testId?: string | null,
  clientId?: string | null,
  survey?: ResultSurveyIdentifierInput | null,
  timing?: ResultTimingInput | null,
  device?: ProbandDeviceInput | null,
  blockIndex?: number | null,
  blockName?: string | null,
  blockType?: BlockType | null,
  trialIndex?: number | null,
  actionResultName?: string | null,
  counterBalancingGroup?: string | null,
  earnedPoints?: number | null,
  reactionLeft?: string | null,
  reactionRight?: string | null,
  scenes?: Array< PodtResultSceneInput > | null,
  target?: PodtResultTargetInput | null,
  accumulatedPoints?: number | null,
  pressedButton?: string | null,
  triggeredControl?: string | null,
  status?: TestStatus | null,
};

export type DeletePodtResultInput = {
  id: string,
};

export type CreatePodtaTestInput = {
  id?: string | null,
  name: string,
  description: string,
  language?: string | null,
  instructions: StyleableTextTemplateInput,
  keyboard: KeyboardSettingsInput,
  counterbalancing: boolean,
  probabilityWithoutReplacement: boolean,
  interTrialInterval: number,
  style: TestStyleInput,
  status: TestStatus,
  endScreen?: StyleableTextTemplateInput | null,
  response: PodtResponseInput,
  scenes: PodtaScenesInput,
  targets: Array< PodtTargetCategoryInput >,
  fixation?: TimedMediaItemInput | null,
  trialFeedback?: PodtTrialFeedbackInput | null,
  blocks: Array< PodtBlockInput >,
  showCategoriesAfterInstructions?: boolean | null,
  startLabel: string,
  nextLabel: string,
  finishLabel: string,
  owner?: string | null,
  editors?: Array< string | null > | null,
  viewers?: Array< string | null > | null,
  resultViewers?: Array< string | null > | null,
};

export type PodtaScenesInput = {
  maxAmountScenesPerTrial: number,
  scenes: Array< PodtaSceneInput >,
  intervalConfig: PodtSceneIntervalConfigInput,
};

export type PodtaSceneInput = {
  mediaItem: MediaItemSnapshotInput,
  boxes: Array< PodtaBoxInput >,
};

export type PodtaBoxInput = {
  top: number,
  left: number,
  width: number,
  height: number,
  position: PodtaBoxPosition,
};

export type ModelPodtaTestConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  language?: ModelStringInput | null,
  counterbalancing?: ModelBooleanInput | null,
  probabilityWithoutReplacement?: ModelBooleanInput | null,
  interTrialInterval?: ModelIntInput | null,
  status?: ModelTestStatusInput | null,
  showCategoriesAfterInstructions?: ModelBooleanInput | null,
  startLabel?: ModelStringInput | null,
  nextLabel?: ModelStringInput | null,
  finishLabel?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  viewers?: ModelStringInput | null,
  resultViewers?: ModelStringInput | null,
  and?: Array< ModelPodtaTestConditionInput | null > | null,
  or?: Array< ModelPodtaTestConditionInput | null > | null,
  not?: ModelPodtaTestConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdatePodtaTestInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  language?: string | null,
  instructions?: StyleableTextTemplateInput | null,
  keyboard?: KeyboardSettingsInput | null,
  counterbalancing?: boolean | null,
  probabilityWithoutReplacement?: boolean | null,
  interTrialInterval?: number | null,
  style?: TestStyleInput | null,
  status?: TestStatus | null,
  endScreen?: StyleableTextTemplateInput | null,
  response?: PodtResponseInput | null,
  scenes?: PodtaScenesInput | null,
  targets?: Array< PodtTargetCategoryInput > | null,
  fixation?: TimedMediaItemInput | null,
  trialFeedback?: PodtTrialFeedbackInput | null,
  blocks?: Array< PodtBlockInput > | null,
  showCategoriesAfterInstructions?: boolean | null,
  startLabel?: string | null,
  nextLabel?: string | null,
  finishLabel?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  viewers?: Array< string | null > | null,
  resultViewers?: Array< string | null > | null,
};

export type DeletePodtaTestInput = {
  id: string,
};

export type CreatePodtaResultInput = {
  id?: string | null,
  testId: string,
  clientId: string,
  survey: ResultSurveyIdentifierInput,
  timing: ResultTimingInput,
  device: ProbandDeviceInput,
  blockIndex: number,
  blockName: string,
  blockType?: BlockType | null,
  trialIndex: number,
  actionResultName: string,
  counterBalancingGroup?: string | null,
  earnedPoints?: number | null,
  reactionLeft: string,
  reactionRight: string,
  scenes: Array< PodtaResultSceneInput >,
  target: PodtResultTargetInput,
  accumulatedPoints: number,
  pressedButton?: string | null,
  triggeredControl?: string | null,
  status?: TestStatus | null,
};

export type PodtaResultSceneInput = {
  scene: PodtaSceneInput,
  interval: number,
};

export type ModelPodtaResultConditionInput = {
  testId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  blockIndex?: ModelIntInput | null,
  blockName?: ModelStringInput | null,
  blockType?: ModelBlockTypeInput | null,
  trialIndex?: ModelIntInput | null,
  actionResultName?: ModelStringInput | null,
  counterBalancingGroup?: ModelStringInput | null,
  earnedPoints?: ModelIntInput | null,
  reactionLeft?: ModelStringInput | null,
  reactionRight?: ModelStringInput | null,
  accumulatedPoints?: ModelIntInput | null,
  pressedButton?: ModelStringInput | null,
  triggeredControl?: ModelStringInput | null,
  status?: ModelTestStatusInput | null,
  and?: Array< ModelPodtaResultConditionInput | null > | null,
  or?: Array< ModelPodtaResultConditionInput | null > | null,
  not?: ModelPodtaResultConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UpdatePodtaResultInput = {
  id: string,
  testId?: string | null,
  clientId?: string | null,
  survey?: ResultSurveyIdentifierInput | null,
  timing?: ResultTimingInput | null,
  device?: ProbandDeviceInput | null,
  blockIndex?: number | null,
  blockName?: string | null,
  blockType?: BlockType | null,
  trialIndex?: number | null,
  actionResultName?: string | null,
  counterBalancingGroup?: string | null,
  earnedPoints?: number | null,
  reactionLeft?: string | null,
  reactionRight?: string | null,
  scenes?: Array< PodtaResultSceneInput > | null,
  target?: PodtResultTargetInput | null,
  accumulatedPoints?: number | null,
  pressedButton?: string | null,
  triggeredControl?: string | null,
  status?: TestStatus | null,
};

export type DeletePodtaResultInput = {
  id: string,
};

export type CreateWSWTestInput = {
  id?: string | null,
  name: string,
  language?: string | null,
  description: string,
  instructions: StyleableTextTemplateInput,
  style: TestStyleInput,
  status: TestStatus,
  endScreen?: StyleableTextTemplateInput | null,
  attributeCombinations: Array< AttributeCombinationInput >,
  mainAttribute: WSWAttributeInput,
  criteriaAttribute: WSWAttributeInput,
  additionalAttribute?: WSWAttributeInput | null,
  groupConstruction?: GroupConstructionInput | null,
  learningPhase: PresentationPhaseInput,
  testPhase: PresentationPhaseInput,
  distractionPhase?: DistractionPhaseInput | null,
  startLabel: string,
  nextLabel: string,
  finishLabel: string,
  owner?: string | null,
  editors?: Array< string | null > | null,
  viewers?: Array< string | null > | null,
  resultViewers?: Array< string | null > | null,
};

export type AttributeCombinationInput = {
  mainCategory: number,
  additionalCategory?: number | null,
  criteriaCategory?: number | null,
  amount: number,
  multiplicator: number,
};

export type WSWAttributeInput = {
  stimuliVariant?: WswStimulusAttributeVariant | null,
  type: WSWAttributeType,
  pickingStrategy: PickingStrategy,
  categories?: Array< WSWAttributeCategoryInput > | null,
};

export type WSWAttributeCategoryInput = {
  name: string,
  mediaSnaps: Array< MediaItemSnapshotInput >,
};

export type GroupConstructionInput = {
  instructionsScreen?: StyleableTextTemplateInput | null,
  feedbackScreen?: StyleableTextTemplateInput | null,
  fakeQuestions: ConditionalQuestionSetInput,
  conditions: GroupConstructionConditionsInput,
  groups: Array< WSWGroupInput >,
  randomizeGroupColors: boolean,
};

export type ConditionalQuestionSetInput = {
  randomizeSequence: boolean,
  conditionalQuestion?: ConditionalQuestionInput | null,
  questionsAfter: Array< TestQuestionInput >,
  questionsBefore: Array< TestQuestionInput >,
};

export type ConditionalQuestionInput = {
  questionText: string,
  confirmText: string,
  declineText: string,
  answerType: ConditionalAnswerType,
};

export type TestQuestionInput = {
  noAnswer?: string | null,
  type: QuestionType,
  questionText: string,
  multiple?: MultipleChoiceQuestionInput | null,
  scale?: ScaleQuestionInput | null,
  mediaItemSnapshot?: MediaItemSnapshotInput | null,
  imageMultipleChoice?: ImageMultipleChoiceQuestionInput | null,
  stimulusTimeout?: number | null,
};

export type MultipleChoiceQuestionInput = {
  randomized: boolean,
  options: Array< string >,
};

export type ScaleQuestionInput = {
  invert: boolean,
  min: BoundaryInput,
  max: BoundaryInput,
};

export type BoundaryInput = {
  name: string,
  value: number,
};

export type ImageMultipleChoiceQuestionInput = {
  randomized: boolean,
  options: Array< MediaItemSnapshotInput >,
};

export type GroupConstructionConditionsInput = {
  condition1: number,
  condition2: number,
  condition3: number,
};

export type WSWGroupInput = {
  name: string,
  color: string,
  questionSet?: ConditionalQuestionSetInput | null,
  probability: number,
  feedbackScreen?: StyleableTextTemplateInput | null,
};

export type PresentationPhaseInput = {
  instructionsScreen?: StyleableTextTemplateInput | null,
  feedbackScreen?: StyleableTextTemplateInput | null,
  questionsSet: ConditionalQuestionSetInput,
  questionOffsetTimeout?: number | null,
};

export type DistractionPhaseInput = {
  instructionsScreen?: StyleableTextTemplateInput | null,
  feedbackScreen?: StyleableTextTemplateInput | null,
  questionsSet: ConditionalQuestionSetInput,
};

export type ModelWSWTestConditionInput = {
  name?: ModelStringInput | null,
  language?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelTestStatusInput | null,
  startLabel?: ModelStringInput | null,
  nextLabel?: ModelStringInput | null,
  finishLabel?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  viewers?: ModelStringInput | null,
  resultViewers?: ModelStringInput | null,
  and?: Array< ModelWSWTestConditionInput | null > | null,
  or?: Array< ModelWSWTestConditionInput | null > | null,
  not?: ModelWSWTestConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateWSWTestInput = {
  id: string,
  name?: string | null,
  language?: string | null,
  description?: string | null,
  instructions?: StyleableTextTemplateInput | null,
  style?: TestStyleInput | null,
  status?: TestStatus | null,
  endScreen?: StyleableTextTemplateInput | null,
  attributeCombinations?: Array< AttributeCombinationInput > | null,
  mainAttribute?: WSWAttributeInput | null,
  criteriaAttribute?: WSWAttributeInput | null,
  additionalAttribute?: WSWAttributeInput | null,
  groupConstruction?: GroupConstructionInput | null,
  learningPhase?: PresentationPhaseInput | null,
  testPhase?: PresentationPhaseInput | null,
  distractionPhase?: DistractionPhaseInput | null,
  startLabel?: string | null,
  nextLabel?: string | null,
  finishLabel?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  viewers?: Array< string | null > | null,
  resultViewers?: Array< string | null > | null,
};

export type DeleteWSWTestInput = {
  id: string,
};

export type CreateWswResultInput = {
  id?: string | null,
  testId: string,
  clientId: string,
  survey: ResultSurveyIdentifierInput,
  timing: ResultTimingInput,
  device: ProbandDeviceInput,
  phase: WswTestPhase,
  position: number,
  trialIndex: number,
  groupIndication?: WswGroupIndicationResultInput | null,
  questionType: WswQuestionType,
  questionAnswer: string,
  questionImageAnswerOriginalFileName?: string | null,
  noAnswer: boolean,
  answerOptionIndex?: number | null,
  questionPosition: number,
  questionPoolName: string,
  questionSnapshot: string,
  contentType: WswContentType,
  resultStimulus?: WswResultStimulusInput | null,
  attributeCombination?: WswAttributeCombinationResultInput | null,
  owner?: string | null,
  groupCondition?: number | null,
  status?: TestStatus | null,
};

export enum WswTestPhase {
  PostGroupDetermination = "PostGroupDetermination",
  PreGroupDetermination = "PreGroupDetermination",
  DistractionPhase = "DistractionPhase",
  LearningPhase = "LearningPhase",
  TestPhase = "TestPhase",
}


export type WswGroupIndicationResultInput = {
  groupIndicatorShow: boolean,
  groupIndicationCategory: string,
  groupIndicationIndex: number,
  groupIndicationColor: string,
};

export enum WswQuestionType {
  Confirm = "Confirm",
  YesNo = "YesNo",
  FreeText = "FreeText",
  RatingScala = "RatingScala",
  MultipleChoice = "MultipleChoice",
  ImageMultipleChoice = "ImageMultipleChoice",
}


export enum WswContentType {
  Instructions = "Instructions",
  Stimulus = "Stimulus",
  Combination = "Combination",
}


export type WswResultStimulusInput = {
  stimulusType: StimulusType,
  stimulusId?: string | null,
  stimulusTextValue?: string | null,
  stimulusTags?: Array< string > | null,
  stimulusOriginalFilename?: string | null,
};

export enum StimulusType {
  Image = "Image",
  Text = "Text",
  Empty = "Empty",
}


export type WswAttributeCombinationResultInput = {
  isLearningStimulus: boolean,
  mainStimulus: CombinationStimulusItemInput,
  criteriaStimulus?: CombinationStimulusItemInput | null,
  additionalStimulus?: CombinationStimulusItemInput | null,
};

export type CombinationStimulusItemInput = {
  stimulusType: WswStimulusType,
  stimulusPoolCategory: string,
  stimulusPoolIndex: number,
  stimulusPoolPosition: number,
  stimulusOriginalFilename?: string | null,
  type?: StimulusType | null,
  stimulusId?: string | null,
  stimulusTextValue?: string | null,
  stimulusTags?: Array< string > | null,
};

export enum WswStimulusType {
  Stimulus = "Stimulus",
  Group = "Group",
}


export type ModelWswResultConditionInput = {
  testId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  phase?: ModelWswTestPhaseInput | null,
  position?: ModelIntInput | null,
  trialIndex?: ModelIntInput | null,
  questionType?: ModelWswQuestionTypeInput | null,
  questionAnswer?: ModelStringInput | null,
  questionImageAnswerOriginalFileName?: ModelStringInput | null,
  noAnswer?: ModelBooleanInput | null,
  answerOptionIndex?: ModelIntInput | null,
  questionPosition?: ModelIntInput | null,
  questionPoolName?: ModelStringInput | null,
  questionSnapshot?: ModelStringInput | null,
  contentType?: ModelWswContentTypeInput | null,
  owner?: ModelStringInput | null,
  groupCondition?: ModelIntInput | null,
  status?: ModelTestStatusInput | null,
  and?: Array< ModelWswResultConditionInput | null > | null,
  or?: Array< ModelWswResultConditionInput | null > | null,
  not?: ModelWswResultConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelWswTestPhaseInput = {
  eq?: WswTestPhase | null,
  ne?: WswTestPhase | null,
};

export type ModelWswQuestionTypeInput = {
  eq?: WswQuestionType | null,
  ne?: WswQuestionType | null,
};

export type ModelWswContentTypeInput = {
  eq?: WswContentType | null,
  ne?: WswContentType | null,
};

export type WswResult = {
  __typename: "WswResult",
  id: string,
  testId: string,
  clientId: string,
  survey: ResultSurveyIdentifier,
  timing: ResultTiming,
  device: ProbandDevice,
  phase: WswTestPhase,
  position: number,
  trialIndex: number,
  groupIndication?: WswGroupIndicationResult | null,
  questionType: WswQuestionType,
  questionAnswer: string,
  questionImageAnswerOriginalFileName?: string | null,
  noAnswer: boolean,
  answerOptionIndex?: number | null,
  questionPosition: number,
  questionPoolName: string,
  questionSnapshot: string,
  contentType: WswContentType,
  resultStimulus?: WswResultStimulus | null,
  attributeCombination?: WswAttributeCombinationResult | null,
  owner?: string | null,
  groupCondition?: number | null,
  status?: TestStatus | null,
  createdAt: string,
  updatedAt: string,
};

export type WswGroupIndicationResult = {
  __typename: "WswGroupIndicationResult",
  groupIndicatorShow: boolean,
  groupIndicationCategory: string,
  groupIndicationIndex: number,
  groupIndicationColor: string,
};

export type WswResultStimulus = {
  __typename: "WswResultStimulus",
  stimulusType: StimulusType,
  stimulusId?: string | null,
  stimulusTextValue?: string | null,
  stimulusTags?: Array< string > | null,
  stimulusOriginalFilename?: string | null,
};

export type WswAttributeCombinationResult = {
  __typename: "WswAttributeCombinationResult",
  isLearningStimulus: boolean,
  mainStimulus: CombinationStimulusItem,
  criteriaStimulus?: CombinationStimulusItem | null,
  additionalStimulus?: CombinationStimulusItem | null,
};

export type CombinationStimulusItem = {
  __typename: "CombinationStimulusItem",
  stimulusType: WswStimulusType,
  stimulusPoolCategory: string,
  stimulusPoolIndex: number,
  stimulusPoolPosition: number,
  stimulusOriginalFilename?: string | null,
  type?: StimulusType | null,
  stimulusId?: string | null,
  stimulusTextValue?: string | null,
  stimulusTags?: Array< string > | null,
};

export type UpdateWswResultInput = {
  id: string,
  testId?: string | null,
  clientId?: string | null,
  survey?: ResultSurveyIdentifierInput | null,
  timing?: ResultTimingInput | null,
  device?: ProbandDeviceInput | null,
  phase?: WswTestPhase | null,
  position?: number | null,
  trialIndex?: number | null,
  groupIndication?: WswGroupIndicationResultInput | null,
  questionType?: WswQuestionType | null,
  questionAnswer?: string | null,
  questionImageAnswerOriginalFileName?: string | null,
  noAnswer?: boolean | null,
  answerOptionIndex?: number | null,
  questionPosition?: number | null,
  questionPoolName?: string | null,
  questionSnapshot?: string | null,
  contentType?: WswContentType | null,
  resultStimulus?: WswResultStimulusInput | null,
  attributeCombination?: WswAttributeCombinationResultInput | null,
  owner?: string | null,
  groupCondition?: number | null,
  status?: TestStatus | null,
};

export type DeleteWswResultInput = {
  id: string,
};

export type CreateSurveyProviderInput = {
  id?: string | null,
  slug: string,
  name: string,
  description: string,
  snippetConfiguration: SurveyProviderSnippetConfigurationInput,
  setupDocumentation: SurveyProviderSetupDocumentationInput,
};

export type SurveyProviderSnippetConfigurationInput = {
  snippetType: SurveyProviderSnippetType,
  surveyIdReplacer?: string | null,
  probandIdReplacer: string,
};

export enum SurveyProviderSnippetType {
  IFRAME = "IFRAME",
}


export type SurveyProviderSetupDocumentationInput = {
  steps: Array< SurveyProviderSetupDocumentationStepInput >,
};

export type SurveyProviderSetupDocumentationStepInput = {
  type: SetupDocumentationStepType,
  textBeforeContent?: string | null,
  textAfterContent?: string | null,
  image?: ImageInstanceInput | null,
};

export enum SetupDocumentationStepType {
  IMAGE = "IMAGE",
  SNIPPET = "SNIPPET",
}


export type ModelSurveyProviderConditionInput = {
  slug?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelSurveyProviderConditionInput | null > | null,
  or?: Array< ModelSurveyProviderConditionInput | null > | null,
  not?: ModelSurveyProviderConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type SurveyProvider = {
  __typename: "SurveyProvider",
  id: string,
  slug: string,
  name: string,
  description: string,
  snippetConfiguration: SurveyProviderSnippetConfiguration,
  setupDocumentation: SurveyProviderSetupDocumentation,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type SurveyProviderSnippetConfiguration = {
  __typename: "SurveyProviderSnippetConfiguration",
  snippetType: SurveyProviderSnippetType,
  surveyIdReplacer?: string | null,
  probandIdReplacer: string,
};

export type SurveyProviderSetupDocumentation = {
  __typename: "SurveyProviderSetupDocumentation",
  steps:  Array<SurveyProviderSetupDocumentationStep >,
};

export type SurveyProviderSetupDocumentationStep = {
  __typename: "SurveyProviderSetupDocumentationStep",
  type: SetupDocumentationStepType,
  textBeforeContent?: string | null,
  textAfterContent?: string | null,
  image?: ImageInstance | null,
};

export type UpdateSurveyProviderInput = {
  id: string,
  slug?: string | null,
  name?: string | null,
  description?: string | null,
  snippetConfiguration?: SurveyProviderSnippetConfigurationInput | null,
  setupDocumentation?: SurveyProviderSetupDocumentationInput | null,
};

export type DeleteSurveyProviderInput = {
  id: string,
};

export type CreateUserRegistrationInput = {
  id?: string | null,
  name: string,
  email: string,
  institution: string,
  researchGoal: string,
  additionalInformation?: string | null,
  confirmedUsagePolicy: boolean,
  status: UserRegistrationStatus,
};

export enum UserRegistrationStatus {
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
  DECLINED = "DECLINED",
}


export type ModelUserRegistrationConditionInput = {
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  institution?: ModelStringInput | null,
  researchGoal?: ModelStringInput | null,
  additionalInformation?: ModelStringInput | null,
  confirmedUsagePolicy?: ModelBooleanInput | null,
  status?: ModelUserRegistrationStatusInput | null,
  and?: Array< ModelUserRegistrationConditionInput | null > | null,
  or?: Array< ModelUserRegistrationConditionInput | null > | null,
  not?: ModelUserRegistrationConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelUserRegistrationStatusInput = {
  eq?: UserRegistrationStatus | null,
  ne?: UserRegistrationStatus | null,
};

export type UserRegistration = {
  __typename: "UserRegistration",
  id: string,
  name: string,
  email: string,
  institution: string,
  researchGoal: string,
  additionalInformation?: string | null,
  confirmedUsagePolicy: boolean,
  status: UserRegistrationStatus,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateUserRegistrationInput = {
  id: string,
  name?: string | null,
  email?: string | null,
  institution?: string | null,
  researchGoal?: string | null,
  additionalInformation?: string | null,
  confirmedUsagePolicy?: boolean | null,
  status?: UserRegistrationStatus | null,
};

export type DeleteUserRegistrationInput = {
  id: string,
};

export type CreateBroadcastMailInput = {
  id?: string | null,
  subject: string,
  sender?: string | null,
  content: string,
  status: BroadcastMailStatus,
};

export enum BroadcastMailStatus {
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  SENT = "SENT",
  ERROR = "ERROR",
}


export type ModelBroadcastMailConditionInput = {
  subject?: ModelStringInput | null,
  sender?: ModelStringInput | null,
  content?: ModelStringInput | null,
  status?: ModelBroadcastMailStatusInput | null,
  and?: Array< ModelBroadcastMailConditionInput | null > | null,
  or?: Array< ModelBroadcastMailConditionInput | null > | null,
  not?: ModelBroadcastMailConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelBroadcastMailStatusInput = {
  eq?: BroadcastMailStatus | null,
  ne?: BroadcastMailStatus | null,
};

export type BroadcastMail = {
  __typename: "BroadcastMail",
  id: string,
  subject: string,
  sender?: string | null,
  content: string,
  status: BroadcastMailStatus,
  createdAt: string,
  updatedAt: string,
};

export type UpdateBroadcastMailInput = {
  id: string,
  subject?: string | null,
  sender?: string | null,
  content?: string | null,
  status?: BroadcastMailStatus | null,
};

export type DeleteBroadcastMailInput = {
  id: string,
};

export type UpdateMediaItemTagsInput = {
  id: string,
  tagId?: string | null,
  mediaItemId?: string | null,
};

export type ModelDocumentationFileFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  s3Key?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDocumentationFileFilterInput | null > | null,
  or?: Array< ModelDocumentationFileFilterInput | null > | null,
  not?: ModelDocumentationFileFilterInput | null,
};

export type ModelDocumentationFileConnection = {
  __typename: "ModelDocumentationFileConnection",
  items:  Array<DocumentationFile | null >,
  nextToken?: string | null,
};

export type ModelDocumentFileFilterInput = {
  id?: ModelIDInput | null,
  path?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  format?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDocumentFileFilterInput | null > | null,
  or?: Array< ModelDocumentFileFilterInput | null > | null,
  not?: ModelDocumentFileFilterInput | null,
};

export type ModelDocumentFileConnection = {
  __typename: "ModelDocumentFileConnection",
  items:  Array<DocumentFile | null >,
  nextToken?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelTestFinalizationFilterInput = {
  id?: ModelIDInput | null,
  testType?: ModelStringInput | null,
  testId?: ModelIDInput | null,
  probandId?: ModelStringInput | null,
  loadingStartedAt?: ModelStringInput | null,
  testStartedAt?: ModelStringInput | null,
  testFinalizedAt?: ModelStringInput | null,
  testAbortedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  status?: ModelTestStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTestFinalizationFilterInput | null > | null,
  or?: Array< ModelTestFinalizationFilterInput | null > | null,
  not?: ModelTestFinalizationFilterInput | null,
};

export type ModelTestFinalizationConnection = {
  __typename: "ModelTestFinalizationConnection",
  items:  Array<TestFinalization | null >,
  nextToken?: string | null,
};

export type ModelTestResultsStatusFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelTestResultsStatusTypeInput | null,
  testType?: ModelStringInput | null,
  error?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTestResultsStatusFilterInput | null > | null,
  or?: Array< ModelTestResultsStatusFilterInput | null > | null,
  not?: ModelTestResultsStatusFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelTestResultsStatusConnection = {
  __typename: "ModelTestResultsStatusConnection",
  items:  Array<TestResultsStatus | null >,
  nextToken?: string | null,
};

export type ModelIatTestFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  language?: ModelStringInput | null,
  interTrialInterval?: ModelIntInput | null,
  counterbalancing?: ModelBooleanInput | null,
  probabilityWithoutReplacement?: ModelBooleanInput | null,
  status?: ModelTestStatusInput | null,
  previewCategories?: ModelCategoryPreviewDisplayInput | null,
  startLabel?: ModelStringInput | null,
  nextLabel?: ModelStringInput | null,
  finishLabel?: ModelStringInput | null,
  feedback?: ModelIatFeedbackTypeInput | null,
  feedbackInterval?: ModelIntInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  viewers?: ModelStringInput | null,
  resultViewers?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelIatTestFilterInput | null > | null,
  or?: Array< ModelIatTestFilterInput | null > | null,
  not?: ModelIatTestFilterInput | null,
};

export type ModelIatTestConnection = {
  __typename: "ModelIatTestConnection",
  items:  Array<IatTest | null >,
  nextToken?: string | null,
};

export type ModelIatResultFilterInput = {
  id?: ModelIDInput | null,
  testId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  blockIndex?: ModelIntInput | null,
  blockName?: ModelStringInput | null,
  blockType?: ModelBlockTypeInput | null,
  trialIndex?: ModelIntInput | null,
  actionResultName?: ModelStringInput | null,
  counterBalancingGroup?: ModelStringInput | null,
  pressedButton?: ModelStringInput | null,
  triggeredControl?: ModelStringInput | null,
  status?: ModelTestStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelIatResultFilterInput | null > | null,
  or?: Array< ModelIatResultFilterInput | null > | null,
  not?: ModelIatResultFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelIatResultConnection = {
  __typename: "ModelIatResultConnection",
  items:  Array<IatResult | null >,
  nextToken?: string | null,
};

export type ModelAmpTestFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  language?: ModelStringInput | null,
  counterbalancing?: ModelBooleanInput | null,
  probabilityWithoutReplacement?: ModelBooleanInput | null,
  interTrialInterval?: ModelIntInput | null,
  status?: ModelTestStatusInput | null,
  blankInterval?: ModelIntInput | null,
  startLabel?: ModelStringInput | null,
  nextLabel?: ModelStringInput | null,
  finishLabel?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  viewers?: ModelStringInput | null,
  resultViewers?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAmpTestFilterInput | null > | null,
  or?: Array< ModelAmpTestFilterInput | null > | null,
  not?: ModelAmpTestFilterInput | null,
};

export type ModelAmpTestConnection = {
  __typename: "ModelAmpTestConnection",
  items:  Array<AmpTest | null >,
  nextToken?: string | null,
};

export type ModelAmpResultFilterInput = {
  id?: ModelIDInput | null,
  testId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  blockIndex?: ModelIntInput | null,
  blockName?: ModelStringInput | null,
  blockType?: ModelBlockTypeInput | null,
  trialIndex?: ModelIntInput | null,
  actionResultName?: ModelStringInput | null,
  counterBalancingGroup?: ModelStringInput | null,
  pressedButton?: ModelStringInput | null,
  triggeredControl?: ModelStringInput | null,
  attributeLeft?: ModelStringInput | null,
  attributeRight?: ModelStringInput | null,
  status?: ModelTestStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAmpResultFilterInput | null > | null,
  or?: Array< ModelAmpResultFilterInput | null > | null,
  not?: ModelAmpResultFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelAmpResultConnection = {
  __typename: "ModelAmpResultConnection",
  items:  Array<AmpResult | null >,
  nextToken?: string | null,
};

export type ModelPodtTestFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  language?: ModelStringInput | null,
  counterbalancing?: ModelBooleanInput | null,
  probabilityWithoutReplacement?: ModelBooleanInput | null,
  interTrialInterval?: ModelIntInput | null,
  status?: ModelTestStatusInput | null,
  showCategoriesAfterInstructions?: ModelBooleanInput | null,
  startLabel?: ModelStringInput | null,
  nextLabel?: ModelStringInput | null,
  finishLabel?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  viewers?: ModelStringInput | null,
  resultViewers?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPodtTestFilterInput | null > | null,
  or?: Array< ModelPodtTestFilterInput | null > | null,
  not?: ModelPodtTestFilterInput | null,
};

export type ModelPodtTestConnection = {
  __typename: "ModelPodtTestConnection",
  items:  Array<PodtTest | null >,
  nextToken?: string | null,
};

export type ModelPodtResultFilterInput = {
  id?: ModelIDInput | null,
  testId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  blockIndex?: ModelIntInput | null,
  blockName?: ModelStringInput | null,
  blockType?: ModelBlockTypeInput | null,
  trialIndex?: ModelIntInput | null,
  actionResultName?: ModelStringInput | null,
  counterBalancingGroup?: ModelStringInput | null,
  earnedPoints?: ModelIntInput | null,
  reactionLeft?: ModelStringInput | null,
  reactionRight?: ModelStringInput | null,
  accumulatedPoints?: ModelIntInput | null,
  pressedButton?: ModelStringInput | null,
  triggeredControl?: ModelStringInput | null,
  status?: ModelTestStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPodtResultFilterInput | null > | null,
  or?: Array< ModelPodtResultFilterInput | null > | null,
  not?: ModelPodtResultFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelPodtResultConnection = {
  __typename: "ModelPodtResultConnection",
  items:  Array<PodtResult | null >,
  nextToken?: string | null,
};

export type ModelPodtaTestFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  language?: ModelStringInput | null,
  counterbalancing?: ModelBooleanInput | null,
  probabilityWithoutReplacement?: ModelBooleanInput | null,
  interTrialInterval?: ModelIntInput | null,
  status?: ModelTestStatusInput | null,
  showCategoriesAfterInstructions?: ModelBooleanInput | null,
  startLabel?: ModelStringInput | null,
  nextLabel?: ModelStringInput | null,
  finishLabel?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  viewers?: ModelStringInput | null,
  resultViewers?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPodtaTestFilterInput | null > | null,
  or?: Array< ModelPodtaTestFilterInput | null > | null,
  not?: ModelPodtaTestFilterInput | null,
};

export type ModelPodtaTestConnection = {
  __typename: "ModelPodtaTestConnection",
  items:  Array<PodtaTest | null >,
  nextToken?: string | null,
};

export type ModelPodtaResultFilterInput = {
  id?: ModelIDInput | null,
  testId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  blockIndex?: ModelIntInput | null,
  blockName?: ModelStringInput | null,
  blockType?: ModelBlockTypeInput | null,
  trialIndex?: ModelIntInput | null,
  actionResultName?: ModelStringInput | null,
  counterBalancingGroup?: ModelStringInput | null,
  earnedPoints?: ModelIntInput | null,
  reactionLeft?: ModelStringInput | null,
  reactionRight?: ModelStringInput | null,
  accumulatedPoints?: ModelIntInput | null,
  pressedButton?: ModelStringInput | null,
  triggeredControl?: ModelStringInput | null,
  status?: ModelTestStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPodtaResultFilterInput | null > | null,
  or?: Array< ModelPodtaResultFilterInput | null > | null,
  not?: ModelPodtaResultFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelPodtaResultConnection = {
  __typename: "ModelPodtaResultConnection",
  items:  Array<PodtaResult | null >,
  nextToken?: string | null,
};

export type ModelWSWTestFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  language?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelTestStatusInput | null,
  startLabel?: ModelStringInput | null,
  nextLabel?: ModelStringInput | null,
  finishLabel?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  viewers?: ModelStringInput | null,
  resultViewers?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelWSWTestFilterInput | null > | null,
  or?: Array< ModelWSWTestFilterInput | null > | null,
  not?: ModelWSWTestFilterInput | null,
};

export type ModelWSWTestConnection = {
  __typename: "ModelWSWTestConnection",
  items:  Array<WSWTest | null >,
  nextToken?: string | null,
};

export type ModelWswResultFilterInput = {
  id?: ModelIDInput | null,
  testId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  phase?: ModelWswTestPhaseInput | null,
  position?: ModelIntInput | null,
  trialIndex?: ModelIntInput | null,
  questionType?: ModelWswQuestionTypeInput | null,
  questionAnswer?: ModelStringInput | null,
  questionImageAnswerOriginalFileName?: ModelStringInput | null,
  noAnswer?: ModelBooleanInput | null,
  answerOptionIndex?: ModelIntInput | null,
  questionPosition?: ModelIntInput | null,
  questionPoolName?: ModelStringInput | null,
  questionSnapshot?: ModelStringInput | null,
  contentType?: ModelWswContentTypeInput | null,
  owner?: ModelStringInput | null,
  groupCondition?: ModelIntInput | null,
  status?: ModelTestStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelWswResultFilterInput | null > | null,
  or?: Array< ModelWswResultFilterInput | null > | null,
  not?: ModelWswResultFilterInput | null,
};

export type ModelWswResultConnection = {
  __typename: "ModelWswResultConnection",
  items:  Array<WswResult | null >,
  nextToken?: string | null,
};

export type ModelSurveyProviderFilterInput = {
  id?: ModelIDInput | null,
  slug?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSurveyProviderFilterInput | null > | null,
  or?: Array< ModelSurveyProviderFilterInput | null > | null,
  not?: ModelSurveyProviderFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelSurveyProviderConnection = {
  __typename: "ModelSurveyProviderConnection",
  items:  Array<SurveyProvider | null >,
  nextToken?: string | null,
};

export type ModelUserRegistrationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  institution?: ModelStringInput | null,
  researchGoal?: ModelStringInput | null,
  additionalInformation?: ModelStringInput | null,
  confirmedUsagePolicy?: ModelBooleanInput | null,
  status?: ModelUserRegistrationStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserRegistrationFilterInput | null > | null,
  or?: Array< ModelUserRegistrationFilterInput | null > | null,
  not?: ModelUserRegistrationFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelUserRegistrationConnection = {
  __typename: "ModelUserRegistrationConnection",
  items:  Array<UserRegistration | null >,
  nextToken?: string | null,
};

export type ModelBroadcastMailFilterInput = {
  id?: ModelIDInput | null,
  subject?: ModelStringInput | null,
  sender?: ModelStringInput | null,
  content?: ModelStringInput | null,
  status?: ModelBroadcastMailStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBroadcastMailFilterInput | null > | null,
  or?: Array< ModelBroadcastMailFilterInput | null > | null,
  not?: ModelBroadcastMailFilterInput | null,
};

export type ModelBroadcastMailConnection = {
  __typename: "ModelBroadcastMailConnection",
  items:  Array<BroadcastMail | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionDocumentationFileFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  s3Key?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDocumentationFileFilterInput | null > | null,
  or?: Array< ModelSubscriptionDocumentationFileFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionDocumentFileFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  path?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  format?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDocumentFileFilterInput | null > | null,
  or?: Array< ModelSubscriptionDocumentFileFilterInput | null > | null,
};

export type ModelSubscriptionTagFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTagFilterInput | null > | null,
  or?: Array< ModelSubscriptionTagFilterInput | null > | null,
};

export type ModelSubscriptionMediaItemFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  source?: ModelSubscriptionStringInput | null,
  originalFileName?: ModelSubscriptionStringInput | null,
  tagsString?: ModelSubscriptionStringInput | null,
  tagsIds?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMediaItemFilterInput | null > | null,
  or?: Array< ModelSubscriptionMediaItemFilterInput | null > | null,
};

export type ModelSubscriptionTestFinalizationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  testType?: ModelSubscriptionStringInput | null,
  testId?: ModelSubscriptionIDInput | null,
  probandId?: ModelSubscriptionStringInput | null,
  loadingStartedAt?: ModelSubscriptionStringInput | null,
  testStartedAt?: ModelSubscriptionStringInput | null,
  testFinalizedAt?: ModelSubscriptionStringInput | null,
  testAbortedAt?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTestFinalizationFilterInput | null > | null,
  or?: Array< ModelSubscriptionTestFinalizationFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionTestResultsStatusFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  testType?: ModelSubscriptionStringInput | null,
  error?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTestResultsStatusFilterInput | null > | null,
  or?: Array< ModelSubscriptionTestResultsStatusFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionIatTestFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  language?: ModelSubscriptionStringInput | null,
  interTrialInterval?: ModelSubscriptionIntInput | null,
  counterbalancing?: ModelSubscriptionBooleanInput | null,
  probabilityWithoutReplacement?: ModelSubscriptionBooleanInput | null,
  status?: ModelSubscriptionStringInput | null,
  previewCategories?: ModelSubscriptionStringInput | null,
  startLabel?: ModelSubscriptionStringInput | null,
  nextLabel?: ModelSubscriptionStringInput | null,
  finishLabel?: ModelSubscriptionStringInput | null,
  feedback?: ModelSubscriptionStringInput | null,
  feedbackInterval?: ModelSubscriptionIntInput | null,
  resultViewers?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionIatTestFilterInput | null > | null,
  or?: Array< ModelSubscriptionIatTestFilterInput | null > | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  viewers?: ModelStringInput | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionIatResultFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  testId?: ModelSubscriptionIDInput | null,
  clientId?: ModelSubscriptionIDInput | null,
  blockIndex?: ModelSubscriptionIntInput | null,
  blockName?: ModelSubscriptionStringInput | null,
  blockType?: ModelSubscriptionStringInput | null,
  trialIndex?: ModelSubscriptionIntInput | null,
  actionResultName?: ModelSubscriptionStringInput | null,
  counterBalancingGroup?: ModelSubscriptionStringInput | null,
  pressedButton?: ModelSubscriptionStringInput | null,
  triggeredControl?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionIatResultFilterInput | null > | null,
  or?: Array< ModelSubscriptionIatResultFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionAmpTestFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  language?: ModelSubscriptionStringInput | null,
  counterbalancing?: ModelSubscriptionBooleanInput | null,
  probabilityWithoutReplacement?: ModelSubscriptionBooleanInput | null,
  interTrialInterval?: ModelSubscriptionIntInput | null,
  status?: ModelSubscriptionStringInput | null,
  blankInterval?: ModelSubscriptionIntInput | null,
  startLabel?: ModelSubscriptionStringInput | null,
  nextLabel?: ModelSubscriptionStringInput | null,
  finishLabel?: ModelSubscriptionStringInput | null,
  resultViewers?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAmpTestFilterInput | null > | null,
  or?: Array< ModelSubscriptionAmpTestFilterInput | null > | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  viewers?: ModelStringInput | null,
};

export type ModelSubscriptionAmpResultFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  testId?: ModelSubscriptionIDInput | null,
  clientId?: ModelSubscriptionIDInput | null,
  blockIndex?: ModelSubscriptionIntInput | null,
  blockName?: ModelSubscriptionStringInput | null,
  blockType?: ModelSubscriptionStringInput | null,
  trialIndex?: ModelSubscriptionIntInput | null,
  actionResultName?: ModelSubscriptionStringInput | null,
  counterBalancingGroup?: ModelSubscriptionStringInput | null,
  pressedButton?: ModelSubscriptionStringInput | null,
  triggeredControl?: ModelSubscriptionStringInput | null,
  attributeLeft?: ModelSubscriptionStringInput | null,
  attributeRight?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAmpResultFilterInput | null > | null,
  or?: Array< ModelSubscriptionAmpResultFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionPodtTestFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  language?: ModelSubscriptionStringInput | null,
  counterbalancing?: ModelSubscriptionBooleanInput | null,
  probabilityWithoutReplacement?: ModelSubscriptionBooleanInput | null,
  interTrialInterval?: ModelSubscriptionIntInput | null,
  status?: ModelSubscriptionStringInput | null,
  showCategoriesAfterInstructions?: ModelSubscriptionBooleanInput | null,
  startLabel?: ModelSubscriptionStringInput | null,
  nextLabel?: ModelSubscriptionStringInput | null,
  finishLabel?: ModelSubscriptionStringInput | null,
  resultViewers?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPodtTestFilterInput | null > | null,
  or?: Array< ModelSubscriptionPodtTestFilterInput | null > | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  viewers?: ModelStringInput | null,
};

export type ModelSubscriptionPodtResultFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  testId?: ModelSubscriptionIDInput | null,
  clientId?: ModelSubscriptionIDInput | null,
  blockIndex?: ModelSubscriptionIntInput | null,
  blockName?: ModelSubscriptionStringInput | null,
  blockType?: ModelSubscriptionStringInput | null,
  trialIndex?: ModelSubscriptionIntInput | null,
  actionResultName?: ModelSubscriptionStringInput | null,
  counterBalancingGroup?: ModelSubscriptionStringInput | null,
  earnedPoints?: ModelSubscriptionIntInput | null,
  reactionLeft?: ModelSubscriptionStringInput | null,
  reactionRight?: ModelSubscriptionStringInput | null,
  accumulatedPoints?: ModelSubscriptionIntInput | null,
  pressedButton?: ModelSubscriptionStringInput | null,
  triggeredControl?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPodtResultFilterInput | null > | null,
  or?: Array< ModelSubscriptionPodtResultFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionPodtaTestFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  language?: ModelSubscriptionStringInput | null,
  counterbalancing?: ModelSubscriptionBooleanInput | null,
  probabilityWithoutReplacement?: ModelSubscriptionBooleanInput | null,
  interTrialInterval?: ModelSubscriptionIntInput | null,
  status?: ModelSubscriptionStringInput | null,
  showCategoriesAfterInstructions?: ModelSubscriptionBooleanInput | null,
  startLabel?: ModelSubscriptionStringInput | null,
  nextLabel?: ModelSubscriptionStringInput | null,
  finishLabel?: ModelSubscriptionStringInput | null,
  resultViewers?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPodtaTestFilterInput | null > | null,
  or?: Array< ModelSubscriptionPodtaTestFilterInput | null > | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  viewers?: ModelStringInput | null,
};

export type ModelSubscriptionPodtaResultFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  testId?: ModelSubscriptionIDInput | null,
  clientId?: ModelSubscriptionIDInput | null,
  blockIndex?: ModelSubscriptionIntInput | null,
  blockName?: ModelSubscriptionStringInput | null,
  blockType?: ModelSubscriptionStringInput | null,
  trialIndex?: ModelSubscriptionIntInput | null,
  actionResultName?: ModelSubscriptionStringInput | null,
  counterBalancingGroup?: ModelSubscriptionStringInput | null,
  earnedPoints?: ModelSubscriptionIntInput | null,
  reactionLeft?: ModelSubscriptionStringInput | null,
  reactionRight?: ModelSubscriptionStringInput | null,
  accumulatedPoints?: ModelSubscriptionIntInput | null,
  pressedButton?: ModelSubscriptionStringInput | null,
  triggeredControl?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPodtaResultFilterInput | null > | null,
  or?: Array< ModelSubscriptionPodtaResultFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionWSWTestFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  language?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  startLabel?: ModelSubscriptionStringInput | null,
  nextLabel?: ModelSubscriptionStringInput | null,
  finishLabel?: ModelSubscriptionStringInput | null,
  resultViewers?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionWSWTestFilterInput | null > | null,
  or?: Array< ModelSubscriptionWSWTestFilterInput | null > | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  viewers?: ModelStringInput | null,
};

export type ModelSubscriptionWswResultFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  testId?: ModelSubscriptionIDInput | null,
  clientId?: ModelSubscriptionIDInput | null,
  phase?: ModelSubscriptionStringInput | null,
  position?: ModelSubscriptionIntInput | null,
  trialIndex?: ModelSubscriptionIntInput | null,
  questionType?: ModelSubscriptionStringInput | null,
  questionAnswer?: ModelSubscriptionStringInput | null,
  questionImageAnswerOriginalFileName?: ModelSubscriptionStringInput | null,
  noAnswer?: ModelSubscriptionBooleanInput | null,
  answerOptionIndex?: ModelSubscriptionIntInput | null,
  questionPosition?: ModelSubscriptionIntInput | null,
  questionPoolName?: ModelSubscriptionStringInput | null,
  questionSnapshot?: ModelSubscriptionStringInput | null,
  contentType?: ModelSubscriptionStringInput | null,
  groupCondition?: ModelSubscriptionIntInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionWswResultFilterInput | null > | null,
  or?: Array< ModelSubscriptionWswResultFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionSurveyProviderFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  slug?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSurveyProviderFilterInput | null > | null,
  or?: Array< ModelSubscriptionSurveyProviderFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionUserRegistrationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  institution?: ModelSubscriptionStringInput | null,
  researchGoal?: ModelSubscriptionStringInput | null,
  additionalInformation?: ModelSubscriptionStringInput | null,
  confirmedUsagePolicy?: ModelSubscriptionBooleanInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserRegistrationFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserRegistrationFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionBroadcastMailFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  subject?: ModelSubscriptionStringInput | null,
  sender?: ModelSubscriptionStringInput | null,
  content?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBroadcastMailFilterInput | null > | null,
  or?: Array< ModelSubscriptionBroadcastMailFilterInput | null > | null,
};

export type ModelSubscriptionMediaItemTagsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tagId?: ModelSubscriptionIDInput | null,
  mediaItemId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMediaItemTagsFilterInput | null > | null,
  or?: Array< ModelSubscriptionMediaItemTagsFilterInput | null > | null,
};

export type DeleteMediaItemTagWithoutCircularMutationVariables = {
  input: DeleteMediaItemTagsInput,
  condition?: ModelMediaItemTagsConditionInput | null,
};

export type DeleteMediaItemTagWithoutCircularMutation = {
  deleteMediaItemTags?:  {
    __typename: "MediaItemTags",
    id: string,
    tagId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMediaItemTagWithoutCircularMutationVariables = {
  input: CreateMediaItemTagsInput,
  condition?: ModelMediaItemTagsConditionInput | null,
};

export type CreateMediaItemTagWithoutCircularMutation = {
  createMediaItemTags?:  {
    __typename: "MediaItemTags",
    id: string,
    tagId: string,
    createdAt: string,
    updatedAt: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string,
    },
  } | null,
};

export type UpdateMediaItemWithoutCircularMutationVariables = {
  input: UpdateMediaItemInput,
  condition?: ModelMediaItemConditionInput | null,
};

export type UpdateMediaItemWithoutCircularMutation = {
  updateMediaItem?:  {
    __typename: "MediaItem",
    id: string,
    image:  {
      __typename: "Image",
      fullSize:  {
        __typename: "ImageInstance",
        width: number,
        height: number,
        key: string,
      },
      thumbNail:  {
        __typename: "ImageInstance",
        width: number,
        height: number,
        key: string,
      },
    },
    source?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMediaItemWithoutCircularMutationVariables = {
  input: CreateMediaItemInput,
  condition?: ModelMediaItemConditionInput | null,
};

export type CreateMediaItemWithoutCircularMutation = {
  createMediaItem?:  {
    __typename: "MediaItem",
    id: string,
    image:  {
      __typename: "Image",
      fullSize:  {
        __typename: "ImageInstance",
        width: number,
        height: number,
        key: string,
      },
      thumbNail:  {
        __typename: "ImageInstance",
        width: number,
        height: number,
        key: string,
      },
    },
    source?: string | null,
    originalFileName: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type PublicRegisterUserMutationVariables = {
  name: string,
  email: string,
  institution: string,
  additionalInformation?: string | null,
  confirmedUsagePolicy: boolean,
  researchGoal: string,
};

export type PublicRegisterUserMutation = {
  registerUser?:  {
    __typename: "FunctionBodyResponse",
  } | null,
};

export type ListMediaItemsWithTagsQueryVariables = {
  filter?: ModelMediaItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMediaItemsWithTagsQuery = {
  listMediaItems?:  {
    __typename: "ModelMediaItemConnection",
    items:  Array< {
      __typename: "MediaItem",
      id: string,
      tagsString?: string | null,
      tags?:  {
        __typename: "ModelMediaItemTagsConnection",
        items:  Array< {
          __typename: "MediaItemTags",
          id: string,
          tagId: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
          },
        } | null >,
      } | null,
      image:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      },
      source?: string | null,
      originalFileName: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMediaItemTagsWithoutCircularQueryVariables = {
  filter?: ModelMediaItemTagsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMediaItemTagsWithoutCircularQuery = {
  listMediaItemTags?:  {
    __typename: "ModelMediaItemTagsConnection",
    items:  Array< {
      __typename: "MediaItemTags",
      mediaItem:  {
        __typename: "MediaItem",
        id: string,
        tags?:  {
          __typename: "ModelMediaItemTagsConnection",
          items:  Array< {
            __typename: "MediaItemTags",
            id: string,
            tagId: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string,
            },
          } | null >,
        } | null,
        image:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        },
        source?: string | null,
        originalFileName: string,
        createdAt: string,
        updatedAt: string,
      },
    } | null >,
  } | null,
};

export type GetMediaItemWithoutCircularQueryVariables = {
  id: string,
};

export type GetMediaItemWithoutCircularQuery = {
  getMediaItem?:  {
    __typename: "MediaItem",
    id: string,
    originalFileName: string,
    image:  {
      __typename: "Image",
      fullSize:  {
        __typename: "ImageInstance",
        width: number,
        height: number,
        key: string,
      },
      thumbNail:  {
        __typename: "ImageInstance",
        width: number,
        height: number,
        key: string,
      },
    },
    source?: string | null,
    tags?:  {
      __typename: "ModelMediaItemTagsConnection",
      items:  Array< {
        __typename: "MediaItemTags",
        id: string,
        tagId: string,
        createdAt: string,
        updatedAt: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTagsWithoutCircularQueryVariables = {
  filter?: ModelTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTagsWithoutCircularQuery = {
  listTags?:  {
    __typename: "ModelTagConnection",
    items:  Array< {
      __typename: "Tag",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      mediaItems?:  {
        __typename: "ModelMediaItemTagsConnection",
        items:  Array< {
          __typename: "MediaItemTags",
          id: string,
        } | null >,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPublicIatQueryVariables = {
  id: string,
};

export type GetPublicIatQuery = {
  getIatTest?:  {
    __typename: "IatTest",
    id: string,
    name: string,
    language?: string | null,
    description: string,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    interTrialInterval: number,
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    previewCategories: CategoryPreviewDisplay,
    blocks:  Array< {
      __typename: "IatBlock",
      index: number,
      type: BlockType,
      name: string,
      amountTrials: number,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      categoryLabelsInInstructions: boolean,
      attributeCategoryVariant?: CategoryVariant | null,
      targetCategoryVariant?: CategoryVariant | null,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    targetCategories:  {
      __typename: "IatCategories",
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      categoryA:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      categoryB:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    },
    categoryDivider?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    attributeCategories:  {
      __typename: "IatCategories",
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      categoryA:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      categoryB:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    },
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    feedback: IatFeedbackType,
    feedbackInterval?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetPublicAmpQueryVariables = {
  id: string,
};

export type GetPublicAmpQuery = {
  getAmpTest?:  {
    __typename: "AmpTest",
    id: string,
    name: string,
    language?: string | null,
    description: string,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    interTrialInterval: number,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    attributeCategories:  {
      __typename: "AmpAttributeCategories",
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      categoryA:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      categoryB:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    fixation?:  {
      __typename: "TimedMediaItem",
      interval: number,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    } | null,
    primes:  {
      __typename: "TimedAmpCategories",
      interval: number,
      categories:  Array< {
        __typename: "AmpLabeledStimuliPool",
        label: string,
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } >,
    },
    targets:  {
      __typename: "TimedAmpCategories",
      interval: number,
      categories:  Array< {
        __typename: "AmpLabeledStimuliPool",
        label: string,
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } >,
    },
    mask:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    },
    blankInterval: number,
    blocks:  Array< {
      __typename: "AmpBlock",
      index: number,
      name: string,
      type: BlockType,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      amountTrials: number,
      attributeVariant: CategoryVariant,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetPublicPodtQueryVariables = {
  id: string,
};

export type GetPublicPodtQuery = {
  getPodtTest?:  {
    __typename: "PodtTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    interTrialInterval: number,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    response:  {
      __typename: "PodtResponse",
      timeout: number,
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      active:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      passive:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    scenes:  {
      __typename: "PodtScenes",
      maxAmountScenesPerTrial: number,
      scenes:  Array< {
        __typename: "PodtScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtBox",
          top: number,
          left: number,
          width: number,
          height: number,
        } >,
      } >,
      intervalConfig:  {
        __typename: "PodtSceneIntervalConfig",
        min: number,
        max: number,
        stepSize: number,
      },
    },
    targets:  Array< {
      __typename: "PodtTargetCategory",
      label: string,
      practiceStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      testStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    } >,
    fixation?:  {
      __typename: "TimedMediaItem",
      interval: number,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    } | null,
    trialFeedback?:  {
      __typename: "PodtTrialFeedback",
      correctRejectionText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      hitText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      missText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      falseAlarmText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      noResponseText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      pointsFontsize?: number | null,
    } | null,
    blocks:  Array< {
      __typename: "PodtBlock",
      index: number,
      name: string,
      type: BlockType,
      amountTrials: number,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      preparationScreen?:  {
        __typename: "PodtPreparationScreen",
        interval: number,
        screen:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        },
      } | null,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    showCategoriesAfterInstructions?: boolean | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetPublicPodtaQueryVariables = {
  id: string,
};

export type GetPublicPodtaQuery = {
  getPodtaTest?:  {
    __typename: "PodtaTest",
    id: string,
    name: string,
    language?: string | null,
    description: string,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    interTrialInterval: number,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    response:  {
      __typename: "PodtResponse",
      timeout: number,
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      active:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      passive:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    scenes:  {
      __typename: "PodtaScenes",
      maxAmountScenesPerTrial: number,
      scenes:  Array< {
        __typename: "PodtaScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtaBox",
          top: number,
          left: number,
          width: number,
          height: number,
          position: PodtaBoxPosition,
        } >,
      } >,
      intervalConfig:  {
        __typename: "PodtSceneIntervalConfig",
        min: number,
        max: number,
        stepSize: number,
      },
    },
    targets:  Array< {
      __typename: "PodtTargetCategory",
      label: string,
      practiceStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      testStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    } >,
    fixation?:  {
      __typename: "TimedMediaItem",
      interval: number,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    } | null,
    trialFeedback?:  {
      __typename: "PodtTrialFeedback",
      correctRejectionText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      hitText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      missText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      falseAlarmText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      noResponseText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      pointsFontsize?: number | null,
    } | null,
    blocks:  Array< {
      __typename: "PodtBlock",
      index: number,
      name: string,
      type: BlockType,
      amountTrials: number,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      preparationScreen?:  {
        __typename: "PodtPreparationScreen",
        interval: number,
        screen:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        },
      } | null,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    showCategoriesAfterInstructions?: boolean | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetPublicWSWQueryVariables = {
  id: string,
};

export type GetPublicWSWQuery = {
  getWSWTest?:  {
    __typename: "WSWTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    attributeCombinations:  Array< {
      __typename: "AttributeCombination",
      mainCategory: number,
      additionalCategory?: number | null,
      criteriaCategory?: number | null,
      amount: number,
      multiplicator: number,
    } >,
    mainAttribute:  {
      __typename: "WSWAttribute",
      stimuliVariant?: WswStimulusAttributeVariant | null,
      type: WSWAttributeType,
      pickingStrategy: PickingStrategy,
      categories?:  Array< {
        __typename: "WSWAttributeCategory",
        name: string,
        mediaSnaps:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } > | null,
    },
    criteriaAttribute:  {
      __typename: "WSWAttribute",
      stimuliVariant?: WswStimulusAttributeVariant | null,
      type: WSWAttributeType,
      pickingStrategy: PickingStrategy,
      categories?:  Array< {
        __typename: "WSWAttributeCategory",
        name: string,
        mediaSnaps:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } > | null,
    },
    additionalAttribute?:  {
      __typename: "WSWAttribute",
      stimuliVariant?: WswStimulusAttributeVariant | null,
      type: WSWAttributeType,
      pickingStrategy: PickingStrategy,
      categories?:  Array< {
        __typename: "WSWAttributeCategory",
        name: string,
        mediaSnaps:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } > | null,
    } | null,
    groupConstruction?:  {
      __typename: "GroupConstruction",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      fakeQuestions:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
      conditions:  {
        __typename: "GroupConstructionConditions",
        condition1: number,
        condition2: number,
        condition3: number,
      },
      groups:  Array< {
        __typename: "WSWGroup",
        name: string,
        color: string,
        questionSet?:  {
          __typename: "ConditionalQuestionSet",
          randomizeSequence: boolean,
          conditionalQuestion?:  {
            __typename: "ConditionalQuestion",
            questionText: string,
            confirmText: string,
            declineText: string,
            answerType: ConditionalAnswerType,
          } | null,
          questionsAfter:  Array< {
            __typename: "TestQuestion",
            noAnswer?: string | null,
            type: QuestionType,
            questionText: string,
            multiple?:  {
              __typename: "MultipleChoiceQuestion",
              randomized: boolean,
              options: Array< string >,
            } | null,
            imageMultipleChoice?:  {
              __typename: "ImageMultipleChoiceQuestion",
              randomized: boolean,
              options:  Array< {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } >,
            } | null,
            scale?:  {
              __typename: "ScaleQuestion",
              invert: boolean,
              min:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
              max:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
            } | null,
            mediaItemSnapshot?:  {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } | null,
            stimulusTimeout?: number | null,
          } >,
          questionsBefore:  Array< {
            __typename: "TestQuestion",
            noAnswer?: string | null,
            type: QuestionType,
            questionText: string,
            multiple?:  {
              __typename: "MultipleChoiceQuestion",
              randomized: boolean,
              options: Array< string >,
            } | null,
            imageMultipleChoice?:  {
              __typename: "ImageMultipleChoiceQuestion",
              randomized: boolean,
              options:  Array< {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } >,
            } | null,
            scale?:  {
              __typename: "ScaleQuestion",
              invert: boolean,
              min:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
              max:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
            } | null,
            mediaItemSnapshot?:  {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } | null,
            stimulusTimeout?: number | null,
          } >,
        } | null,
        probability: number,
        feedbackScreen?:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        } | null,
      } >,
      randomizeGroupColors: boolean,
    } | null,
    learningPhase:  {
      __typename: "PresentationPhase",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      questionsSet:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
      questionOffsetTimeout?: number | null,
    },
    testPhase:  {
      __typename: "PresentationPhase",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      questionsSet:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
      questionOffsetTimeout?: number | null,
    },
    distractionPhase?:  {
      __typename: "DistractionPhase",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      questionsSet:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
    } | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CloudwatchLogMutationVariables = {
  severity?: Severity | null,
  name?: string | null,
  message?: string | null,
};

export type CloudwatchLogMutation = {
  cloudwatchLog?: number | null,
};

export type DownloadTestResultsMutationVariables = {
  testId: string,
  testType: string,
};

export type DownloadTestResultsMutation = {
  downloadTestResults: string,
};

export type GetMediaItemStatusMutationVariables = {
  mediaItemId: string,
};

export type GetMediaItemStatusMutation = {
  getMediaItemStatus?:  {
    __typename: "MediaItemStatusResponse",
    mediaItemId: string,
    usingTests:  Array< {
      __typename: "TestUsageInformation",
      testId: string,
      testName: string,
      testType: string,
      testOwnerMail: string,
      testStatus: TestStatus,
    } >,
  } | null,
};

export type RemoveMediaItemMutationVariables = {
  mediaItemId: string,
};

export type RemoveMediaItemMutation = {
  removeMediaItem: string,
};

export type InviteUserToTestMutationVariables = {
  testId: string,
  testType: string,
  permissions: Array< string >,
  userEmails: Array< string >,
};

export type InviteUserToTestMutation = {
  inviteUserToTest?:  {
    __typename: "FunctionBodyResponse",
    body?: string | null,
  } | null,
};

export type GetUserEmailsByIdsMutationVariables = {
  userIds: Array< string >,
  testId: string,
  testType: string,
};

export type GetUserEmailsByIdsMutation = {
  getUserEmailsByIds?:  {
    __typename: "FunctionBodyResponse",
    body?: string | null,
  } | null,
};

export type RemoveUserFromTestMutationVariables = {
  testId: string,
  testType: string,
  userId: string,
};

export type RemoveUserFromTestMutation = {
  removeUserFromTest?:  {
    __typename: "FunctionBodyResponse",
    body?: string | null,
  } | null,
};

export type AdminListUsersMutationVariables = {
  nextToken?: string | null,
};

export type AdminListUsersMutation = {
  adminListUsers?:  {
    __typename: "AdminListUsersResponse",
    users:  Array< {
      __typename: "UserListElement",
      sub: string,
      email: string,
      groups: Array< string >,
      userCreateDate: string,
      userLastModifiedDate: string,
      userStatus: string,
    } >,
  } | null,
};

export type AdminAddUserMutationVariables = {
  email: string,
  groups: Array< string >,
};

export type AdminAddUserMutation = {
  adminAddUser?:  {
    __typename: "FunctionBodyResponse",
    body?: string | null,
  } | null,
};

export type AdminRemoveUserMutationVariables = {
  userId: string,
};

export type AdminRemoveUserMutation = {
  adminRemoveUser?:  {
    __typename: "FunctionBodyResponse",
    body?: string | null,
  } | null,
};

export type AdminChangeUserGroupsMutationVariables = {
  userId: string,
  groups: Array< string >,
};

export type AdminChangeUserGroupsMutation = {
  adminChangeUserGroups?:  {
    __typename: "FunctionBodyResponse",
    body?: string | null,
  } | null,
};

export type UserTestStatusMutationVariables = {
  userId: string,
};

export type UserTestStatusMutation = {
  userTestStatus:  {
    __typename: "UserTestStatusResponse",
    userId: string,
    ownedTests:  Array< {
      __typename: "TestOwnerInformation",
      testId: string,
      testName: string,
      testType: string,
      testStatus: TestStatus,
    } >,
  },
};

export type DeleteTestMutationVariables = {
  testType: string,
  testId: string,
};

export type DeleteTestMutation = {
  deleteTest?:  {
    __typename: "FunctionBodyResponse",
    body?: string | null,
  } | null,
};

export type RequestTestDownloadLinkMutationVariables = {
  testType: string,
  testId: string,
  withDeviceDetails: boolean,
};

export type RequestTestDownloadLinkMutation = {
  requestTestDownloadLink?:  {
    __typename: "TestResultDownloadLinkResponse",
    downloadLink: string,
  } | null,
};

export type RemoveMediaItemTagMutationVariables = {
  tagId: string,
  dryRun: boolean,
};

export type RemoveMediaItemTagMutation = {
  removeMediaItemTag?:  {
    __typename: "RemoveMediaItemTagResponse",
    type: RemoveMediaItemTagResultType,
    associatedMediaItems: Array< string >,
  } | null,
};

export type ResendUserInvitationMutationVariables = {
  email: string,
};

export type ResendUserInvitationMutation = {
  resendUserInvitation?:  {
    __typename: "FunctionBodyResponse",
    body?: string | null,
  } | null,
};

export type SendBroadcastMailMutationVariables = {
  subject: string,
  sender?: string | null,
  content: string,
};

export type SendBroadcastMailMutation = {
  sendBroadcastMail?:  {
    __typename: "FunctionBodyResponse",
    body?: string | null,
  } | null,
};

export type CreateDocumentationFileMutationVariables = {
  input: CreateDocumentationFileInput,
  condition?: ModelDocumentationFileConditionInput | null,
};

export type CreateDocumentationFileMutation = {
  createDocumentationFile?:  {
    __typename: "DocumentationFile",
    id: string,
    name: string,
    description: string,
    s3Key: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDocumentationFileMutationVariables = {
  input: UpdateDocumentationFileInput,
  condition?: ModelDocumentationFileConditionInput | null,
};

export type UpdateDocumentationFileMutation = {
  updateDocumentationFile?:  {
    __typename: "DocumentationFile",
    id: string,
    name: string,
    description: string,
    s3Key: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDocumentationFileMutationVariables = {
  input: DeleteDocumentationFileInput,
  condition?: ModelDocumentationFileConditionInput | null,
};

export type DeleteDocumentationFileMutation = {
  deleteDocumentationFile?:  {
    __typename: "DocumentationFile",
    id: string,
    name: string,
    description: string,
    s3Key: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDocumentFileMutationVariables = {
  input: CreateDocumentFileInput,
  condition?: ModelDocumentFileConditionInput | null,
};

export type CreateDocumentFileMutation = {
  createDocumentFile?:  {
    __typename: "DocumentFile",
    id: string,
    path: Array< string >,
    name: string,
    description: string,
    format: string,
    currentVersion:  {
      __typename: "DocumentFileVersion",
      createdAt: string,
      version: string,
      validSince?: string | null,
      s3Key: string,
    },
    history:  Array< {
      __typename: "DocumentFileVersion",
      createdAt: string,
      version: string,
      validSince?: string | null,
      s3Key: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDocumentFileMutationVariables = {
  input: UpdateDocumentFileInput,
  condition?: ModelDocumentFileConditionInput | null,
};

export type UpdateDocumentFileMutation = {
  updateDocumentFile?:  {
    __typename: "DocumentFile",
    id: string,
    path: Array< string >,
    name: string,
    description: string,
    format: string,
    currentVersion:  {
      __typename: "DocumentFileVersion",
      createdAt: string,
      version: string,
      validSince?: string | null,
      s3Key: string,
    },
    history:  Array< {
      __typename: "DocumentFileVersion",
      createdAt: string,
      version: string,
      validSince?: string | null,
      s3Key: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDocumentFileMutationVariables = {
  input: DeleteDocumentFileInput,
  condition?: ModelDocumentFileConditionInput | null,
};

export type DeleteDocumentFileMutation = {
  deleteDocumentFile?:  {
    __typename: "DocumentFile",
    id: string,
    path: Array< string >,
    name: string,
    description: string,
    format: string,
    currentVersion:  {
      __typename: "DocumentFileVersion",
      createdAt: string,
      version: string,
      validSince?: string | null,
      s3Key: string,
    },
    history:  Array< {
      __typename: "DocumentFileVersion",
      createdAt: string,
      version: string,
      validSince?: string | null,
      s3Key: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTagMutationVariables = {
  input: CreateTagInput,
  condition?: ModelTagConditionInput | null,
};

export type CreateTagMutation = {
  createTag?:  {
    __typename: "Tag",
    id: string,
    name: string,
    mediaItems?:  {
      __typename: "ModelMediaItemTagsConnection",
      items:  Array< {
        __typename: "MediaItemTags",
        id: string,
        tagId: string,
        mediaItemId: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          mediaItems?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        mediaItem:  {
          __typename: "MediaItem",
          id: string,
          image:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          },
          source?: string | null,
          originalFileName: string,
          tags?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tagsString?: string | null,
          tagsIds: Array< string >,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTagMutationVariables = {
  input: UpdateTagInput,
  condition?: ModelTagConditionInput | null,
};

export type UpdateTagMutation = {
  updateTag?:  {
    __typename: "Tag",
    id: string,
    name: string,
    mediaItems?:  {
      __typename: "ModelMediaItemTagsConnection",
      items:  Array< {
        __typename: "MediaItemTags",
        id: string,
        tagId: string,
        mediaItemId: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          mediaItems?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        mediaItem:  {
          __typename: "MediaItem",
          id: string,
          image:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          },
          source?: string | null,
          originalFileName: string,
          tags?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tagsString?: string | null,
          tagsIds: Array< string >,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTagMutationVariables = {
  input: DeleteTagInput,
  condition?: ModelTagConditionInput | null,
};

export type DeleteTagMutation = {
  deleteTag?:  {
    __typename: "Tag",
    id: string,
    name: string,
    mediaItems?:  {
      __typename: "ModelMediaItemTagsConnection",
      items:  Array< {
        __typename: "MediaItemTags",
        id: string,
        tagId: string,
        mediaItemId: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          mediaItems?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        mediaItem:  {
          __typename: "MediaItem",
          id: string,
          image:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          },
          source?: string | null,
          originalFileName: string,
          tags?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tagsString?: string | null,
          tagsIds: Array< string >,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMediaItemMutationVariables = {
  input: CreateMediaItemInput,
  condition?: ModelMediaItemConditionInput | null,
};

export type CreateMediaItemMutation = {
  createMediaItem?:  {
    __typename: "MediaItem",
    id: string,
    image:  {
      __typename: "Image",
      fullSize:  {
        __typename: "ImageInstance",
        width: number,
        height: number,
        key: string,
      },
      thumbNail:  {
        __typename: "ImageInstance",
        width: number,
        height: number,
        key: string,
      },
    },
    source?: string | null,
    originalFileName: string,
    tags?:  {
      __typename: "ModelMediaItemTagsConnection",
      items:  Array< {
        __typename: "MediaItemTags",
        id: string,
        tagId: string,
        mediaItemId: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          mediaItems?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        mediaItem:  {
          __typename: "MediaItem",
          id: string,
          image:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          },
          source?: string | null,
          originalFileName: string,
          tags?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tagsString?: string | null,
          tagsIds: Array< string >,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tagsString?: string | null,
    tagsIds: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMediaItemMutationVariables = {
  input: UpdateMediaItemInput,
  condition?: ModelMediaItemConditionInput | null,
};

export type UpdateMediaItemMutation = {
  updateMediaItem?:  {
    __typename: "MediaItem",
    id: string,
    image:  {
      __typename: "Image",
      fullSize:  {
        __typename: "ImageInstance",
        width: number,
        height: number,
        key: string,
      },
      thumbNail:  {
        __typename: "ImageInstance",
        width: number,
        height: number,
        key: string,
      },
    },
    source?: string | null,
    originalFileName: string,
    tags?:  {
      __typename: "ModelMediaItemTagsConnection",
      items:  Array< {
        __typename: "MediaItemTags",
        id: string,
        tagId: string,
        mediaItemId: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          mediaItems?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        mediaItem:  {
          __typename: "MediaItem",
          id: string,
          image:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          },
          source?: string | null,
          originalFileName: string,
          tags?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tagsString?: string | null,
          tagsIds: Array< string >,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tagsString?: string | null,
    tagsIds: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMediaItemMutationVariables = {
  input: DeleteMediaItemInput,
  condition?: ModelMediaItemConditionInput | null,
};

export type DeleteMediaItemMutation = {
  deleteMediaItem?:  {
    __typename: "MediaItem",
    id: string,
    image:  {
      __typename: "Image",
      fullSize:  {
        __typename: "ImageInstance",
        width: number,
        height: number,
        key: string,
      },
      thumbNail:  {
        __typename: "ImageInstance",
        width: number,
        height: number,
        key: string,
      },
    },
    source?: string | null,
    originalFileName: string,
    tags?:  {
      __typename: "ModelMediaItemTagsConnection",
      items:  Array< {
        __typename: "MediaItemTags",
        id: string,
        tagId: string,
        mediaItemId: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          mediaItems?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        mediaItem:  {
          __typename: "MediaItem",
          id: string,
          image:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          },
          source?: string | null,
          originalFileName: string,
          tags?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tagsString?: string | null,
          tagsIds: Array< string >,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tagsString?: string | null,
    tagsIds: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTestFinalizationMutationVariables = {
  input: CreateTestFinalizationInput,
  condition?: ModelTestFinalizationConditionInput | null,
};

export type CreateTestFinalizationMutation = {
  createTestFinalization?:  {
    __typename: "TestFinalization",
    id: string,
    testType: string,
    testId: string,
    probandId: string,
    loadingStartedAt: string,
    testStartedAt?: string | null,
    testFinalizedAt?: string | null,
    testAbortedAt?: string | null,
    owner?: string | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTestFinalizationMutationVariables = {
  input: UpdateTestFinalizationInput,
  condition?: ModelTestFinalizationConditionInput | null,
};

export type UpdateTestFinalizationMutation = {
  updateTestFinalization?:  {
    __typename: "TestFinalization",
    id: string,
    testType: string,
    testId: string,
    probandId: string,
    loadingStartedAt: string,
    testStartedAt?: string | null,
    testFinalizedAt?: string | null,
    testAbortedAt?: string | null,
    owner?: string | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTestFinalizationMutationVariables = {
  input: DeleteTestFinalizationInput,
  condition?: ModelTestFinalizationConditionInput | null,
};

export type DeleteTestFinalizationMutation = {
  deleteTestFinalization?:  {
    __typename: "TestFinalization",
    id: string,
    testType: string,
    testId: string,
    probandId: string,
    loadingStartedAt: string,
    testStartedAt?: string | null,
    testFinalizedAt?: string | null,
    testAbortedAt?: string | null,
    owner?: string | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTestResultsStatusMutationVariables = {
  input: CreateTestResultsStatusInput,
  condition?: ModelTestResultsStatusConditionInput | null,
};

export type CreateTestResultsStatusMutation = {
  createTestResultsStatus?:  {
    __typename: "TestResultsStatus",
    id: string,
    status: TestResultsStatusType,
    testType: string,
    error?: string | null,
    statistics?:  {
      __typename: "TestResultsStatistics",
      processedResultRows: number,
      exportedResults: number,
      processedProbandAmount: number,
      finishedProbandAmount: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateTestResultsStatusMutationVariables = {
  input: UpdateTestResultsStatusInput,
  condition?: ModelTestResultsStatusConditionInput | null,
};

export type UpdateTestResultsStatusMutation = {
  updateTestResultsStatus?:  {
    __typename: "TestResultsStatus",
    id: string,
    status: TestResultsStatusType,
    testType: string,
    error?: string | null,
    statistics?:  {
      __typename: "TestResultsStatistics",
      processedResultRows: number,
      exportedResults: number,
      processedProbandAmount: number,
      finishedProbandAmount: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteTestResultsStatusMutationVariables = {
  input: DeleteTestResultsStatusInput,
  condition?: ModelTestResultsStatusConditionInput | null,
};

export type DeleteTestResultsStatusMutation = {
  deleteTestResultsStatus?:  {
    __typename: "TestResultsStatus",
    id: string,
    status: TestResultsStatusType,
    testType: string,
    error?: string | null,
    statistics?:  {
      __typename: "TestResultsStatistics",
      processedResultRows: number,
      exportedResults: number,
      processedProbandAmount: number,
      finishedProbandAmount: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateIatTestMutationVariables = {
  input: CreateIatTestInput,
  condition?: ModelIatTestConditionInput | null,
};

export type CreateIatTestMutation = {
  createIatTest?:  {
    __typename: "IatTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    interTrialInterval: number,
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    previewCategories: CategoryPreviewDisplay,
    blocks:  Array< {
      __typename: "IatBlock",
      index: number,
      type: BlockType,
      name: string,
      amountTrials: number,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      categoryLabelsInInstructions: boolean,
      attributeCategoryVariant?: CategoryVariant | null,
      targetCategoryVariant?: CategoryVariant | null,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    targetCategories:  {
      __typename: "IatCategories",
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      categoryA:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      categoryB:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    },
    categoryDivider?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    attributeCategories:  {
      __typename: "IatCategories",
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      categoryA:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      categoryB:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    },
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    feedback: IatFeedbackType,
    feedbackInterval?: number | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateIatTestMutationVariables = {
  input: UpdateIatTestInput,
  condition?: ModelIatTestConditionInput | null,
};

export type UpdateIatTestMutation = {
  updateIatTest?:  {
    __typename: "IatTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    interTrialInterval: number,
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    previewCategories: CategoryPreviewDisplay,
    blocks:  Array< {
      __typename: "IatBlock",
      index: number,
      type: BlockType,
      name: string,
      amountTrials: number,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      categoryLabelsInInstructions: boolean,
      attributeCategoryVariant?: CategoryVariant | null,
      targetCategoryVariant?: CategoryVariant | null,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    targetCategories:  {
      __typename: "IatCategories",
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      categoryA:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      categoryB:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    },
    categoryDivider?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    attributeCategories:  {
      __typename: "IatCategories",
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      categoryA:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      categoryB:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    },
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    feedback: IatFeedbackType,
    feedbackInterval?: number | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteIatTestMutationVariables = {
  input: DeleteIatTestInput,
  condition?: ModelIatTestConditionInput | null,
};

export type DeleteIatTestMutation = {
  deleteIatTest?:  {
    __typename: "IatTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    interTrialInterval: number,
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    previewCategories: CategoryPreviewDisplay,
    blocks:  Array< {
      __typename: "IatBlock",
      index: number,
      type: BlockType,
      name: string,
      amountTrials: number,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      categoryLabelsInInstructions: boolean,
      attributeCategoryVariant?: CategoryVariant | null,
      targetCategoryVariant?: CategoryVariant | null,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    targetCategories:  {
      __typename: "IatCategories",
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      categoryA:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      categoryB:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    },
    categoryDivider?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    attributeCategories:  {
      __typename: "IatCategories",
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      categoryA:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      categoryB:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    },
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    feedback: IatFeedbackType,
    feedbackInterval?: number | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateIatResultMutationVariables = {
  input: CreateIatResultInput,
  condition?: ModelIatResultConditionInput | null,
};

export type CreateIatResultMutation = {
  createIatResult?:  {
    __typename: "IatResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    actionTarget?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    actionAttribute?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    counterBalancingGroup?: string | null,
    stimulus:  {
      __typename: "ResultStimulus",
      categoryLabel:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      categoryType: IatCategoryType,
      stimulus:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    pressedButton?: string | null,
    triggeredControl?: string | null,
    attributeLeft?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    attributeRight?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    targetLeft?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    targetRight?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateIatResultMutationVariables = {
  input: UpdateIatResultInput,
  condition?: ModelIatResultConditionInput | null,
};

export type UpdateIatResultMutation = {
  updateIatResult?:  {
    __typename: "IatResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    actionTarget?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    actionAttribute?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    counterBalancingGroup?: string | null,
    stimulus:  {
      __typename: "ResultStimulus",
      categoryLabel:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      categoryType: IatCategoryType,
      stimulus:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    pressedButton?: string | null,
    triggeredControl?: string | null,
    attributeLeft?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    attributeRight?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    targetLeft?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    targetRight?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteIatResultMutationVariables = {
  input: DeleteIatResultInput,
  condition?: ModelIatResultConditionInput | null,
};

export type DeleteIatResultMutation = {
  deleteIatResult?:  {
    __typename: "IatResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    actionTarget?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    actionAttribute?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    counterBalancingGroup?: string | null,
    stimulus:  {
      __typename: "ResultStimulus",
      categoryLabel:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      categoryType: IatCategoryType,
      stimulus:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    pressedButton?: string | null,
    triggeredControl?: string | null,
    attributeLeft?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    attributeRight?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    targetLeft?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    targetRight?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateAmpTestMutationVariables = {
  input: CreateAmpTestInput,
  condition?: ModelAmpTestConditionInput | null,
};

export type CreateAmpTestMutation = {
  createAmpTest?:  {
    __typename: "AmpTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    interTrialInterval: number,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    attributeCategories:  {
      __typename: "AmpAttributeCategories",
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      categoryA:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      categoryB:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    fixation?:  {
      __typename: "TimedMediaItem",
      interval: number,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    } | null,
    primes:  {
      __typename: "TimedAmpCategories",
      interval: number,
      categories:  Array< {
        __typename: "AmpLabeledStimuliPool",
        label: string,
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } >,
    },
    targets:  {
      __typename: "TimedAmpCategories",
      interval: number,
      categories:  Array< {
        __typename: "AmpLabeledStimuliPool",
        label: string,
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } >,
    },
    mask:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    },
    blankInterval: number,
    blocks:  Array< {
      __typename: "AmpBlock",
      index: number,
      name: string,
      type: BlockType,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      amountTrials: number,
      attributeVariant: CategoryVariant,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAmpTestMutationVariables = {
  input: UpdateAmpTestInput,
  condition?: ModelAmpTestConditionInput | null,
};

export type UpdateAmpTestMutation = {
  updateAmpTest?:  {
    __typename: "AmpTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    interTrialInterval: number,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    attributeCategories:  {
      __typename: "AmpAttributeCategories",
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      categoryA:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      categoryB:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    fixation?:  {
      __typename: "TimedMediaItem",
      interval: number,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    } | null,
    primes:  {
      __typename: "TimedAmpCategories",
      interval: number,
      categories:  Array< {
        __typename: "AmpLabeledStimuliPool",
        label: string,
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } >,
    },
    targets:  {
      __typename: "TimedAmpCategories",
      interval: number,
      categories:  Array< {
        __typename: "AmpLabeledStimuliPool",
        label: string,
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } >,
    },
    mask:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    },
    blankInterval: number,
    blocks:  Array< {
      __typename: "AmpBlock",
      index: number,
      name: string,
      type: BlockType,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      amountTrials: number,
      attributeVariant: CategoryVariant,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAmpTestMutationVariables = {
  input: DeleteAmpTestInput,
  condition?: ModelAmpTestConditionInput | null,
};

export type DeleteAmpTestMutation = {
  deleteAmpTest?:  {
    __typename: "AmpTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    interTrialInterval: number,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    attributeCategories:  {
      __typename: "AmpAttributeCategories",
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      categoryA:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      categoryB:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    fixation?:  {
      __typename: "TimedMediaItem",
      interval: number,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    } | null,
    primes:  {
      __typename: "TimedAmpCategories",
      interval: number,
      categories:  Array< {
        __typename: "AmpLabeledStimuliPool",
        label: string,
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } >,
    },
    targets:  {
      __typename: "TimedAmpCategories",
      interval: number,
      categories:  Array< {
        __typename: "AmpLabeledStimuliPool",
        label: string,
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } >,
    },
    mask:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    },
    blankInterval: number,
    blocks:  Array< {
      __typename: "AmpBlock",
      index: number,
      name: string,
      type: BlockType,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      amountTrials: number,
      attributeVariant: CategoryVariant,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAmpResultMutationVariables = {
  input: CreateAmpResultInput,
  condition?: ModelAmpResultConditionInput | null,
};

export type CreateAmpResultMutation = {
  createAmpResult?:  {
    __typename: "AmpResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    counterBalancingGroup?: string | null,
    pressedButton?: string | null,
    triggeredControl?: string | null,
    attributeLeftStimulus?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    attributeRightStimulus?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    actionResultStimulus?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    attributeLeft: string,
    attributeRight: string,
    prime:  {
      __typename: "AmpResultCategoryMediaItem",
      label: string,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    target:  {
      __typename: "AmpResultCategoryMediaItem",
      label: string,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateAmpResultMutationVariables = {
  input: UpdateAmpResultInput,
  condition?: ModelAmpResultConditionInput | null,
};

export type UpdateAmpResultMutation = {
  updateAmpResult?:  {
    __typename: "AmpResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    counterBalancingGroup?: string | null,
    pressedButton?: string | null,
    triggeredControl?: string | null,
    attributeLeftStimulus?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    attributeRightStimulus?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    actionResultStimulus?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    attributeLeft: string,
    attributeRight: string,
    prime:  {
      __typename: "AmpResultCategoryMediaItem",
      label: string,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    target:  {
      __typename: "AmpResultCategoryMediaItem",
      label: string,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteAmpResultMutationVariables = {
  input: DeleteAmpResultInput,
  condition?: ModelAmpResultConditionInput | null,
};

export type DeleteAmpResultMutation = {
  deleteAmpResult?:  {
    __typename: "AmpResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    counterBalancingGroup?: string | null,
    pressedButton?: string | null,
    triggeredControl?: string | null,
    attributeLeftStimulus?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    attributeRightStimulus?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    actionResultStimulus?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    attributeLeft: string,
    attributeRight: string,
    prime:  {
      __typename: "AmpResultCategoryMediaItem",
      label: string,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    target:  {
      __typename: "AmpResultCategoryMediaItem",
      label: string,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreatePodtTestMutationVariables = {
  input: CreatePodtTestInput,
  condition?: ModelPodtTestConditionInput | null,
};

export type CreatePodtTestMutation = {
  createPodtTest?:  {
    __typename: "PodtTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    interTrialInterval: number,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    response:  {
      __typename: "PodtResponse",
      timeout: number,
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      active:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      passive:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    scenes:  {
      __typename: "PodtScenes",
      maxAmountScenesPerTrial: number,
      scenes:  Array< {
        __typename: "PodtScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtBox",
          top: number,
          left: number,
          width: number,
          height: number,
        } >,
      } >,
      intervalConfig:  {
        __typename: "PodtSceneIntervalConfig",
        min: number,
        max: number,
        stepSize: number,
      },
    },
    targets:  Array< {
      __typename: "PodtTargetCategory",
      label: string,
      practiceStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      testStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    } >,
    fixation?:  {
      __typename: "TimedMediaItem",
      interval: number,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    } | null,
    trialFeedback?:  {
      __typename: "PodtTrialFeedback",
      correctRejectionText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      hitText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      missText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      falseAlarmText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      noResponseText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      pointsFontsize?: number | null,
    } | null,
    blocks:  Array< {
      __typename: "PodtBlock",
      index: number,
      name: string,
      type: BlockType,
      amountTrials: number,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      preparationScreen?:  {
        __typename: "PodtPreparationScreen",
        interval: number,
        screen:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        },
      } | null,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    showCategoriesAfterInstructions?: boolean | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePodtTestMutationVariables = {
  input: UpdatePodtTestInput,
  condition?: ModelPodtTestConditionInput | null,
};

export type UpdatePodtTestMutation = {
  updatePodtTest?:  {
    __typename: "PodtTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    interTrialInterval: number,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    response:  {
      __typename: "PodtResponse",
      timeout: number,
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      active:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      passive:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    scenes:  {
      __typename: "PodtScenes",
      maxAmountScenesPerTrial: number,
      scenes:  Array< {
        __typename: "PodtScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtBox",
          top: number,
          left: number,
          width: number,
          height: number,
        } >,
      } >,
      intervalConfig:  {
        __typename: "PodtSceneIntervalConfig",
        min: number,
        max: number,
        stepSize: number,
      },
    },
    targets:  Array< {
      __typename: "PodtTargetCategory",
      label: string,
      practiceStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      testStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    } >,
    fixation?:  {
      __typename: "TimedMediaItem",
      interval: number,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    } | null,
    trialFeedback?:  {
      __typename: "PodtTrialFeedback",
      correctRejectionText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      hitText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      missText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      falseAlarmText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      noResponseText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      pointsFontsize?: number | null,
    } | null,
    blocks:  Array< {
      __typename: "PodtBlock",
      index: number,
      name: string,
      type: BlockType,
      amountTrials: number,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      preparationScreen?:  {
        __typename: "PodtPreparationScreen",
        interval: number,
        screen:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        },
      } | null,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    showCategoriesAfterInstructions?: boolean | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePodtTestMutationVariables = {
  input: DeletePodtTestInput,
  condition?: ModelPodtTestConditionInput | null,
};

export type DeletePodtTestMutation = {
  deletePodtTest?:  {
    __typename: "PodtTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    interTrialInterval: number,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    response:  {
      __typename: "PodtResponse",
      timeout: number,
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      active:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      passive:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    scenes:  {
      __typename: "PodtScenes",
      maxAmountScenesPerTrial: number,
      scenes:  Array< {
        __typename: "PodtScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtBox",
          top: number,
          left: number,
          width: number,
          height: number,
        } >,
      } >,
      intervalConfig:  {
        __typename: "PodtSceneIntervalConfig",
        min: number,
        max: number,
        stepSize: number,
      },
    },
    targets:  Array< {
      __typename: "PodtTargetCategory",
      label: string,
      practiceStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      testStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    } >,
    fixation?:  {
      __typename: "TimedMediaItem",
      interval: number,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    } | null,
    trialFeedback?:  {
      __typename: "PodtTrialFeedback",
      correctRejectionText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      hitText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      missText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      falseAlarmText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      noResponseText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      pointsFontsize?: number | null,
    } | null,
    blocks:  Array< {
      __typename: "PodtBlock",
      index: number,
      name: string,
      type: BlockType,
      amountTrials: number,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      preparationScreen?:  {
        __typename: "PodtPreparationScreen",
        interval: number,
        screen:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        },
      } | null,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    showCategoriesAfterInstructions?: boolean | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePodtResultMutationVariables = {
  input: CreatePodtResultInput,
  condition?: ModelPodtResultConditionInput | null,
};

export type CreatePodtResultMutation = {
  createPodtResult?:  {
    __typename: "PodtResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    counterBalancingGroup?: string | null,
    earnedPoints?: number | null,
    reactionLeft: string,
    reactionRight: string,
    scenes:  Array< {
      __typename: "PodtResultScene",
      scene:  {
        __typename: "PodtScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtBox",
          top: number,
          left: number,
          width: number,
          height: number,
        } >,
      },
      interval: number,
    } >,
    target:  {
      __typename: "PodtResultTarget",
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      category: string,
      dangerous: boolean,
    },
    accumulatedPoints: number,
    pressedButton?: string | null,
    triggeredControl?: string | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdatePodtResultMutationVariables = {
  input: UpdatePodtResultInput,
  condition?: ModelPodtResultConditionInput | null,
};

export type UpdatePodtResultMutation = {
  updatePodtResult?:  {
    __typename: "PodtResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    counterBalancingGroup?: string | null,
    earnedPoints?: number | null,
    reactionLeft: string,
    reactionRight: string,
    scenes:  Array< {
      __typename: "PodtResultScene",
      scene:  {
        __typename: "PodtScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtBox",
          top: number,
          left: number,
          width: number,
          height: number,
        } >,
      },
      interval: number,
    } >,
    target:  {
      __typename: "PodtResultTarget",
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      category: string,
      dangerous: boolean,
    },
    accumulatedPoints: number,
    pressedButton?: string | null,
    triggeredControl?: string | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeletePodtResultMutationVariables = {
  input: DeletePodtResultInput,
  condition?: ModelPodtResultConditionInput | null,
};

export type DeletePodtResultMutation = {
  deletePodtResult?:  {
    __typename: "PodtResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    counterBalancingGroup?: string | null,
    earnedPoints?: number | null,
    reactionLeft: string,
    reactionRight: string,
    scenes:  Array< {
      __typename: "PodtResultScene",
      scene:  {
        __typename: "PodtScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtBox",
          top: number,
          left: number,
          width: number,
          height: number,
        } >,
      },
      interval: number,
    } >,
    target:  {
      __typename: "PodtResultTarget",
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      category: string,
      dangerous: boolean,
    },
    accumulatedPoints: number,
    pressedButton?: string | null,
    triggeredControl?: string | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreatePodtaTestMutationVariables = {
  input: CreatePodtaTestInput,
  condition?: ModelPodtaTestConditionInput | null,
};

export type CreatePodtaTestMutation = {
  createPodtaTest?:  {
    __typename: "PodtaTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    interTrialInterval: number,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    response:  {
      __typename: "PodtResponse",
      timeout: number,
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      active:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      passive:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    scenes:  {
      __typename: "PodtaScenes",
      maxAmountScenesPerTrial: number,
      scenes:  Array< {
        __typename: "PodtaScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtaBox",
          top: number,
          left: number,
          width: number,
          height: number,
          position: PodtaBoxPosition,
        } >,
      } >,
      intervalConfig:  {
        __typename: "PodtSceneIntervalConfig",
        min: number,
        max: number,
        stepSize: number,
      },
    },
    targets:  Array< {
      __typename: "PodtTargetCategory",
      label: string,
      practiceStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      testStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    } >,
    fixation?:  {
      __typename: "TimedMediaItem",
      interval: number,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    } | null,
    trialFeedback?:  {
      __typename: "PodtTrialFeedback",
      correctRejectionText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      hitText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      missText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      falseAlarmText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      noResponseText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      pointsFontsize?: number | null,
    } | null,
    blocks:  Array< {
      __typename: "PodtBlock",
      index: number,
      name: string,
      type: BlockType,
      amountTrials: number,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      preparationScreen?:  {
        __typename: "PodtPreparationScreen",
        interval: number,
        screen:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        },
      } | null,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    showCategoriesAfterInstructions?: boolean | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePodtaTestMutationVariables = {
  input: UpdatePodtaTestInput,
  condition?: ModelPodtaTestConditionInput | null,
};

export type UpdatePodtaTestMutation = {
  updatePodtaTest?:  {
    __typename: "PodtaTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    interTrialInterval: number,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    response:  {
      __typename: "PodtResponse",
      timeout: number,
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      active:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      passive:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    scenes:  {
      __typename: "PodtaScenes",
      maxAmountScenesPerTrial: number,
      scenes:  Array< {
        __typename: "PodtaScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtaBox",
          top: number,
          left: number,
          width: number,
          height: number,
          position: PodtaBoxPosition,
        } >,
      } >,
      intervalConfig:  {
        __typename: "PodtSceneIntervalConfig",
        min: number,
        max: number,
        stepSize: number,
      },
    },
    targets:  Array< {
      __typename: "PodtTargetCategory",
      label: string,
      practiceStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      testStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    } >,
    fixation?:  {
      __typename: "TimedMediaItem",
      interval: number,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    } | null,
    trialFeedback?:  {
      __typename: "PodtTrialFeedback",
      correctRejectionText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      hitText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      missText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      falseAlarmText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      noResponseText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      pointsFontsize?: number | null,
    } | null,
    blocks:  Array< {
      __typename: "PodtBlock",
      index: number,
      name: string,
      type: BlockType,
      amountTrials: number,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      preparationScreen?:  {
        __typename: "PodtPreparationScreen",
        interval: number,
        screen:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        },
      } | null,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    showCategoriesAfterInstructions?: boolean | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePodtaTestMutationVariables = {
  input: DeletePodtaTestInput,
  condition?: ModelPodtaTestConditionInput | null,
};

export type DeletePodtaTestMutation = {
  deletePodtaTest?:  {
    __typename: "PodtaTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    interTrialInterval: number,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    response:  {
      __typename: "PodtResponse",
      timeout: number,
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      active:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      passive:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    scenes:  {
      __typename: "PodtaScenes",
      maxAmountScenesPerTrial: number,
      scenes:  Array< {
        __typename: "PodtaScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtaBox",
          top: number,
          left: number,
          width: number,
          height: number,
          position: PodtaBoxPosition,
        } >,
      } >,
      intervalConfig:  {
        __typename: "PodtSceneIntervalConfig",
        min: number,
        max: number,
        stepSize: number,
      },
    },
    targets:  Array< {
      __typename: "PodtTargetCategory",
      label: string,
      practiceStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      testStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    } >,
    fixation?:  {
      __typename: "TimedMediaItem",
      interval: number,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    } | null,
    trialFeedback?:  {
      __typename: "PodtTrialFeedback",
      correctRejectionText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      hitText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      missText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      falseAlarmText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      noResponseText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      pointsFontsize?: number | null,
    } | null,
    blocks:  Array< {
      __typename: "PodtBlock",
      index: number,
      name: string,
      type: BlockType,
      amountTrials: number,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      preparationScreen?:  {
        __typename: "PodtPreparationScreen",
        interval: number,
        screen:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        },
      } | null,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    showCategoriesAfterInstructions?: boolean | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePodtaResultMutationVariables = {
  input: CreatePodtaResultInput,
  condition?: ModelPodtaResultConditionInput | null,
};

export type CreatePodtaResultMutation = {
  createPodtaResult?:  {
    __typename: "PodtaResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    counterBalancingGroup?: string | null,
    earnedPoints?: number | null,
    reactionLeft: string,
    reactionRight: string,
    scenes:  Array< {
      __typename: "PodtaResultScene",
      scene:  {
        __typename: "PodtaScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtaBox",
          top: number,
          left: number,
          width: number,
          height: number,
          position: PodtaBoxPosition,
        } >,
      },
      interval: number,
    } >,
    target:  {
      __typename: "PodtResultTarget",
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      category: string,
      dangerous: boolean,
    },
    accumulatedPoints: number,
    pressedButton?: string | null,
    triggeredControl?: string | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdatePodtaResultMutationVariables = {
  input: UpdatePodtaResultInput,
  condition?: ModelPodtaResultConditionInput | null,
};

export type UpdatePodtaResultMutation = {
  updatePodtaResult?:  {
    __typename: "PodtaResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    counterBalancingGroup?: string | null,
    earnedPoints?: number | null,
    reactionLeft: string,
    reactionRight: string,
    scenes:  Array< {
      __typename: "PodtaResultScene",
      scene:  {
        __typename: "PodtaScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtaBox",
          top: number,
          left: number,
          width: number,
          height: number,
          position: PodtaBoxPosition,
        } >,
      },
      interval: number,
    } >,
    target:  {
      __typename: "PodtResultTarget",
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      category: string,
      dangerous: boolean,
    },
    accumulatedPoints: number,
    pressedButton?: string | null,
    triggeredControl?: string | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeletePodtaResultMutationVariables = {
  input: DeletePodtaResultInput,
  condition?: ModelPodtaResultConditionInput | null,
};

export type DeletePodtaResultMutation = {
  deletePodtaResult?:  {
    __typename: "PodtaResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    counterBalancingGroup?: string | null,
    earnedPoints?: number | null,
    reactionLeft: string,
    reactionRight: string,
    scenes:  Array< {
      __typename: "PodtaResultScene",
      scene:  {
        __typename: "PodtaScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtaBox",
          top: number,
          left: number,
          width: number,
          height: number,
          position: PodtaBoxPosition,
        } >,
      },
      interval: number,
    } >,
    target:  {
      __typename: "PodtResultTarget",
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      category: string,
      dangerous: boolean,
    },
    accumulatedPoints: number,
    pressedButton?: string | null,
    triggeredControl?: string | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateWSWTestMutationVariables = {
  input: CreateWSWTestInput,
  condition?: ModelWSWTestConditionInput | null,
};

export type CreateWSWTestMutation = {
  createWSWTest?:  {
    __typename: "WSWTest",
    id: string,
    name: string,
    language?: string | null,
    description: string,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    attributeCombinations:  Array< {
      __typename: "AttributeCombination",
      mainCategory: number,
      additionalCategory?: number | null,
      criteriaCategory?: number | null,
      amount: number,
      multiplicator: number,
    } >,
    mainAttribute:  {
      __typename: "WSWAttribute",
      stimuliVariant?: WswStimulusAttributeVariant | null,
      type: WSWAttributeType,
      pickingStrategy: PickingStrategy,
      categories?:  Array< {
        __typename: "WSWAttributeCategory",
        name: string,
        mediaSnaps:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } > | null,
    },
    criteriaAttribute:  {
      __typename: "WSWAttribute",
      stimuliVariant?: WswStimulusAttributeVariant | null,
      type: WSWAttributeType,
      pickingStrategy: PickingStrategy,
      categories?:  Array< {
        __typename: "WSWAttributeCategory",
        name: string,
        mediaSnaps:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } > | null,
    },
    additionalAttribute?:  {
      __typename: "WSWAttribute",
      stimuliVariant?: WswStimulusAttributeVariant | null,
      type: WSWAttributeType,
      pickingStrategy: PickingStrategy,
      categories?:  Array< {
        __typename: "WSWAttributeCategory",
        name: string,
        mediaSnaps:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } > | null,
    } | null,
    groupConstruction?:  {
      __typename: "GroupConstruction",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      fakeQuestions:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
      conditions:  {
        __typename: "GroupConstructionConditions",
        condition1: number,
        condition2: number,
        condition3: number,
      },
      groups:  Array< {
        __typename: "WSWGroup",
        name: string,
        color: string,
        questionSet?:  {
          __typename: "ConditionalQuestionSet",
          randomizeSequence: boolean,
          conditionalQuestion?:  {
            __typename: "ConditionalQuestion",
            questionText: string,
            confirmText: string,
            declineText: string,
            answerType: ConditionalAnswerType,
          } | null,
          questionsAfter:  Array< {
            __typename: "TestQuestion",
            noAnswer?: string | null,
            type: QuestionType,
            questionText: string,
            multiple?:  {
              __typename: "MultipleChoiceQuestion",
              randomized: boolean,
              options: Array< string >,
            } | null,
            scale?:  {
              __typename: "ScaleQuestion",
              invert: boolean,
              min:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
              max:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
            } | null,
            mediaItemSnapshot?:  {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } | null,
            imageMultipleChoice?:  {
              __typename: "ImageMultipleChoiceQuestion",
              randomized: boolean,
              options:  Array< {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } >,
            } | null,
            stimulusTimeout?: number | null,
          } >,
          questionsBefore:  Array< {
            __typename: "TestQuestion",
            noAnswer?: string | null,
            type: QuestionType,
            questionText: string,
            multiple?:  {
              __typename: "MultipleChoiceQuestion",
              randomized: boolean,
              options: Array< string >,
            } | null,
            scale?:  {
              __typename: "ScaleQuestion",
              invert: boolean,
              min:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
              max:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
            } | null,
            mediaItemSnapshot?:  {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } | null,
            imageMultipleChoice?:  {
              __typename: "ImageMultipleChoiceQuestion",
              randomized: boolean,
              options:  Array< {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } >,
            } | null,
            stimulusTimeout?: number | null,
          } >,
        } | null,
        probability: number,
        feedbackScreen?:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        } | null,
      } >,
      randomizeGroupColors: boolean,
    } | null,
    learningPhase:  {
      __typename: "PresentationPhase",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      questionsSet:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
      questionOffsetTimeout?: number | null,
    },
    testPhase:  {
      __typename: "PresentationPhase",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      questionsSet:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
      questionOffsetTimeout?: number | null,
    },
    distractionPhase?:  {
      __typename: "DistractionPhase",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      questionsSet:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
    } | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateWSWTestMutationVariables = {
  input: UpdateWSWTestInput,
  condition?: ModelWSWTestConditionInput | null,
};

export type UpdateWSWTestMutation = {
  updateWSWTest?:  {
    __typename: "WSWTest",
    id: string,
    name: string,
    language?: string | null,
    description: string,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    attributeCombinations:  Array< {
      __typename: "AttributeCombination",
      mainCategory: number,
      additionalCategory?: number | null,
      criteriaCategory?: number | null,
      amount: number,
      multiplicator: number,
    } >,
    mainAttribute:  {
      __typename: "WSWAttribute",
      stimuliVariant?: WswStimulusAttributeVariant | null,
      type: WSWAttributeType,
      pickingStrategy: PickingStrategy,
      categories?:  Array< {
        __typename: "WSWAttributeCategory",
        name: string,
        mediaSnaps:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } > | null,
    },
    criteriaAttribute:  {
      __typename: "WSWAttribute",
      stimuliVariant?: WswStimulusAttributeVariant | null,
      type: WSWAttributeType,
      pickingStrategy: PickingStrategy,
      categories?:  Array< {
        __typename: "WSWAttributeCategory",
        name: string,
        mediaSnaps:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } > | null,
    },
    additionalAttribute?:  {
      __typename: "WSWAttribute",
      stimuliVariant?: WswStimulusAttributeVariant | null,
      type: WSWAttributeType,
      pickingStrategy: PickingStrategy,
      categories?:  Array< {
        __typename: "WSWAttributeCategory",
        name: string,
        mediaSnaps:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } > | null,
    } | null,
    groupConstruction?:  {
      __typename: "GroupConstruction",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      fakeQuestions:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
      conditions:  {
        __typename: "GroupConstructionConditions",
        condition1: number,
        condition2: number,
        condition3: number,
      },
      groups:  Array< {
        __typename: "WSWGroup",
        name: string,
        color: string,
        questionSet?:  {
          __typename: "ConditionalQuestionSet",
          randomizeSequence: boolean,
          conditionalQuestion?:  {
            __typename: "ConditionalQuestion",
            questionText: string,
            confirmText: string,
            declineText: string,
            answerType: ConditionalAnswerType,
          } | null,
          questionsAfter:  Array< {
            __typename: "TestQuestion",
            noAnswer?: string | null,
            type: QuestionType,
            questionText: string,
            multiple?:  {
              __typename: "MultipleChoiceQuestion",
              randomized: boolean,
              options: Array< string >,
            } | null,
            scale?:  {
              __typename: "ScaleQuestion",
              invert: boolean,
              min:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
              max:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
            } | null,
            mediaItemSnapshot?:  {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } | null,
            imageMultipleChoice?:  {
              __typename: "ImageMultipleChoiceQuestion",
              randomized: boolean,
              options:  Array< {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } >,
            } | null,
            stimulusTimeout?: number | null,
          } >,
          questionsBefore:  Array< {
            __typename: "TestQuestion",
            noAnswer?: string | null,
            type: QuestionType,
            questionText: string,
            multiple?:  {
              __typename: "MultipleChoiceQuestion",
              randomized: boolean,
              options: Array< string >,
            } | null,
            scale?:  {
              __typename: "ScaleQuestion",
              invert: boolean,
              min:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
              max:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
            } | null,
            mediaItemSnapshot?:  {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } | null,
            imageMultipleChoice?:  {
              __typename: "ImageMultipleChoiceQuestion",
              randomized: boolean,
              options:  Array< {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } >,
            } | null,
            stimulusTimeout?: number | null,
          } >,
        } | null,
        probability: number,
        feedbackScreen?:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        } | null,
      } >,
      randomizeGroupColors: boolean,
    } | null,
    learningPhase:  {
      __typename: "PresentationPhase",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      questionsSet:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
      questionOffsetTimeout?: number | null,
    },
    testPhase:  {
      __typename: "PresentationPhase",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      questionsSet:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
      questionOffsetTimeout?: number | null,
    },
    distractionPhase?:  {
      __typename: "DistractionPhase",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      questionsSet:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
    } | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteWSWTestMutationVariables = {
  input: DeleteWSWTestInput,
  condition?: ModelWSWTestConditionInput | null,
};

export type DeleteWSWTestMutation = {
  deleteWSWTest?:  {
    __typename: "WSWTest",
    id: string,
    name: string,
    language?: string | null,
    description: string,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    attributeCombinations:  Array< {
      __typename: "AttributeCombination",
      mainCategory: number,
      additionalCategory?: number | null,
      criteriaCategory?: number | null,
      amount: number,
      multiplicator: number,
    } >,
    mainAttribute:  {
      __typename: "WSWAttribute",
      stimuliVariant?: WswStimulusAttributeVariant | null,
      type: WSWAttributeType,
      pickingStrategy: PickingStrategy,
      categories?:  Array< {
        __typename: "WSWAttributeCategory",
        name: string,
        mediaSnaps:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } > | null,
    },
    criteriaAttribute:  {
      __typename: "WSWAttribute",
      stimuliVariant?: WswStimulusAttributeVariant | null,
      type: WSWAttributeType,
      pickingStrategy: PickingStrategy,
      categories?:  Array< {
        __typename: "WSWAttributeCategory",
        name: string,
        mediaSnaps:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } > | null,
    },
    additionalAttribute?:  {
      __typename: "WSWAttribute",
      stimuliVariant?: WswStimulusAttributeVariant | null,
      type: WSWAttributeType,
      pickingStrategy: PickingStrategy,
      categories?:  Array< {
        __typename: "WSWAttributeCategory",
        name: string,
        mediaSnaps:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } > | null,
    } | null,
    groupConstruction?:  {
      __typename: "GroupConstruction",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      fakeQuestions:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
      conditions:  {
        __typename: "GroupConstructionConditions",
        condition1: number,
        condition2: number,
        condition3: number,
      },
      groups:  Array< {
        __typename: "WSWGroup",
        name: string,
        color: string,
        questionSet?:  {
          __typename: "ConditionalQuestionSet",
          randomizeSequence: boolean,
          conditionalQuestion?:  {
            __typename: "ConditionalQuestion",
            questionText: string,
            confirmText: string,
            declineText: string,
            answerType: ConditionalAnswerType,
          } | null,
          questionsAfter:  Array< {
            __typename: "TestQuestion",
            noAnswer?: string | null,
            type: QuestionType,
            questionText: string,
            multiple?:  {
              __typename: "MultipleChoiceQuestion",
              randomized: boolean,
              options: Array< string >,
            } | null,
            scale?:  {
              __typename: "ScaleQuestion",
              invert: boolean,
              min:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
              max:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
            } | null,
            mediaItemSnapshot?:  {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } | null,
            imageMultipleChoice?:  {
              __typename: "ImageMultipleChoiceQuestion",
              randomized: boolean,
              options:  Array< {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } >,
            } | null,
            stimulusTimeout?: number | null,
          } >,
          questionsBefore:  Array< {
            __typename: "TestQuestion",
            noAnswer?: string | null,
            type: QuestionType,
            questionText: string,
            multiple?:  {
              __typename: "MultipleChoiceQuestion",
              randomized: boolean,
              options: Array< string >,
            } | null,
            scale?:  {
              __typename: "ScaleQuestion",
              invert: boolean,
              min:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
              max:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
            } | null,
            mediaItemSnapshot?:  {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } | null,
            imageMultipleChoice?:  {
              __typename: "ImageMultipleChoiceQuestion",
              randomized: boolean,
              options:  Array< {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } >,
            } | null,
            stimulusTimeout?: number | null,
          } >,
        } | null,
        probability: number,
        feedbackScreen?:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        } | null,
      } >,
      randomizeGroupColors: boolean,
    } | null,
    learningPhase:  {
      __typename: "PresentationPhase",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      questionsSet:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
      questionOffsetTimeout?: number | null,
    },
    testPhase:  {
      __typename: "PresentationPhase",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      questionsSet:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
      questionOffsetTimeout?: number | null,
    },
    distractionPhase?:  {
      __typename: "DistractionPhase",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      questionsSet:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
    } | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateWswResultMutationVariables = {
  input: CreateWswResultInput,
  condition?: ModelWswResultConditionInput | null,
};

export type CreateWswResultMutation = {
  createWswResult?:  {
    __typename: "WswResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    phase: WswTestPhase,
    position: number,
    trialIndex: number,
    groupIndication?:  {
      __typename: "WswGroupIndicationResult",
      groupIndicatorShow: boolean,
      groupIndicationCategory: string,
      groupIndicationIndex: number,
      groupIndicationColor: string,
    } | null,
    questionType: WswQuestionType,
    questionAnswer: string,
    questionImageAnswerOriginalFileName?: string | null,
    noAnswer: boolean,
    answerOptionIndex?: number | null,
    questionPosition: number,
    questionPoolName: string,
    questionSnapshot: string,
    contentType: WswContentType,
    resultStimulus?:  {
      __typename: "WswResultStimulus",
      stimulusType: StimulusType,
      stimulusId?: string | null,
      stimulusTextValue?: string | null,
      stimulusTags?: Array< string > | null,
      stimulusOriginalFilename?: string | null,
    } | null,
    attributeCombination?:  {
      __typename: "WswAttributeCombinationResult",
      isLearningStimulus: boolean,
      mainStimulus:  {
        __typename: "CombinationStimulusItem",
        stimulusType: WswStimulusType,
        stimulusPoolCategory: string,
        stimulusPoolIndex: number,
        stimulusPoolPosition: number,
        stimulusOriginalFilename?: string | null,
        type?: StimulusType | null,
        stimulusId?: string | null,
        stimulusTextValue?: string | null,
        stimulusTags?: Array< string > | null,
      },
      criteriaStimulus?:  {
        __typename: "CombinationStimulusItem",
        stimulusType: WswStimulusType,
        stimulusPoolCategory: string,
        stimulusPoolIndex: number,
        stimulusPoolPosition: number,
        stimulusOriginalFilename?: string | null,
        type?: StimulusType | null,
        stimulusId?: string | null,
        stimulusTextValue?: string | null,
        stimulusTags?: Array< string > | null,
      } | null,
      additionalStimulus?:  {
        __typename: "CombinationStimulusItem",
        stimulusType: WswStimulusType,
        stimulusPoolCategory: string,
        stimulusPoolIndex: number,
        stimulusPoolPosition: number,
        stimulusOriginalFilename?: string | null,
        type?: StimulusType | null,
        stimulusId?: string | null,
        stimulusTextValue?: string | null,
        stimulusTags?: Array< string > | null,
      } | null,
    } | null,
    owner?: string | null,
    groupCondition?: number | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateWswResultMutationVariables = {
  input: UpdateWswResultInput,
  condition?: ModelWswResultConditionInput | null,
};

export type UpdateWswResultMutation = {
  updateWswResult?:  {
    __typename: "WswResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    phase: WswTestPhase,
    position: number,
    trialIndex: number,
    groupIndication?:  {
      __typename: "WswGroupIndicationResult",
      groupIndicatorShow: boolean,
      groupIndicationCategory: string,
      groupIndicationIndex: number,
      groupIndicationColor: string,
    } | null,
    questionType: WswQuestionType,
    questionAnswer: string,
    questionImageAnswerOriginalFileName?: string | null,
    noAnswer: boolean,
    answerOptionIndex?: number | null,
    questionPosition: number,
    questionPoolName: string,
    questionSnapshot: string,
    contentType: WswContentType,
    resultStimulus?:  {
      __typename: "WswResultStimulus",
      stimulusType: StimulusType,
      stimulusId?: string | null,
      stimulusTextValue?: string | null,
      stimulusTags?: Array< string > | null,
      stimulusOriginalFilename?: string | null,
    } | null,
    attributeCombination?:  {
      __typename: "WswAttributeCombinationResult",
      isLearningStimulus: boolean,
      mainStimulus:  {
        __typename: "CombinationStimulusItem",
        stimulusType: WswStimulusType,
        stimulusPoolCategory: string,
        stimulusPoolIndex: number,
        stimulusPoolPosition: number,
        stimulusOriginalFilename?: string | null,
        type?: StimulusType | null,
        stimulusId?: string | null,
        stimulusTextValue?: string | null,
        stimulusTags?: Array< string > | null,
      },
      criteriaStimulus?:  {
        __typename: "CombinationStimulusItem",
        stimulusType: WswStimulusType,
        stimulusPoolCategory: string,
        stimulusPoolIndex: number,
        stimulusPoolPosition: number,
        stimulusOriginalFilename?: string | null,
        type?: StimulusType | null,
        stimulusId?: string | null,
        stimulusTextValue?: string | null,
        stimulusTags?: Array< string > | null,
      } | null,
      additionalStimulus?:  {
        __typename: "CombinationStimulusItem",
        stimulusType: WswStimulusType,
        stimulusPoolCategory: string,
        stimulusPoolIndex: number,
        stimulusPoolPosition: number,
        stimulusOriginalFilename?: string | null,
        type?: StimulusType | null,
        stimulusId?: string | null,
        stimulusTextValue?: string | null,
        stimulusTags?: Array< string > | null,
      } | null,
    } | null,
    owner?: string | null,
    groupCondition?: number | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteWswResultMutationVariables = {
  input: DeleteWswResultInput,
  condition?: ModelWswResultConditionInput | null,
};

export type DeleteWswResultMutation = {
  deleteWswResult?:  {
    __typename: "WswResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    phase: WswTestPhase,
    position: number,
    trialIndex: number,
    groupIndication?:  {
      __typename: "WswGroupIndicationResult",
      groupIndicatorShow: boolean,
      groupIndicationCategory: string,
      groupIndicationIndex: number,
      groupIndicationColor: string,
    } | null,
    questionType: WswQuestionType,
    questionAnswer: string,
    questionImageAnswerOriginalFileName?: string | null,
    noAnswer: boolean,
    answerOptionIndex?: number | null,
    questionPosition: number,
    questionPoolName: string,
    questionSnapshot: string,
    contentType: WswContentType,
    resultStimulus?:  {
      __typename: "WswResultStimulus",
      stimulusType: StimulusType,
      stimulusId?: string | null,
      stimulusTextValue?: string | null,
      stimulusTags?: Array< string > | null,
      stimulusOriginalFilename?: string | null,
    } | null,
    attributeCombination?:  {
      __typename: "WswAttributeCombinationResult",
      isLearningStimulus: boolean,
      mainStimulus:  {
        __typename: "CombinationStimulusItem",
        stimulusType: WswStimulusType,
        stimulusPoolCategory: string,
        stimulusPoolIndex: number,
        stimulusPoolPosition: number,
        stimulusOriginalFilename?: string | null,
        type?: StimulusType | null,
        stimulusId?: string | null,
        stimulusTextValue?: string | null,
        stimulusTags?: Array< string > | null,
      },
      criteriaStimulus?:  {
        __typename: "CombinationStimulusItem",
        stimulusType: WswStimulusType,
        stimulusPoolCategory: string,
        stimulusPoolIndex: number,
        stimulusPoolPosition: number,
        stimulusOriginalFilename?: string | null,
        type?: StimulusType | null,
        stimulusId?: string | null,
        stimulusTextValue?: string | null,
        stimulusTags?: Array< string > | null,
      } | null,
      additionalStimulus?:  {
        __typename: "CombinationStimulusItem",
        stimulusType: WswStimulusType,
        stimulusPoolCategory: string,
        stimulusPoolIndex: number,
        stimulusPoolPosition: number,
        stimulusOriginalFilename?: string | null,
        type?: StimulusType | null,
        stimulusId?: string | null,
        stimulusTextValue?: string | null,
        stimulusTags?: Array< string > | null,
      } | null,
    } | null,
    owner?: string | null,
    groupCondition?: number | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSurveyProviderMutationVariables = {
  input: CreateSurveyProviderInput,
  condition?: ModelSurveyProviderConditionInput | null,
};

export type CreateSurveyProviderMutation = {
  createSurveyProvider?:  {
    __typename: "SurveyProvider",
    id: string,
    slug: string,
    name: string,
    description: string,
    snippetConfiguration:  {
      __typename: "SurveyProviderSnippetConfiguration",
      snippetType: SurveyProviderSnippetType,
      surveyIdReplacer?: string | null,
      probandIdReplacer: string,
    },
    setupDocumentation:  {
      __typename: "SurveyProviderSetupDocumentation",
      steps:  Array< {
        __typename: "SurveyProviderSetupDocumentationStep",
        type: SetupDocumentationStepType,
        textBeforeContent?: string | null,
        textAfterContent?: string | null,
        image?:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        } | null,
      } >,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateSurveyProviderMutationVariables = {
  input: UpdateSurveyProviderInput,
  condition?: ModelSurveyProviderConditionInput | null,
};

export type UpdateSurveyProviderMutation = {
  updateSurveyProvider?:  {
    __typename: "SurveyProvider",
    id: string,
    slug: string,
    name: string,
    description: string,
    snippetConfiguration:  {
      __typename: "SurveyProviderSnippetConfiguration",
      snippetType: SurveyProviderSnippetType,
      surveyIdReplacer?: string | null,
      probandIdReplacer: string,
    },
    setupDocumentation:  {
      __typename: "SurveyProviderSetupDocumentation",
      steps:  Array< {
        __typename: "SurveyProviderSetupDocumentationStep",
        type: SetupDocumentationStepType,
        textBeforeContent?: string | null,
        textAfterContent?: string | null,
        image?:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        } | null,
      } >,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteSurveyProviderMutationVariables = {
  input: DeleteSurveyProviderInput,
  condition?: ModelSurveyProviderConditionInput | null,
};

export type DeleteSurveyProviderMutation = {
  deleteSurveyProvider?:  {
    __typename: "SurveyProvider",
    id: string,
    slug: string,
    name: string,
    description: string,
    snippetConfiguration:  {
      __typename: "SurveyProviderSnippetConfiguration",
      snippetType: SurveyProviderSnippetType,
      surveyIdReplacer?: string | null,
      probandIdReplacer: string,
    },
    setupDocumentation:  {
      __typename: "SurveyProviderSetupDocumentation",
      steps:  Array< {
        __typename: "SurveyProviderSetupDocumentationStep",
        type: SetupDocumentationStepType,
        textBeforeContent?: string | null,
        textAfterContent?: string | null,
        image?:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        } | null,
      } >,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateUserRegistrationMutationVariables = {
  input: CreateUserRegistrationInput,
  condition?: ModelUserRegistrationConditionInput | null,
};

export type CreateUserRegistrationMutation = {
  createUserRegistration?:  {
    __typename: "UserRegistration",
    id: string,
    name: string,
    email: string,
    institution: string,
    researchGoal: string,
    additionalInformation?: string | null,
    confirmedUsagePolicy: boolean,
    status: UserRegistrationStatus,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateUserRegistrationMutationVariables = {
  input: UpdateUserRegistrationInput,
  condition?: ModelUserRegistrationConditionInput | null,
};

export type UpdateUserRegistrationMutation = {
  updateUserRegistration?:  {
    __typename: "UserRegistration",
    id: string,
    name: string,
    email: string,
    institution: string,
    researchGoal: string,
    additionalInformation?: string | null,
    confirmedUsagePolicy: boolean,
    status: UserRegistrationStatus,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteUserRegistrationMutationVariables = {
  input: DeleteUserRegistrationInput,
  condition?: ModelUserRegistrationConditionInput | null,
};

export type DeleteUserRegistrationMutation = {
  deleteUserRegistration?:  {
    __typename: "UserRegistration",
    id: string,
    name: string,
    email: string,
    institution: string,
    researchGoal: string,
    additionalInformation?: string | null,
    confirmedUsagePolicy: boolean,
    status: UserRegistrationStatus,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateBroadcastMailMutationVariables = {
  input: CreateBroadcastMailInput,
  condition?: ModelBroadcastMailConditionInput | null,
};

export type CreateBroadcastMailMutation = {
  createBroadcastMail?:  {
    __typename: "BroadcastMail",
    id: string,
    subject: string,
    sender?: string | null,
    content: string,
    status: BroadcastMailStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBroadcastMailMutationVariables = {
  input: UpdateBroadcastMailInput,
  condition?: ModelBroadcastMailConditionInput | null,
};

export type UpdateBroadcastMailMutation = {
  updateBroadcastMail?:  {
    __typename: "BroadcastMail",
    id: string,
    subject: string,
    sender?: string | null,
    content: string,
    status: BroadcastMailStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBroadcastMailMutationVariables = {
  input: DeleteBroadcastMailInput,
  condition?: ModelBroadcastMailConditionInput | null,
};

export type DeleteBroadcastMailMutation = {
  deleteBroadcastMail?:  {
    __typename: "BroadcastMail",
    id: string,
    subject: string,
    sender?: string | null,
    content: string,
    status: BroadcastMailStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMediaItemTagsMutationVariables = {
  input: CreateMediaItemTagsInput,
  condition?: ModelMediaItemTagsConditionInput | null,
};

export type CreateMediaItemTagsMutation = {
  createMediaItemTags?:  {
    __typename: "MediaItemTags",
    id: string,
    tagId: string,
    mediaItemId: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string,
      mediaItems?:  {
        __typename: "ModelMediaItemTagsConnection",
        items:  Array< {
          __typename: "MediaItemTags",
          id: string,
          tagId: string,
          mediaItemId: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            mediaItems?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          mediaItem:  {
            __typename: "MediaItem",
            id: string,
            image:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            },
            source?: string | null,
            originalFileName: string,
            tags?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            tagsString?: string | null,
            tagsIds: Array< string >,
            createdAt: string,
            updatedAt: string,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    mediaItem:  {
      __typename: "MediaItem",
      id: string,
      image:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      },
      source?: string | null,
      originalFileName: string,
      tags?:  {
        __typename: "ModelMediaItemTagsConnection",
        items:  Array< {
          __typename: "MediaItemTags",
          id: string,
          tagId: string,
          mediaItemId: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            mediaItems?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          mediaItem:  {
            __typename: "MediaItem",
            id: string,
            image:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            },
            source?: string | null,
            originalFileName: string,
            tags?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            tagsString?: string | null,
            tagsIds: Array< string >,
            createdAt: string,
            updatedAt: string,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tagsString?: string | null,
      tagsIds: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMediaItemTagsMutationVariables = {
  input: UpdateMediaItemTagsInput,
  condition?: ModelMediaItemTagsConditionInput | null,
};

export type UpdateMediaItemTagsMutation = {
  updateMediaItemTags?:  {
    __typename: "MediaItemTags",
    id: string,
    tagId: string,
    mediaItemId: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string,
      mediaItems?:  {
        __typename: "ModelMediaItemTagsConnection",
        items:  Array< {
          __typename: "MediaItemTags",
          id: string,
          tagId: string,
          mediaItemId: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            mediaItems?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          mediaItem:  {
            __typename: "MediaItem",
            id: string,
            image:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            },
            source?: string | null,
            originalFileName: string,
            tags?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            tagsString?: string | null,
            tagsIds: Array< string >,
            createdAt: string,
            updatedAt: string,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    mediaItem:  {
      __typename: "MediaItem",
      id: string,
      image:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      },
      source?: string | null,
      originalFileName: string,
      tags?:  {
        __typename: "ModelMediaItemTagsConnection",
        items:  Array< {
          __typename: "MediaItemTags",
          id: string,
          tagId: string,
          mediaItemId: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            mediaItems?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          mediaItem:  {
            __typename: "MediaItem",
            id: string,
            image:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            },
            source?: string | null,
            originalFileName: string,
            tags?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            tagsString?: string | null,
            tagsIds: Array< string >,
            createdAt: string,
            updatedAt: string,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tagsString?: string | null,
      tagsIds: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMediaItemTagsMutationVariables = {
  input: DeleteMediaItemTagsInput,
  condition?: ModelMediaItemTagsConditionInput | null,
};

export type DeleteMediaItemTagsMutation = {
  deleteMediaItemTags?:  {
    __typename: "MediaItemTags",
    id: string,
    tagId: string,
    mediaItemId: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string,
      mediaItems?:  {
        __typename: "ModelMediaItemTagsConnection",
        items:  Array< {
          __typename: "MediaItemTags",
          id: string,
          tagId: string,
          mediaItemId: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            mediaItems?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          mediaItem:  {
            __typename: "MediaItem",
            id: string,
            image:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            },
            source?: string | null,
            originalFileName: string,
            tags?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            tagsString?: string | null,
            tagsIds: Array< string >,
            createdAt: string,
            updatedAt: string,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    mediaItem:  {
      __typename: "MediaItem",
      id: string,
      image:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      },
      source?: string | null,
      originalFileName: string,
      tags?:  {
        __typename: "ModelMediaItemTagsConnection",
        items:  Array< {
          __typename: "MediaItemTags",
          id: string,
          tagId: string,
          mediaItemId: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            mediaItems?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          mediaItem:  {
            __typename: "MediaItem",
            id: string,
            image:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            },
            source?: string | null,
            originalFileName: string,
            tags?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            tagsString?: string | null,
            tagsIds: Array< string >,
            createdAt: string,
            updatedAt: string,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tagsString?: string | null,
      tagsIds: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type RegisterUserMutationVariables = {
  name: string,
  email: string,
  institution: string,
  additionalInformation?: string | null,
  confirmedUsagePolicy: boolean,
  researchGoal: string,
};

export type RegisterUserMutation = {
  registerUser?:  {
    __typename: "FunctionBodyResponse",
    body?: string | null,
  } | null,
};

export type ConfirmRegistrationMutationVariables = {
  registrationId: string,
  groups: Array< string >,
  institution?: string | null,
  additionalInformation?: string | null,
  researchGoal?: string | null,
};

export type ConfirmRegistrationMutation = {
  confirmRegistration?:  {
    __typename: "FunctionBodyResponse",
    body?: string | null,
  } | null,
};

export type DenyRegistrationMutationVariables = {
  registrationId: string,
};

export type DenyRegistrationMutation = {
  denyRegistration?:  {
    __typename: "FunctionBodyResponse",
    body?: string | null,
  } | null,
};

export type MindSetLoggingAdapterMutationVariables = {
  severity: Severity,
  name: string,
  message: string,
  context?: string | null,
};

export type MindSetLoggingAdapterMutation = {
  mindSetLoggingAdapter?:  {
    __typename: "FunctionBodyResponse",
    body?: string | null,
  } | null,
};

export type GetDocumentationFileQueryVariables = {
  id: string,
};

export type GetDocumentationFileQuery = {
  getDocumentationFile?:  {
    __typename: "DocumentationFile",
    id: string,
    name: string,
    description: string,
    s3Key: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDocumentationFilesQueryVariables = {
  filter?: ModelDocumentationFileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDocumentationFilesQuery = {
  listDocumentationFiles?:  {
    __typename: "ModelDocumentationFileConnection",
    items:  Array< {
      __typename: "DocumentationFile",
      id: string,
      name: string,
      description: string,
      s3Key: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDocumentFileQueryVariables = {
  id: string,
};

export type GetDocumentFileQuery = {
  getDocumentFile?:  {
    __typename: "DocumentFile",
    id: string,
    path: Array< string >,
    name: string,
    description: string,
    format: string,
    currentVersion:  {
      __typename: "DocumentFileVersion",
      createdAt: string,
      version: string,
      validSince?: string | null,
      s3Key: string,
    },
    history:  Array< {
      __typename: "DocumentFileVersion",
      createdAt: string,
      version: string,
      validSince?: string | null,
      s3Key: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDocumentFilesQueryVariables = {
  filter?: ModelDocumentFileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDocumentFilesQuery = {
  listDocumentFiles?:  {
    __typename: "ModelDocumentFileConnection",
    items:  Array< {
      __typename: "DocumentFile",
      id: string,
      path: Array< string >,
      name: string,
      description: string,
      format: string,
      currentVersion:  {
        __typename: "DocumentFileVersion",
        createdAt: string,
        version: string,
        validSince?: string | null,
        s3Key: string,
      },
      history:  Array< {
        __typename: "DocumentFileVersion",
        createdAt: string,
        version: string,
        validSince?: string | null,
        s3Key: string,
      } >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTagQueryVariables = {
  id: string,
};

export type GetTagQuery = {
  getTag?:  {
    __typename: "Tag",
    id: string,
    name: string,
    mediaItems?:  {
      __typename: "ModelMediaItemTagsConnection",
      items:  Array< {
        __typename: "MediaItemTags",
        id: string,
        tagId: string,
        mediaItemId: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          mediaItems?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        mediaItem:  {
          __typename: "MediaItem",
          id: string,
          image:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          },
          source?: string | null,
          originalFileName: string,
          tags?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tagsString?: string | null,
          tagsIds: Array< string >,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTagsQueryVariables = {
  filter?: ModelTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTagsQuery = {
  listTags?:  {
    __typename: "ModelTagConnection",
    items:  Array< {
      __typename: "Tag",
      id: string,
      name: string,
      mediaItems?:  {
        __typename: "ModelMediaItemTagsConnection",
        items:  Array< {
          __typename: "MediaItemTags",
          id: string,
          tagId: string,
          mediaItemId: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            mediaItems?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          mediaItem:  {
            __typename: "MediaItem",
            id: string,
            image:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            },
            source?: string | null,
            originalFileName: string,
            tags?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            tagsString?: string | null,
            tagsIds: Array< string >,
            createdAt: string,
            updatedAt: string,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMediaItemQueryVariables = {
  id: string,
};

export type GetMediaItemQuery = {
  getMediaItem?:  {
    __typename: "MediaItem",
    id: string,
    image:  {
      __typename: "Image",
      fullSize:  {
        __typename: "ImageInstance",
        width: number,
        height: number,
        key: string,
      },
      thumbNail:  {
        __typename: "ImageInstance",
        width: number,
        height: number,
        key: string,
      },
    },
    source?: string | null,
    originalFileName: string,
    tags?:  {
      __typename: "ModelMediaItemTagsConnection",
      items:  Array< {
        __typename: "MediaItemTags",
        id: string,
        tagId: string,
        mediaItemId: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          mediaItems?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        mediaItem:  {
          __typename: "MediaItem",
          id: string,
          image:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          },
          source?: string | null,
          originalFileName: string,
          tags?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tagsString?: string | null,
          tagsIds: Array< string >,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tagsString?: string | null,
    tagsIds: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMediaItemsQueryVariables = {
  filter?: ModelMediaItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMediaItemsQuery = {
  listMediaItems?:  {
    __typename: "ModelMediaItemConnection",
    items:  Array< {
      __typename: "MediaItem",
      id: string,
      image:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      },
      source?: string | null,
      originalFileName: string,
      tags?:  {
        __typename: "ModelMediaItemTagsConnection",
        items:  Array< {
          __typename: "MediaItemTags",
          id: string,
          tagId: string,
          mediaItemId: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            mediaItems?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          mediaItem:  {
            __typename: "MediaItem",
            id: string,
            image:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            },
            source?: string | null,
            originalFileName: string,
            tags?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            tagsString?: string | null,
            tagsIds: Array< string >,
            createdAt: string,
            updatedAt: string,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tagsString?: string | null,
      tagsIds: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MediaItemsByTagsStringQueryVariables = {
  tagsString: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMediaItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MediaItemsByTagsStringQuery = {
  mediaItemsByTagsString?:  {
    __typename: "ModelMediaItemConnection",
    items:  Array< {
      __typename: "MediaItem",
      id: string,
      image:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      },
      source?: string | null,
      originalFileName: string,
      tags?:  {
        __typename: "ModelMediaItemTagsConnection",
        items:  Array< {
          __typename: "MediaItemTags",
          id: string,
          tagId: string,
          mediaItemId: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            mediaItems?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          mediaItem:  {
            __typename: "MediaItem",
            id: string,
            image:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            },
            source?: string | null,
            originalFileName: string,
            tags?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            tagsString?: string | null,
            tagsIds: Array< string >,
            createdAt: string,
            updatedAt: string,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tagsString?: string | null,
      tagsIds: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTestFinalizationQueryVariables = {
  id: string,
};

export type GetTestFinalizationQuery = {
  getTestFinalization?:  {
    __typename: "TestFinalization",
    id: string,
    testType: string,
    testId: string,
    probandId: string,
    loadingStartedAt: string,
    testStartedAt?: string | null,
    testFinalizedAt?: string | null,
    testAbortedAt?: string | null,
    owner?: string | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTestFinalizationsQueryVariables = {
  filter?: ModelTestFinalizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTestFinalizationsQuery = {
  listTestFinalizations?:  {
    __typename: "ModelTestFinalizationConnection",
    items:  Array< {
      __typename: "TestFinalization",
      id: string,
      testType: string,
      testId: string,
      probandId: string,
      loadingStartedAt: string,
      testStartedAt?: string | null,
      testFinalizedAt?: string | null,
      testAbortedAt?: string | null,
      owner?: string | null,
      status?: TestStatus | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TestFinalizationsByTestIdQueryVariables = {
  testId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTestFinalizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TestFinalizationsByTestIdQuery = {
  testFinalizationsByTestId?:  {
    __typename: "ModelTestFinalizationConnection",
    items:  Array< {
      __typename: "TestFinalization",
      id: string,
      testType: string,
      testId: string,
      probandId: string,
      loadingStartedAt: string,
      testStartedAt?: string | null,
      testFinalizedAt?: string | null,
      testAbortedAt?: string | null,
      owner?: string | null,
      status?: TestStatus | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTestResultsStatusQueryVariables = {
  id: string,
};

export type GetTestResultsStatusQuery = {
  getTestResultsStatus?:  {
    __typename: "TestResultsStatus",
    id: string,
    status: TestResultsStatusType,
    testType: string,
    error?: string | null,
    statistics?:  {
      __typename: "TestResultsStatistics",
      processedResultRows: number,
      exportedResults: number,
      processedProbandAmount: number,
      finishedProbandAmount: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListTestResultsStatusesQueryVariables = {
  filter?: ModelTestResultsStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTestResultsStatusesQuery = {
  listTestResultsStatuses?:  {
    __typename: "ModelTestResultsStatusConnection",
    items:  Array< {
      __typename: "TestResultsStatus",
      id: string,
      status: TestResultsStatusType,
      testType: string,
      error?: string | null,
      statistics?:  {
        __typename: "TestResultsStatistics",
        processedResultRows: number,
        exportedResults: number,
        processedProbandAmount: number,
        finishedProbandAmount: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetIatTestQueryVariables = {
  id: string,
};

export type GetIatTestQuery = {
  getIatTest?:  {
    __typename: "IatTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    interTrialInterval: number,
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    previewCategories: CategoryPreviewDisplay,
    blocks:  Array< {
      __typename: "IatBlock",
      index: number,
      type: BlockType,
      name: string,
      amountTrials: number,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      categoryLabelsInInstructions: boolean,
      attributeCategoryVariant?: CategoryVariant | null,
      targetCategoryVariant?: CategoryVariant | null,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    targetCategories:  {
      __typename: "IatCategories",
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      categoryA:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      categoryB:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    },
    categoryDivider?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    attributeCategories:  {
      __typename: "IatCategories",
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      categoryA:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      categoryB:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    },
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    feedback: IatFeedbackType,
    feedbackInterval?: number | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListIatTestsQueryVariables = {
  filter?: ModelIatTestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIatTestsQuery = {
  listIatTests?:  {
    __typename: "ModelIatTestConnection",
    items:  Array< {
      __typename: "IatTest",
      id: string,
      name: string,
      description: string,
      language?: string | null,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      keyboard:  {
        __typename: "KeyboardSettings",
        left: string,
        right: string,
        confirm: string,
      },
      interTrialInterval: number,
      counterbalancing: boolean,
      probabilityWithoutReplacement: boolean,
      style:  {
        __typename: "TestStyle",
        maxWidth: number,
        backgroundColour?: string | null,
        stimulusTextStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        mobileStimulusTextStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
        optionsPosition: OptionsPosition,
        optionsPositionMobile?: OptionsPosition | null,
      },
      status: TestStatus,
      previewCategories: CategoryPreviewDisplay,
      blocks:  Array< {
        __typename: "IatBlock",
        index: number,
        type: BlockType,
        name: string,
        amountTrials: number,
        instructions:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        },
        categoryLabelsInInstructions: boolean,
        attributeCategoryVariant?: CategoryVariant | null,
        targetCategoryVariant?: CategoryVariant | null,
        tips?:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        } | null,
      } >,
      targetCategories:  {
        __typename: "IatCategories",
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        categoryA:  {
          __typename: "LabeledStimuliPool",
          label:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          },
          practiceStimuliPool:  Array< {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } >,
          testStimuliPool:  Array< {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } >,
        },
        categoryB:  {
          __typename: "LabeledStimuliPool",
          label:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          },
          practiceStimuliPool:  Array< {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } >,
          testStimuliPool:  Array< {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } >,
        },
      },
      categoryDivider?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      attributeCategories:  {
        __typename: "IatCategories",
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        categoryA:  {
          __typename: "LabeledStimuliPool",
          label:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          },
          practiceStimuliPool:  Array< {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } >,
          testStimuliPool:  Array< {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } >,
        },
        categoryB:  {
          __typename: "LabeledStimuliPool",
          label:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          },
          practiceStimuliPool:  Array< {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } >,
          testStimuliPool:  Array< {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } >,
        },
      },
      endScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      startLabel: string,
      nextLabel: string,
      finishLabel: string,
      feedback: IatFeedbackType,
      feedbackInterval?: number | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      viewers?: Array< string | null > | null,
      resultViewers?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetIatResultQueryVariables = {
  id: string,
};

export type GetIatResultQuery = {
  getIatResult?:  {
    __typename: "IatResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    actionTarget?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    actionAttribute?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    counterBalancingGroup?: string | null,
    stimulus:  {
      __typename: "ResultStimulus",
      categoryLabel:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      categoryType: IatCategoryType,
      stimulus:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    pressedButton?: string | null,
    triggeredControl?: string | null,
    attributeLeft?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    attributeRight?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    targetLeft?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    targetRight?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListIatResultsQueryVariables = {
  filter?: ModelIatResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIatResultsQuery = {
  listIatResults?:  {
    __typename: "ModelIatResultConnection",
    items:  Array< {
      __typename: "IatResult",
      id: string,
      testId: string,
      clientId: string,
      survey:  {
        __typename: "ResultSurveyIdentifier",
        probandId: string,
        surveyId?: string | null,
        surveyProbandId?: string | null,
        testProviderName: string,
      },
      timing:  {
        __typename: "ResultTiming",
        renderStartDelay?: number | null,
        renderEndDelay?: number | null,
        startTriggerDelay: number,
        endTriggerDelay?: number | null,
        controlRaceDelay?: number | null,
      },
      device:  {
        __typename: "ProbandDevice",
        ua: string,
        browser?:  {
          __typename: "BrowserInfo",
          name?: string | null,
          version?: string | null,
          major?: string | null,
        } | null,
        device?:  {
          __typename: "DeviceInfo",
          model?: string | null,
          type?: string | null,
          vendor?: string | null,
        } | null,
        engine?:  {
          __typename: "EngineInfo",
          name?: string | null,
          version?: string | null,
        } | null,
        os?:  {
          __typename: "OsInfo",
          name?: string | null,
          version?: string | null,
        } | null,
        cpu?:  {
          __typename: "CpuInfo",
          architecture?: string | null,
        } | null,
      },
      blockIndex: number,
      blockName: string,
      blockType?: BlockType | null,
      trialIndex: number,
      actionResultName: string,
      actionTarget?:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      } | null,
      actionAttribute?:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      } | null,
      counterBalancingGroup?: string | null,
      stimulus:  {
        __typename: "ResultStimulus",
        categoryLabel:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        categoryType: IatCategoryType,
        stimulus:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
      },
      pressedButton?: string | null,
      triggeredControl?: string | null,
      attributeLeft?:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      } | null,
      attributeRight?:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      } | null,
      targetLeft?:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      } | null,
      targetRight?:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      } | null,
      status?: TestStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type IatResultsByTestIdQueryVariables = {
  testId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIatResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type IatResultsByTestIdQuery = {
  iatResultsByTestId?:  {
    __typename: "ModelIatResultConnection",
    items:  Array< {
      __typename: "IatResult",
      id: string,
      testId: string,
      clientId: string,
      survey:  {
        __typename: "ResultSurveyIdentifier",
        probandId: string,
        surveyId?: string | null,
        surveyProbandId?: string | null,
        testProviderName: string,
      },
      timing:  {
        __typename: "ResultTiming",
        renderStartDelay?: number | null,
        renderEndDelay?: number | null,
        startTriggerDelay: number,
        endTriggerDelay?: number | null,
        controlRaceDelay?: number | null,
      },
      device:  {
        __typename: "ProbandDevice",
        ua: string,
        browser?:  {
          __typename: "BrowserInfo",
          name?: string | null,
          version?: string | null,
          major?: string | null,
        } | null,
        device?:  {
          __typename: "DeviceInfo",
          model?: string | null,
          type?: string | null,
          vendor?: string | null,
        } | null,
        engine?:  {
          __typename: "EngineInfo",
          name?: string | null,
          version?: string | null,
        } | null,
        os?:  {
          __typename: "OsInfo",
          name?: string | null,
          version?: string | null,
        } | null,
        cpu?:  {
          __typename: "CpuInfo",
          architecture?: string | null,
        } | null,
      },
      blockIndex: number,
      blockName: string,
      blockType?: BlockType | null,
      trialIndex: number,
      actionResultName: string,
      actionTarget?:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      } | null,
      actionAttribute?:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      } | null,
      counterBalancingGroup?: string | null,
      stimulus:  {
        __typename: "ResultStimulus",
        categoryLabel:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        categoryType: IatCategoryType,
        stimulus:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
      },
      pressedButton?: string | null,
      triggeredControl?: string | null,
      attributeLeft?:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      } | null,
      attributeRight?:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      } | null,
      targetLeft?:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      } | null,
      targetRight?:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      } | null,
      status?: TestStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAmpTestQueryVariables = {
  id: string,
};

export type GetAmpTestQuery = {
  getAmpTest?:  {
    __typename: "AmpTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    interTrialInterval: number,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    attributeCategories:  {
      __typename: "AmpAttributeCategories",
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      categoryA:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      categoryB:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    fixation?:  {
      __typename: "TimedMediaItem",
      interval: number,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    } | null,
    primes:  {
      __typename: "TimedAmpCategories",
      interval: number,
      categories:  Array< {
        __typename: "AmpLabeledStimuliPool",
        label: string,
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } >,
    },
    targets:  {
      __typename: "TimedAmpCategories",
      interval: number,
      categories:  Array< {
        __typename: "AmpLabeledStimuliPool",
        label: string,
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } >,
    },
    mask:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    },
    blankInterval: number,
    blocks:  Array< {
      __typename: "AmpBlock",
      index: number,
      name: string,
      type: BlockType,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      amountTrials: number,
      attributeVariant: CategoryVariant,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAmpTestsQueryVariables = {
  filter?: ModelAmpTestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAmpTestsQuery = {
  listAmpTests?:  {
    __typename: "ModelAmpTestConnection",
    items:  Array< {
      __typename: "AmpTest",
      id: string,
      name: string,
      description: string,
      language?: string | null,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      keyboard:  {
        __typename: "KeyboardSettings",
        left: string,
        right: string,
        confirm: string,
      },
      counterbalancing: boolean,
      probabilityWithoutReplacement: boolean,
      interTrialInterval: number,
      style:  {
        __typename: "TestStyle",
        maxWidth: number,
        backgroundColour?: string | null,
        stimulusTextStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        mobileStimulusTextStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
        optionsPosition: OptionsPosition,
        optionsPositionMobile?: OptionsPosition | null,
      },
      status: TestStatus,
      endScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      attributeCategories:  {
        __typename: "AmpAttributeCategories",
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        categoryA:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        categoryB:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
      },
      fixation?:  {
        __typename: "TimedMediaItem",
        interval: number,
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
      } | null,
      primes:  {
        __typename: "TimedAmpCategories",
        interval: number,
        categories:  Array< {
          __typename: "AmpLabeledStimuliPool",
          label: string,
          practiceStimuliPool:  Array< {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } >,
          testStimuliPool:  Array< {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } >,
        } >,
      },
      targets:  {
        __typename: "TimedAmpCategories",
        interval: number,
        categories:  Array< {
          __typename: "AmpLabeledStimuliPool",
          label: string,
          practiceStimuliPool:  Array< {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } >,
          testStimuliPool:  Array< {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } >,
        } >,
      },
      mask:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      blankInterval: number,
      blocks:  Array< {
        __typename: "AmpBlock",
        index: number,
        name: string,
        type: BlockType,
        instructions:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        },
        amountTrials: number,
        attributeVariant: CategoryVariant,
        tips?:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        } | null,
      } >,
      startLabel: string,
      nextLabel: string,
      finishLabel: string,
      owner?: string | null,
      editors?: Array< string | null > | null,
      viewers?: Array< string | null > | null,
      resultViewers?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAmpResultQueryVariables = {
  id: string,
};

export type GetAmpResultQuery = {
  getAmpResult?:  {
    __typename: "AmpResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    counterBalancingGroup?: string | null,
    pressedButton?: string | null,
    triggeredControl?: string | null,
    attributeLeftStimulus?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    attributeRightStimulus?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    actionResultStimulus?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    attributeLeft: string,
    attributeRight: string,
    prime:  {
      __typename: "AmpResultCategoryMediaItem",
      label: string,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    target:  {
      __typename: "AmpResultCategoryMediaItem",
      label: string,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListAmpResultsQueryVariables = {
  filter?: ModelAmpResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAmpResultsQuery = {
  listAmpResults?:  {
    __typename: "ModelAmpResultConnection",
    items:  Array< {
      __typename: "AmpResult",
      id: string,
      testId: string,
      clientId: string,
      survey:  {
        __typename: "ResultSurveyIdentifier",
        probandId: string,
        surveyId?: string | null,
        surveyProbandId?: string | null,
        testProviderName: string,
      },
      timing:  {
        __typename: "ResultTiming",
        renderStartDelay?: number | null,
        renderEndDelay?: number | null,
        startTriggerDelay: number,
        endTriggerDelay?: number | null,
        controlRaceDelay?: number | null,
      },
      device:  {
        __typename: "ProbandDevice",
        ua: string,
        browser?:  {
          __typename: "BrowserInfo",
          name?: string | null,
          version?: string | null,
          major?: string | null,
        } | null,
        device?:  {
          __typename: "DeviceInfo",
          model?: string | null,
          type?: string | null,
          vendor?: string | null,
        } | null,
        engine?:  {
          __typename: "EngineInfo",
          name?: string | null,
          version?: string | null,
        } | null,
        os?:  {
          __typename: "OsInfo",
          name?: string | null,
          version?: string | null,
        } | null,
        cpu?:  {
          __typename: "CpuInfo",
          architecture?: string | null,
        } | null,
      },
      blockIndex: number,
      blockName: string,
      blockType?: BlockType | null,
      trialIndex: number,
      actionResultName: string,
      counterBalancingGroup?: string | null,
      pressedButton?: string | null,
      triggeredControl?: string | null,
      attributeLeftStimulus?:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      } | null,
      attributeRightStimulus?:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      } | null,
      actionResultStimulus?:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      } | null,
      attributeLeft: string,
      attributeRight: string,
      prime:  {
        __typename: "AmpResultCategoryMediaItem",
        label: string,
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
      },
      target:  {
        __typename: "AmpResultCategoryMediaItem",
        label: string,
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
      },
      status?: TestStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AmpResultsByTestIdQueryVariables = {
  testId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAmpResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AmpResultsByTestIdQuery = {
  ampResultsByTestId?:  {
    __typename: "ModelAmpResultConnection",
    items:  Array< {
      __typename: "AmpResult",
      id: string,
      testId: string,
      clientId: string,
      survey:  {
        __typename: "ResultSurveyIdentifier",
        probandId: string,
        surveyId?: string | null,
        surveyProbandId?: string | null,
        testProviderName: string,
      },
      timing:  {
        __typename: "ResultTiming",
        renderStartDelay?: number | null,
        renderEndDelay?: number | null,
        startTriggerDelay: number,
        endTriggerDelay?: number | null,
        controlRaceDelay?: number | null,
      },
      device:  {
        __typename: "ProbandDevice",
        ua: string,
        browser?:  {
          __typename: "BrowserInfo",
          name?: string | null,
          version?: string | null,
          major?: string | null,
        } | null,
        device?:  {
          __typename: "DeviceInfo",
          model?: string | null,
          type?: string | null,
          vendor?: string | null,
        } | null,
        engine?:  {
          __typename: "EngineInfo",
          name?: string | null,
          version?: string | null,
        } | null,
        os?:  {
          __typename: "OsInfo",
          name?: string | null,
          version?: string | null,
        } | null,
        cpu?:  {
          __typename: "CpuInfo",
          architecture?: string | null,
        } | null,
      },
      blockIndex: number,
      blockName: string,
      blockType?: BlockType | null,
      trialIndex: number,
      actionResultName: string,
      counterBalancingGroup?: string | null,
      pressedButton?: string | null,
      triggeredControl?: string | null,
      attributeLeftStimulus?:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      } | null,
      attributeRightStimulus?:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      } | null,
      actionResultStimulus?:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      } | null,
      attributeLeft: string,
      attributeRight: string,
      prime:  {
        __typename: "AmpResultCategoryMediaItem",
        label: string,
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
      },
      target:  {
        __typename: "AmpResultCategoryMediaItem",
        label: string,
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
      },
      status?: TestStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPodtTestQueryVariables = {
  id: string,
};

export type GetPodtTestQuery = {
  getPodtTest?:  {
    __typename: "PodtTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    interTrialInterval: number,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    response:  {
      __typename: "PodtResponse",
      timeout: number,
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      active:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      passive:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    scenes:  {
      __typename: "PodtScenes",
      maxAmountScenesPerTrial: number,
      scenes:  Array< {
        __typename: "PodtScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtBox",
          top: number,
          left: number,
          width: number,
          height: number,
        } >,
      } >,
      intervalConfig:  {
        __typename: "PodtSceneIntervalConfig",
        min: number,
        max: number,
        stepSize: number,
      },
    },
    targets:  Array< {
      __typename: "PodtTargetCategory",
      label: string,
      practiceStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      testStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    } >,
    fixation?:  {
      __typename: "TimedMediaItem",
      interval: number,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    } | null,
    trialFeedback?:  {
      __typename: "PodtTrialFeedback",
      correctRejectionText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      hitText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      missText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      falseAlarmText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      noResponseText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      pointsFontsize?: number | null,
    } | null,
    blocks:  Array< {
      __typename: "PodtBlock",
      index: number,
      name: string,
      type: BlockType,
      amountTrials: number,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      preparationScreen?:  {
        __typename: "PodtPreparationScreen",
        interval: number,
        screen:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        },
      } | null,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    showCategoriesAfterInstructions?: boolean | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPodtTestsQueryVariables = {
  filter?: ModelPodtTestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPodtTestsQuery = {
  listPodtTests?:  {
    __typename: "ModelPodtTestConnection",
    items:  Array< {
      __typename: "PodtTest",
      id: string,
      name: string,
      description: string,
      language?: string | null,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      keyboard:  {
        __typename: "KeyboardSettings",
        left: string,
        right: string,
        confirm: string,
      },
      counterbalancing: boolean,
      probabilityWithoutReplacement: boolean,
      interTrialInterval: number,
      style:  {
        __typename: "TestStyle",
        maxWidth: number,
        backgroundColour?: string | null,
        stimulusTextStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        mobileStimulusTextStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
        optionsPosition: OptionsPosition,
        optionsPositionMobile?: OptionsPosition | null,
      },
      status: TestStatus,
      endScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      response:  {
        __typename: "PodtResponse",
        timeout: number,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        active:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        passive:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
      },
      scenes:  {
        __typename: "PodtScenes",
        maxAmountScenesPerTrial: number,
        scenes:  Array< {
          __typename: "PodtScene",
          mediaItem:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          },
          boxes:  Array< {
            __typename: "PodtBox",
            top: number,
            left: number,
            width: number,
            height: number,
          } >,
        } >,
        intervalConfig:  {
          __typename: "PodtSceneIntervalConfig",
          min: number,
          max: number,
          stepSize: number,
        },
      },
      targets:  Array< {
        __typename: "PodtTargetCategory",
        label: string,
        practiceStimuliPool:  {
          __typename: "PodtCategoryPool",
          dangerousStimuli:  Array< {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } >,
          nonDangerousStimuli:  Array< {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } >,
        },
        testStimuliPool:  {
          __typename: "PodtCategoryPool",
          dangerousStimuli:  Array< {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } >,
          nonDangerousStimuli:  Array< {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } >,
        },
      } >,
      fixation?:  {
        __typename: "TimedMediaItem",
        interval: number,
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
      } | null,
      trialFeedback?:  {
        __typename: "PodtTrialFeedback",
        correctRejectionText:  {
          __typename: "WeightedStyledText",
          value: string,
          fontStyle:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          },
          weight: number,
        },
        hitText:  {
          __typename: "WeightedStyledText",
          value: string,
          fontStyle:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          },
          weight: number,
        },
        missText:  {
          __typename: "WeightedStyledText",
          value: string,
          fontStyle:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          },
          weight: number,
        },
        falseAlarmText:  {
          __typename: "WeightedStyledText",
          value: string,
          fontStyle:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          },
          weight: number,
        },
        noResponseText:  {
          __typename: "WeightedStyledText",
          value: string,
          fontStyle:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          },
          weight: number,
        },
        pointsFontsize?: number | null,
      } | null,
      blocks:  Array< {
        __typename: "PodtBlock",
        index: number,
        name: string,
        type: BlockType,
        amountTrials: number,
        instructions:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        },
        preparationScreen?:  {
          __typename: "PodtPreparationScreen",
          interval: number,
          screen:  {
            __typename: "StyleableTextTemplate",
            desktop: string,
            mobile?: string | null,
            fontStyle?:  {
              __typename: "FontStyle",
              fontSize: number,
              color: string,
              alignment: TextAlignment,
              fontFace: FontFace,
            } | null,
          },
        } | null,
        tips?:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        } | null,
      } >,
      showCategoriesAfterInstructions?: boolean | null,
      startLabel: string,
      nextLabel: string,
      finishLabel: string,
      owner?: string | null,
      editors?: Array< string | null > | null,
      viewers?: Array< string | null > | null,
      resultViewers?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPodtResultQueryVariables = {
  id: string,
};

export type GetPodtResultQuery = {
  getPodtResult?:  {
    __typename: "PodtResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    counterBalancingGroup?: string | null,
    earnedPoints?: number | null,
    reactionLeft: string,
    reactionRight: string,
    scenes:  Array< {
      __typename: "PodtResultScene",
      scene:  {
        __typename: "PodtScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtBox",
          top: number,
          left: number,
          width: number,
          height: number,
        } >,
      },
      interval: number,
    } >,
    target:  {
      __typename: "PodtResultTarget",
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      category: string,
      dangerous: boolean,
    },
    accumulatedPoints: number,
    pressedButton?: string | null,
    triggeredControl?: string | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListPodtResultsQueryVariables = {
  filter?: ModelPodtResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPodtResultsQuery = {
  listPodtResults?:  {
    __typename: "ModelPodtResultConnection",
    items:  Array< {
      __typename: "PodtResult",
      id: string,
      testId: string,
      clientId: string,
      survey:  {
        __typename: "ResultSurveyIdentifier",
        probandId: string,
        surveyId?: string | null,
        surveyProbandId?: string | null,
        testProviderName: string,
      },
      timing:  {
        __typename: "ResultTiming",
        renderStartDelay?: number | null,
        renderEndDelay?: number | null,
        startTriggerDelay: number,
        endTriggerDelay?: number | null,
        controlRaceDelay?: number | null,
      },
      device:  {
        __typename: "ProbandDevice",
        ua: string,
        browser?:  {
          __typename: "BrowserInfo",
          name?: string | null,
          version?: string | null,
          major?: string | null,
        } | null,
        device?:  {
          __typename: "DeviceInfo",
          model?: string | null,
          type?: string | null,
          vendor?: string | null,
        } | null,
        engine?:  {
          __typename: "EngineInfo",
          name?: string | null,
          version?: string | null,
        } | null,
        os?:  {
          __typename: "OsInfo",
          name?: string | null,
          version?: string | null,
        } | null,
        cpu?:  {
          __typename: "CpuInfo",
          architecture?: string | null,
        } | null,
      },
      blockIndex: number,
      blockName: string,
      blockType?: BlockType | null,
      trialIndex: number,
      actionResultName: string,
      counterBalancingGroup?: string | null,
      earnedPoints?: number | null,
      reactionLeft: string,
      reactionRight: string,
      scenes:  Array< {
        __typename: "PodtResultScene",
        scene:  {
          __typename: "PodtScene",
          mediaItem:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          },
          boxes:  Array< {
            __typename: "PodtBox",
            top: number,
            left: number,
            width: number,
            height: number,
          } >,
        },
        interval: number,
      } >,
      target:  {
        __typename: "PodtResultTarget",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        category: string,
        dangerous: boolean,
      },
      accumulatedPoints: number,
      pressedButton?: string | null,
      triggeredControl?: string | null,
      status?: TestStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PodtResultsByTestIdQueryVariables = {
  testId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPodtResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PodtResultsByTestIdQuery = {
  podtResultsByTestId?:  {
    __typename: "ModelPodtResultConnection",
    items:  Array< {
      __typename: "PodtResult",
      id: string,
      testId: string,
      clientId: string,
      survey:  {
        __typename: "ResultSurveyIdentifier",
        probandId: string,
        surveyId?: string | null,
        surveyProbandId?: string | null,
        testProviderName: string,
      },
      timing:  {
        __typename: "ResultTiming",
        renderStartDelay?: number | null,
        renderEndDelay?: number | null,
        startTriggerDelay: number,
        endTriggerDelay?: number | null,
        controlRaceDelay?: number | null,
      },
      device:  {
        __typename: "ProbandDevice",
        ua: string,
        browser?:  {
          __typename: "BrowserInfo",
          name?: string | null,
          version?: string | null,
          major?: string | null,
        } | null,
        device?:  {
          __typename: "DeviceInfo",
          model?: string | null,
          type?: string | null,
          vendor?: string | null,
        } | null,
        engine?:  {
          __typename: "EngineInfo",
          name?: string | null,
          version?: string | null,
        } | null,
        os?:  {
          __typename: "OsInfo",
          name?: string | null,
          version?: string | null,
        } | null,
        cpu?:  {
          __typename: "CpuInfo",
          architecture?: string | null,
        } | null,
      },
      blockIndex: number,
      blockName: string,
      blockType?: BlockType | null,
      trialIndex: number,
      actionResultName: string,
      counterBalancingGroup?: string | null,
      earnedPoints?: number | null,
      reactionLeft: string,
      reactionRight: string,
      scenes:  Array< {
        __typename: "PodtResultScene",
        scene:  {
          __typename: "PodtScene",
          mediaItem:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          },
          boxes:  Array< {
            __typename: "PodtBox",
            top: number,
            left: number,
            width: number,
            height: number,
          } >,
        },
        interval: number,
      } >,
      target:  {
        __typename: "PodtResultTarget",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        category: string,
        dangerous: boolean,
      },
      accumulatedPoints: number,
      pressedButton?: string | null,
      triggeredControl?: string | null,
      status?: TestStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPodtaTestQueryVariables = {
  id: string,
};

export type GetPodtaTestQuery = {
  getPodtaTest?:  {
    __typename: "PodtaTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    interTrialInterval: number,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    response:  {
      __typename: "PodtResponse",
      timeout: number,
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      active:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      passive:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    scenes:  {
      __typename: "PodtaScenes",
      maxAmountScenesPerTrial: number,
      scenes:  Array< {
        __typename: "PodtaScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtaBox",
          top: number,
          left: number,
          width: number,
          height: number,
          position: PodtaBoxPosition,
        } >,
      } >,
      intervalConfig:  {
        __typename: "PodtSceneIntervalConfig",
        min: number,
        max: number,
        stepSize: number,
      },
    },
    targets:  Array< {
      __typename: "PodtTargetCategory",
      label: string,
      practiceStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      testStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    } >,
    fixation?:  {
      __typename: "TimedMediaItem",
      interval: number,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    } | null,
    trialFeedback?:  {
      __typename: "PodtTrialFeedback",
      correctRejectionText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      hitText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      missText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      falseAlarmText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      noResponseText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      pointsFontsize?: number | null,
    } | null,
    blocks:  Array< {
      __typename: "PodtBlock",
      index: number,
      name: string,
      type: BlockType,
      amountTrials: number,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      preparationScreen?:  {
        __typename: "PodtPreparationScreen",
        interval: number,
        screen:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        },
      } | null,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    showCategoriesAfterInstructions?: boolean | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPodtaTestsQueryVariables = {
  filter?: ModelPodtaTestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPodtaTestsQuery = {
  listPodtaTests?:  {
    __typename: "ModelPodtaTestConnection",
    items:  Array< {
      __typename: "PodtaTest",
      id: string,
      name: string,
      description: string,
      language?: string | null,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      keyboard:  {
        __typename: "KeyboardSettings",
        left: string,
        right: string,
        confirm: string,
      },
      counterbalancing: boolean,
      probabilityWithoutReplacement: boolean,
      interTrialInterval: number,
      style:  {
        __typename: "TestStyle",
        maxWidth: number,
        backgroundColour?: string | null,
        stimulusTextStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        mobileStimulusTextStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
        optionsPosition: OptionsPosition,
        optionsPositionMobile?: OptionsPosition | null,
      },
      status: TestStatus,
      endScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      response:  {
        __typename: "PodtResponse",
        timeout: number,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        active:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        passive:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
      },
      scenes:  {
        __typename: "PodtaScenes",
        maxAmountScenesPerTrial: number,
        scenes:  Array< {
          __typename: "PodtaScene",
          mediaItem:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          },
          boxes:  Array< {
            __typename: "PodtaBox",
            top: number,
            left: number,
            width: number,
            height: number,
            position: PodtaBoxPosition,
          } >,
        } >,
        intervalConfig:  {
          __typename: "PodtSceneIntervalConfig",
          min: number,
          max: number,
          stepSize: number,
        },
      },
      targets:  Array< {
        __typename: "PodtTargetCategory",
        label: string,
        practiceStimuliPool:  {
          __typename: "PodtCategoryPool",
          dangerousStimuli:  Array< {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } >,
          nonDangerousStimuli:  Array< {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } >,
        },
        testStimuliPool:  {
          __typename: "PodtCategoryPool",
          dangerousStimuli:  Array< {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } >,
          nonDangerousStimuli:  Array< {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } >,
        },
      } >,
      fixation?:  {
        __typename: "TimedMediaItem",
        interval: number,
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
      } | null,
      trialFeedback?:  {
        __typename: "PodtTrialFeedback",
        correctRejectionText:  {
          __typename: "WeightedStyledText",
          value: string,
          fontStyle:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          },
          weight: number,
        },
        hitText:  {
          __typename: "WeightedStyledText",
          value: string,
          fontStyle:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          },
          weight: number,
        },
        missText:  {
          __typename: "WeightedStyledText",
          value: string,
          fontStyle:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          },
          weight: number,
        },
        falseAlarmText:  {
          __typename: "WeightedStyledText",
          value: string,
          fontStyle:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          },
          weight: number,
        },
        noResponseText:  {
          __typename: "WeightedStyledText",
          value: string,
          fontStyle:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          },
          weight: number,
        },
        pointsFontsize?: number | null,
      } | null,
      blocks:  Array< {
        __typename: "PodtBlock",
        index: number,
        name: string,
        type: BlockType,
        amountTrials: number,
        instructions:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        },
        preparationScreen?:  {
          __typename: "PodtPreparationScreen",
          interval: number,
          screen:  {
            __typename: "StyleableTextTemplate",
            desktop: string,
            mobile?: string | null,
            fontStyle?:  {
              __typename: "FontStyle",
              fontSize: number,
              color: string,
              alignment: TextAlignment,
              fontFace: FontFace,
            } | null,
          },
        } | null,
        tips?:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        } | null,
      } >,
      showCategoriesAfterInstructions?: boolean | null,
      startLabel: string,
      nextLabel: string,
      finishLabel: string,
      owner?: string | null,
      editors?: Array< string | null > | null,
      viewers?: Array< string | null > | null,
      resultViewers?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPodtaResultQueryVariables = {
  id: string,
};

export type GetPodtaResultQuery = {
  getPodtaResult?:  {
    __typename: "PodtaResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    counterBalancingGroup?: string | null,
    earnedPoints?: number | null,
    reactionLeft: string,
    reactionRight: string,
    scenes:  Array< {
      __typename: "PodtaResultScene",
      scene:  {
        __typename: "PodtaScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtaBox",
          top: number,
          left: number,
          width: number,
          height: number,
          position: PodtaBoxPosition,
        } >,
      },
      interval: number,
    } >,
    target:  {
      __typename: "PodtResultTarget",
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      category: string,
      dangerous: boolean,
    },
    accumulatedPoints: number,
    pressedButton?: string | null,
    triggeredControl?: string | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListPodtaResultsQueryVariables = {
  filter?: ModelPodtaResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPodtaResultsQuery = {
  listPodtaResults?:  {
    __typename: "ModelPodtaResultConnection",
    items:  Array< {
      __typename: "PodtaResult",
      id: string,
      testId: string,
      clientId: string,
      survey:  {
        __typename: "ResultSurveyIdentifier",
        probandId: string,
        surveyId?: string | null,
        surveyProbandId?: string | null,
        testProviderName: string,
      },
      timing:  {
        __typename: "ResultTiming",
        renderStartDelay?: number | null,
        renderEndDelay?: number | null,
        startTriggerDelay: number,
        endTriggerDelay?: number | null,
        controlRaceDelay?: number | null,
      },
      device:  {
        __typename: "ProbandDevice",
        ua: string,
        browser?:  {
          __typename: "BrowserInfo",
          name?: string | null,
          version?: string | null,
          major?: string | null,
        } | null,
        device?:  {
          __typename: "DeviceInfo",
          model?: string | null,
          type?: string | null,
          vendor?: string | null,
        } | null,
        engine?:  {
          __typename: "EngineInfo",
          name?: string | null,
          version?: string | null,
        } | null,
        os?:  {
          __typename: "OsInfo",
          name?: string | null,
          version?: string | null,
        } | null,
        cpu?:  {
          __typename: "CpuInfo",
          architecture?: string | null,
        } | null,
      },
      blockIndex: number,
      blockName: string,
      blockType?: BlockType | null,
      trialIndex: number,
      actionResultName: string,
      counterBalancingGroup?: string | null,
      earnedPoints?: number | null,
      reactionLeft: string,
      reactionRight: string,
      scenes:  Array< {
        __typename: "PodtaResultScene",
        scene:  {
          __typename: "PodtaScene",
          mediaItem:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          },
          boxes:  Array< {
            __typename: "PodtaBox",
            top: number,
            left: number,
            width: number,
            height: number,
            position: PodtaBoxPosition,
          } >,
        },
        interval: number,
      } >,
      target:  {
        __typename: "PodtResultTarget",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        category: string,
        dangerous: boolean,
      },
      accumulatedPoints: number,
      pressedButton?: string | null,
      triggeredControl?: string | null,
      status?: TestStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PodtaResultsByTestIdQueryVariables = {
  testId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPodtaResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PodtaResultsByTestIdQuery = {
  podtaResultsByTestId?:  {
    __typename: "ModelPodtaResultConnection",
    items:  Array< {
      __typename: "PodtaResult",
      id: string,
      testId: string,
      clientId: string,
      survey:  {
        __typename: "ResultSurveyIdentifier",
        probandId: string,
        surveyId?: string | null,
        surveyProbandId?: string | null,
        testProviderName: string,
      },
      timing:  {
        __typename: "ResultTiming",
        renderStartDelay?: number | null,
        renderEndDelay?: number | null,
        startTriggerDelay: number,
        endTriggerDelay?: number | null,
        controlRaceDelay?: number | null,
      },
      device:  {
        __typename: "ProbandDevice",
        ua: string,
        browser?:  {
          __typename: "BrowserInfo",
          name?: string | null,
          version?: string | null,
          major?: string | null,
        } | null,
        device?:  {
          __typename: "DeviceInfo",
          model?: string | null,
          type?: string | null,
          vendor?: string | null,
        } | null,
        engine?:  {
          __typename: "EngineInfo",
          name?: string | null,
          version?: string | null,
        } | null,
        os?:  {
          __typename: "OsInfo",
          name?: string | null,
          version?: string | null,
        } | null,
        cpu?:  {
          __typename: "CpuInfo",
          architecture?: string | null,
        } | null,
      },
      blockIndex: number,
      blockName: string,
      blockType?: BlockType | null,
      trialIndex: number,
      actionResultName: string,
      counterBalancingGroup?: string | null,
      earnedPoints?: number | null,
      reactionLeft: string,
      reactionRight: string,
      scenes:  Array< {
        __typename: "PodtaResultScene",
        scene:  {
          __typename: "PodtaScene",
          mediaItem:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          },
          boxes:  Array< {
            __typename: "PodtaBox",
            top: number,
            left: number,
            width: number,
            height: number,
            position: PodtaBoxPosition,
          } >,
        },
        interval: number,
      } >,
      target:  {
        __typename: "PodtResultTarget",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        category: string,
        dangerous: boolean,
      },
      accumulatedPoints: number,
      pressedButton?: string | null,
      triggeredControl?: string | null,
      status?: TestStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetWSWTestQueryVariables = {
  id: string,
};

export type GetWSWTestQuery = {
  getWSWTest?:  {
    __typename: "WSWTest",
    id: string,
    name: string,
    language?: string | null,
    description: string,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    attributeCombinations:  Array< {
      __typename: "AttributeCombination",
      mainCategory: number,
      additionalCategory?: number | null,
      criteriaCategory?: number | null,
      amount: number,
      multiplicator: number,
    } >,
    mainAttribute:  {
      __typename: "WSWAttribute",
      stimuliVariant?: WswStimulusAttributeVariant | null,
      type: WSWAttributeType,
      pickingStrategy: PickingStrategy,
      categories?:  Array< {
        __typename: "WSWAttributeCategory",
        name: string,
        mediaSnaps:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } > | null,
    },
    criteriaAttribute:  {
      __typename: "WSWAttribute",
      stimuliVariant?: WswStimulusAttributeVariant | null,
      type: WSWAttributeType,
      pickingStrategy: PickingStrategy,
      categories?:  Array< {
        __typename: "WSWAttributeCategory",
        name: string,
        mediaSnaps:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } > | null,
    },
    additionalAttribute?:  {
      __typename: "WSWAttribute",
      stimuliVariant?: WswStimulusAttributeVariant | null,
      type: WSWAttributeType,
      pickingStrategy: PickingStrategy,
      categories?:  Array< {
        __typename: "WSWAttributeCategory",
        name: string,
        mediaSnaps:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } > | null,
    } | null,
    groupConstruction?:  {
      __typename: "GroupConstruction",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      fakeQuestions:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
      conditions:  {
        __typename: "GroupConstructionConditions",
        condition1: number,
        condition2: number,
        condition3: number,
      },
      groups:  Array< {
        __typename: "WSWGroup",
        name: string,
        color: string,
        questionSet?:  {
          __typename: "ConditionalQuestionSet",
          randomizeSequence: boolean,
          conditionalQuestion?:  {
            __typename: "ConditionalQuestion",
            questionText: string,
            confirmText: string,
            declineText: string,
            answerType: ConditionalAnswerType,
          } | null,
          questionsAfter:  Array< {
            __typename: "TestQuestion",
            noAnswer?: string | null,
            type: QuestionType,
            questionText: string,
            multiple?:  {
              __typename: "MultipleChoiceQuestion",
              randomized: boolean,
              options: Array< string >,
            } | null,
            scale?:  {
              __typename: "ScaleQuestion",
              invert: boolean,
              min:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
              max:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
            } | null,
            mediaItemSnapshot?:  {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } | null,
            imageMultipleChoice?:  {
              __typename: "ImageMultipleChoiceQuestion",
              randomized: boolean,
              options:  Array< {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } >,
            } | null,
            stimulusTimeout?: number | null,
          } >,
          questionsBefore:  Array< {
            __typename: "TestQuestion",
            noAnswer?: string | null,
            type: QuestionType,
            questionText: string,
            multiple?:  {
              __typename: "MultipleChoiceQuestion",
              randomized: boolean,
              options: Array< string >,
            } | null,
            scale?:  {
              __typename: "ScaleQuestion",
              invert: boolean,
              min:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
              max:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
            } | null,
            mediaItemSnapshot?:  {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } | null,
            imageMultipleChoice?:  {
              __typename: "ImageMultipleChoiceQuestion",
              randomized: boolean,
              options:  Array< {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } >,
            } | null,
            stimulusTimeout?: number | null,
          } >,
        } | null,
        probability: number,
        feedbackScreen?:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        } | null,
      } >,
      randomizeGroupColors: boolean,
    } | null,
    learningPhase:  {
      __typename: "PresentationPhase",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      questionsSet:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
      questionOffsetTimeout?: number | null,
    },
    testPhase:  {
      __typename: "PresentationPhase",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      questionsSet:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
      questionOffsetTimeout?: number | null,
    },
    distractionPhase?:  {
      __typename: "DistractionPhase",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      questionsSet:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
    } | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListWSWTestsQueryVariables = {
  filter?: ModelWSWTestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWSWTestsQuery = {
  listWSWTests?:  {
    __typename: "ModelWSWTestConnection",
    items:  Array< {
      __typename: "WSWTest",
      id: string,
      name: string,
      language?: string | null,
      description: string,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      style:  {
        __typename: "TestStyle",
        maxWidth: number,
        backgroundColour?: string | null,
        stimulusTextStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        mobileStimulusTextStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
        optionsPosition: OptionsPosition,
        optionsPositionMobile?: OptionsPosition | null,
      },
      status: TestStatus,
      endScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      attributeCombinations:  Array< {
        __typename: "AttributeCombination",
        mainCategory: number,
        additionalCategory?: number | null,
        criteriaCategory?: number | null,
        amount: number,
        multiplicator: number,
      } >,
      mainAttribute:  {
        __typename: "WSWAttribute",
        stimuliVariant?: WswStimulusAttributeVariant | null,
        type: WSWAttributeType,
        pickingStrategy: PickingStrategy,
        categories?:  Array< {
          __typename: "WSWAttributeCategory",
          name: string,
          mediaSnaps:  Array< {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } >,
        } > | null,
      },
      criteriaAttribute:  {
        __typename: "WSWAttribute",
        stimuliVariant?: WswStimulusAttributeVariant | null,
        type: WSWAttributeType,
        pickingStrategy: PickingStrategy,
        categories?:  Array< {
          __typename: "WSWAttributeCategory",
          name: string,
          mediaSnaps:  Array< {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } >,
        } > | null,
      },
      additionalAttribute?:  {
        __typename: "WSWAttribute",
        stimuliVariant?: WswStimulusAttributeVariant | null,
        type: WSWAttributeType,
        pickingStrategy: PickingStrategy,
        categories?:  Array< {
          __typename: "WSWAttributeCategory",
          name: string,
          mediaSnaps:  Array< {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } >,
        } > | null,
      } | null,
      groupConstruction?:  {
        __typename: "GroupConstruction",
        instructionsScreen?:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        } | null,
        feedbackScreen?:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        } | null,
        fakeQuestions:  {
          __typename: "ConditionalQuestionSet",
          randomizeSequence: boolean,
          conditionalQuestion?:  {
            __typename: "ConditionalQuestion",
            questionText: string,
            confirmText: string,
            declineText: string,
            answerType: ConditionalAnswerType,
          } | null,
          questionsAfter:  Array< {
            __typename: "TestQuestion",
            noAnswer?: string | null,
            type: QuestionType,
            questionText: string,
            multiple?:  {
              __typename: "MultipleChoiceQuestion",
              randomized: boolean,
              options: Array< string >,
            } | null,
            scale?:  {
              __typename: "ScaleQuestion",
              invert: boolean,
              min:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
              max:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
            } | null,
            mediaItemSnapshot?:  {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } | null,
            imageMultipleChoice?:  {
              __typename: "ImageMultipleChoiceQuestion",
              randomized: boolean,
              options:  Array< {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } >,
            } | null,
            stimulusTimeout?: number | null,
          } >,
          questionsBefore:  Array< {
            __typename: "TestQuestion",
            noAnswer?: string | null,
            type: QuestionType,
            questionText: string,
            multiple?:  {
              __typename: "MultipleChoiceQuestion",
              randomized: boolean,
              options: Array< string >,
            } | null,
            scale?:  {
              __typename: "ScaleQuestion",
              invert: boolean,
              min:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
              max:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
            } | null,
            mediaItemSnapshot?:  {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } | null,
            imageMultipleChoice?:  {
              __typename: "ImageMultipleChoiceQuestion",
              randomized: boolean,
              options:  Array< {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } >,
            } | null,
            stimulusTimeout?: number | null,
          } >,
        },
        conditions:  {
          __typename: "GroupConstructionConditions",
          condition1: number,
          condition2: number,
          condition3: number,
        },
        groups:  Array< {
          __typename: "WSWGroup",
          name: string,
          color: string,
          questionSet?:  {
            __typename: "ConditionalQuestionSet",
            randomizeSequence: boolean,
            conditionalQuestion?:  {
              __typename: "ConditionalQuestion",
              questionText: string,
              confirmText: string,
              declineText: string,
              answerType: ConditionalAnswerType,
            } | null,
            questionsAfter:  Array< {
              __typename: "TestQuestion",
              noAnswer?: string | null,
              type: QuestionType,
              questionText: string,
              multiple?:  {
                __typename: "MultipleChoiceQuestion",
                randomized: boolean,
                options: Array< string >,
              } | null,
              scale?:  {
                __typename: "ScaleQuestion",
                invert: boolean,
                min:  {
                  __typename: "Boundary",
                  name: string,
                  value: number,
                },
                max:  {
                  __typename: "Boundary",
                  name: string,
                  value: number,
                },
              } | null,
              mediaItemSnapshot?:  {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } | null,
              imageMultipleChoice?:  {
                __typename: "ImageMultipleChoiceQuestion",
                randomized: boolean,
                options:  Array< {
                  __typename: "MediaItemSnapshot",
                  id: string,
                  tags:  Array< {
                    __typename: "TagSnapShot",
                    id: string,
                    name: string,
                  } >,
                  scope: MediaItemSnapshotScope,
                  text?: string | null,
                  image?:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  } | null,
                  originalFileName?: string | null,
                } >,
              } | null,
              stimulusTimeout?: number | null,
            } >,
            questionsBefore:  Array< {
              __typename: "TestQuestion",
              noAnswer?: string | null,
              type: QuestionType,
              questionText: string,
              multiple?:  {
                __typename: "MultipleChoiceQuestion",
                randomized: boolean,
                options: Array< string >,
              } | null,
              scale?:  {
                __typename: "ScaleQuestion",
                invert: boolean,
                min:  {
                  __typename: "Boundary",
                  name: string,
                  value: number,
                },
                max:  {
                  __typename: "Boundary",
                  name: string,
                  value: number,
                },
              } | null,
              mediaItemSnapshot?:  {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } | null,
              imageMultipleChoice?:  {
                __typename: "ImageMultipleChoiceQuestion",
                randomized: boolean,
                options:  Array< {
                  __typename: "MediaItemSnapshot",
                  id: string,
                  tags:  Array< {
                    __typename: "TagSnapShot",
                    id: string,
                    name: string,
                  } >,
                  scope: MediaItemSnapshotScope,
                  text?: string | null,
                  image?:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  } | null,
                  originalFileName?: string | null,
                } >,
              } | null,
              stimulusTimeout?: number | null,
            } >,
          } | null,
          probability: number,
          feedbackScreen?:  {
            __typename: "StyleableTextTemplate",
            desktop: string,
            mobile?: string | null,
            fontStyle?:  {
              __typename: "FontStyle",
              fontSize: number,
              color: string,
              alignment: TextAlignment,
              fontFace: FontFace,
            } | null,
          } | null,
        } >,
        randomizeGroupColors: boolean,
      } | null,
      learningPhase:  {
        __typename: "PresentationPhase",
        instructionsScreen?:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        } | null,
        feedbackScreen?:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        } | null,
        questionsSet:  {
          __typename: "ConditionalQuestionSet",
          randomizeSequence: boolean,
          conditionalQuestion?:  {
            __typename: "ConditionalQuestion",
            questionText: string,
            confirmText: string,
            declineText: string,
            answerType: ConditionalAnswerType,
          } | null,
          questionsAfter:  Array< {
            __typename: "TestQuestion",
            noAnswer?: string | null,
            type: QuestionType,
            questionText: string,
            multiple?:  {
              __typename: "MultipleChoiceQuestion",
              randomized: boolean,
              options: Array< string >,
            } | null,
            scale?:  {
              __typename: "ScaleQuestion",
              invert: boolean,
              min:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
              max:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
            } | null,
            mediaItemSnapshot?:  {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } | null,
            imageMultipleChoice?:  {
              __typename: "ImageMultipleChoiceQuestion",
              randomized: boolean,
              options:  Array< {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } >,
            } | null,
            stimulusTimeout?: number | null,
          } >,
          questionsBefore:  Array< {
            __typename: "TestQuestion",
            noAnswer?: string | null,
            type: QuestionType,
            questionText: string,
            multiple?:  {
              __typename: "MultipleChoiceQuestion",
              randomized: boolean,
              options: Array< string >,
            } | null,
            scale?:  {
              __typename: "ScaleQuestion",
              invert: boolean,
              min:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
              max:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
            } | null,
            mediaItemSnapshot?:  {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } | null,
            imageMultipleChoice?:  {
              __typename: "ImageMultipleChoiceQuestion",
              randomized: boolean,
              options:  Array< {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } >,
            } | null,
            stimulusTimeout?: number | null,
          } >,
        },
        questionOffsetTimeout?: number | null,
      },
      testPhase:  {
        __typename: "PresentationPhase",
        instructionsScreen?:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        } | null,
        feedbackScreen?:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        } | null,
        questionsSet:  {
          __typename: "ConditionalQuestionSet",
          randomizeSequence: boolean,
          conditionalQuestion?:  {
            __typename: "ConditionalQuestion",
            questionText: string,
            confirmText: string,
            declineText: string,
            answerType: ConditionalAnswerType,
          } | null,
          questionsAfter:  Array< {
            __typename: "TestQuestion",
            noAnswer?: string | null,
            type: QuestionType,
            questionText: string,
            multiple?:  {
              __typename: "MultipleChoiceQuestion",
              randomized: boolean,
              options: Array< string >,
            } | null,
            scale?:  {
              __typename: "ScaleQuestion",
              invert: boolean,
              min:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
              max:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
            } | null,
            mediaItemSnapshot?:  {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } | null,
            imageMultipleChoice?:  {
              __typename: "ImageMultipleChoiceQuestion",
              randomized: boolean,
              options:  Array< {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } >,
            } | null,
            stimulusTimeout?: number | null,
          } >,
          questionsBefore:  Array< {
            __typename: "TestQuestion",
            noAnswer?: string | null,
            type: QuestionType,
            questionText: string,
            multiple?:  {
              __typename: "MultipleChoiceQuestion",
              randomized: boolean,
              options: Array< string >,
            } | null,
            scale?:  {
              __typename: "ScaleQuestion",
              invert: boolean,
              min:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
              max:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
            } | null,
            mediaItemSnapshot?:  {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } | null,
            imageMultipleChoice?:  {
              __typename: "ImageMultipleChoiceQuestion",
              randomized: boolean,
              options:  Array< {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } >,
            } | null,
            stimulusTimeout?: number | null,
          } >,
        },
        questionOffsetTimeout?: number | null,
      },
      distractionPhase?:  {
        __typename: "DistractionPhase",
        instructionsScreen?:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        } | null,
        feedbackScreen?:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        } | null,
        questionsSet:  {
          __typename: "ConditionalQuestionSet",
          randomizeSequence: boolean,
          conditionalQuestion?:  {
            __typename: "ConditionalQuestion",
            questionText: string,
            confirmText: string,
            declineText: string,
            answerType: ConditionalAnswerType,
          } | null,
          questionsAfter:  Array< {
            __typename: "TestQuestion",
            noAnswer?: string | null,
            type: QuestionType,
            questionText: string,
            multiple?:  {
              __typename: "MultipleChoiceQuestion",
              randomized: boolean,
              options: Array< string >,
            } | null,
            scale?:  {
              __typename: "ScaleQuestion",
              invert: boolean,
              min:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
              max:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
            } | null,
            mediaItemSnapshot?:  {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } | null,
            imageMultipleChoice?:  {
              __typename: "ImageMultipleChoiceQuestion",
              randomized: boolean,
              options:  Array< {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } >,
            } | null,
            stimulusTimeout?: number | null,
          } >,
          questionsBefore:  Array< {
            __typename: "TestQuestion",
            noAnswer?: string | null,
            type: QuestionType,
            questionText: string,
            multiple?:  {
              __typename: "MultipleChoiceQuestion",
              randomized: boolean,
              options: Array< string >,
            } | null,
            scale?:  {
              __typename: "ScaleQuestion",
              invert: boolean,
              min:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
              max:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
            } | null,
            mediaItemSnapshot?:  {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } | null,
            imageMultipleChoice?:  {
              __typename: "ImageMultipleChoiceQuestion",
              randomized: boolean,
              options:  Array< {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } >,
            } | null,
            stimulusTimeout?: number | null,
          } >,
        },
      } | null,
      startLabel: string,
      nextLabel: string,
      finishLabel: string,
      owner?: string | null,
      editors?: Array< string | null > | null,
      viewers?: Array< string | null > | null,
      resultViewers?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetWswResultQueryVariables = {
  id: string,
};

export type GetWswResultQuery = {
  getWswResult?:  {
    __typename: "WswResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    phase: WswTestPhase,
    position: number,
    trialIndex: number,
    groupIndication?:  {
      __typename: "WswGroupIndicationResult",
      groupIndicatorShow: boolean,
      groupIndicationCategory: string,
      groupIndicationIndex: number,
      groupIndicationColor: string,
    } | null,
    questionType: WswQuestionType,
    questionAnswer: string,
    questionImageAnswerOriginalFileName?: string | null,
    noAnswer: boolean,
    answerOptionIndex?: number | null,
    questionPosition: number,
    questionPoolName: string,
    questionSnapshot: string,
    contentType: WswContentType,
    resultStimulus?:  {
      __typename: "WswResultStimulus",
      stimulusType: StimulusType,
      stimulusId?: string | null,
      stimulusTextValue?: string | null,
      stimulusTags?: Array< string > | null,
      stimulusOriginalFilename?: string | null,
    } | null,
    attributeCombination?:  {
      __typename: "WswAttributeCombinationResult",
      isLearningStimulus: boolean,
      mainStimulus:  {
        __typename: "CombinationStimulusItem",
        stimulusType: WswStimulusType,
        stimulusPoolCategory: string,
        stimulusPoolIndex: number,
        stimulusPoolPosition: number,
        stimulusOriginalFilename?: string | null,
        type?: StimulusType | null,
        stimulusId?: string | null,
        stimulusTextValue?: string | null,
        stimulusTags?: Array< string > | null,
      },
      criteriaStimulus?:  {
        __typename: "CombinationStimulusItem",
        stimulusType: WswStimulusType,
        stimulusPoolCategory: string,
        stimulusPoolIndex: number,
        stimulusPoolPosition: number,
        stimulusOriginalFilename?: string | null,
        type?: StimulusType | null,
        stimulusId?: string | null,
        stimulusTextValue?: string | null,
        stimulusTags?: Array< string > | null,
      } | null,
      additionalStimulus?:  {
        __typename: "CombinationStimulusItem",
        stimulusType: WswStimulusType,
        stimulusPoolCategory: string,
        stimulusPoolIndex: number,
        stimulusPoolPosition: number,
        stimulusOriginalFilename?: string | null,
        type?: StimulusType | null,
        stimulusId?: string | null,
        stimulusTextValue?: string | null,
        stimulusTags?: Array< string > | null,
      } | null,
    } | null,
    owner?: string | null,
    groupCondition?: number | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListWswResultsQueryVariables = {
  filter?: ModelWswResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWswResultsQuery = {
  listWswResults?:  {
    __typename: "ModelWswResultConnection",
    items:  Array< {
      __typename: "WswResult",
      id: string,
      testId: string,
      clientId: string,
      survey:  {
        __typename: "ResultSurveyIdentifier",
        probandId: string,
        surveyId?: string | null,
        surveyProbandId?: string | null,
        testProviderName: string,
      },
      timing:  {
        __typename: "ResultTiming",
        renderStartDelay?: number | null,
        renderEndDelay?: number | null,
        startTriggerDelay: number,
        endTriggerDelay?: number | null,
        controlRaceDelay?: number | null,
      },
      device:  {
        __typename: "ProbandDevice",
        ua: string,
        browser?:  {
          __typename: "BrowserInfo",
          name?: string | null,
          version?: string | null,
          major?: string | null,
        } | null,
        device?:  {
          __typename: "DeviceInfo",
          model?: string | null,
          type?: string | null,
          vendor?: string | null,
        } | null,
        engine?:  {
          __typename: "EngineInfo",
          name?: string | null,
          version?: string | null,
        } | null,
        os?:  {
          __typename: "OsInfo",
          name?: string | null,
          version?: string | null,
        } | null,
        cpu?:  {
          __typename: "CpuInfo",
          architecture?: string | null,
        } | null,
      },
      phase: WswTestPhase,
      position: number,
      trialIndex: number,
      groupIndication?:  {
        __typename: "WswGroupIndicationResult",
        groupIndicatorShow: boolean,
        groupIndicationCategory: string,
        groupIndicationIndex: number,
        groupIndicationColor: string,
      } | null,
      questionType: WswQuestionType,
      questionAnswer: string,
      questionImageAnswerOriginalFileName?: string | null,
      noAnswer: boolean,
      answerOptionIndex?: number | null,
      questionPosition: number,
      questionPoolName: string,
      questionSnapshot: string,
      contentType: WswContentType,
      resultStimulus?:  {
        __typename: "WswResultStimulus",
        stimulusType: StimulusType,
        stimulusId?: string | null,
        stimulusTextValue?: string | null,
        stimulusTags?: Array< string > | null,
        stimulusOriginalFilename?: string | null,
      } | null,
      attributeCombination?:  {
        __typename: "WswAttributeCombinationResult",
        isLearningStimulus: boolean,
        mainStimulus:  {
          __typename: "CombinationStimulusItem",
          stimulusType: WswStimulusType,
          stimulusPoolCategory: string,
          stimulusPoolIndex: number,
          stimulusPoolPosition: number,
          stimulusOriginalFilename?: string | null,
          type?: StimulusType | null,
          stimulusId?: string | null,
          stimulusTextValue?: string | null,
          stimulusTags?: Array< string > | null,
        },
        criteriaStimulus?:  {
          __typename: "CombinationStimulusItem",
          stimulusType: WswStimulusType,
          stimulusPoolCategory: string,
          stimulusPoolIndex: number,
          stimulusPoolPosition: number,
          stimulusOriginalFilename?: string | null,
          type?: StimulusType | null,
          stimulusId?: string | null,
          stimulusTextValue?: string | null,
          stimulusTags?: Array< string > | null,
        } | null,
        additionalStimulus?:  {
          __typename: "CombinationStimulusItem",
          stimulusType: WswStimulusType,
          stimulusPoolCategory: string,
          stimulusPoolIndex: number,
          stimulusPoolPosition: number,
          stimulusOriginalFilename?: string | null,
          type?: StimulusType | null,
          stimulusId?: string | null,
          stimulusTextValue?: string | null,
          stimulusTags?: Array< string > | null,
        } | null,
      } | null,
      owner?: string | null,
      groupCondition?: number | null,
      status?: TestStatus | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type WswResultsByTestIdQueryVariables = {
  testId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWswResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type WswResultsByTestIdQuery = {
  wswResultsByTestId?:  {
    __typename: "ModelWswResultConnection",
    items:  Array< {
      __typename: "WswResult",
      id: string,
      testId: string,
      clientId: string,
      survey:  {
        __typename: "ResultSurveyIdentifier",
        probandId: string,
        surveyId?: string | null,
        surveyProbandId?: string | null,
        testProviderName: string,
      },
      timing:  {
        __typename: "ResultTiming",
        renderStartDelay?: number | null,
        renderEndDelay?: number | null,
        startTriggerDelay: number,
        endTriggerDelay?: number | null,
        controlRaceDelay?: number | null,
      },
      device:  {
        __typename: "ProbandDevice",
        ua: string,
        browser?:  {
          __typename: "BrowserInfo",
          name?: string | null,
          version?: string | null,
          major?: string | null,
        } | null,
        device?:  {
          __typename: "DeviceInfo",
          model?: string | null,
          type?: string | null,
          vendor?: string | null,
        } | null,
        engine?:  {
          __typename: "EngineInfo",
          name?: string | null,
          version?: string | null,
        } | null,
        os?:  {
          __typename: "OsInfo",
          name?: string | null,
          version?: string | null,
        } | null,
        cpu?:  {
          __typename: "CpuInfo",
          architecture?: string | null,
        } | null,
      },
      phase: WswTestPhase,
      position: number,
      trialIndex: number,
      groupIndication?:  {
        __typename: "WswGroupIndicationResult",
        groupIndicatorShow: boolean,
        groupIndicationCategory: string,
        groupIndicationIndex: number,
        groupIndicationColor: string,
      } | null,
      questionType: WswQuestionType,
      questionAnswer: string,
      questionImageAnswerOriginalFileName?: string | null,
      noAnswer: boolean,
      answerOptionIndex?: number | null,
      questionPosition: number,
      questionPoolName: string,
      questionSnapshot: string,
      contentType: WswContentType,
      resultStimulus?:  {
        __typename: "WswResultStimulus",
        stimulusType: StimulusType,
        stimulusId?: string | null,
        stimulusTextValue?: string | null,
        stimulusTags?: Array< string > | null,
        stimulusOriginalFilename?: string | null,
      } | null,
      attributeCombination?:  {
        __typename: "WswAttributeCombinationResult",
        isLearningStimulus: boolean,
        mainStimulus:  {
          __typename: "CombinationStimulusItem",
          stimulusType: WswStimulusType,
          stimulusPoolCategory: string,
          stimulusPoolIndex: number,
          stimulusPoolPosition: number,
          stimulusOriginalFilename?: string | null,
          type?: StimulusType | null,
          stimulusId?: string | null,
          stimulusTextValue?: string | null,
          stimulusTags?: Array< string > | null,
        },
        criteriaStimulus?:  {
          __typename: "CombinationStimulusItem",
          stimulusType: WswStimulusType,
          stimulusPoolCategory: string,
          stimulusPoolIndex: number,
          stimulusPoolPosition: number,
          stimulusOriginalFilename?: string | null,
          type?: StimulusType | null,
          stimulusId?: string | null,
          stimulusTextValue?: string | null,
          stimulusTags?: Array< string > | null,
        } | null,
        additionalStimulus?:  {
          __typename: "CombinationStimulusItem",
          stimulusType: WswStimulusType,
          stimulusPoolCategory: string,
          stimulusPoolIndex: number,
          stimulusPoolPosition: number,
          stimulusOriginalFilename?: string | null,
          type?: StimulusType | null,
          stimulusId?: string | null,
          stimulusTextValue?: string | null,
          stimulusTags?: Array< string > | null,
        } | null,
      } | null,
      owner?: string | null,
      groupCondition?: number | null,
      status?: TestStatus | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSurveyProviderQueryVariables = {
  id: string,
};

export type GetSurveyProviderQuery = {
  getSurveyProvider?:  {
    __typename: "SurveyProvider",
    id: string,
    slug: string,
    name: string,
    description: string,
    snippetConfiguration:  {
      __typename: "SurveyProviderSnippetConfiguration",
      snippetType: SurveyProviderSnippetType,
      surveyIdReplacer?: string | null,
      probandIdReplacer: string,
    },
    setupDocumentation:  {
      __typename: "SurveyProviderSetupDocumentation",
      steps:  Array< {
        __typename: "SurveyProviderSetupDocumentationStep",
        type: SetupDocumentationStepType,
        textBeforeContent?: string | null,
        textAfterContent?: string | null,
        image?:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        } | null,
      } >,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListSurveyProvidersQueryVariables = {
  filter?: ModelSurveyProviderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSurveyProvidersQuery = {
  listSurveyProviders?:  {
    __typename: "ModelSurveyProviderConnection",
    items:  Array< {
      __typename: "SurveyProvider",
      id: string,
      slug: string,
      name: string,
      description: string,
      snippetConfiguration:  {
        __typename: "SurveyProviderSnippetConfiguration",
        snippetType: SurveyProviderSnippetType,
        surveyIdReplacer?: string | null,
        probandIdReplacer: string,
      },
      setupDocumentation:  {
        __typename: "SurveyProviderSetupDocumentation",
        steps:  Array< {
          __typename: "SurveyProviderSetupDocumentationStep",
          type: SetupDocumentationStepType,
          textBeforeContent?: string | null,
          textAfterContent?: string | null,
          image?:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          } | null,
        } >,
      },
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserRegistrationQueryVariables = {
  id: string,
};

export type GetUserRegistrationQuery = {
  getUserRegistration?:  {
    __typename: "UserRegistration",
    id: string,
    name: string,
    email: string,
    institution: string,
    researchGoal: string,
    additionalInformation?: string | null,
    confirmedUsagePolicy: boolean,
    status: UserRegistrationStatus,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListUserRegistrationsQueryVariables = {
  filter?: ModelUserRegistrationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserRegistrationsQuery = {
  listUserRegistrations?:  {
    __typename: "ModelUserRegistrationConnection",
    items:  Array< {
      __typename: "UserRegistration",
      id: string,
      name: string,
      email: string,
      institution: string,
      researchGoal: string,
      additionalInformation?: string | null,
      confirmedUsagePolicy: boolean,
      status: UserRegistrationStatus,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBroadcastMailQueryVariables = {
  id: string,
};

export type GetBroadcastMailQuery = {
  getBroadcastMail?:  {
    __typename: "BroadcastMail",
    id: string,
    subject: string,
    sender?: string | null,
    content: string,
    status: BroadcastMailStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBroadcastMailsQueryVariables = {
  filter?: ModelBroadcastMailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBroadcastMailsQuery = {
  listBroadcastMails?:  {
    __typename: "ModelBroadcastMailConnection",
    items:  Array< {
      __typename: "BroadcastMail",
      id: string,
      subject: string,
      sender?: string | null,
      content: string,
      status: BroadcastMailStatus,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMediaItemTagsQueryVariables = {
  id: string,
};

export type GetMediaItemTagsQuery = {
  getMediaItemTags?:  {
    __typename: "MediaItemTags",
    id: string,
    tagId: string,
    mediaItemId: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string,
      mediaItems?:  {
        __typename: "ModelMediaItemTagsConnection",
        items:  Array< {
          __typename: "MediaItemTags",
          id: string,
          tagId: string,
          mediaItemId: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            mediaItems?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          mediaItem:  {
            __typename: "MediaItem",
            id: string,
            image:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            },
            source?: string | null,
            originalFileName: string,
            tags?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            tagsString?: string | null,
            tagsIds: Array< string >,
            createdAt: string,
            updatedAt: string,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    mediaItem:  {
      __typename: "MediaItem",
      id: string,
      image:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      },
      source?: string | null,
      originalFileName: string,
      tags?:  {
        __typename: "ModelMediaItemTagsConnection",
        items:  Array< {
          __typename: "MediaItemTags",
          id: string,
          tagId: string,
          mediaItemId: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            mediaItems?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          mediaItem:  {
            __typename: "MediaItem",
            id: string,
            image:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            },
            source?: string | null,
            originalFileName: string,
            tags?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            tagsString?: string | null,
            tagsIds: Array< string >,
            createdAt: string,
            updatedAt: string,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tagsString?: string | null,
      tagsIds: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMediaItemTagsQueryVariables = {
  filter?: ModelMediaItemTagsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMediaItemTagsQuery = {
  listMediaItemTags?:  {
    __typename: "ModelMediaItemTagsConnection",
    items:  Array< {
      __typename: "MediaItemTags",
      id: string,
      tagId: string,
      mediaItemId: string,
      tag:  {
        __typename: "Tag",
        id: string,
        name: string,
        mediaItems?:  {
          __typename: "ModelMediaItemTagsConnection",
          items:  Array< {
            __typename: "MediaItemTags",
            id: string,
            tagId: string,
            mediaItemId: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string,
              mediaItems?:  {
                __typename: "ModelMediaItemTagsConnection",
                items:  Array< {
                  __typename: "MediaItemTags",
                  id: string,
                  tagId: string,
                  mediaItemId: string,
                  tag:  {
                    __typename: "Tag",
                    id: string,
                    name: string,
                    mediaItems?:  {
                      __typename: "ModelMediaItemTagsConnection",
                      items:  Array< {
                        __typename: "MediaItemTags",
                        id: string,
                        tagId: string,
                        mediaItemId: string,
                        tag:  {
                          __typename: "Tag",
                          id: string,
                          name: string,
                          mediaItems?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          createdAt: string,
                          updatedAt: string,
                        },
                        mediaItem:  {
                          __typename: "MediaItem",
                          id: string,
                          image:  {
                            __typename: "Image",
                            fullSize:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                            thumbNail:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                          },
                          source?: string | null,
                          originalFileName: string,
                          tags?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          tagsString?: string | null,
                          tagsIds: Array< string >,
                          createdAt: string,
                          updatedAt: string,
                        },
                        createdAt: string,
                        updatedAt: string,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                    createdAt: string,
                    updatedAt: string,
                  },
                  mediaItem:  {
                    __typename: "MediaItem",
                    id: string,
                    image:  {
                      __typename: "Image",
                      fullSize:  {
                        __typename: "ImageInstance",
                        width: number,
                        height: number,
                        key: string,
                      },
                      thumbNail:  {
                        __typename: "ImageInstance",
                        width: number,
                        height: number,
                        key: string,
                      },
                    },
                    source?: string | null,
                    originalFileName: string,
                    tags?:  {
                      __typename: "ModelMediaItemTagsConnection",
                      items:  Array< {
                        __typename: "MediaItemTags",
                        id: string,
                        tagId: string,
                        mediaItemId: string,
                        tag:  {
                          __typename: "Tag",
                          id: string,
                          name: string,
                          mediaItems?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          createdAt: string,
                          updatedAt: string,
                        },
                        mediaItem:  {
                          __typename: "MediaItem",
                          id: string,
                          image:  {
                            __typename: "Image",
                            fullSize:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                            thumbNail:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                          },
                          source?: string | null,
                          originalFileName: string,
                          tags?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          tagsString?: string | null,
                          tagsIds: Array< string >,
                          createdAt: string,
                          updatedAt: string,
                        },
                        createdAt: string,
                        updatedAt: string,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                    tagsString?: string | null,
                    tagsIds: Array< string >,
                    createdAt: string,
                    updatedAt: string,
                  },
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            },
            mediaItem:  {
              __typename: "MediaItem",
              id: string,
              image:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              },
              source?: string | null,
              originalFileName: string,
              tags?:  {
                __typename: "ModelMediaItemTagsConnection",
                items:  Array< {
                  __typename: "MediaItemTags",
                  id: string,
                  tagId: string,
                  mediaItemId: string,
                  tag:  {
                    __typename: "Tag",
                    id: string,
                    name: string,
                    mediaItems?:  {
                      __typename: "ModelMediaItemTagsConnection",
                      items:  Array< {
                        __typename: "MediaItemTags",
                        id: string,
                        tagId: string,
                        mediaItemId: string,
                        tag:  {
                          __typename: "Tag",
                          id: string,
                          name: string,
                          mediaItems?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          createdAt: string,
                          updatedAt: string,
                        },
                        mediaItem:  {
                          __typename: "MediaItem",
                          id: string,
                          image:  {
                            __typename: "Image",
                            fullSize:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                            thumbNail:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                          },
                          source?: string | null,
                          originalFileName: string,
                          tags?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          tagsString?: string | null,
                          tagsIds: Array< string >,
                          createdAt: string,
                          updatedAt: string,
                        },
                        createdAt: string,
                        updatedAt: string,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                    createdAt: string,
                    updatedAt: string,
                  },
                  mediaItem:  {
                    __typename: "MediaItem",
                    id: string,
                    image:  {
                      __typename: "Image",
                      fullSize:  {
                        __typename: "ImageInstance",
                        width: number,
                        height: number,
                        key: string,
                      },
                      thumbNail:  {
                        __typename: "ImageInstance",
                        width: number,
                        height: number,
                        key: string,
                      },
                    },
                    source?: string | null,
                    originalFileName: string,
                    tags?:  {
                      __typename: "ModelMediaItemTagsConnection",
                      items:  Array< {
                        __typename: "MediaItemTags",
                        id: string,
                        tagId: string,
                        mediaItemId: string,
                        tag:  {
                          __typename: "Tag",
                          id: string,
                          name: string,
                          mediaItems?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          createdAt: string,
                          updatedAt: string,
                        },
                        mediaItem:  {
                          __typename: "MediaItem",
                          id: string,
                          image:  {
                            __typename: "Image",
                            fullSize:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                            thumbNail:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                          },
                          source?: string | null,
                          originalFileName: string,
                          tags?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          tagsString?: string | null,
                          tagsIds: Array< string >,
                          createdAt: string,
                          updatedAt: string,
                        },
                        createdAt: string,
                        updatedAt: string,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                    tagsString?: string | null,
                    tagsIds: Array< string >,
                    createdAt: string,
                    updatedAt: string,
                  },
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              tagsString?: string | null,
              tagsIds: Array< string >,
              createdAt: string,
              updatedAt: string,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      },
      mediaItem:  {
        __typename: "MediaItem",
        id: string,
        image:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        },
        source?: string | null,
        originalFileName: string,
        tags?:  {
          __typename: "ModelMediaItemTagsConnection",
          items:  Array< {
            __typename: "MediaItemTags",
            id: string,
            tagId: string,
            mediaItemId: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string,
              mediaItems?:  {
                __typename: "ModelMediaItemTagsConnection",
                items:  Array< {
                  __typename: "MediaItemTags",
                  id: string,
                  tagId: string,
                  mediaItemId: string,
                  tag:  {
                    __typename: "Tag",
                    id: string,
                    name: string,
                    mediaItems?:  {
                      __typename: "ModelMediaItemTagsConnection",
                      items:  Array< {
                        __typename: "MediaItemTags",
                        id: string,
                        tagId: string,
                        mediaItemId: string,
                        tag:  {
                          __typename: "Tag",
                          id: string,
                          name: string,
                          mediaItems?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          createdAt: string,
                          updatedAt: string,
                        },
                        mediaItem:  {
                          __typename: "MediaItem",
                          id: string,
                          image:  {
                            __typename: "Image",
                            fullSize:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                            thumbNail:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                          },
                          source?: string | null,
                          originalFileName: string,
                          tags?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          tagsString?: string | null,
                          tagsIds: Array< string >,
                          createdAt: string,
                          updatedAt: string,
                        },
                        createdAt: string,
                        updatedAt: string,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                    createdAt: string,
                    updatedAt: string,
                  },
                  mediaItem:  {
                    __typename: "MediaItem",
                    id: string,
                    image:  {
                      __typename: "Image",
                      fullSize:  {
                        __typename: "ImageInstance",
                        width: number,
                        height: number,
                        key: string,
                      },
                      thumbNail:  {
                        __typename: "ImageInstance",
                        width: number,
                        height: number,
                        key: string,
                      },
                    },
                    source?: string | null,
                    originalFileName: string,
                    tags?:  {
                      __typename: "ModelMediaItemTagsConnection",
                      items:  Array< {
                        __typename: "MediaItemTags",
                        id: string,
                        tagId: string,
                        mediaItemId: string,
                        tag:  {
                          __typename: "Tag",
                          id: string,
                          name: string,
                          mediaItems?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          createdAt: string,
                          updatedAt: string,
                        },
                        mediaItem:  {
                          __typename: "MediaItem",
                          id: string,
                          image:  {
                            __typename: "Image",
                            fullSize:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                            thumbNail:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                          },
                          source?: string | null,
                          originalFileName: string,
                          tags?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          tagsString?: string | null,
                          tagsIds: Array< string >,
                          createdAt: string,
                          updatedAt: string,
                        },
                        createdAt: string,
                        updatedAt: string,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                    tagsString?: string | null,
                    tagsIds: Array< string >,
                    createdAt: string,
                    updatedAt: string,
                  },
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            },
            mediaItem:  {
              __typename: "MediaItem",
              id: string,
              image:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              },
              source?: string | null,
              originalFileName: string,
              tags?:  {
                __typename: "ModelMediaItemTagsConnection",
                items:  Array< {
                  __typename: "MediaItemTags",
                  id: string,
                  tagId: string,
                  mediaItemId: string,
                  tag:  {
                    __typename: "Tag",
                    id: string,
                    name: string,
                    mediaItems?:  {
                      __typename: "ModelMediaItemTagsConnection",
                      items:  Array< {
                        __typename: "MediaItemTags",
                        id: string,
                        tagId: string,
                        mediaItemId: string,
                        tag:  {
                          __typename: "Tag",
                          id: string,
                          name: string,
                          mediaItems?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          createdAt: string,
                          updatedAt: string,
                        },
                        mediaItem:  {
                          __typename: "MediaItem",
                          id: string,
                          image:  {
                            __typename: "Image",
                            fullSize:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                            thumbNail:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                          },
                          source?: string | null,
                          originalFileName: string,
                          tags?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          tagsString?: string | null,
                          tagsIds: Array< string >,
                          createdAt: string,
                          updatedAt: string,
                        },
                        createdAt: string,
                        updatedAt: string,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                    createdAt: string,
                    updatedAt: string,
                  },
                  mediaItem:  {
                    __typename: "MediaItem",
                    id: string,
                    image:  {
                      __typename: "Image",
                      fullSize:  {
                        __typename: "ImageInstance",
                        width: number,
                        height: number,
                        key: string,
                      },
                      thumbNail:  {
                        __typename: "ImageInstance",
                        width: number,
                        height: number,
                        key: string,
                      },
                    },
                    source?: string | null,
                    originalFileName: string,
                    tags?:  {
                      __typename: "ModelMediaItemTagsConnection",
                      items:  Array< {
                        __typename: "MediaItemTags",
                        id: string,
                        tagId: string,
                        mediaItemId: string,
                        tag:  {
                          __typename: "Tag",
                          id: string,
                          name: string,
                          mediaItems?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          createdAt: string,
                          updatedAt: string,
                        },
                        mediaItem:  {
                          __typename: "MediaItem",
                          id: string,
                          image:  {
                            __typename: "Image",
                            fullSize:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                            thumbNail:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                          },
                          source?: string | null,
                          originalFileName: string,
                          tags?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          tagsString?: string | null,
                          tagsIds: Array< string >,
                          createdAt: string,
                          updatedAt: string,
                        },
                        createdAt: string,
                        updatedAt: string,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                    tagsString?: string | null,
                    tagsIds: Array< string >,
                    createdAt: string,
                    updatedAt: string,
                  },
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              tagsString?: string | null,
              tagsIds: Array< string >,
              createdAt: string,
              updatedAt: string,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tagsString?: string | null,
        tagsIds: Array< string >,
        createdAt: string,
        updatedAt: string,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MediaItemTagsByTagIdQueryVariables = {
  tagId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMediaItemTagsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MediaItemTagsByTagIdQuery = {
  mediaItemTagsByTagId?:  {
    __typename: "ModelMediaItemTagsConnection",
    items:  Array< {
      __typename: "MediaItemTags",
      id: string,
      tagId: string,
      mediaItemId: string,
      tag:  {
        __typename: "Tag",
        id: string,
        name: string,
        mediaItems?:  {
          __typename: "ModelMediaItemTagsConnection",
          items:  Array< {
            __typename: "MediaItemTags",
            id: string,
            tagId: string,
            mediaItemId: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string,
              mediaItems?:  {
                __typename: "ModelMediaItemTagsConnection",
                items:  Array< {
                  __typename: "MediaItemTags",
                  id: string,
                  tagId: string,
                  mediaItemId: string,
                  tag:  {
                    __typename: "Tag",
                    id: string,
                    name: string,
                    mediaItems?:  {
                      __typename: "ModelMediaItemTagsConnection",
                      items:  Array< {
                        __typename: "MediaItemTags",
                        id: string,
                        tagId: string,
                        mediaItemId: string,
                        tag:  {
                          __typename: "Tag",
                          id: string,
                          name: string,
                          mediaItems?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          createdAt: string,
                          updatedAt: string,
                        },
                        mediaItem:  {
                          __typename: "MediaItem",
                          id: string,
                          image:  {
                            __typename: "Image",
                            fullSize:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                            thumbNail:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                          },
                          source?: string | null,
                          originalFileName: string,
                          tags?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          tagsString?: string | null,
                          tagsIds: Array< string >,
                          createdAt: string,
                          updatedAt: string,
                        },
                        createdAt: string,
                        updatedAt: string,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                    createdAt: string,
                    updatedAt: string,
                  },
                  mediaItem:  {
                    __typename: "MediaItem",
                    id: string,
                    image:  {
                      __typename: "Image",
                      fullSize:  {
                        __typename: "ImageInstance",
                        width: number,
                        height: number,
                        key: string,
                      },
                      thumbNail:  {
                        __typename: "ImageInstance",
                        width: number,
                        height: number,
                        key: string,
                      },
                    },
                    source?: string | null,
                    originalFileName: string,
                    tags?:  {
                      __typename: "ModelMediaItemTagsConnection",
                      items:  Array< {
                        __typename: "MediaItemTags",
                        id: string,
                        tagId: string,
                        mediaItemId: string,
                        tag:  {
                          __typename: "Tag",
                          id: string,
                          name: string,
                          mediaItems?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          createdAt: string,
                          updatedAt: string,
                        },
                        mediaItem:  {
                          __typename: "MediaItem",
                          id: string,
                          image:  {
                            __typename: "Image",
                            fullSize:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                            thumbNail:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                          },
                          source?: string | null,
                          originalFileName: string,
                          tags?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          tagsString?: string | null,
                          tagsIds: Array< string >,
                          createdAt: string,
                          updatedAt: string,
                        },
                        createdAt: string,
                        updatedAt: string,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                    tagsString?: string | null,
                    tagsIds: Array< string >,
                    createdAt: string,
                    updatedAt: string,
                  },
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            },
            mediaItem:  {
              __typename: "MediaItem",
              id: string,
              image:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              },
              source?: string | null,
              originalFileName: string,
              tags?:  {
                __typename: "ModelMediaItemTagsConnection",
                items:  Array< {
                  __typename: "MediaItemTags",
                  id: string,
                  tagId: string,
                  mediaItemId: string,
                  tag:  {
                    __typename: "Tag",
                    id: string,
                    name: string,
                    mediaItems?:  {
                      __typename: "ModelMediaItemTagsConnection",
                      items:  Array< {
                        __typename: "MediaItemTags",
                        id: string,
                        tagId: string,
                        mediaItemId: string,
                        tag:  {
                          __typename: "Tag",
                          id: string,
                          name: string,
                          mediaItems?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          createdAt: string,
                          updatedAt: string,
                        },
                        mediaItem:  {
                          __typename: "MediaItem",
                          id: string,
                          image:  {
                            __typename: "Image",
                            fullSize:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                            thumbNail:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                          },
                          source?: string | null,
                          originalFileName: string,
                          tags?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          tagsString?: string | null,
                          tagsIds: Array< string >,
                          createdAt: string,
                          updatedAt: string,
                        },
                        createdAt: string,
                        updatedAt: string,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                    createdAt: string,
                    updatedAt: string,
                  },
                  mediaItem:  {
                    __typename: "MediaItem",
                    id: string,
                    image:  {
                      __typename: "Image",
                      fullSize:  {
                        __typename: "ImageInstance",
                        width: number,
                        height: number,
                        key: string,
                      },
                      thumbNail:  {
                        __typename: "ImageInstance",
                        width: number,
                        height: number,
                        key: string,
                      },
                    },
                    source?: string | null,
                    originalFileName: string,
                    tags?:  {
                      __typename: "ModelMediaItemTagsConnection",
                      items:  Array< {
                        __typename: "MediaItemTags",
                        id: string,
                        tagId: string,
                        mediaItemId: string,
                        tag:  {
                          __typename: "Tag",
                          id: string,
                          name: string,
                          mediaItems?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          createdAt: string,
                          updatedAt: string,
                        },
                        mediaItem:  {
                          __typename: "MediaItem",
                          id: string,
                          image:  {
                            __typename: "Image",
                            fullSize:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                            thumbNail:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                          },
                          source?: string | null,
                          originalFileName: string,
                          tags?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          tagsString?: string | null,
                          tagsIds: Array< string >,
                          createdAt: string,
                          updatedAt: string,
                        },
                        createdAt: string,
                        updatedAt: string,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                    tagsString?: string | null,
                    tagsIds: Array< string >,
                    createdAt: string,
                    updatedAt: string,
                  },
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              tagsString?: string | null,
              tagsIds: Array< string >,
              createdAt: string,
              updatedAt: string,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      },
      mediaItem:  {
        __typename: "MediaItem",
        id: string,
        image:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        },
        source?: string | null,
        originalFileName: string,
        tags?:  {
          __typename: "ModelMediaItemTagsConnection",
          items:  Array< {
            __typename: "MediaItemTags",
            id: string,
            tagId: string,
            mediaItemId: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string,
              mediaItems?:  {
                __typename: "ModelMediaItemTagsConnection",
                items:  Array< {
                  __typename: "MediaItemTags",
                  id: string,
                  tagId: string,
                  mediaItemId: string,
                  tag:  {
                    __typename: "Tag",
                    id: string,
                    name: string,
                    mediaItems?:  {
                      __typename: "ModelMediaItemTagsConnection",
                      items:  Array< {
                        __typename: "MediaItemTags",
                        id: string,
                        tagId: string,
                        mediaItemId: string,
                        tag:  {
                          __typename: "Tag",
                          id: string,
                          name: string,
                          mediaItems?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          createdAt: string,
                          updatedAt: string,
                        },
                        mediaItem:  {
                          __typename: "MediaItem",
                          id: string,
                          image:  {
                            __typename: "Image",
                            fullSize:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                            thumbNail:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                          },
                          source?: string | null,
                          originalFileName: string,
                          tags?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          tagsString?: string | null,
                          tagsIds: Array< string >,
                          createdAt: string,
                          updatedAt: string,
                        },
                        createdAt: string,
                        updatedAt: string,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                    createdAt: string,
                    updatedAt: string,
                  },
                  mediaItem:  {
                    __typename: "MediaItem",
                    id: string,
                    image:  {
                      __typename: "Image",
                      fullSize:  {
                        __typename: "ImageInstance",
                        width: number,
                        height: number,
                        key: string,
                      },
                      thumbNail:  {
                        __typename: "ImageInstance",
                        width: number,
                        height: number,
                        key: string,
                      },
                    },
                    source?: string | null,
                    originalFileName: string,
                    tags?:  {
                      __typename: "ModelMediaItemTagsConnection",
                      items:  Array< {
                        __typename: "MediaItemTags",
                        id: string,
                        tagId: string,
                        mediaItemId: string,
                        tag:  {
                          __typename: "Tag",
                          id: string,
                          name: string,
                          mediaItems?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          createdAt: string,
                          updatedAt: string,
                        },
                        mediaItem:  {
                          __typename: "MediaItem",
                          id: string,
                          image:  {
                            __typename: "Image",
                            fullSize:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                            thumbNail:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                          },
                          source?: string | null,
                          originalFileName: string,
                          tags?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          tagsString?: string | null,
                          tagsIds: Array< string >,
                          createdAt: string,
                          updatedAt: string,
                        },
                        createdAt: string,
                        updatedAt: string,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                    tagsString?: string | null,
                    tagsIds: Array< string >,
                    createdAt: string,
                    updatedAt: string,
                  },
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            },
            mediaItem:  {
              __typename: "MediaItem",
              id: string,
              image:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              },
              source?: string | null,
              originalFileName: string,
              tags?:  {
                __typename: "ModelMediaItemTagsConnection",
                items:  Array< {
                  __typename: "MediaItemTags",
                  id: string,
                  tagId: string,
                  mediaItemId: string,
                  tag:  {
                    __typename: "Tag",
                    id: string,
                    name: string,
                    mediaItems?:  {
                      __typename: "ModelMediaItemTagsConnection",
                      items:  Array< {
                        __typename: "MediaItemTags",
                        id: string,
                        tagId: string,
                        mediaItemId: string,
                        tag:  {
                          __typename: "Tag",
                          id: string,
                          name: string,
                          mediaItems?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          createdAt: string,
                          updatedAt: string,
                        },
                        mediaItem:  {
                          __typename: "MediaItem",
                          id: string,
                          image:  {
                            __typename: "Image",
                            fullSize:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                            thumbNail:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                          },
                          source?: string | null,
                          originalFileName: string,
                          tags?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          tagsString?: string | null,
                          tagsIds: Array< string >,
                          createdAt: string,
                          updatedAt: string,
                        },
                        createdAt: string,
                        updatedAt: string,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                    createdAt: string,
                    updatedAt: string,
                  },
                  mediaItem:  {
                    __typename: "MediaItem",
                    id: string,
                    image:  {
                      __typename: "Image",
                      fullSize:  {
                        __typename: "ImageInstance",
                        width: number,
                        height: number,
                        key: string,
                      },
                      thumbNail:  {
                        __typename: "ImageInstance",
                        width: number,
                        height: number,
                        key: string,
                      },
                    },
                    source?: string | null,
                    originalFileName: string,
                    tags?:  {
                      __typename: "ModelMediaItemTagsConnection",
                      items:  Array< {
                        __typename: "MediaItemTags",
                        id: string,
                        tagId: string,
                        mediaItemId: string,
                        tag:  {
                          __typename: "Tag",
                          id: string,
                          name: string,
                          mediaItems?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          createdAt: string,
                          updatedAt: string,
                        },
                        mediaItem:  {
                          __typename: "MediaItem",
                          id: string,
                          image:  {
                            __typename: "Image",
                            fullSize:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                            thumbNail:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                          },
                          source?: string | null,
                          originalFileName: string,
                          tags?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          tagsString?: string | null,
                          tagsIds: Array< string >,
                          createdAt: string,
                          updatedAt: string,
                        },
                        createdAt: string,
                        updatedAt: string,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                    tagsString?: string | null,
                    tagsIds: Array< string >,
                    createdAt: string,
                    updatedAt: string,
                  },
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              tagsString?: string | null,
              tagsIds: Array< string >,
              createdAt: string,
              updatedAt: string,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tagsString?: string | null,
        tagsIds: Array< string >,
        createdAt: string,
        updatedAt: string,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MediaItemTagsByMediaItemIdQueryVariables = {
  mediaItemId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMediaItemTagsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MediaItemTagsByMediaItemIdQuery = {
  mediaItemTagsByMediaItemId?:  {
    __typename: "ModelMediaItemTagsConnection",
    items:  Array< {
      __typename: "MediaItemTags",
      id: string,
      tagId: string,
      mediaItemId: string,
      tag:  {
        __typename: "Tag",
        id: string,
        name: string,
        mediaItems?:  {
          __typename: "ModelMediaItemTagsConnection",
          items:  Array< {
            __typename: "MediaItemTags",
            id: string,
            tagId: string,
            mediaItemId: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string,
              mediaItems?:  {
                __typename: "ModelMediaItemTagsConnection",
                items:  Array< {
                  __typename: "MediaItemTags",
                  id: string,
                  tagId: string,
                  mediaItemId: string,
                  tag:  {
                    __typename: "Tag",
                    id: string,
                    name: string,
                    mediaItems?:  {
                      __typename: "ModelMediaItemTagsConnection",
                      items:  Array< {
                        __typename: "MediaItemTags",
                        id: string,
                        tagId: string,
                        mediaItemId: string,
                        tag:  {
                          __typename: "Tag",
                          id: string,
                          name: string,
                          mediaItems?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          createdAt: string,
                          updatedAt: string,
                        },
                        mediaItem:  {
                          __typename: "MediaItem",
                          id: string,
                          image:  {
                            __typename: "Image",
                            fullSize:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                            thumbNail:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                          },
                          source?: string | null,
                          originalFileName: string,
                          tags?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          tagsString?: string | null,
                          tagsIds: Array< string >,
                          createdAt: string,
                          updatedAt: string,
                        },
                        createdAt: string,
                        updatedAt: string,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                    createdAt: string,
                    updatedAt: string,
                  },
                  mediaItem:  {
                    __typename: "MediaItem",
                    id: string,
                    image:  {
                      __typename: "Image",
                      fullSize:  {
                        __typename: "ImageInstance",
                        width: number,
                        height: number,
                        key: string,
                      },
                      thumbNail:  {
                        __typename: "ImageInstance",
                        width: number,
                        height: number,
                        key: string,
                      },
                    },
                    source?: string | null,
                    originalFileName: string,
                    tags?:  {
                      __typename: "ModelMediaItemTagsConnection",
                      items:  Array< {
                        __typename: "MediaItemTags",
                        id: string,
                        tagId: string,
                        mediaItemId: string,
                        tag:  {
                          __typename: "Tag",
                          id: string,
                          name: string,
                          mediaItems?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          createdAt: string,
                          updatedAt: string,
                        },
                        mediaItem:  {
                          __typename: "MediaItem",
                          id: string,
                          image:  {
                            __typename: "Image",
                            fullSize:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                            thumbNail:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                          },
                          source?: string | null,
                          originalFileName: string,
                          tags?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          tagsString?: string | null,
                          tagsIds: Array< string >,
                          createdAt: string,
                          updatedAt: string,
                        },
                        createdAt: string,
                        updatedAt: string,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                    tagsString?: string | null,
                    tagsIds: Array< string >,
                    createdAt: string,
                    updatedAt: string,
                  },
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            },
            mediaItem:  {
              __typename: "MediaItem",
              id: string,
              image:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              },
              source?: string | null,
              originalFileName: string,
              tags?:  {
                __typename: "ModelMediaItemTagsConnection",
                items:  Array< {
                  __typename: "MediaItemTags",
                  id: string,
                  tagId: string,
                  mediaItemId: string,
                  tag:  {
                    __typename: "Tag",
                    id: string,
                    name: string,
                    mediaItems?:  {
                      __typename: "ModelMediaItemTagsConnection",
                      items:  Array< {
                        __typename: "MediaItemTags",
                        id: string,
                        tagId: string,
                        mediaItemId: string,
                        tag:  {
                          __typename: "Tag",
                          id: string,
                          name: string,
                          mediaItems?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          createdAt: string,
                          updatedAt: string,
                        },
                        mediaItem:  {
                          __typename: "MediaItem",
                          id: string,
                          image:  {
                            __typename: "Image",
                            fullSize:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                            thumbNail:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                          },
                          source?: string | null,
                          originalFileName: string,
                          tags?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          tagsString?: string | null,
                          tagsIds: Array< string >,
                          createdAt: string,
                          updatedAt: string,
                        },
                        createdAt: string,
                        updatedAt: string,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                    createdAt: string,
                    updatedAt: string,
                  },
                  mediaItem:  {
                    __typename: "MediaItem",
                    id: string,
                    image:  {
                      __typename: "Image",
                      fullSize:  {
                        __typename: "ImageInstance",
                        width: number,
                        height: number,
                        key: string,
                      },
                      thumbNail:  {
                        __typename: "ImageInstance",
                        width: number,
                        height: number,
                        key: string,
                      },
                    },
                    source?: string | null,
                    originalFileName: string,
                    tags?:  {
                      __typename: "ModelMediaItemTagsConnection",
                      items:  Array< {
                        __typename: "MediaItemTags",
                        id: string,
                        tagId: string,
                        mediaItemId: string,
                        tag:  {
                          __typename: "Tag",
                          id: string,
                          name: string,
                          mediaItems?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          createdAt: string,
                          updatedAt: string,
                        },
                        mediaItem:  {
                          __typename: "MediaItem",
                          id: string,
                          image:  {
                            __typename: "Image",
                            fullSize:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                            thumbNail:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                          },
                          source?: string | null,
                          originalFileName: string,
                          tags?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          tagsString?: string | null,
                          tagsIds: Array< string >,
                          createdAt: string,
                          updatedAt: string,
                        },
                        createdAt: string,
                        updatedAt: string,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                    tagsString?: string | null,
                    tagsIds: Array< string >,
                    createdAt: string,
                    updatedAt: string,
                  },
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              tagsString?: string | null,
              tagsIds: Array< string >,
              createdAt: string,
              updatedAt: string,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      },
      mediaItem:  {
        __typename: "MediaItem",
        id: string,
        image:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        },
        source?: string | null,
        originalFileName: string,
        tags?:  {
          __typename: "ModelMediaItemTagsConnection",
          items:  Array< {
            __typename: "MediaItemTags",
            id: string,
            tagId: string,
            mediaItemId: string,
            tag:  {
              __typename: "Tag",
              id: string,
              name: string,
              mediaItems?:  {
                __typename: "ModelMediaItemTagsConnection",
                items:  Array< {
                  __typename: "MediaItemTags",
                  id: string,
                  tagId: string,
                  mediaItemId: string,
                  tag:  {
                    __typename: "Tag",
                    id: string,
                    name: string,
                    mediaItems?:  {
                      __typename: "ModelMediaItemTagsConnection",
                      items:  Array< {
                        __typename: "MediaItemTags",
                        id: string,
                        tagId: string,
                        mediaItemId: string,
                        tag:  {
                          __typename: "Tag",
                          id: string,
                          name: string,
                          mediaItems?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          createdAt: string,
                          updatedAt: string,
                        },
                        mediaItem:  {
                          __typename: "MediaItem",
                          id: string,
                          image:  {
                            __typename: "Image",
                            fullSize:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                            thumbNail:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                          },
                          source?: string | null,
                          originalFileName: string,
                          tags?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          tagsString?: string | null,
                          tagsIds: Array< string >,
                          createdAt: string,
                          updatedAt: string,
                        },
                        createdAt: string,
                        updatedAt: string,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                    createdAt: string,
                    updatedAt: string,
                  },
                  mediaItem:  {
                    __typename: "MediaItem",
                    id: string,
                    image:  {
                      __typename: "Image",
                      fullSize:  {
                        __typename: "ImageInstance",
                        width: number,
                        height: number,
                        key: string,
                      },
                      thumbNail:  {
                        __typename: "ImageInstance",
                        width: number,
                        height: number,
                        key: string,
                      },
                    },
                    source?: string | null,
                    originalFileName: string,
                    tags?:  {
                      __typename: "ModelMediaItemTagsConnection",
                      items:  Array< {
                        __typename: "MediaItemTags",
                        id: string,
                        tagId: string,
                        mediaItemId: string,
                        tag:  {
                          __typename: "Tag",
                          id: string,
                          name: string,
                          mediaItems?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          createdAt: string,
                          updatedAt: string,
                        },
                        mediaItem:  {
                          __typename: "MediaItem",
                          id: string,
                          image:  {
                            __typename: "Image",
                            fullSize:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                            thumbNail:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                          },
                          source?: string | null,
                          originalFileName: string,
                          tags?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          tagsString?: string | null,
                          tagsIds: Array< string >,
                          createdAt: string,
                          updatedAt: string,
                        },
                        createdAt: string,
                        updatedAt: string,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                    tagsString?: string | null,
                    tagsIds: Array< string >,
                    createdAt: string,
                    updatedAt: string,
                  },
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            },
            mediaItem:  {
              __typename: "MediaItem",
              id: string,
              image:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              },
              source?: string | null,
              originalFileName: string,
              tags?:  {
                __typename: "ModelMediaItemTagsConnection",
                items:  Array< {
                  __typename: "MediaItemTags",
                  id: string,
                  tagId: string,
                  mediaItemId: string,
                  tag:  {
                    __typename: "Tag",
                    id: string,
                    name: string,
                    mediaItems?:  {
                      __typename: "ModelMediaItemTagsConnection",
                      items:  Array< {
                        __typename: "MediaItemTags",
                        id: string,
                        tagId: string,
                        mediaItemId: string,
                        tag:  {
                          __typename: "Tag",
                          id: string,
                          name: string,
                          mediaItems?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          createdAt: string,
                          updatedAt: string,
                        },
                        mediaItem:  {
                          __typename: "MediaItem",
                          id: string,
                          image:  {
                            __typename: "Image",
                            fullSize:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                            thumbNail:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                          },
                          source?: string | null,
                          originalFileName: string,
                          tags?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          tagsString?: string | null,
                          tagsIds: Array< string >,
                          createdAt: string,
                          updatedAt: string,
                        },
                        createdAt: string,
                        updatedAt: string,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                    createdAt: string,
                    updatedAt: string,
                  },
                  mediaItem:  {
                    __typename: "MediaItem",
                    id: string,
                    image:  {
                      __typename: "Image",
                      fullSize:  {
                        __typename: "ImageInstance",
                        width: number,
                        height: number,
                        key: string,
                      },
                      thumbNail:  {
                        __typename: "ImageInstance",
                        width: number,
                        height: number,
                        key: string,
                      },
                    },
                    source?: string | null,
                    originalFileName: string,
                    tags?:  {
                      __typename: "ModelMediaItemTagsConnection",
                      items:  Array< {
                        __typename: "MediaItemTags",
                        id: string,
                        tagId: string,
                        mediaItemId: string,
                        tag:  {
                          __typename: "Tag",
                          id: string,
                          name: string,
                          mediaItems?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          createdAt: string,
                          updatedAt: string,
                        },
                        mediaItem:  {
                          __typename: "MediaItem",
                          id: string,
                          image:  {
                            __typename: "Image",
                            fullSize:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                            thumbNail:  {
                              __typename: "ImageInstance",
                              width: number,
                              height: number,
                              key: string,
                            },
                          },
                          source?: string | null,
                          originalFileName: string,
                          tags?:  {
                            __typename: "ModelMediaItemTagsConnection",
                            items:  Array< {
                              __typename: "MediaItemTags",
                              id: string,
                              tagId: string,
                              mediaItemId: string,
                              tag:  {
                                __typename: "Tag",
                                id: string,
                                name: string,
                                mediaItems?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                createdAt: string,
                                updatedAt: string,
                              },
                              mediaItem:  {
                                __typename: "MediaItem",
                                id: string,
                                image:  {
                                  __typename: "Image",
                                },
                                source?: string | null,
                                originalFileName: string,
                                tags?:  {
                                  __typename: "ModelMediaItemTagsConnection",
                                  nextToken?: string | null,
                                } | null,
                                tagsString?: string | null,
                                tagsIds: Array< string >,
                                createdAt: string,
                                updatedAt: string,
                              },
                              createdAt: string,
                              updatedAt: string,
                            } | null >,
                            nextToken?: string | null,
                          } | null,
                          tagsString?: string | null,
                          tagsIds: Array< string >,
                          createdAt: string,
                          updatedAt: string,
                        },
                        createdAt: string,
                        updatedAt: string,
                      } | null >,
                      nextToken?: string | null,
                    } | null,
                    tagsString?: string | null,
                    tagsIds: Array< string >,
                    createdAt: string,
                    updatedAt: string,
                  },
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              tagsString?: string | null,
              tagsIds: Array< string >,
              createdAt: string,
              updatedAt: string,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tagsString?: string | null,
        tagsIds: Array< string >,
        createdAt: string,
        updatedAt: string,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateDocumentationFileSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentationFileFilterInput | null,
};

export type OnCreateDocumentationFileSubscription = {
  onCreateDocumentationFile?:  {
    __typename: "DocumentationFile",
    id: string,
    name: string,
    description: string,
    s3Key: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDocumentationFileSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentationFileFilterInput | null,
};

export type OnUpdateDocumentationFileSubscription = {
  onUpdateDocumentationFile?:  {
    __typename: "DocumentationFile",
    id: string,
    name: string,
    description: string,
    s3Key: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDocumentationFileSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentationFileFilterInput | null,
};

export type OnDeleteDocumentationFileSubscription = {
  onDeleteDocumentationFile?:  {
    __typename: "DocumentationFile",
    id: string,
    name: string,
    description: string,
    s3Key: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDocumentFileSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentFileFilterInput | null,
};

export type OnCreateDocumentFileSubscription = {
  onCreateDocumentFile?:  {
    __typename: "DocumentFile",
    id: string,
    path: Array< string >,
    name: string,
    description: string,
    format: string,
    currentVersion:  {
      __typename: "DocumentFileVersion",
      createdAt: string,
      version: string,
      validSince?: string | null,
      s3Key: string,
    },
    history:  Array< {
      __typename: "DocumentFileVersion",
      createdAt: string,
      version: string,
      validSince?: string | null,
      s3Key: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDocumentFileSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentFileFilterInput | null,
};

export type OnUpdateDocumentFileSubscription = {
  onUpdateDocumentFile?:  {
    __typename: "DocumentFile",
    id: string,
    path: Array< string >,
    name: string,
    description: string,
    format: string,
    currentVersion:  {
      __typename: "DocumentFileVersion",
      createdAt: string,
      version: string,
      validSince?: string | null,
      s3Key: string,
    },
    history:  Array< {
      __typename: "DocumentFileVersion",
      createdAt: string,
      version: string,
      validSince?: string | null,
      s3Key: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDocumentFileSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentFileFilterInput | null,
};

export type OnDeleteDocumentFileSubscription = {
  onDeleteDocumentFile?:  {
    __typename: "DocumentFile",
    id: string,
    path: Array< string >,
    name: string,
    description: string,
    format: string,
    currentVersion:  {
      __typename: "DocumentFileVersion",
      createdAt: string,
      version: string,
      validSince?: string | null,
      s3Key: string,
    },
    history:  Array< {
      __typename: "DocumentFileVersion",
      createdAt: string,
      version: string,
      validSince?: string | null,
      s3Key: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTagSubscriptionVariables = {
  filter?: ModelSubscriptionTagFilterInput | null,
};

export type OnCreateTagSubscription = {
  onCreateTag?:  {
    __typename: "Tag",
    id: string,
    name: string,
    mediaItems?:  {
      __typename: "ModelMediaItemTagsConnection",
      items:  Array< {
        __typename: "MediaItemTags",
        id: string,
        tagId: string,
        mediaItemId: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          mediaItems?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        mediaItem:  {
          __typename: "MediaItem",
          id: string,
          image:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          },
          source?: string | null,
          originalFileName: string,
          tags?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tagsString?: string | null,
          tagsIds: Array< string >,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTagSubscriptionVariables = {
  filter?: ModelSubscriptionTagFilterInput | null,
};

export type OnUpdateTagSubscription = {
  onUpdateTag?:  {
    __typename: "Tag",
    id: string,
    name: string,
    mediaItems?:  {
      __typename: "ModelMediaItemTagsConnection",
      items:  Array< {
        __typename: "MediaItemTags",
        id: string,
        tagId: string,
        mediaItemId: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          mediaItems?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        mediaItem:  {
          __typename: "MediaItem",
          id: string,
          image:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          },
          source?: string | null,
          originalFileName: string,
          tags?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tagsString?: string | null,
          tagsIds: Array< string >,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTagSubscriptionVariables = {
  filter?: ModelSubscriptionTagFilterInput | null,
};

export type OnDeleteTagSubscription = {
  onDeleteTag?:  {
    __typename: "Tag",
    id: string,
    name: string,
    mediaItems?:  {
      __typename: "ModelMediaItemTagsConnection",
      items:  Array< {
        __typename: "MediaItemTags",
        id: string,
        tagId: string,
        mediaItemId: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          mediaItems?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        mediaItem:  {
          __typename: "MediaItem",
          id: string,
          image:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          },
          source?: string | null,
          originalFileName: string,
          tags?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tagsString?: string | null,
          tagsIds: Array< string >,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMediaItemSubscriptionVariables = {
  filter?: ModelSubscriptionMediaItemFilterInput | null,
};

export type OnCreateMediaItemSubscription = {
  onCreateMediaItem?:  {
    __typename: "MediaItem",
    id: string,
    image:  {
      __typename: "Image",
      fullSize:  {
        __typename: "ImageInstance",
        width: number,
        height: number,
        key: string,
      },
      thumbNail:  {
        __typename: "ImageInstance",
        width: number,
        height: number,
        key: string,
      },
    },
    source?: string | null,
    originalFileName: string,
    tags?:  {
      __typename: "ModelMediaItemTagsConnection",
      items:  Array< {
        __typename: "MediaItemTags",
        id: string,
        tagId: string,
        mediaItemId: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          mediaItems?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        mediaItem:  {
          __typename: "MediaItem",
          id: string,
          image:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          },
          source?: string | null,
          originalFileName: string,
          tags?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tagsString?: string | null,
          tagsIds: Array< string >,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tagsString?: string | null,
    tagsIds: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMediaItemSubscriptionVariables = {
  filter?: ModelSubscriptionMediaItemFilterInput | null,
};

export type OnUpdateMediaItemSubscription = {
  onUpdateMediaItem?:  {
    __typename: "MediaItem",
    id: string,
    image:  {
      __typename: "Image",
      fullSize:  {
        __typename: "ImageInstance",
        width: number,
        height: number,
        key: string,
      },
      thumbNail:  {
        __typename: "ImageInstance",
        width: number,
        height: number,
        key: string,
      },
    },
    source?: string | null,
    originalFileName: string,
    tags?:  {
      __typename: "ModelMediaItemTagsConnection",
      items:  Array< {
        __typename: "MediaItemTags",
        id: string,
        tagId: string,
        mediaItemId: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          mediaItems?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        mediaItem:  {
          __typename: "MediaItem",
          id: string,
          image:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          },
          source?: string | null,
          originalFileName: string,
          tags?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tagsString?: string | null,
          tagsIds: Array< string >,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tagsString?: string | null,
    tagsIds: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMediaItemSubscriptionVariables = {
  filter?: ModelSubscriptionMediaItemFilterInput | null,
};

export type OnDeleteMediaItemSubscription = {
  onDeleteMediaItem?:  {
    __typename: "MediaItem",
    id: string,
    image:  {
      __typename: "Image",
      fullSize:  {
        __typename: "ImageInstance",
        width: number,
        height: number,
        key: string,
      },
      thumbNail:  {
        __typename: "ImageInstance",
        width: number,
        height: number,
        key: string,
      },
    },
    source?: string | null,
    originalFileName: string,
    tags?:  {
      __typename: "ModelMediaItemTagsConnection",
      items:  Array< {
        __typename: "MediaItemTags",
        id: string,
        tagId: string,
        mediaItemId: string,
        tag:  {
          __typename: "Tag",
          id: string,
          name: string,
          mediaItems?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        mediaItem:  {
          __typename: "MediaItem",
          id: string,
          image:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          },
          source?: string | null,
          originalFileName: string,
          tags?:  {
            __typename: "ModelMediaItemTagsConnection",
            items:  Array< {
              __typename: "MediaItemTags",
              id: string,
              tagId: string,
              mediaItemId: string,
              tag:  {
                __typename: "Tag",
                id: string,
                name: string,
                mediaItems?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                createdAt: string,
                updatedAt: string,
              },
              mediaItem:  {
                __typename: "MediaItem",
                id: string,
                image:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                },
                source?: string | null,
                originalFileName: string,
                tags?:  {
                  __typename: "ModelMediaItemTagsConnection",
                  items:  Array< {
                    __typename: "MediaItemTags",
                    id: string,
                    tagId: string,
                    mediaItemId: string,
                    tag:  {
                      __typename: "Tag",
                      id: string,
                      name: string,
                      mediaItems?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      createdAt: string,
                      updatedAt: string,
                    },
                    mediaItem:  {
                      __typename: "MediaItem",
                      id: string,
                      image:  {
                        __typename: "Image",
                        fullSize:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                        thumbNail:  {
                          __typename: "ImageInstance",
                          width: number,
                          height: number,
                          key: string,
                        },
                      },
                      source?: string | null,
                      originalFileName: string,
                      tags?:  {
                        __typename: "ModelMediaItemTagsConnection",
                        items:  Array< {
                          __typename: "MediaItemTags",
                          id: string,
                          tagId: string,
                          mediaItemId: string,
                          tag:  {
                            __typename: "Tag",
                            id: string,
                            name: string,
                            mediaItems?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            createdAt: string,
                            updatedAt: string,
                          },
                          mediaItem:  {
                            __typename: "MediaItem",
                            id: string,
                            image:  {
                              __typename: "Image",
                              fullSize:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                              thumbNail:  {
                                __typename: "ImageInstance",
                                width: number,
                                height: number,
                                key: string,
                              },
                            },
                            source?: string | null,
                            originalFileName: string,
                            tags?:  {
                              __typename: "ModelMediaItemTagsConnection",
                              items:  Array< {
                                __typename: "MediaItemTags",
                                id: string,
                                tagId: string,
                                mediaItemId: string,
                                tag:  {
                                  __typename: "Tag",
                                  id: string,
                                  name: string,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                mediaItem:  {
                                  __typename: "MediaItem",
                                  id: string,
                                  source?: string | null,
                                  originalFileName: string,
                                  tagsString?: string | null,
                                  tagsIds: Array< string >,
                                  createdAt: string,
                                  updatedAt: string,
                                },
                                createdAt: string,
                                updatedAt: string,
                              } | null >,
                              nextToken?: string | null,
                            } | null,
                            tagsString?: string | null,
                            tagsIds: Array< string >,
                            createdAt: string,
                            updatedAt: string,
                          },
                          createdAt: string,
                          updatedAt: string,
                        } | null >,
                        nextToken?: string | null,
                      } | null,
                      tagsString?: string | null,
                      tagsIds: Array< string >,
                      createdAt: string,
                      updatedAt: string,
                    },
                    createdAt: string,
                    updatedAt: string,
                  } | null >,
                  nextToken?: string | null,
                } | null,
                tagsString?: string | null,
                tagsIds: Array< string >,
                createdAt: string,
                updatedAt: string,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tagsString?: string | null,
          tagsIds: Array< string >,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tagsString?: string | null,
    tagsIds: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTestFinalizationSubscriptionVariables = {
  filter?: ModelSubscriptionTestFinalizationFilterInput | null,
  owner?: string | null,
};

export type OnCreateTestFinalizationSubscription = {
  onCreateTestFinalization?:  {
    __typename: "TestFinalization",
    id: string,
    testType: string,
    testId: string,
    probandId: string,
    loadingStartedAt: string,
    testStartedAt?: string | null,
    testFinalizedAt?: string | null,
    testAbortedAt?: string | null,
    owner?: string | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTestFinalizationSubscriptionVariables = {
  filter?: ModelSubscriptionTestFinalizationFilterInput | null,
  owner?: string | null,
};

export type OnUpdateTestFinalizationSubscription = {
  onUpdateTestFinalization?:  {
    __typename: "TestFinalization",
    id: string,
    testType: string,
    testId: string,
    probandId: string,
    loadingStartedAt: string,
    testStartedAt?: string | null,
    testFinalizedAt?: string | null,
    testAbortedAt?: string | null,
    owner?: string | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTestFinalizationSubscriptionVariables = {
  filter?: ModelSubscriptionTestFinalizationFilterInput | null,
  owner?: string | null,
};

export type OnDeleteTestFinalizationSubscription = {
  onDeleteTestFinalization?:  {
    __typename: "TestFinalization",
    id: string,
    testType: string,
    testId: string,
    probandId: string,
    loadingStartedAt: string,
    testStartedAt?: string | null,
    testFinalizedAt?: string | null,
    testAbortedAt?: string | null,
    owner?: string | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTestResultsStatusSubscriptionVariables = {
  filter?: ModelSubscriptionTestResultsStatusFilterInput | null,
  owner?: string | null,
};

export type OnCreateTestResultsStatusSubscription = {
  onCreateTestResultsStatus?:  {
    __typename: "TestResultsStatus",
    id: string,
    status: TestResultsStatusType,
    testType: string,
    error?: string | null,
    statistics?:  {
      __typename: "TestResultsStatistics",
      processedResultRows: number,
      exportedResults: number,
      processedProbandAmount: number,
      finishedProbandAmount: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateTestResultsStatusSubscriptionVariables = {
  filter?: ModelSubscriptionTestResultsStatusFilterInput | null,
  owner?: string | null,
};

export type OnUpdateTestResultsStatusSubscription = {
  onUpdateTestResultsStatus?:  {
    __typename: "TestResultsStatus",
    id: string,
    status: TestResultsStatusType,
    testType: string,
    error?: string | null,
    statistics?:  {
      __typename: "TestResultsStatistics",
      processedResultRows: number,
      exportedResults: number,
      processedProbandAmount: number,
      finishedProbandAmount: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteTestResultsStatusSubscriptionVariables = {
  filter?: ModelSubscriptionTestResultsStatusFilterInput | null,
  owner?: string | null,
};

export type OnDeleteTestResultsStatusSubscription = {
  onDeleteTestResultsStatus?:  {
    __typename: "TestResultsStatus",
    id: string,
    status: TestResultsStatusType,
    testType: string,
    error?: string | null,
    statistics?:  {
      __typename: "TestResultsStatistics",
      processedResultRows: number,
      exportedResults: number,
      processedProbandAmount: number,
      finishedProbandAmount: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateIatTestSubscriptionVariables = {
  filter?: ModelSubscriptionIatTestFilterInput | null,
  owner?: string | null,
};

export type OnCreateIatTestSubscription = {
  onCreateIatTest?:  {
    __typename: "IatTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    interTrialInterval: number,
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    previewCategories: CategoryPreviewDisplay,
    blocks:  Array< {
      __typename: "IatBlock",
      index: number,
      type: BlockType,
      name: string,
      amountTrials: number,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      categoryLabelsInInstructions: boolean,
      attributeCategoryVariant?: CategoryVariant | null,
      targetCategoryVariant?: CategoryVariant | null,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    targetCategories:  {
      __typename: "IatCategories",
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      categoryA:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      categoryB:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    },
    categoryDivider?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    attributeCategories:  {
      __typename: "IatCategories",
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      categoryA:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      categoryB:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    },
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    feedback: IatFeedbackType,
    feedbackInterval?: number | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateIatTestSubscriptionVariables = {
  filter?: ModelSubscriptionIatTestFilterInput | null,
  owner?: string | null,
};

export type OnUpdateIatTestSubscription = {
  onUpdateIatTest?:  {
    __typename: "IatTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    interTrialInterval: number,
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    previewCategories: CategoryPreviewDisplay,
    blocks:  Array< {
      __typename: "IatBlock",
      index: number,
      type: BlockType,
      name: string,
      amountTrials: number,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      categoryLabelsInInstructions: boolean,
      attributeCategoryVariant?: CategoryVariant | null,
      targetCategoryVariant?: CategoryVariant | null,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    targetCategories:  {
      __typename: "IatCategories",
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      categoryA:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      categoryB:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    },
    categoryDivider?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    attributeCategories:  {
      __typename: "IatCategories",
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      categoryA:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      categoryB:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    },
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    feedback: IatFeedbackType,
    feedbackInterval?: number | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteIatTestSubscriptionVariables = {
  filter?: ModelSubscriptionIatTestFilterInput | null,
  owner?: string | null,
};

export type OnDeleteIatTestSubscription = {
  onDeleteIatTest?:  {
    __typename: "IatTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    interTrialInterval: number,
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    previewCategories: CategoryPreviewDisplay,
    blocks:  Array< {
      __typename: "IatBlock",
      index: number,
      type: BlockType,
      name: string,
      amountTrials: number,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      categoryLabelsInInstructions: boolean,
      attributeCategoryVariant?: CategoryVariant | null,
      targetCategoryVariant?: CategoryVariant | null,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    targetCategories:  {
      __typename: "IatCategories",
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      categoryA:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      categoryB:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    },
    categoryDivider?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    attributeCategories:  {
      __typename: "IatCategories",
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      categoryA:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      categoryB:  {
        __typename: "LabeledStimuliPool",
        label:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    },
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    feedback: IatFeedbackType,
    feedbackInterval?: number | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateIatResultSubscriptionVariables = {
  filter?: ModelSubscriptionIatResultFilterInput | null,
  owner?: string | null,
};

export type OnCreateIatResultSubscription = {
  onCreateIatResult?:  {
    __typename: "IatResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    actionTarget?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    actionAttribute?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    counterBalancingGroup?: string | null,
    stimulus:  {
      __typename: "ResultStimulus",
      categoryLabel:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      categoryType: IatCategoryType,
      stimulus:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    pressedButton?: string | null,
    triggeredControl?: string | null,
    attributeLeft?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    attributeRight?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    targetLeft?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    targetRight?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateIatResultSubscriptionVariables = {
  filter?: ModelSubscriptionIatResultFilterInput | null,
  owner?: string | null,
};

export type OnUpdateIatResultSubscription = {
  onUpdateIatResult?:  {
    __typename: "IatResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    actionTarget?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    actionAttribute?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    counterBalancingGroup?: string | null,
    stimulus:  {
      __typename: "ResultStimulus",
      categoryLabel:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      categoryType: IatCategoryType,
      stimulus:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    pressedButton?: string | null,
    triggeredControl?: string | null,
    attributeLeft?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    attributeRight?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    targetLeft?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    targetRight?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteIatResultSubscriptionVariables = {
  filter?: ModelSubscriptionIatResultFilterInput | null,
  owner?: string | null,
};

export type OnDeleteIatResultSubscription = {
  onDeleteIatResult?:  {
    __typename: "IatResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    actionTarget?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    actionAttribute?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    counterBalancingGroup?: string | null,
    stimulus:  {
      __typename: "ResultStimulus",
      categoryLabel:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      categoryType: IatCategoryType,
      stimulus:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    pressedButton?: string | null,
    triggeredControl?: string | null,
    attributeLeft?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    attributeRight?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    targetLeft?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    targetRight?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateAmpTestSubscriptionVariables = {
  filter?: ModelSubscriptionAmpTestFilterInput | null,
  owner?: string | null,
};

export type OnCreateAmpTestSubscription = {
  onCreateAmpTest?:  {
    __typename: "AmpTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    interTrialInterval: number,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    attributeCategories:  {
      __typename: "AmpAttributeCategories",
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      categoryA:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      categoryB:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    fixation?:  {
      __typename: "TimedMediaItem",
      interval: number,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    } | null,
    primes:  {
      __typename: "TimedAmpCategories",
      interval: number,
      categories:  Array< {
        __typename: "AmpLabeledStimuliPool",
        label: string,
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } >,
    },
    targets:  {
      __typename: "TimedAmpCategories",
      interval: number,
      categories:  Array< {
        __typename: "AmpLabeledStimuliPool",
        label: string,
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } >,
    },
    mask:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    },
    blankInterval: number,
    blocks:  Array< {
      __typename: "AmpBlock",
      index: number,
      name: string,
      type: BlockType,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      amountTrials: number,
      attributeVariant: CategoryVariant,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAmpTestSubscriptionVariables = {
  filter?: ModelSubscriptionAmpTestFilterInput | null,
  owner?: string | null,
};

export type OnUpdateAmpTestSubscription = {
  onUpdateAmpTest?:  {
    __typename: "AmpTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    interTrialInterval: number,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    attributeCategories:  {
      __typename: "AmpAttributeCategories",
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      categoryA:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      categoryB:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    fixation?:  {
      __typename: "TimedMediaItem",
      interval: number,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    } | null,
    primes:  {
      __typename: "TimedAmpCategories",
      interval: number,
      categories:  Array< {
        __typename: "AmpLabeledStimuliPool",
        label: string,
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } >,
    },
    targets:  {
      __typename: "TimedAmpCategories",
      interval: number,
      categories:  Array< {
        __typename: "AmpLabeledStimuliPool",
        label: string,
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } >,
    },
    mask:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    },
    blankInterval: number,
    blocks:  Array< {
      __typename: "AmpBlock",
      index: number,
      name: string,
      type: BlockType,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      amountTrials: number,
      attributeVariant: CategoryVariant,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAmpTestSubscriptionVariables = {
  filter?: ModelSubscriptionAmpTestFilterInput | null,
  owner?: string | null,
};

export type OnDeleteAmpTestSubscription = {
  onDeleteAmpTest?:  {
    __typename: "AmpTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    interTrialInterval: number,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    attributeCategories:  {
      __typename: "AmpAttributeCategories",
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      categoryA:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      categoryB:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    fixation?:  {
      __typename: "TimedMediaItem",
      interval: number,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    } | null,
    primes:  {
      __typename: "TimedAmpCategories",
      interval: number,
      categories:  Array< {
        __typename: "AmpLabeledStimuliPool",
        label: string,
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } >,
    },
    targets:  {
      __typename: "TimedAmpCategories",
      interval: number,
      categories:  Array< {
        __typename: "AmpLabeledStimuliPool",
        label: string,
        practiceStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        testStimuliPool:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } >,
    },
    mask:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    },
    blankInterval: number,
    blocks:  Array< {
      __typename: "AmpBlock",
      index: number,
      name: string,
      type: BlockType,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      amountTrials: number,
      attributeVariant: CategoryVariant,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAmpResultSubscriptionVariables = {
  filter?: ModelSubscriptionAmpResultFilterInput | null,
  owner?: string | null,
};

export type OnCreateAmpResultSubscription = {
  onCreateAmpResult?:  {
    __typename: "AmpResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    counterBalancingGroup?: string | null,
    pressedButton?: string | null,
    triggeredControl?: string | null,
    attributeLeftStimulus?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    attributeRightStimulus?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    actionResultStimulus?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    attributeLeft: string,
    attributeRight: string,
    prime:  {
      __typename: "AmpResultCategoryMediaItem",
      label: string,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    target:  {
      __typename: "AmpResultCategoryMediaItem",
      label: string,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateAmpResultSubscriptionVariables = {
  filter?: ModelSubscriptionAmpResultFilterInput | null,
  owner?: string | null,
};

export type OnUpdateAmpResultSubscription = {
  onUpdateAmpResult?:  {
    __typename: "AmpResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    counterBalancingGroup?: string | null,
    pressedButton?: string | null,
    triggeredControl?: string | null,
    attributeLeftStimulus?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    attributeRightStimulus?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    actionResultStimulus?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    attributeLeft: string,
    attributeRight: string,
    prime:  {
      __typename: "AmpResultCategoryMediaItem",
      label: string,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    target:  {
      __typename: "AmpResultCategoryMediaItem",
      label: string,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteAmpResultSubscriptionVariables = {
  filter?: ModelSubscriptionAmpResultFilterInput | null,
  owner?: string | null,
};

export type OnDeleteAmpResultSubscription = {
  onDeleteAmpResult?:  {
    __typename: "AmpResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    counterBalancingGroup?: string | null,
    pressedButton?: string | null,
    triggeredControl?: string | null,
    attributeLeftStimulus?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    attributeRightStimulus?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    actionResultStimulus?:  {
      __typename: "MediaItemSnapshot",
      id: string,
      tags:  Array< {
        __typename: "TagSnapShot",
        id: string,
        name: string,
      } >,
      scope: MediaItemSnapshotScope,
      text?: string | null,
      image?:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      } | null,
      originalFileName?: string | null,
    } | null,
    attributeLeft: string,
    attributeRight: string,
    prime:  {
      __typename: "AmpResultCategoryMediaItem",
      label: string,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    target:  {
      __typename: "AmpResultCategoryMediaItem",
      label: string,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreatePodtTestSubscriptionVariables = {
  filter?: ModelSubscriptionPodtTestFilterInput | null,
  owner?: string | null,
};

export type OnCreatePodtTestSubscription = {
  onCreatePodtTest?:  {
    __typename: "PodtTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    interTrialInterval: number,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    response:  {
      __typename: "PodtResponse",
      timeout: number,
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      active:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      passive:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    scenes:  {
      __typename: "PodtScenes",
      maxAmountScenesPerTrial: number,
      scenes:  Array< {
        __typename: "PodtScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtBox",
          top: number,
          left: number,
          width: number,
          height: number,
        } >,
      } >,
      intervalConfig:  {
        __typename: "PodtSceneIntervalConfig",
        min: number,
        max: number,
        stepSize: number,
      },
    },
    targets:  Array< {
      __typename: "PodtTargetCategory",
      label: string,
      practiceStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      testStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    } >,
    fixation?:  {
      __typename: "TimedMediaItem",
      interval: number,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    } | null,
    trialFeedback?:  {
      __typename: "PodtTrialFeedback",
      correctRejectionText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      hitText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      missText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      falseAlarmText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      noResponseText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      pointsFontsize?: number | null,
    } | null,
    blocks:  Array< {
      __typename: "PodtBlock",
      index: number,
      name: string,
      type: BlockType,
      amountTrials: number,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      preparationScreen?:  {
        __typename: "PodtPreparationScreen",
        interval: number,
        screen:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        },
      } | null,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    showCategoriesAfterInstructions?: boolean | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePodtTestSubscriptionVariables = {
  filter?: ModelSubscriptionPodtTestFilterInput | null,
  owner?: string | null,
};

export type OnUpdatePodtTestSubscription = {
  onUpdatePodtTest?:  {
    __typename: "PodtTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    interTrialInterval: number,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    response:  {
      __typename: "PodtResponse",
      timeout: number,
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      active:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      passive:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    scenes:  {
      __typename: "PodtScenes",
      maxAmountScenesPerTrial: number,
      scenes:  Array< {
        __typename: "PodtScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtBox",
          top: number,
          left: number,
          width: number,
          height: number,
        } >,
      } >,
      intervalConfig:  {
        __typename: "PodtSceneIntervalConfig",
        min: number,
        max: number,
        stepSize: number,
      },
    },
    targets:  Array< {
      __typename: "PodtTargetCategory",
      label: string,
      practiceStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      testStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    } >,
    fixation?:  {
      __typename: "TimedMediaItem",
      interval: number,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    } | null,
    trialFeedback?:  {
      __typename: "PodtTrialFeedback",
      correctRejectionText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      hitText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      missText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      falseAlarmText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      noResponseText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      pointsFontsize?: number | null,
    } | null,
    blocks:  Array< {
      __typename: "PodtBlock",
      index: number,
      name: string,
      type: BlockType,
      amountTrials: number,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      preparationScreen?:  {
        __typename: "PodtPreparationScreen",
        interval: number,
        screen:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        },
      } | null,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    showCategoriesAfterInstructions?: boolean | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePodtTestSubscriptionVariables = {
  filter?: ModelSubscriptionPodtTestFilterInput | null,
  owner?: string | null,
};

export type OnDeletePodtTestSubscription = {
  onDeletePodtTest?:  {
    __typename: "PodtTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    interTrialInterval: number,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    response:  {
      __typename: "PodtResponse",
      timeout: number,
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      active:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      passive:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    scenes:  {
      __typename: "PodtScenes",
      maxAmountScenesPerTrial: number,
      scenes:  Array< {
        __typename: "PodtScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtBox",
          top: number,
          left: number,
          width: number,
          height: number,
        } >,
      } >,
      intervalConfig:  {
        __typename: "PodtSceneIntervalConfig",
        min: number,
        max: number,
        stepSize: number,
      },
    },
    targets:  Array< {
      __typename: "PodtTargetCategory",
      label: string,
      practiceStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      testStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    } >,
    fixation?:  {
      __typename: "TimedMediaItem",
      interval: number,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    } | null,
    trialFeedback?:  {
      __typename: "PodtTrialFeedback",
      correctRejectionText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      hitText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      missText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      falseAlarmText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      noResponseText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      pointsFontsize?: number | null,
    } | null,
    blocks:  Array< {
      __typename: "PodtBlock",
      index: number,
      name: string,
      type: BlockType,
      amountTrials: number,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      preparationScreen?:  {
        __typename: "PodtPreparationScreen",
        interval: number,
        screen:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        },
      } | null,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    showCategoriesAfterInstructions?: boolean | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePodtResultSubscriptionVariables = {
  filter?: ModelSubscriptionPodtResultFilterInput | null,
  owner?: string | null,
};

export type OnCreatePodtResultSubscription = {
  onCreatePodtResult?:  {
    __typename: "PodtResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    counterBalancingGroup?: string | null,
    earnedPoints?: number | null,
    reactionLeft: string,
    reactionRight: string,
    scenes:  Array< {
      __typename: "PodtResultScene",
      scene:  {
        __typename: "PodtScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtBox",
          top: number,
          left: number,
          width: number,
          height: number,
        } >,
      },
      interval: number,
    } >,
    target:  {
      __typename: "PodtResultTarget",
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      category: string,
      dangerous: boolean,
    },
    accumulatedPoints: number,
    pressedButton?: string | null,
    triggeredControl?: string | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdatePodtResultSubscriptionVariables = {
  filter?: ModelSubscriptionPodtResultFilterInput | null,
  owner?: string | null,
};

export type OnUpdatePodtResultSubscription = {
  onUpdatePodtResult?:  {
    __typename: "PodtResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    counterBalancingGroup?: string | null,
    earnedPoints?: number | null,
    reactionLeft: string,
    reactionRight: string,
    scenes:  Array< {
      __typename: "PodtResultScene",
      scene:  {
        __typename: "PodtScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtBox",
          top: number,
          left: number,
          width: number,
          height: number,
        } >,
      },
      interval: number,
    } >,
    target:  {
      __typename: "PodtResultTarget",
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      category: string,
      dangerous: boolean,
    },
    accumulatedPoints: number,
    pressedButton?: string | null,
    triggeredControl?: string | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeletePodtResultSubscriptionVariables = {
  filter?: ModelSubscriptionPodtResultFilterInput | null,
  owner?: string | null,
};

export type OnDeletePodtResultSubscription = {
  onDeletePodtResult?:  {
    __typename: "PodtResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    counterBalancingGroup?: string | null,
    earnedPoints?: number | null,
    reactionLeft: string,
    reactionRight: string,
    scenes:  Array< {
      __typename: "PodtResultScene",
      scene:  {
        __typename: "PodtScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtBox",
          top: number,
          left: number,
          width: number,
          height: number,
        } >,
      },
      interval: number,
    } >,
    target:  {
      __typename: "PodtResultTarget",
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      category: string,
      dangerous: boolean,
    },
    accumulatedPoints: number,
    pressedButton?: string | null,
    triggeredControl?: string | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreatePodtaTestSubscriptionVariables = {
  filter?: ModelSubscriptionPodtaTestFilterInput | null,
  owner?: string | null,
};

export type OnCreatePodtaTestSubscription = {
  onCreatePodtaTest?:  {
    __typename: "PodtaTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    interTrialInterval: number,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    response:  {
      __typename: "PodtResponse",
      timeout: number,
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      active:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      passive:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    scenes:  {
      __typename: "PodtaScenes",
      maxAmountScenesPerTrial: number,
      scenes:  Array< {
        __typename: "PodtaScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtaBox",
          top: number,
          left: number,
          width: number,
          height: number,
          position: PodtaBoxPosition,
        } >,
      } >,
      intervalConfig:  {
        __typename: "PodtSceneIntervalConfig",
        min: number,
        max: number,
        stepSize: number,
      },
    },
    targets:  Array< {
      __typename: "PodtTargetCategory",
      label: string,
      practiceStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      testStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    } >,
    fixation?:  {
      __typename: "TimedMediaItem",
      interval: number,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    } | null,
    trialFeedback?:  {
      __typename: "PodtTrialFeedback",
      correctRejectionText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      hitText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      missText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      falseAlarmText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      noResponseText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      pointsFontsize?: number | null,
    } | null,
    blocks:  Array< {
      __typename: "PodtBlock",
      index: number,
      name: string,
      type: BlockType,
      amountTrials: number,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      preparationScreen?:  {
        __typename: "PodtPreparationScreen",
        interval: number,
        screen:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        },
      } | null,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    showCategoriesAfterInstructions?: boolean | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePodtaTestSubscriptionVariables = {
  filter?: ModelSubscriptionPodtaTestFilterInput | null,
  owner?: string | null,
};

export type OnUpdatePodtaTestSubscription = {
  onUpdatePodtaTest?:  {
    __typename: "PodtaTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    interTrialInterval: number,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    response:  {
      __typename: "PodtResponse",
      timeout: number,
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      active:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      passive:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    scenes:  {
      __typename: "PodtaScenes",
      maxAmountScenesPerTrial: number,
      scenes:  Array< {
        __typename: "PodtaScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtaBox",
          top: number,
          left: number,
          width: number,
          height: number,
          position: PodtaBoxPosition,
        } >,
      } >,
      intervalConfig:  {
        __typename: "PodtSceneIntervalConfig",
        min: number,
        max: number,
        stepSize: number,
      },
    },
    targets:  Array< {
      __typename: "PodtTargetCategory",
      label: string,
      practiceStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      testStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    } >,
    fixation?:  {
      __typename: "TimedMediaItem",
      interval: number,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    } | null,
    trialFeedback?:  {
      __typename: "PodtTrialFeedback",
      correctRejectionText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      hitText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      missText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      falseAlarmText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      noResponseText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      pointsFontsize?: number | null,
    } | null,
    blocks:  Array< {
      __typename: "PodtBlock",
      index: number,
      name: string,
      type: BlockType,
      amountTrials: number,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      preparationScreen?:  {
        __typename: "PodtPreparationScreen",
        interval: number,
        screen:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        },
      } | null,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    showCategoriesAfterInstructions?: boolean | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePodtaTestSubscriptionVariables = {
  filter?: ModelSubscriptionPodtaTestFilterInput | null,
  owner?: string | null,
};

export type OnDeletePodtaTestSubscription = {
  onDeletePodtaTest?:  {
    __typename: "PodtaTest",
    id: string,
    name: string,
    description: string,
    language?: string | null,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    keyboard:  {
      __typename: "KeyboardSettings",
      left: string,
      right: string,
      confirm: string,
    },
    counterbalancing: boolean,
    probabilityWithoutReplacement: boolean,
    interTrialInterval: number,
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    response:  {
      __typename: "PodtResponse",
      timeout: number,
      fontStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      active:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      passive:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    },
    scenes:  {
      __typename: "PodtaScenes",
      maxAmountScenesPerTrial: number,
      scenes:  Array< {
        __typename: "PodtaScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtaBox",
          top: number,
          left: number,
          width: number,
          height: number,
          position: PodtaBoxPosition,
        } >,
      } >,
      intervalConfig:  {
        __typename: "PodtSceneIntervalConfig",
        min: number,
        max: number,
        stepSize: number,
      },
    },
    targets:  Array< {
      __typename: "PodtTargetCategory",
      label: string,
      practiceStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
      testStimuliPool:  {
        __typename: "PodtCategoryPool",
        dangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
        nonDangerousStimuli:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      },
    } >,
    fixation?:  {
      __typename: "TimedMediaItem",
      interval: number,
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
    } | null,
    trialFeedback?:  {
      __typename: "PodtTrialFeedback",
      correctRejectionText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      hitText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      missText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      falseAlarmText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      noResponseText:  {
        __typename: "WeightedStyledText",
        value: string,
        fontStyle:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        },
        weight: number,
      },
      pointsFontsize?: number | null,
    } | null,
    blocks:  Array< {
      __typename: "PodtBlock",
      index: number,
      name: string,
      type: BlockType,
      amountTrials: number,
      instructions:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      },
      preparationScreen?:  {
        __typename: "PodtPreparationScreen",
        interval: number,
        screen:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        },
      } | null,
      tips?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
    } >,
    showCategoriesAfterInstructions?: boolean | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePodtaResultSubscriptionVariables = {
  filter?: ModelSubscriptionPodtaResultFilterInput | null,
  owner?: string | null,
};

export type OnCreatePodtaResultSubscription = {
  onCreatePodtaResult?:  {
    __typename: "PodtaResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    counterBalancingGroup?: string | null,
    earnedPoints?: number | null,
    reactionLeft: string,
    reactionRight: string,
    scenes:  Array< {
      __typename: "PodtaResultScene",
      scene:  {
        __typename: "PodtaScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtaBox",
          top: number,
          left: number,
          width: number,
          height: number,
          position: PodtaBoxPosition,
        } >,
      },
      interval: number,
    } >,
    target:  {
      __typename: "PodtResultTarget",
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      category: string,
      dangerous: boolean,
    },
    accumulatedPoints: number,
    pressedButton?: string | null,
    triggeredControl?: string | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdatePodtaResultSubscriptionVariables = {
  filter?: ModelSubscriptionPodtaResultFilterInput | null,
  owner?: string | null,
};

export type OnUpdatePodtaResultSubscription = {
  onUpdatePodtaResult?:  {
    __typename: "PodtaResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    counterBalancingGroup?: string | null,
    earnedPoints?: number | null,
    reactionLeft: string,
    reactionRight: string,
    scenes:  Array< {
      __typename: "PodtaResultScene",
      scene:  {
        __typename: "PodtaScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtaBox",
          top: number,
          left: number,
          width: number,
          height: number,
          position: PodtaBoxPosition,
        } >,
      },
      interval: number,
    } >,
    target:  {
      __typename: "PodtResultTarget",
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      category: string,
      dangerous: boolean,
    },
    accumulatedPoints: number,
    pressedButton?: string | null,
    triggeredControl?: string | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeletePodtaResultSubscriptionVariables = {
  filter?: ModelSubscriptionPodtaResultFilterInput | null,
  owner?: string | null,
};

export type OnDeletePodtaResultSubscription = {
  onDeletePodtaResult?:  {
    __typename: "PodtaResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    blockIndex: number,
    blockName: string,
    blockType?: BlockType | null,
    trialIndex: number,
    actionResultName: string,
    counterBalancingGroup?: string | null,
    earnedPoints?: number | null,
    reactionLeft: string,
    reactionRight: string,
    scenes:  Array< {
      __typename: "PodtaResultScene",
      scene:  {
        __typename: "PodtaScene",
        mediaItem:  {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        },
        boxes:  Array< {
          __typename: "PodtaBox",
          top: number,
          left: number,
          width: number,
          height: number,
          position: PodtaBoxPosition,
        } >,
      },
      interval: number,
    } >,
    target:  {
      __typename: "PodtResultTarget",
      mediaItem:  {
        __typename: "MediaItemSnapshot",
        id: string,
        tags:  Array< {
          __typename: "TagSnapShot",
          id: string,
          name: string,
        } >,
        scope: MediaItemSnapshotScope,
        text?: string | null,
        image?:  {
          __typename: "Image",
          fullSize:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
          thumbNail:  {
            __typename: "ImageInstance",
            width: number,
            height: number,
            key: string,
          },
        } | null,
        originalFileName?: string | null,
      },
      category: string,
      dangerous: boolean,
    },
    accumulatedPoints: number,
    pressedButton?: string | null,
    triggeredControl?: string | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateWSWTestSubscriptionVariables = {
  filter?: ModelSubscriptionWSWTestFilterInput | null,
  owner?: string | null,
};

export type OnCreateWSWTestSubscription = {
  onCreateWSWTest?:  {
    __typename: "WSWTest",
    id: string,
    name: string,
    language?: string | null,
    description: string,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    attributeCombinations:  Array< {
      __typename: "AttributeCombination",
      mainCategory: number,
      additionalCategory?: number | null,
      criteriaCategory?: number | null,
      amount: number,
      multiplicator: number,
    } >,
    mainAttribute:  {
      __typename: "WSWAttribute",
      stimuliVariant?: WswStimulusAttributeVariant | null,
      type: WSWAttributeType,
      pickingStrategy: PickingStrategy,
      categories?:  Array< {
        __typename: "WSWAttributeCategory",
        name: string,
        mediaSnaps:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } > | null,
    },
    criteriaAttribute:  {
      __typename: "WSWAttribute",
      stimuliVariant?: WswStimulusAttributeVariant | null,
      type: WSWAttributeType,
      pickingStrategy: PickingStrategy,
      categories?:  Array< {
        __typename: "WSWAttributeCategory",
        name: string,
        mediaSnaps:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } > | null,
    },
    additionalAttribute?:  {
      __typename: "WSWAttribute",
      stimuliVariant?: WswStimulusAttributeVariant | null,
      type: WSWAttributeType,
      pickingStrategy: PickingStrategy,
      categories?:  Array< {
        __typename: "WSWAttributeCategory",
        name: string,
        mediaSnaps:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } > | null,
    } | null,
    groupConstruction?:  {
      __typename: "GroupConstruction",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      fakeQuestions:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
      conditions:  {
        __typename: "GroupConstructionConditions",
        condition1: number,
        condition2: number,
        condition3: number,
      },
      groups:  Array< {
        __typename: "WSWGroup",
        name: string,
        color: string,
        questionSet?:  {
          __typename: "ConditionalQuestionSet",
          randomizeSequence: boolean,
          conditionalQuestion?:  {
            __typename: "ConditionalQuestion",
            questionText: string,
            confirmText: string,
            declineText: string,
            answerType: ConditionalAnswerType,
          } | null,
          questionsAfter:  Array< {
            __typename: "TestQuestion",
            noAnswer?: string | null,
            type: QuestionType,
            questionText: string,
            multiple?:  {
              __typename: "MultipleChoiceQuestion",
              randomized: boolean,
              options: Array< string >,
            } | null,
            scale?:  {
              __typename: "ScaleQuestion",
              invert: boolean,
              min:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
              max:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
            } | null,
            mediaItemSnapshot?:  {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } | null,
            imageMultipleChoice?:  {
              __typename: "ImageMultipleChoiceQuestion",
              randomized: boolean,
              options:  Array< {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } >,
            } | null,
            stimulusTimeout?: number | null,
          } >,
          questionsBefore:  Array< {
            __typename: "TestQuestion",
            noAnswer?: string | null,
            type: QuestionType,
            questionText: string,
            multiple?:  {
              __typename: "MultipleChoiceQuestion",
              randomized: boolean,
              options: Array< string >,
            } | null,
            scale?:  {
              __typename: "ScaleQuestion",
              invert: boolean,
              min:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
              max:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
            } | null,
            mediaItemSnapshot?:  {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } | null,
            imageMultipleChoice?:  {
              __typename: "ImageMultipleChoiceQuestion",
              randomized: boolean,
              options:  Array< {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } >,
            } | null,
            stimulusTimeout?: number | null,
          } >,
        } | null,
        probability: number,
        feedbackScreen?:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        } | null,
      } >,
      randomizeGroupColors: boolean,
    } | null,
    learningPhase:  {
      __typename: "PresentationPhase",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      questionsSet:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
      questionOffsetTimeout?: number | null,
    },
    testPhase:  {
      __typename: "PresentationPhase",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      questionsSet:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
      questionOffsetTimeout?: number | null,
    },
    distractionPhase?:  {
      __typename: "DistractionPhase",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      questionsSet:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
    } | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateWSWTestSubscriptionVariables = {
  filter?: ModelSubscriptionWSWTestFilterInput | null,
  owner?: string | null,
};

export type OnUpdateWSWTestSubscription = {
  onUpdateWSWTest?:  {
    __typename: "WSWTest",
    id: string,
    name: string,
    language?: string | null,
    description: string,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    attributeCombinations:  Array< {
      __typename: "AttributeCombination",
      mainCategory: number,
      additionalCategory?: number | null,
      criteriaCategory?: number | null,
      amount: number,
      multiplicator: number,
    } >,
    mainAttribute:  {
      __typename: "WSWAttribute",
      stimuliVariant?: WswStimulusAttributeVariant | null,
      type: WSWAttributeType,
      pickingStrategy: PickingStrategy,
      categories?:  Array< {
        __typename: "WSWAttributeCategory",
        name: string,
        mediaSnaps:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } > | null,
    },
    criteriaAttribute:  {
      __typename: "WSWAttribute",
      stimuliVariant?: WswStimulusAttributeVariant | null,
      type: WSWAttributeType,
      pickingStrategy: PickingStrategy,
      categories?:  Array< {
        __typename: "WSWAttributeCategory",
        name: string,
        mediaSnaps:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } > | null,
    },
    additionalAttribute?:  {
      __typename: "WSWAttribute",
      stimuliVariant?: WswStimulusAttributeVariant | null,
      type: WSWAttributeType,
      pickingStrategy: PickingStrategy,
      categories?:  Array< {
        __typename: "WSWAttributeCategory",
        name: string,
        mediaSnaps:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } > | null,
    } | null,
    groupConstruction?:  {
      __typename: "GroupConstruction",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      fakeQuestions:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
      conditions:  {
        __typename: "GroupConstructionConditions",
        condition1: number,
        condition2: number,
        condition3: number,
      },
      groups:  Array< {
        __typename: "WSWGroup",
        name: string,
        color: string,
        questionSet?:  {
          __typename: "ConditionalQuestionSet",
          randomizeSequence: boolean,
          conditionalQuestion?:  {
            __typename: "ConditionalQuestion",
            questionText: string,
            confirmText: string,
            declineText: string,
            answerType: ConditionalAnswerType,
          } | null,
          questionsAfter:  Array< {
            __typename: "TestQuestion",
            noAnswer?: string | null,
            type: QuestionType,
            questionText: string,
            multiple?:  {
              __typename: "MultipleChoiceQuestion",
              randomized: boolean,
              options: Array< string >,
            } | null,
            scale?:  {
              __typename: "ScaleQuestion",
              invert: boolean,
              min:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
              max:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
            } | null,
            mediaItemSnapshot?:  {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } | null,
            imageMultipleChoice?:  {
              __typename: "ImageMultipleChoiceQuestion",
              randomized: boolean,
              options:  Array< {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } >,
            } | null,
            stimulusTimeout?: number | null,
          } >,
          questionsBefore:  Array< {
            __typename: "TestQuestion",
            noAnswer?: string | null,
            type: QuestionType,
            questionText: string,
            multiple?:  {
              __typename: "MultipleChoiceQuestion",
              randomized: boolean,
              options: Array< string >,
            } | null,
            scale?:  {
              __typename: "ScaleQuestion",
              invert: boolean,
              min:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
              max:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
            } | null,
            mediaItemSnapshot?:  {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } | null,
            imageMultipleChoice?:  {
              __typename: "ImageMultipleChoiceQuestion",
              randomized: boolean,
              options:  Array< {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } >,
            } | null,
            stimulusTimeout?: number | null,
          } >,
        } | null,
        probability: number,
        feedbackScreen?:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        } | null,
      } >,
      randomizeGroupColors: boolean,
    } | null,
    learningPhase:  {
      __typename: "PresentationPhase",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      questionsSet:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
      questionOffsetTimeout?: number | null,
    },
    testPhase:  {
      __typename: "PresentationPhase",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      questionsSet:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
      questionOffsetTimeout?: number | null,
    },
    distractionPhase?:  {
      __typename: "DistractionPhase",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      questionsSet:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
    } | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteWSWTestSubscriptionVariables = {
  filter?: ModelSubscriptionWSWTestFilterInput | null,
  owner?: string | null,
};

export type OnDeleteWSWTestSubscription = {
  onDeleteWSWTest?:  {
    __typename: "WSWTest",
    id: string,
    name: string,
    language?: string | null,
    description: string,
    instructions:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    },
    style:  {
      __typename: "TestStyle",
      maxWidth: number,
      backgroundColour?: string | null,
      stimulusTextStyle:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      },
      mobileStimulusTextStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
      optionsPosition: OptionsPosition,
      optionsPositionMobile?: OptionsPosition | null,
    },
    status: TestStatus,
    endScreen?:  {
      __typename: "StyleableTextTemplate",
      desktop: string,
      mobile?: string | null,
      fontStyle?:  {
        __typename: "FontStyle",
        fontSize: number,
        color: string,
        alignment: TextAlignment,
        fontFace: FontFace,
      } | null,
    } | null,
    attributeCombinations:  Array< {
      __typename: "AttributeCombination",
      mainCategory: number,
      additionalCategory?: number | null,
      criteriaCategory?: number | null,
      amount: number,
      multiplicator: number,
    } >,
    mainAttribute:  {
      __typename: "WSWAttribute",
      stimuliVariant?: WswStimulusAttributeVariant | null,
      type: WSWAttributeType,
      pickingStrategy: PickingStrategy,
      categories?:  Array< {
        __typename: "WSWAttributeCategory",
        name: string,
        mediaSnaps:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } > | null,
    },
    criteriaAttribute:  {
      __typename: "WSWAttribute",
      stimuliVariant?: WswStimulusAttributeVariant | null,
      type: WSWAttributeType,
      pickingStrategy: PickingStrategy,
      categories?:  Array< {
        __typename: "WSWAttributeCategory",
        name: string,
        mediaSnaps:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } > | null,
    },
    additionalAttribute?:  {
      __typename: "WSWAttribute",
      stimuliVariant?: WswStimulusAttributeVariant | null,
      type: WSWAttributeType,
      pickingStrategy: PickingStrategy,
      categories?:  Array< {
        __typename: "WSWAttributeCategory",
        name: string,
        mediaSnaps:  Array< {
          __typename: "MediaItemSnapshot",
          id: string,
          tags:  Array< {
            __typename: "TagSnapShot",
            id: string,
            name: string,
          } >,
          scope: MediaItemSnapshotScope,
          text?: string | null,
          image?:  {
            __typename: "Image",
            fullSize:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
            thumbNail:  {
              __typename: "ImageInstance",
              width: number,
              height: number,
              key: string,
            },
          } | null,
          originalFileName?: string | null,
        } >,
      } > | null,
    } | null,
    groupConstruction?:  {
      __typename: "GroupConstruction",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      fakeQuestions:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
      conditions:  {
        __typename: "GroupConstructionConditions",
        condition1: number,
        condition2: number,
        condition3: number,
      },
      groups:  Array< {
        __typename: "WSWGroup",
        name: string,
        color: string,
        questionSet?:  {
          __typename: "ConditionalQuestionSet",
          randomizeSequence: boolean,
          conditionalQuestion?:  {
            __typename: "ConditionalQuestion",
            questionText: string,
            confirmText: string,
            declineText: string,
            answerType: ConditionalAnswerType,
          } | null,
          questionsAfter:  Array< {
            __typename: "TestQuestion",
            noAnswer?: string | null,
            type: QuestionType,
            questionText: string,
            multiple?:  {
              __typename: "MultipleChoiceQuestion",
              randomized: boolean,
              options: Array< string >,
            } | null,
            scale?:  {
              __typename: "ScaleQuestion",
              invert: boolean,
              min:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
              max:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
            } | null,
            mediaItemSnapshot?:  {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } | null,
            imageMultipleChoice?:  {
              __typename: "ImageMultipleChoiceQuestion",
              randomized: boolean,
              options:  Array< {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } >,
            } | null,
            stimulusTimeout?: number | null,
          } >,
          questionsBefore:  Array< {
            __typename: "TestQuestion",
            noAnswer?: string | null,
            type: QuestionType,
            questionText: string,
            multiple?:  {
              __typename: "MultipleChoiceQuestion",
              randomized: boolean,
              options: Array< string >,
            } | null,
            scale?:  {
              __typename: "ScaleQuestion",
              invert: boolean,
              min:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
              max:  {
                __typename: "Boundary",
                name: string,
                value: number,
              },
            } | null,
            mediaItemSnapshot?:  {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } | null,
            imageMultipleChoice?:  {
              __typename: "ImageMultipleChoiceQuestion",
              randomized: boolean,
              options:  Array< {
                __typename: "MediaItemSnapshot",
                id: string,
                tags:  Array< {
                  __typename: "TagSnapShot",
                  id: string,
                  name: string,
                } >,
                scope: MediaItemSnapshotScope,
                text?: string | null,
                image?:  {
                  __typename: "Image",
                  fullSize:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                  thumbNail:  {
                    __typename: "ImageInstance",
                    width: number,
                    height: number,
                    key: string,
                  },
                } | null,
                originalFileName?: string | null,
              } >,
            } | null,
            stimulusTimeout?: number | null,
          } >,
        } | null,
        probability: number,
        feedbackScreen?:  {
          __typename: "StyleableTextTemplate",
          desktop: string,
          mobile?: string | null,
          fontStyle?:  {
            __typename: "FontStyle",
            fontSize: number,
            color: string,
            alignment: TextAlignment,
            fontFace: FontFace,
          } | null,
        } | null,
      } >,
      randomizeGroupColors: boolean,
    } | null,
    learningPhase:  {
      __typename: "PresentationPhase",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      questionsSet:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
      questionOffsetTimeout?: number | null,
    },
    testPhase:  {
      __typename: "PresentationPhase",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      questionsSet:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
      questionOffsetTimeout?: number | null,
    },
    distractionPhase?:  {
      __typename: "DistractionPhase",
      instructionsScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      feedbackScreen?:  {
        __typename: "StyleableTextTemplate",
        desktop: string,
        mobile?: string | null,
        fontStyle?:  {
          __typename: "FontStyle",
          fontSize: number,
          color: string,
          alignment: TextAlignment,
          fontFace: FontFace,
        } | null,
      } | null,
      questionsSet:  {
        __typename: "ConditionalQuestionSet",
        randomizeSequence: boolean,
        conditionalQuestion?:  {
          __typename: "ConditionalQuestion",
          questionText: string,
          confirmText: string,
          declineText: string,
          answerType: ConditionalAnswerType,
        } | null,
        questionsAfter:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
        questionsBefore:  Array< {
          __typename: "TestQuestion",
          noAnswer?: string | null,
          type: QuestionType,
          questionText: string,
          multiple?:  {
            __typename: "MultipleChoiceQuestion",
            randomized: boolean,
            options: Array< string >,
          } | null,
          scale?:  {
            __typename: "ScaleQuestion",
            invert: boolean,
            min:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
            max:  {
              __typename: "Boundary",
              name: string,
              value: number,
            },
          } | null,
          mediaItemSnapshot?:  {
            __typename: "MediaItemSnapshot",
            id: string,
            tags:  Array< {
              __typename: "TagSnapShot",
              id: string,
              name: string,
            } >,
            scope: MediaItemSnapshotScope,
            text?: string | null,
            image?:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            } | null,
            originalFileName?: string | null,
          } | null,
          imageMultipleChoice?:  {
            __typename: "ImageMultipleChoiceQuestion",
            randomized: boolean,
            options:  Array< {
              __typename: "MediaItemSnapshot",
              id: string,
              tags:  Array< {
                __typename: "TagSnapShot",
                id: string,
                name: string,
              } >,
              scope: MediaItemSnapshotScope,
              text?: string | null,
              image?:  {
                __typename: "Image",
                fullSize:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
                thumbNail:  {
                  __typename: "ImageInstance",
                  width: number,
                  height: number,
                  key: string,
                },
              } | null,
              originalFileName?: string | null,
            } >,
          } | null,
          stimulusTimeout?: number | null,
        } >,
      },
    } | null,
    startLabel: string,
    nextLabel: string,
    finishLabel: string,
    owner?: string | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    resultViewers?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateWswResultSubscriptionVariables = {
  filter?: ModelSubscriptionWswResultFilterInput | null,
  owner?: string | null,
};

export type OnCreateWswResultSubscription = {
  onCreateWswResult?:  {
    __typename: "WswResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    phase: WswTestPhase,
    position: number,
    trialIndex: number,
    groupIndication?:  {
      __typename: "WswGroupIndicationResult",
      groupIndicatorShow: boolean,
      groupIndicationCategory: string,
      groupIndicationIndex: number,
      groupIndicationColor: string,
    } | null,
    questionType: WswQuestionType,
    questionAnswer: string,
    questionImageAnswerOriginalFileName?: string | null,
    noAnswer: boolean,
    answerOptionIndex?: number | null,
    questionPosition: number,
    questionPoolName: string,
    questionSnapshot: string,
    contentType: WswContentType,
    resultStimulus?:  {
      __typename: "WswResultStimulus",
      stimulusType: StimulusType,
      stimulusId?: string | null,
      stimulusTextValue?: string | null,
      stimulusTags?: Array< string > | null,
      stimulusOriginalFilename?: string | null,
    } | null,
    attributeCombination?:  {
      __typename: "WswAttributeCombinationResult",
      isLearningStimulus: boolean,
      mainStimulus:  {
        __typename: "CombinationStimulusItem",
        stimulusType: WswStimulusType,
        stimulusPoolCategory: string,
        stimulusPoolIndex: number,
        stimulusPoolPosition: number,
        stimulusOriginalFilename?: string | null,
        type?: StimulusType | null,
        stimulusId?: string | null,
        stimulusTextValue?: string | null,
        stimulusTags?: Array< string > | null,
      },
      criteriaStimulus?:  {
        __typename: "CombinationStimulusItem",
        stimulusType: WswStimulusType,
        stimulusPoolCategory: string,
        stimulusPoolIndex: number,
        stimulusPoolPosition: number,
        stimulusOriginalFilename?: string | null,
        type?: StimulusType | null,
        stimulusId?: string | null,
        stimulusTextValue?: string | null,
        stimulusTags?: Array< string > | null,
      } | null,
      additionalStimulus?:  {
        __typename: "CombinationStimulusItem",
        stimulusType: WswStimulusType,
        stimulusPoolCategory: string,
        stimulusPoolIndex: number,
        stimulusPoolPosition: number,
        stimulusOriginalFilename?: string | null,
        type?: StimulusType | null,
        stimulusId?: string | null,
        stimulusTextValue?: string | null,
        stimulusTags?: Array< string > | null,
      } | null,
    } | null,
    owner?: string | null,
    groupCondition?: number | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateWswResultSubscriptionVariables = {
  filter?: ModelSubscriptionWswResultFilterInput | null,
  owner?: string | null,
};

export type OnUpdateWswResultSubscription = {
  onUpdateWswResult?:  {
    __typename: "WswResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    phase: WswTestPhase,
    position: number,
    trialIndex: number,
    groupIndication?:  {
      __typename: "WswGroupIndicationResult",
      groupIndicatorShow: boolean,
      groupIndicationCategory: string,
      groupIndicationIndex: number,
      groupIndicationColor: string,
    } | null,
    questionType: WswQuestionType,
    questionAnswer: string,
    questionImageAnswerOriginalFileName?: string | null,
    noAnswer: boolean,
    answerOptionIndex?: number | null,
    questionPosition: number,
    questionPoolName: string,
    questionSnapshot: string,
    contentType: WswContentType,
    resultStimulus?:  {
      __typename: "WswResultStimulus",
      stimulusType: StimulusType,
      stimulusId?: string | null,
      stimulusTextValue?: string | null,
      stimulusTags?: Array< string > | null,
      stimulusOriginalFilename?: string | null,
    } | null,
    attributeCombination?:  {
      __typename: "WswAttributeCombinationResult",
      isLearningStimulus: boolean,
      mainStimulus:  {
        __typename: "CombinationStimulusItem",
        stimulusType: WswStimulusType,
        stimulusPoolCategory: string,
        stimulusPoolIndex: number,
        stimulusPoolPosition: number,
        stimulusOriginalFilename?: string | null,
        type?: StimulusType | null,
        stimulusId?: string | null,
        stimulusTextValue?: string | null,
        stimulusTags?: Array< string > | null,
      },
      criteriaStimulus?:  {
        __typename: "CombinationStimulusItem",
        stimulusType: WswStimulusType,
        stimulusPoolCategory: string,
        stimulusPoolIndex: number,
        stimulusPoolPosition: number,
        stimulusOriginalFilename?: string | null,
        type?: StimulusType | null,
        stimulusId?: string | null,
        stimulusTextValue?: string | null,
        stimulusTags?: Array< string > | null,
      } | null,
      additionalStimulus?:  {
        __typename: "CombinationStimulusItem",
        stimulusType: WswStimulusType,
        stimulusPoolCategory: string,
        stimulusPoolIndex: number,
        stimulusPoolPosition: number,
        stimulusOriginalFilename?: string | null,
        type?: StimulusType | null,
        stimulusId?: string | null,
        stimulusTextValue?: string | null,
        stimulusTags?: Array< string > | null,
      } | null,
    } | null,
    owner?: string | null,
    groupCondition?: number | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteWswResultSubscriptionVariables = {
  filter?: ModelSubscriptionWswResultFilterInput | null,
  owner?: string | null,
};

export type OnDeleteWswResultSubscription = {
  onDeleteWswResult?:  {
    __typename: "WswResult",
    id: string,
    testId: string,
    clientId: string,
    survey:  {
      __typename: "ResultSurveyIdentifier",
      probandId: string,
      surveyId?: string | null,
      surveyProbandId?: string | null,
      testProviderName: string,
    },
    timing:  {
      __typename: "ResultTiming",
      renderStartDelay?: number | null,
      renderEndDelay?: number | null,
      startTriggerDelay: number,
      endTriggerDelay?: number | null,
      controlRaceDelay?: number | null,
    },
    device:  {
      __typename: "ProbandDevice",
      ua: string,
      browser?:  {
        __typename: "BrowserInfo",
        name?: string | null,
        version?: string | null,
        major?: string | null,
      } | null,
      device?:  {
        __typename: "DeviceInfo",
        model?: string | null,
        type?: string | null,
        vendor?: string | null,
      } | null,
      engine?:  {
        __typename: "EngineInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      os?:  {
        __typename: "OsInfo",
        name?: string | null,
        version?: string | null,
      } | null,
      cpu?:  {
        __typename: "CpuInfo",
        architecture?: string | null,
      } | null,
    },
    phase: WswTestPhase,
    position: number,
    trialIndex: number,
    groupIndication?:  {
      __typename: "WswGroupIndicationResult",
      groupIndicatorShow: boolean,
      groupIndicationCategory: string,
      groupIndicationIndex: number,
      groupIndicationColor: string,
    } | null,
    questionType: WswQuestionType,
    questionAnswer: string,
    questionImageAnswerOriginalFileName?: string | null,
    noAnswer: boolean,
    answerOptionIndex?: number | null,
    questionPosition: number,
    questionPoolName: string,
    questionSnapshot: string,
    contentType: WswContentType,
    resultStimulus?:  {
      __typename: "WswResultStimulus",
      stimulusType: StimulusType,
      stimulusId?: string | null,
      stimulusTextValue?: string | null,
      stimulusTags?: Array< string > | null,
      stimulusOriginalFilename?: string | null,
    } | null,
    attributeCombination?:  {
      __typename: "WswAttributeCombinationResult",
      isLearningStimulus: boolean,
      mainStimulus:  {
        __typename: "CombinationStimulusItem",
        stimulusType: WswStimulusType,
        stimulusPoolCategory: string,
        stimulusPoolIndex: number,
        stimulusPoolPosition: number,
        stimulusOriginalFilename?: string | null,
        type?: StimulusType | null,
        stimulusId?: string | null,
        stimulusTextValue?: string | null,
        stimulusTags?: Array< string > | null,
      },
      criteriaStimulus?:  {
        __typename: "CombinationStimulusItem",
        stimulusType: WswStimulusType,
        stimulusPoolCategory: string,
        stimulusPoolIndex: number,
        stimulusPoolPosition: number,
        stimulusOriginalFilename?: string | null,
        type?: StimulusType | null,
        stimulusId?: string | null,
        stimulusTextValue?: string | null,
        stimulusTags?: Array< string > | null,
      } | null,
      additionalStimulus?:  {
        __typename: "CombinationStimulusItem",
        stimulusType: WswStimulusType,
        stimulusPoolCategory: string,
        stimulusPoolIndex: number,
        stimulusPoolPosition: number,
        stimulusOriginalFilename?: string | null,
        type?: StimulusType | null,
        stimulusId?: string | null,
        stimulusTextValue?: string | null,
        stimulusTags?: Array< string > | null,
      } | null,
    } | null,
    owner?: string | null,
    groupCondition?: number | null,
    status?: TestStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSurveyProviderSubscriptionVariables = {
  filter?: ModelSubscriptionSurveyProviderFilterInput | null,
  owner?: string | null,
};

export type OnCreateSurveyProviderSubscription = {
  onCreateSurveyProvider?:  {
    __typename: "SurveyProvider",
    id: string,
    slug: string,
    name: string,
    description: string,
    snippetConfiguration:  {
      __typename: "SurveyProviderSnippetConfiguration",
      snippetType: SurveyProviderSnippetType,
      surveyIdReplacer?: string | null,
      probandIdReplacer: string,
    },
    setupDocumentation:  {
      __typename: "SurveyProviderSetupDocumentation",
      steps:  Array< {
        __typename: "SurveyProviderSetupDocumentationStep",
        type: SetupDocumentationStepType,
        textBeforeContent?: string | null,
        textAfterContent?: string | null,
        image?:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        } | null,
      } >,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateSurveyProviderSubscriptionVariables = {
  filter?: ModelSubscriptionSurveyProviderFilterInput | null,
  owner?: string | null,
};

export type OnUpdateSurveyProviderSubscription = {
  onUpdateSurveyProvider?:  {
    __typename: "SurveyProvider",
    id: string,
    slug: string,
    name: string,
    description: string,
    snippetConfiguration:  {
      __typename: "SurveyProviderSnippetConfiguration",
      snippetType: SurveyProviderSnippetType,
      surveyIdReplacer?: string | null,
      probandIdReplacer: string,
    },
    setupDocumentation:  {
      __typename: "SurveyProviderSetupDocumentation",
      steps:  Array< {
        __typename: "SurveyProviderSetupDocumentationStep",
        type: SetupDocumentationStepType,
        textBeforeContent?: string | null,
        textAfterContent?: string | null,
        image?:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        } | null,
      } >,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteSurveyProviderSubscriptionVariables = {
  filter?: ModelSubscriptionSurveyProviderFilterInput | null,
  owner?: string | null,
};

export type OnDeleteSurveyProviderSubscription = {
  onDeleteSurveyProvider?:  {
    __typename: "SurveyProvider",
    id: string,
    slug: string,
    name: string,
    description: string,
    snippetConfiguration:  {
      __typename: "SurveyProviderSnippetConfiguration",
      snippetType: SurveyProviderSnippetType,
      surveyIdReplacer?: string | null,
      probandIdReplacer: string,
    },
    setupDocumentation:  {
      __typename: "SurveyProviderSetupDocumentation",
      steps:  Array< {
        __typename: "SurveyProviderSetupDocumentationStep",
        type: SetupDocumentationStepType,
        textBeforeContent?: string | null,
        textAfterContent?: string | null,
        image?:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        } | null,
      } >,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateUserRegistrationSubscriptionVariables = {
  filter?: ModelSubscriptionUserRegistrationFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserRegistrationSubscription = {
  onCreateUserRegistration?:  {
    __typename: "UserRegistration",
    id: string,
    name: string,
    email: string,
    institution: string,
    researchGoal: string,
    additionalInformation?: string | null,
    confirmedUsagePolicy: boolean,
    status: UserRegistrationStatus,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateUserRegistrationSubscriptionVariables = {
  filter?: ModelSubscriptionUserRegistrationFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserRegistrationSubscription = {
  onUpdateUserRegistration?:  {
    __typename: "UserRegistration",
    id: string,
    name: string,
    email: string,
    institution: string,
    researchGoal: string,
    additionalInformation?: string | null,
    confirmedUsagePolicy: boolean,
    status: UserRegistrationStatus,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteUserRegistrationSubscriptionVariables = {
  filter?: ModelSubscriptionUserRegistrationFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserRegistrationSubscription = {
  onDeleteUserRegistration?:  {
    __typename: "UserRegistration",
    id: string,
    name: string,
    email: string,
    institution: string,
    researchGoal: string,
    additionalInformation?: string | null,
    confirmedUsagePolicy: boolean,
    status: UserRegistrationStatus,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateBroadcastMailSubscriptionVariables = {
  filter?: ModelSubscriptionBroadcastMailFilterInput | null,
};

export type OnCreateBroadcastMailSubscription = {
  onCreateBroadcastMail?:  {
    __typename: "BroadcastMail",
    id: string,
    subject: string,
    sender?: string | null,
    content: string,
    status: BroadcastMailStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBroadcastMailSubscriptionVariables = {
  filter?: ModelSubscriptionBroadcastMailFilterInput | null,
};

export type OnUpdateBroadcastMailSubscription = {
  onUpdateBroadcastMail?:  {
    __typename: "BroadcastMail",
    id: string,
    subject: string,
    sender?: string | null,
    content: string,
    status: BroadcastMailStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBroadcastMailSubscriptionVariables = {
  filter?: ModelSubscriptionBroadcastMailFilterInput | null,
};

export type OnDeleteBroadcastMailSubscription = {
  onDeleteBroadcastMail?:  {
    __typename: "BroadcastMail",
    id: string,
    subject: string,
    sender?: string | null,
    content: string,
    status: BroadcastMailStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMediaItemTagsSubscriptionVariables = {
  filter?: ModelSubscriptionMediaItemTagsFilterInput | null,
};

export type OnCreateMediaItemTagsSubscription = {
  onCreateMediaItemTags?:  {
    __typename: "MediaItemTags",
    id: string,
    tagId: string,
    mediaItemId: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string,
      mediaItems?:  {
        __typename: "ModelMediaItemTagsConnection",
        items:  Array< {
          __typename: "MediaItemTags",
          id: string,
          tagId: string,
          mediaItemId: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            mediaItems?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          mediaItem:  {
            __typename: "MediaItem",
            id: string,
            image:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            },
            source?: string | null,
            originalFileName: string,
            tags?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            tagsString?: string | null,
            tagsIds: Array< string >,
            createdAt: string,
            updatedAt: string,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    mediaItem:  {
      __typename: "MediaItem",
      id: string,
      image:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      },
      source?: string | null,
      originalFileName: string,
      tags?:  {
        __typename: "ModelMediaItemTagsConnection",
        items:  Array< {
          __typename: "MediaItemTags",
          id: string,
          tagId: string,
          mediaItemId: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            mediaItems?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          mediaItem:  {
            __typename: "MediaItem",
            id: string,
            image:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            },
            source?: string | null,
            originalFileName: string,
            tags?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            tagsString?: string | null,
            tagsIds: Array< string >,
            createdAt: string,
            updatedAt: string,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tagsString?: string | null,
      tagsIds: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMediaItemTagsSubscriptionVariables = {
  filter?: ModelSubscriptionMediaItemTagsFilterInput | null,
};

export type OnUpdateMediaItemTagsSubscription = {
  onUpdateMediaItemTags?:  {
    __typename: "MediaItemTags",
    id: string,
    tagId: string,
    mediaItemId: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string,
      mediaItems?:  {
        __typename: "ModelMediaItemTagsConnection",
        items:  Array< {
          __typename: "MediaItemTags",
          id: string,
          tagId: string,
          mediaItemId: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            mediaItems?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          mediaItem:  {
            __typename: "MediaItem",
            id: string,
            image:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            },
            source?: string | null,
            originalFileName: string,
            tags?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            tagsString?: string | null,
            tagsIds: Array< string >,
            createdAt: string,
            updatedAt: string,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    mediaItem:  {
      __typename: "MediaItem",
      id: string,
      image:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      },
      source?: string | null,
      originalFileName: string,
      tags?:  {
        __typename: "ModelMediaItemTagsConnection",
        items:  Array< {
          __typename: "MediaItemTags",
          id: string,
          tagId: string,
          mediaItemId: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            mediaItems?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          mediaItem:  {
            __typename: "MediaItem",
            id: string,
            image:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            },
            source?: string | null,
            originalFileName: string,
            tags?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            tagsString?: string | null,
            tagsIds: Array< string >,
            createdAt: string,
            updatedAt: string,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tagsString?: string | null,
      tagsIds: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMediaItemTagsSubscriptionVariables = {
  filter?: ModelSubscriptionMediaItemTagsFilterInput | null,
};

export type OnDeleteMediaItemTagsSubscription = {
  onDeleteMediaItemTags?:  {
    __typename: "MediaItemTags",
    id: string,
    tagId: string,
    mediaItemId: string,
    tag:  {
      __typename: "Tag",
      id: string,
      name: string,
      mediaItems?:  {
        __typename: "ModelMediaItemTagsConnection",
        items:  Array< {
          __typename: "MediaItemTags",
          id: string,
          tagId: string,
          mediaItemId: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            mediaItems?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          mediaItem:  {
            __typename: "MediaItem",
            id: string,
            image:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            },
            source?: string | null,
            originalFileName: string,
            tags?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            tagsString?: string | null,
            tagsIds: Array< string >,
            createdAt: string,
            updatedAt: string,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    mediaItem:  {
      __typename: "MediaItem",
      id: string,
      image:  {
        __typename: "Image",
        fullSize:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
        thumbNail:  {
          __typename: "ImageInstance",
          width: number,
          height: number,
          key: string,
        },
      },
      source?: string | null,
      originalFileName: string,
      tags?:  {
        __typename: "ModelMediaItemTagsConnection",
        items:  Array< {
          __typename: "MediaItemTags",
          id: string,
          tagId: string,
          mediaItemId: string,
          tag:  {
            __typename: "Tag",
            id: string,
            name: string,
            mediaItems?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          mediaItem:  {
            __typename: "MediaItem",
            id: string,
            image:  {
              __typename: "Image",
              fullSize:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
              thumbNail:  {
                __typename: "ImageInstance",
                width: number,
                height: number,
                key: string,
              },
            },
            source?: string | null,
            originalFileName: string,
            tags?:  {
              __typename: "ModelMediaItemTagsConnection",
              items:  Array< {
                __typename: "MediaItemTags",
                id: string,
                tagId: string,
                mediaItemId: string,
                tag:  {
                  __typename: "Tag",
                  id: string,
                  name: string,
                  mediaItems?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  createdAt: string,
                  updatedAt: string,
                },
                mediaItem:  {
                  __typename: "MediaItem",
                  id: string,
                  image:  {
                    __typename: "Image",
                    fullSize:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                    thumbNail:  {
                      __typename: "ImageInstance",
                      width: number,
                      height: number,
                      key: string,
                    },
                  },
                  source?: string | null,
                  originalFileName: string,
                  tags?:  {
                    __typename: "ModelMediaItemTagsConnection",
                    items:  Array< {
                      __typename: "MediaItemTags",
                      id: string,
                      tagId: string,
                      mediaItemId: string,
                      tag:  {
                        __typename: "Tag",
                        id: string,
                        name: string,
                        mediaItems?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        createdAt: string,
                        updatedAt: string,
                      },
                      mediaItem:  {
                        __typename: "MediaItem",
                        id: string,
                        image:  {
                          __typename: "Image",
                          fullSize:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                          thumbNail:  {
                            __typename: "ImageInstance",
                            width: number,
                            height: number,
                            key: string,
                          },
                        },
                        source?: string | null,
                        originalFileName: string,
                        tags?:  {
                          __typename: "ModelMediaItemTagsConnection",
                          items:  Array< {
                            __typename: "MediaItemTags",
                            id: string,
                            tagId: string,
                            mediaItemId: string,
                            tag:  {
                              __typename: "Tag",
                              id: string,
                              name: string,
                              mediaItems?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              createdAt: string,
                              updatedAt: string,
                            },
                            mediaItem:  {
                              __typename: "MediaItem",
                              id: string,
                              image:  {
                                __typename: "Image",
                                fullSize:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                                thumbNail:  {
                                  __typename: "ImageInstance",
                                  width: number,
                                  height: number,
                                  key: string,
                                },
                              },
                              source?: string | null,
                              originalFileName: string,
                              tags?:  {
                                __typename: "ModelMediaItemTagsConnection",
                                items:  Array< {
                                  __typename: "MediaItemTags",
                                  id: string,
                                  tagId: string,
                                  mediaItemId: string,
                                  createdAt: string,
                                  updatedAt: string,
                                } | null >,
                                nextToken?: string | null,
                              } | null,
                              tagsString?: string | null,
                              tagsIds: Array< string >,
                              createdAt: string,
                              updatedAt: string,
                            },
                            createdAt: string,
                            updatedAt: string,
                          } | null >,
                          nextToken?: string | null,
                        } | null,
                        tagsString?: string | null,
                        tagsIds: Array< string >,
                        createdAt: string,
                        updatedAt: string,
                      },
                      createdAt: string,
                      updatedAt: string,
                    } | null >,
                    nextToken?: string | null,
                  } | null,
                  tagsString?: string | null,
                  tagsIds: Array< string >,
                  createdAt: string,
                  updatedAt: string,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            tagsString?: string | null,
            tagsIds: Array< string >,
            createdAt: string,
            updatedAt: string,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tagsString?: string | null,
      tagsIds: Array< string >,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};
