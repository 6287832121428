import React, { FC } from 'react';
import { Iat } from '../../types';
import { Box, Stack } from '@mui/material';
import MediaItemComponent from '../../../../media/MediaItemComponent';
import MediaItemList from '../../../../media/MediaItemList';
import { TextDisplay } from '../../../../../components/TextDisplay';

export const IatStimuliSettingsView: FC<{ test: Iat }> = ({ test }) => {
  return (
    <Stack spacing={2}>
      <TextDisplay
        label="Probability Without Replacement"
        value={test.probabilityWithoutReplacement ? 'Yes' : 'No'}
      />
      <TextDisplay
        label="Inter Trial Interval"
        value={test.interTrialInterval}
        suffix="ms"
      />
      <Stack direction="row" spacing={3}>
        <Box width={240}>
          <MediaItemComponent
            label="Target Category A Label"
            mediaItem={test.targetCategories.categoryA.label}
            color={test.targetCategories.fontStyle.color}
            bgColor={test.style.backgroundColour}
          />
        </Box>
        <MediaItemList
          items={test.targetCategories.categoryA.testStimuliPool}
          datatype={'image'}
          bgColor={test.style.backgroundColour ?? undefined}
          fontColor={test.targetCategories.fontStyle.color}
        />
      </Stack>
      <Stack direction="row" spacing={3}>
        <Box width={240}>
          <MediaItemComponent
            label="Target Category B Label"
            mediaItem={test.targetCategories.categoryB.label}
            color={test.targetCategories.fontStyle.color}
            bgColor={test.style.backgroundColour}
          />
        </Box>
        <MediaItemList
          items={test.targetCategories.categoryB.testStimuliPool}
          datatype={'image'}
          bgColor={test.style.backgroundColour ?? undefined}
          fontColor={test.targetCategories.fontStyle.color}
        />
      </Stack>
      <Stack direction="row" spacing={3}>
        <Box width={240}>
          <MediaItemComponent
            label="Attribute Category A Label"
            mediaItem={test.attributeCategories.categoryA.label}
            color={test.attributeCategories.fontStyle.color}
            bgColor={test.style.backgroundColour}
          />
        </Box>
        <MediaItemList
          items={test.attributeCategories.categoryA.testStimuliPool}
          datatype={'image'}
          bgColor={test.style.backgroundColour ?? undefined}
          fontColor={test.attributeCategories.fontStyle.color}
        />
      </Stack>
      <Stack direction="row" spacing={3}>
        <Box width={240}>
          <MediaItemComponent
            label="Attribute Category B Label"
            mediaItem={test.attributeCategories.categoryB.label}
            color={test.attributeCategories.fontStyle.color}
            bgColor={test.style.backgroundColour}
          />
        </Box>
        <MediaItemList
          items={test.attributeCategories.categoryB.testStimuliPool}
          datatype={'image'}
          bgColor={test.style.backgroundColour ?? undefined}
          fontColor={test.attributeCategories.fontStyle.color}
        />
      </Stack>
    </Stack>
  );
};
