export enum TestType {
  IAT = 'IAT',
  AMP = 'AMP',
  PODT = 'PODT',
  PODTA = 'PODTA',
  WSW = 'WSW',
  Example = 'Example',
}

export interface TestMetaData {
  shortName: string;
  longName: string;
  identifier: string;
  visible: boolean;
}

export const TestsMetaData: Record<TestType, TestMetaData> = {
  AMP: {
    shortName: 'AMP',
    longName: 'Affect Misattribution Procedure',
    identifier: 'AMP',
    visible: true,
  },
  IAT: {
    shortName: 'IAT',
    longName: 'Implicit Association Test',
    identifier: 'IAT',
    visible: true,
  },
  PODT: {
    shortName: 'ST',
    longName: 'First-Person Shooter Task',
    identifier: 'PODT',
    visible: true,
  },
  PODTA: {
    shortName: 'AT',
    longName: 'Avoidance Task',
    identifier: 'PODTA',
    visible: true,
  },
  WSW: {
    shortName: 'SMP',
    longName: 'Source Monitoring Paradigm',
    identifier: 'SMP',
    visible: true,
  },
  [TestType.Example]: {
    shortName: 'Example',
    longName: 'Example',
    identifier: 'Example',
    visible: false,
  },
};

export function getTestTypeByIdentifier(
  identifier: string,
): TestType | undefined {
  return Object.entries(TestsMetaData).find(
    ([, md]) => md.identifier === identifier,
  )?.[0] as TestType | undefined;
}
