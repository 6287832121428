import React, { CSSProperties } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import MediaItemPicker from '../../../../media/picker/MediaItemPicker';
import MediaItemsPicker from '../../../../media/picker/MidiaItemsPicker/MediaItemsPicker';
import StyledNumberInput from '../../../../../components/input/StyledNumberInput';
import DuplicateButton from '../../../../../components/input/DuplicateButton';
import FixationPickerWithToggle, {
  FixationWithToggleSchema,
} from '../../../../../components/input/FixationPickerWithToggle';
import { EditorStepTitle } from '../../../../../components/grid';
import { Build, ExpandMore } from '@mui/icons-material';
import TextStyleDialog from '../../../../../components/TextStyleDialog';
import StyledTextInput from '../../../../../components/input/StyledTextInput';
import { useField } from 'formik';
import {
  AmpLabeledStimuliPoolInput,
  TimedMediaItemInput,
} from '../../../../../API';
import StyledSwitch from '../../../../../components/input/StyledSwitch';

const ampCategoriesWithIntervalSchema = Yup.object().shape({
  categories: Yup.array()
    .min(1)
    .of(
      Yup.object()
        .required()
        .shape({
          label: Yup.string().required('chose a label'),
          practiceStimuliPool: Yup.array().min(1, 'min 1'),
          testStimuliPool: Yup.array().min(1, 'min 1'),
        }),
    ),
});

export const StimuliSettingsSchema = Yup.object().shape({
  fixation: FixationWithToggleSchema,
  attributeCategories: Yup.object().shape({
    categoryA: Yup.object().test(
      'hasStimulus',
      'category must be chosen.',
      (value: any) => Boolean(value.image || value.text),
    ),
    categoryB: Yup.object().test(
      'hasStimulus',
      'category must be chosen.',
      (value: any) => Boolean(value.image || value.text),
    ),
  }),
  primes: ampCategoriesWithIntervalSchema,
  targets: ampCategoriesWithIntervalSchema,
  mask: Yup.object()
    .shape({})
    .test('hasStimulus', 'mask stimulus must be chosen.', (value: any) =>
      Boolean(value.image || value.text),
    ),
});

const AmpStimuliSettings = () => {
  const duplicateBtnContainerStyle: CSSProperties = {
    position: 'absolute',
    top: 8,
    right: 24,
  };

  const [, { value: fixation }] = useField<TimedMediaItemInput | undefined>(
    'fixation',
  );
  const [, { value: stimulusTextColor }] = useField<string | undefined>(
    'style.stimulusTextStyle.color',
  );
  const [, { value: bgColor }] = useField<string | undefined>(
    'style.backgroundColour',
  );

  const [, { value: primeCategories }] =
    useField<AmpLabeledStimuliPoolInput[]>('primes.categories');

  return (
    <Stack spacing={2}>
      <EditorStepTitle prefix={3} title="Stimuli Settings" />
      <Stack direction="row" spacing={3}>
        <StyledNumberInput
          name="interTrialInterval"
          label="Inter Trial Interval"
          unit="ms"
        />
        <StyledNumberInput
          name="blankInterval"
          label="Blank Interval"
          unit={'ms'}
        />
        <Box sx={{ width: '15em' }}>
          <StyledSwitch
            name="probabilityWithoutReplacement"
            label="Probability Without Replacement"
            left="Off"
            right="On"
            tooltip={
              // <React.Fragment>
              //   If "Probability without replacement" = on, then Stimuli are picked from pool (once) and are not
              //   replaced.<br />
              //   If "Probability without replacement" = off, then Stimuli are picked from pool and are being returned to
              //   pool after wards.
              // </React.Fragment>
              <React.Fragment>
                We advise to use randomization without replacement of selected
                units
                <br />
                <br />
                <b>ON</b> {'->'} Stimuli are picked from pool (once) and are not
                replaced.
                <br />
                <b>OFF</b> {'->'} Stimuli are picked from pool and are being
                returned to pool afterwards.
              </React.Fragment>
            }
          />
        </Box>
      </Stack>
      <Stack spacing={3} direction="row">
        <MediaItemPicker
          name="mask"
          label="Mask"
          colorFieldName={'style.stimulusTextStyle.color'}
          bgColorFieldName={'style.backgroundColour'}
        />
        <TextStyleDialog
          label={'Stimuli Text Style'}
          name={'style.stimulusTextStyle'}
        >
          <Build />
        </TextStyleDialog>
      </Stack>
      <FixationPickerWithToggle
        fixationOn={Boolean(fixation)}
        stylesEditable={false}
      />
      <StyledTextInput
        name="targets.categories[0].label"
        label="Target Label"
        sx={{ mt: 2, width: 340 }}
      />
      <StyledNumberInput
        name="targets.interval"
        label="Target Interval"
        sx={{ width: 340 }}
        unit="ms"
      />
      <MediaItemsPicker
        name="targets.categories[0].practiceStimuliPool"
        label="Targets Practice Stimuli"
        color={stimulusTextColor}
        bgColor={bgColor}
      />
      <DuplicateButton
        label="Duplicate Practice- to Test-Pool"
        // tooltip={t("amp_editor_tooltip_button_duplicate_stimuli")}
        from="targets.categories[0].practiceStimuliPool"
        to="targets.categories[0].testStimuliPool"
      />
      <MediaItemsPicker
        name="targets.categories[0].testStimuliPool"
        label="Targets Test Stimuli"
        color={stimulusTextColor}
        bgColor={bgColor}
      />
      <StyledNumberInput
        InputProps={{ endAdornment: ' ms' }}
        name="primes.interval"
        label="Primes Interval"
        style={{ width: 250 }}
      />
      <Box>
        {primeCategories.map((category, i) => (
          <Box key={`category${i}`}>
            <Accordion
              key={`category${i}`}
              defaultExpanded
              // color={error && error.length > 0 ? "red": "white"}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography color="textSecondary">{`Prime ${
                    i + 1
                  }`}</Typography>
                  <Typography variant="h5">{category.label}</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={8} style={{ position: 'relative' }}>
                    <MediaItemsPicker
                      name={`primes.categories[${i}].practiceStimuliPool`}
                      label="Primes Practice Stimuli"
                      color={stimulusTextColor}
                      bgColor={bgColor}
                    />
                    <div style={duplicateBtnContainerStyle}>
                      <DuplicateButton
                        label="Duplicate Practice- to Test-Pool"
                        // tooltip={t("amp_editor_tooltip_button_duplicate_stimuli")}
                        from={`primes.categories[${i}].practiceStimuliPool`}
                        to={`primes.categories[${i}].testStimuliPool`}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <MediaItemsPicker
                      name={`primes.categories[${i}].testStimuliPool`}
                      label="Primes Test Stimuli"
                      color={stimulusTextColor}
                      bgColor={bgColor}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </Box>
    </Stack>
  );
};

export default AmpStimuliSettings;
