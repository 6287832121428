import { TrialPreviewTree } from './tree-walkers';
import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Folder, FolderOpen } from '@mui/icons-material';
import { BlockTreeTrialList } from './BlockTreeTrialList';
import { TestType } from '../../../../types';

export function BlockList({
  block,
  testType,
}: {
  block: TrialPreviewTree['blocks'][number];
  testType: TestType;
}) {
  const [expanded, setExpanded] = React.useState(true);
  return (
    <>
      <ListItem sx={{ marginLeft: '2em' }} key={block.name}>
        <ListItemIcon onClick={() => setExpanded(!expanded)}>
          {expanded ? <FolderOpen /> : <Folder />}
        </ListItemIcon>
        <ListItemText
          sx={{ fontSize: '1.5em' }}
          primaryTypographyProps={{
            sx: { fontSize: '1.2em', fontWeight: 'bold' },
          }}
          secondaryTypographyProps={{
            sx: { fontSize: '0.8em', fontWeight: '500' },
          }}
          primary={block.name}
          secondary={
            <>
              {`Block Type: ${block.blockInfo.blockType}`}
              <br />
              {`# Trials: ${block.blockInfo.amountOfTrials}`}
              {testType === TestType.IAT &&
                `Block Categories ${(block.blockInfo.blockOptions as any).join('-')}`}
            </>
          }
        />
      </ListItem>
      <ListItem
        sx={{
          marginLeft: '5em',
          display: expanded ? undefined : 'none',
          marginBottom: '2.5em',
          paddingBottom: '2.5em',
          borderBottom: '3px solid grey',
        }}
        key={block.name + 'trials'}
        dense
      >
        <BlockTreeTrialList testType={testType} block={block} />
      </ListItem>
    </>
  );
}
