import { TreeSequence } from '../../../graph/tree-sequence/tree-sequence';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { TestType } from '../../../../types';
import { SortedTree } from '../../../graph/sorted-tree/sorted-tree';
import { InfoRounded } from '@mui/icons-material';
import { TestResourceAccessor } from '../../../media/test-resource-loader';
import { treeWalkers, TrialPreviewTree } from './tree-walkers';
import { TestInfoTable } from './TestInfoTable';
import { BlockList } from './BlockList';

export function PreviewTrialSummary({
  treeSequence,
  testType,
  testAccessor,
}: {
  treeSequence: TreeSequence;
  testType: TestType;
  testAccessor: TestResourceAccessor<any>;
}) {
  const sortedTree = React.useMemo(() => {
    return SortedTree.create(treeSequence);
  }, [treeSequence]);

  const treeWalker = treeWalkers.find((tw) => tw.testType === testType);
  const transformationResult = React.useMemo(() => {
    return treeWalker
      ? treeWalker.transformTreeSequence(treeSequence, sortedTree, testAccessor)
      : undefined;
  }, []);
  return (
    <Stack spacing={2} sx={{ padding: '1em' }}>
      <Typography variant="h6">Trial Summary</Typography>
      {!treeWalker && <Typography>Test type not supported</Typography>}
      {transformationResult && (
        <TransformedBlockTreeView
          blockTree={transformationResult}
          testType={testType}
        />
      )}
    </Stack>
  );
}

function TransformedBlockTreeView({
  blockTree,
  testType,
}: {
  blockTree: TrialPreviewTree;
  testType: TestType;
}) {
  return (
    <List>
      <ListItem key={'test info'}>
        <ListItemIcon>
          <InfoRounded />
        </ListItemIcon>
        <ListItemText
          primary={'Test Info'}
          secondary={
            <TestInfoTable blockTree={blockTree} testType={testType} />
          }
        />
      </ListItem>
      {blockTree.blocks.map((block) => (
        <>
          <BlockList testType={testType} block={block} />
        </>
      ))}
    </List>
  );
}
