import { UserRegistration, UserRegistrationStatus } from '../../API';
import {
  DataGridPro,
  getGridStringOperators,
  GridColDef,
  GridFilterInputValueProps,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { formatDate } from '../../dateUtils';
import { useGetUserRegistrationsQuery } from './registrationsApi';
import { RequireAuth } from '../../auth/RequiredAuth';
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectProps,
} from '@mui/material';
import { Cached } from '@mui/icons-material';
import React from 'react';
import { RegistrationRequestDialog } from './RegistrationRequestDialog';
import {
  UserRegistrationStatusChip,
  userRegistrationStatusMap,
} from './RegistrationStatusChip';

function UserRegistrationStatusFilterInput({
  applyValue,
  item,
}: GridFilterInputValueProps) {
  const handleFilterChange: SelectProps['onChange'] = (event) => {
    applyValue({ ...item, value: event.target.value as string });
  };

  return (
    <FormControl fullWidth variant="standard">
      <InputLabel id="status-select-label">Status</InputLabel>
      <Select
        label="Status"
        id="status-select"
        labelId="status-select-label"
        onChange={handleFilterChange}
        value={item.value}
      >
        {Object.keys(userRegistrationStatusMap).map((stateKey) => (
          <MenuItem key={`state-display-${stateKey}`} value={stateKey}>
            <UserRegistrationStatusChip
              status={stateKey as UserRegistrationStatus}
              size="small"
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const columns: GridColDef<UserRegistration>[] = [
  {
    headerName: 'Name',
    field: 'name',
  },
  {
    headerName: 'E-Mail',
    field: 'email',
    flex: 3,
  },
  {
    headerName: 'Institution',
    field: 'institution',
    flex: 3,
  },
  { headerName: 'ID', field: 'id', flex: 1 },
  {
    headerName: 'Status',
    field: 'status',
    flex: 2,
    filterOperators: getGridStringOperators()
      .filter((stringOperator) => stringOperator.value === 'equals')
      .map((stringOperator) => ({
        ...stringOperator,
        InputComponent: UserRegistrationStatusFilterInput,
      })),
    renderCell: (params) => {
      return (
        <UserRegistrationStatusChip status={params.row.status} size="small" />
      );
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    flex: 2,
    type: 'dateTime',
    renderCell: (params) => formatDate(params.value as string),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated at',
    flex: 2,
    type: 'dateTime',
    renderCell: (params) => formatDate(params.value as string),
  },
];

export const RegistrationsRequestsScreen = () => {
  const [openRequest, setOpenRequest] = React.useState<UserRegistration>();
  const {
    data: registrationRequests,
    isLoading: loading,
    refetch,
  } = useGetUserRegistrationsQuery();
  return (
    <RequireAuth>
      <Paper sx={{ minHeight: 600 }}>
        {openRequest && (
          <RegistrationRequestDialog
            request={openRequest}
            closeDialog={(reload?: boolean) => {
              setOpenRequest(undefined);
              if (reload) {
                refetch();
              }
            }}
          />
        )}
        <DataGridPro
          disableSelectionOnClick
          loading={loading}
          getRowId={(row) => row.id}
          columns={columns}
          initialState={{
            columns: {
              columnVisibilityModel: { id: false, updatedAt: false },
            },
            filter: {
              filterModel: {
                items: [
                  {
                    columnField: 'status',
                    operatorValue: 'equals',
                    value: 'PENDING',
                  },
                ],
              },
            },
          }}
          onRowClick={(params) => {
            setOpenRequest(params.row);
          }}
          rows={registrationRequests ?? []}
          components={{
            Toolbar: () => (
              <GridToolbarContainer>
                <GridToolbarFilterButton
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                  placeholder=""
                  sx={{ m: 1 }}
                />
                <GridToolbarColumnsButton
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                  placeholder=""
                />
                <GridToolbarExport
                  csvOptions={{
                    fileName: 'MINDset Registration Requests.csv',
                    utf8WithBom: true,
                  }}
                />
                <IconButton onClick={refetch} sx={{ ml: 'auto' }}>
                  <Cached />
                </IconButton>
              </GridToolbarContainer>
            ),
          }}
        />
      </Paper>
    </RequireAuth>
  );
};
