import { MediaItemSnapshotInput } from '../../../../../../API';
import { Box, Stack, Typography } from '@mui/material';
import { renderMediaItem } from '../../../../../media/picker/pickerUtils';
import React from 'react';

export function StimuliRenderer({
  mediaItemSnapshot,
  descriptor,
}: {
  mediaItemSnapshot: MediaItemSnapshotInput;
  descriptor?: string;
}) {
  const { width, height } = mediaItemSnapshot.image
    ? mediaItemSnapshot.image.fullSize!
    : { width: 120, height: 120 };
  const maxHeight = 120;
  const ratioWidth = Math.round(maxHeight * (width / height));
  return (
    <Stack spacing={1} alignItems="center">
      <Box
        sx={{
          width: `${ratioWidth}px`,
        }}
      >
        {renderMediaItem(mediaItemSnapshot, true, false, true)}
      </Box>
      {descriptor && (
        <Typography
          variant="caption"
          sx={{ fontSize: '2em', color: 'black', fontWeight: 'bold' }}
        >
          {descriptor}
        </Typography>
      )}
    </Stack>
  );
}
