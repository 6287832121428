import React from 'react';

export function PickingScrollUI() {
  return (
    <StepScroller
      steps={[
        { title: 'Step 1', content: 'Content for step 1' },
        { title: 'Step 2', content: 'Content for step 2' },
        { title: 'Step 3', content: 'Content for step 3' },
        // Add more steps as needed
      ]}
    />
  );
}
const StepScroller = ({
  steps,
}: {
  steps: { title: string; content: string }[];
}) => {
  const [currentStep, setCurrentStep] = React.useState(0);
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const { scrollTop, clientHeight } = containerRef.current;
        const newStep = Math.floor(scrollTop / clientHeight);
        if (newStep !== currentStep && newStep < steps.length) {
          setCurrentStep(newStep);
        }
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [currentStep, steps.length]);

  return (
    <div
      ref={containerRef}
      className="step-scroller"
      style={{
        height: '10vh',
        overflowY: 'auto',
        scrollSnapType: 'y mandatory',
      }}
    >
      {steps.map((step, index) => (
        <div
          key={index}
          style={{
            height: '10vh',
            scrollSnapAlign: 'start',
          }}
        >
          <Step config={step} isVisible={index === currentStep} />
        </div>
      ))}
    </div>
  );
};
const Step = ({
  config,
  isVisible,
}: {
  config: { title: string; content: string };
  isVisible: boolean;
}) => {
  return (
    <div style={{ visibility: isVisible ? 'visible' : 'hidden' }}>
      {/* Apply the configuration to your base layout here */}
      <h2>{config.title}</h2>
      <p>{config.content}</p>
    </div>
  );
};
