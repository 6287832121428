import * as React from 'react';
import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';
import { AdminAppDrawer, AdminAppDrawerProps } from './AdminAppDrawer';
import { StagingAlert } from './staging-alert';
import { TestResultsStatusContextProvider } from '../tests/testAdmin/testResults/TestResultsStatusContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

interface AdminAppNavigationProps extends AdminAppDrawerProps {}

export default function AdminAppNavigation({
  primaryPages,
  secondaryPages,
}: AdminAppNavigationProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ display: 'flex' }} component="nav">
        <AdminAppDrawer
          primaryPages={primaryPages}
          secondaryPages={secondaryPages}
        />
        <Box
          component="main"
          sx={{
            width: '100%',
            bgcolor: 'background.default',
            px: 2,
            pt: 2,
          }}
        >
          <StagingAlert absolute />
          <TestResultsStatusContextProvider>
            <Outlet />
          </TestResultsStatusContextProvider>
        </Box>
      </Box>
    </LocalizationProvider>
  );
}
