import { CanvasTextMeasurePlayground } from './canvas-text/canvas-text-measure.playground';
import { FC } from 'react';
import { TranslationTreePlayground } from './translation-tree/translation-tree.playground';
import { TestIatPickingVisualizer } from './picking/test-iat-picking-visualizer';
import { PickingScrollUI } from './picking/picking-scroll-ui';
import { TestAmpPickingVisualizer } from './picking/test-amp-picking-visualizer';

const playgrounds = {
  canvasText: CanvasTextMeasurePlayground,
  translationTree: TranslationTreePlayground,
  pickingVisualizer: TestIatPickingVisualizer,
  pickingVisualizerAmp: TestAmpPickingVisualizer,
  pickingScrollUI: PickingScrollUI,
} satisfies Record<string, FC>;

const activePlayground = process.env.REACT_APP_ACTIVE_PLAYGROUND_NAME;

export function PlaygroundApp() {
  const PlaygroundComponent =
    activePlayground && activePlayground in playgrounds
      ? playgrounds[activePlayground as keyof typeof playgrounds]
      : undefined;
  return (
    <div style={{ width: '100%', height: '100vh' }}>
      {PlaygroundComponent ? (
        <PlaygroundComponent />
      ) : (
        <>
          <h1>Playground App</h1>
          <p>
            Playground App is a place to test and experiment with different
            features of the app.
          </p>
          <p>
            Run with{' '}
            <pre>
              npm run start:playground %playground_name% with %playground_name%
              keyof playgrounds in playground-app.tsx
            </pre>
          </p>
        </>
      )}
    </div>
  );
}
