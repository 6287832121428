/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const cloudwatchLog = /* GraphQL */ `mutation CloudwatchLog($severity: Severity, $name: String, $message: String) {
  cloudwatchLog(severity: $severity, name: $name, message: $message)
}
` as GeneratedMutation<
  APITypes.CloudwatchLogMutationVariables,
  APITypes.CloudwatchLogMutation
>;
export const downloadTestResults = /* GraphQL */ `mutation DownloadTestResults($testId: String!, $testType: String!) {
  downloadTestResults(testId: $testId, testType: $testType)
}
` as GeneratedMutation<
  APITypes.DownloadTestResultsMutationVariables,
  APITypes.DownloadTestResultsMutation
>;
export const getMediaItemStatus = /* GraphQL */ `mutation GetMediaItemStatus($mediaItemId: String!) {
  getMediaItemStatus(mediaItemId: $mediaItemId) {
    mediaItemId
    usingTests {
      testId
      testName
      testType
      testOwnerMail
      testStatus
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GetMediaItemStatusMutationVariables,
  APITypes.GetMediaItemStatusMutation
>;
export const removeMediaItem = /* GraphQL */ `mutation RemoveMediaItem($mediaItemId: String!) {
  removeMediaItem(mediaItemId: $mediaItemId)
}
` as GeneratedMutation<
  APITypes.RemoveMediaItemMutationVariables,
  APITypes.RemoveMediaItemMutation
>;
export const inviteUserToTest = /* GraphQL */ `mutation InviteUserToTest(
  $testId: String!
  $testType: String!
  $permissions: [String!]!
  $userEmails: [String!]!
) {
  inviteUserToTest(
    testId: $testId
    testType: $testType
    permissions: $permissions
    userEmails: $userEmails
  ) {
    body
    __typename
  }
}
` as GeneratedMutation<
  APITypes.InviteUserToTestMutationVariables,
  APITypes.InviteUserToTestMutation
>;
export const getUserEmailsByIds = /* GraphQL */ `mutation GetUserEmailsByIds(
  $userIds: [String!]!
  $testId: String!
  $testType: String!
) {
  getUserEmailsByIds(userIds: $userIds, testId: $testId, testType: $testType) {
    body
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GetUserEmailsByIdsMutationVariables,
  APITypes.GetUserEmailsByIdsMutation
>;
export const removeUserFromTest = /* GraphQL */ `mutation RemoveUserFromTest(
  $testId: String!
  $testType: String!
  $userId: String!
) {
  removeUserFromTest(testId: $testId, testType: $testType, userId: $userId) {
    body
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveUserFromTestMutationVariables,
  APITypes.RemoveUserFromTestMutation
>;
export const adminListUsers = /* GraphQL */ `mutation AdminListUsers($nextToken: String) {
  adminListUsers(nextToken: $nextToken) {
    users {
      sub
      email
      groups
      userCreateDate
      userLastModifiedDate
      userStatus
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AdminListUsersMutationVariables,
  APITypes.AdminListUsersMutation
>;
export const adminAddUser = /* GraphQL */ `mutation AdminAddUser($email: String!, $groups: [String!]!) {
  adminAddUser(email: $email, groups: $groups) {
    body
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AdminAddUserMutationVariables,
  APITypes.AdminAddUserMutation
>;
export const adminRemoveUser = /* GraphQL */ `mutation AdminRemoveUser($userId: String!) {
  adminRemoveUser(userId: $userId) {
    body
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AdminRemoveUserMutationVariables,
  APITypes.AdminRemoveUserMutation
>;
export const adminChangeUserGroups = /* GraphQL */ `mutation AdminChangeUserGroups($userId: String!, $groups: [String!]!) {
  adminChangeUserGroups(userId: $userId, groups: $groups) {
    body
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AdminChangeUserGroupsMutationVariables,
  APITypes.AdminChangeUserGroupsMutation
>;
export const userTestStatus = /* GraphQL */ `mutation UserTestStatus($userId: String!) {
  userTestStatus(userId: $userId) {
    userId
    ownedTests {
      testId
      testName
      testType
      testStatus
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UserTestStatusMutationVariables,
  APITypes.UserTestStatusMutation
>;
export const deleteTest = /* GraphQL */ `mutation DeleteTest($testType: String!, $testId: String!) {
  deleteTest(testType: $testType, testId: $testId) {
    body
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTestMutationVariables,
  APITypes.DeleteTestMutation
>;
export const requestTestDownloadLink = /* GraphQL */ `mutation RequestTestDownloadLink(
  $testType: String!
  $testId: String!
  $withDeviceDetails: Boolean!
) {
  requestTestDownloadLink(
    testType: $testType
    testId: $testId
    withDeviceDetails: $withDeviceDetails
  ) {
    downloadLink
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RequestTestDownloadLinkMutationVariables,
  APITypes.RequestTestDownloadLinkMutation
>;
export const removeMediaItemTag = /* GraphQL */ `mutation RemoveMediaItemTag($tagId: String!, $dryRun: Boolean!) {
  removeMediaItemTag(tagId: $tagId, dryRun: $dryRun) {
    type
    associatedMediaItems
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveMediaItemTagMutationVariables,
  APITypes.RemoveMediaItemTagMutation
>;
export const resendUserInvitation = /* GraphQL */ `mutation ResendUserInvitation($email: String!) {
  resendUserInvitation(email: $email) {
    body
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ResendUserInvitationMutationVariables,
  APITypes.ResendUserInvitationMutation
>;
export const sendBroadcastMail = /* GraphQL */ `mutation SendBroadcastMail(
  $subject: String!
  $sender: String
  $content: String!
) {
  sendBroadcastMail(subject: $subject, sender: $sender, content: $content) {
    body
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SendBroadcastMailMutationVariables,
  APITypes.SendBroadcastMailMutation
>;
export const createDocumentationFile = /* GraphQL */ `mutation CreateDocumentationFile(
  $input: CreateDocumentationFileInput!
  $condition: ModelDocumentationFileConditionInput
) {
  createDocumentationFile(input: $input, condition: $condition) {
    id
    name
    description
    s3Key
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDocumentationFileMutationVariables,
  APITypes.CreateDocumentationFileMutation
>;
export const updateDocumentationFile = /* GraphQL */ `mutation UpdateDocumentationFile(
  $input: UpdateDocumentationFileInput!
  $condition: ModelDocumentationFileConditionInput
) {
  updateDocumentationFile(input: $input, condition: $condition) {
    id
    name
    description
    s3Key
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDocumentationFileMutationVariables,
  APITypes.UpdateDocumentationFileMutation
>;
export const deleteDocumentationFile = /* GraphQL */ `mutation DeleteDocumentationFile(
  $input: DeleteDocumentationFileInput!
  $condition: ModelDocumentationFileConditionInput
) {
  deleteDocumentationFile(input: $input, condition: $condition) {
    id
    name
    description
    s3Key
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDocumentationFileMutationVariables,
  APITypes.DeleteDocumentationFileMutation
>;
export const createDocumentFile = /* GraphQL */ `mutation CreateDocumentFile(
  $input: CreateDocumentFileInput!
  $condition: ModelDocumentFileConditionInput
) {
  createDocumentFile(input: $input, condition: $condition) {
    id
    path
    name
    description
    format
    currentVersion {
      createdAt
      version
      validSince
      s3Key
      __typename
    }
    history {
      createdAt
      version
      validSince
      s3Key
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDocumentFileMutationVariables,
  APITypes.CreateDocumentFileMutation
>;
export const updateDocumentFile = /* GraphQL */ `mutation UpdateDocumentFile(
  $input: UpdateDocumentFileInput!
  $condition: ModelDocumentFileConditionInput
) {
  updateDocumentFile(input: $input, condition: $condition) {
    id
    path
    name
    description
    format
    currentVersion {
      createdAt
      version
      validSince
      s3Key
      __typename
    }
    history {
      createdAt
      version
      validSince
      s3Key
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDocumentFileMutationVariables,
  APITypes.UpdateDocumentFileMutation
>;
export const deleteDocumentFile = /* GraphQL */ `mutation DeleteDocumentFile(
  $input: DeleteDocumentFileInput!
  $condition: ModelDocumentFileConditionInput
) {
  deleteDocumentFile(input: $input, condition: $condition) {
    id
    path
    name
    description
    format
    currentVersion {
      createdAt
      version
      validSince
      s3Key
      __typename
    }
    history {
      createdAt
      version
      validSince
      s3Key
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDocumentFileMutationVariables,
  APITypes.DeleteDocumentFileMutation
>;
export const createTag = /* GraphQL */ `mutation CreateTag(
  $input: CreateTagInput!
  $condition: ModelTagConditionInput
) {
  createTag(input: $input, condition: $condition) {
    id
    name
    mediaItems {
      items {
        id
        tagId
        mediaItemId
        tag {
          id
          name
          mediaItems {
            items {
              id
              tagId
              mediaItemId
              tag {
                id
                name
                mediaItems {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              mediaItem {
                id
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                source
                originalFileName
                tags {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                tagsString
                tagsIds
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        mediaItem {
          id
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          source
          originalFileName
          tags {
            items {
              id
              tagId
              mediaItemId
              tag {
                id
                name
                mediaItems {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              mediaItem {
                id
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                source
                originalFileName
                tags {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                tagsString
                tagsIds
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tagsString
          tagsIds
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTagMutationVariables,
  APITypes.CreateTagMutation
>;
export const updateTag = /* GraphQL */ `mutation UpdateTag(
  $input: UpdateTagInput!
  $condition: ModelTagConditionInput
) {
  updateTag(input: $input, condition: $condition) {
    id
    name
    mediaItems {
      items {
        id
        tagId
        mediaItemId
        tag {
          id
          name
          mediaItems {
            items {
              id
              tagId
              mediaItemId
              tag {
                id
                name
                mediaItems {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              mediaItem {
                id
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                source
                originalFileName
                tags {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                tagsString
                tagsIds
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        mediaItem {
          id
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          source
          originalFileName
          tags {
            items {
              id
              tagId
              mediaItemId
              tag {
                id
                name
                mediaItems {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              mediaItem {
                id
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                source
                originalFileName
                tags {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                tagsString
                tagsIds
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tagsString
          tagsIds
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTagMutationVariables,
  APITypes.UpdateTagMutation
>;
export const deleteTag = /* GraphQL */ `mutation DeleteTag(
  $input: DeleteTagInput!
  $condition: ModelTagConditionInput
) {
  deleteTag(input: $input, condition: $condition) {
    id
    name
    mediaItems {
      items {
        id
        tagId
        mediaItemId
        tag {
          id
          name
          mediaItems {
            items {
              id
              tagId
              mediaItemId
              tag {
                id
                name
                mediaItems {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              mediaItem {
                id
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                source
                originalFileName
                tags {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                tagsString
                tagsIds
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        mediaItem {
          id
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          source
          originalFileName
          tags {
            items {
              id
              tagId
              mediaItemId
              tag {
                id
                name
                mediaItems {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              mediaItem {
                id
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                source
                originalFileName
                tags {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                tagsString
                tagsIds
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tagsString
          tagsIds
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTagMutationVariables,
  APITypes.DeleteTagMutation
>;
export const createMediaItem = /* GraphQL */ `mutation CreateMediaItem(
  $input: CreateMediaItemInput!
  $condition: ModelMediaItemConditionInput
) {
  createMediaItem(input: $input, condition: $condition) {
    id
    image {
      fullSize {
        width
        height
        key
        __typename
      }
      thumbNail {
        width
        height
        key
        __typename
      }
      __typename
    }
    source
    originalFileName
    tags {
      items {
        id
        tagId
        mediaItemId
        tag {
          id
          name
          mediaItems {
            items {
              id
              tagId
              mediaItemId
              tag {
                id
                name
                mediaItems {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              mediaItem {
                id
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                source
                originalFileName
                tags {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                tagsString
                tagsIds
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        mediaItem {
          id
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          source
          originalFileName
          tags {
            items {
              id
              tagId
              mediaItemId
              tag {
                id
                name
                mediaItems {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              mediaItem {
                id
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                source
                originalFileName
                tags {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                tagsString
                tagsIds
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tagsString
          tagsIds
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    tagsString
    tagsIds
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMediaItemMutationVariables,
  APITypes.CreateMediaItemMutation
>;
export const updateMediaItem = /* GraphQL */ `mutation UpdateMediaItem(
  $input: UpdateMediaItemInput!
  $condition: ModelMediaItemConditionInput
) {
  updateMediaItem(input: $input, condition: $condition) {
    id
    image {
      fullSize {
        width
        height
        key
        __typename
      }
      thumbNail {
        width
        height
        key
        __typename
      }
      __typename
    }
    source
    originalFileName
    tags {
      items {
        id
        tagId
        mediaItemId
        tag {
          id
          name
          mediaItems {
            items {
              id
              tagId
              mediaItemId
              tag {
                id
                name
                mediaItems {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              mediaItem {
                id
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                source
                originalFileName
                tags {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                tagsString
                tagsIds
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        mediaItem {
          id
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          source
          originalFileName
          tags {
            items {
              id
              tagId
              mediaItemId
              tag {
                id
                name
                mediaItems {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              mediaItem {
                id
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                source
                originalFileName
                tags {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                tagsString
                tagsIds
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tagsString
          tagsIds
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    tagsString
    tagsIds
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMediaItemMutationVariables,
  APITypes.UpdateMediaItemMutation
>;
export const deleteMediaItem = /* GraphQL */ `mutation DeleteMediaItem(
  $input: DeleteMediaItemInput!
  $condition: ModelMediaItemConditionInput
) {
  deleteMediaItem(input: $input, condition: $condition) {
    id
    image {
      fullSize {
        width
        height
        key
        __typename
      }
      thumbNail {
        width
        height
        key
        __typename
      }
      __typename
    }
    source
    originalFileName
    tags {
      items {
        id
        tagId
        mediaItemId
        tag {
          id
          name
          mediaItems {
            items {
              id
              tagId
              mediaItemId
              tag {
                id
                name
                mediaItems {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              mediaItem {
                id
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                source
                originalFileName
                tags {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                tagsString
                tagsIds
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        mediaItem {
          id
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          source
          originalFileName
          tags {
            items {
              id
              tagId
              mediaItemId
              tag {
                id
                name
                mediaItems {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              mediaItem {
                id
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                source
                originalFileName
                tags {
                  items {
                    id
                    tagId
                    mediaItemId
                    tag {
                      id
                      name
                      mediaItems {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    mediaItem {
                      id
                      image {
                        fullSize {
                          width
                          height
                          key
                          __typename
                        }
                        thumbNail {
                          width
                          height
                          key
                          __typename
                        }
                        __typename
                      }
                      source
                      originalFileName
                      tags {
                        items {
                          id
                          tagId
                          mediaItemId
                          tag {
                            id
                            name
                            mediaItems {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          mediaItem {
                            id
                            image {
                              fullSize {
                                width
                                height
                                key
                                __typename
                              }
                              thumbNail {
                                width
                                height
                                key
                                __typename
                              }
                              __typename
                            }
                            source
                            originalFileName
                            tags {
                              items {
                                id
                                tagId
                                mediaItemId
                                tag {
                                  id
                                  name
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                mediaItem {
                                  id
                                  source
                                  originalFileName
                                  tagsString
                                  tagsIds
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                createdAt
                                updatedAt
                                __typename
                              }
                              nextToken
                              __typename
                            }
                            tagsString
                            tagsIds
                            createdAt
                            updatedAt
                            __typename
                          }
                          createdAt
                          updatedAt
                          __typename
                        }
                        nextToken
                        __typename
                      }
                      tagsString
                      tagsIds
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                tagsString
                tagsIds
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          tagsString
          tagsIds
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    tagsString
    tagsIds
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMediaItemMutationVariables,
  APITypes.DeleteMediaItemMutation
>;
export const createTestFinalization = /* GraphQL */ `mutation CreateTestFinalization(
  $input: CreateTestFinalizationInput!
  $condition: ModelTestFinalizationConditionInput
) {
  createTestFinalization(input: $input, condition: $condition) {
    id
    testType
    testId
    probandId
    loadingStartedAt
    testStartedAt
    testFinalizedAt
    testAbortedAt
    owner
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTestFinalizationMutationVariables,
  APITypes.CreateTestFinalizationMutation
>;
export const updateTestFinalization = /* GraphQL */ `mutation UpdateTestFinalization(
  $input: UpdateTestFinalizationInput!
  $condition: ModelTestFinalizationConditionInput
) {
  updateTestFinalization(input: $input, condition: $condition) {
    id
    testType
    testId
    probandId
    loadingStartedAt
    testStartedAt
    testFinalizedAt
    testAbortedAt
    owner
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTestFinalizationMutationVariables,
  APITypes.UpdateTestFinalizationMutation
>;
export const deleteTestFinalization = /* GraphQL */ `mutation DeleteTestFinalization(
  $input: DeleteTestFinalizationInput!
  $condition: ModelTestFinalizationConditionInput
) {
  deleteTestFinalization(input: $input, condition: $condition) {
    id
    testType
    testId
    probandId
    loadingStartedAt
    testStartedAt
    testFinalizedAt
    testAbortedAt
    owner
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTestFinalizationMutationVariables,
  APITypes.DeleteTestFinalizationMutation
>;
export const createTestResultsStatus = /* GraphQL */ `mutation CreateTestResultsStatus(
  $input: CreateTestResultsStatusInput!
  $condition: ModelTestResultsStatusConditionInput
) {
  createTestResultsStatus(input: $input, condition: $condition) {
    id
    status
    testType
    error
    statistics {
      processedResultRows
      exportedResults
      processedProbandAmount
      finishedProbandAmount
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTestResultsStatusMutationVariables,
  APITypes.CreateTestResultsStatusMutation
>;
export const updateTestResultsStatus = /* GraphQL */ `mutation UpdateTestResultsStatus(
  $input: UpdateTestResultsStatusInput!
  $condition: ModelTestResultsStatusConditionInput
) {
  updateTestResultsStatus(input: $input, condition: $condition) {
    id
    status
    testType
    error
    statistics {
      processedResultRows
      exportedResults
      processedProbandAmount
      finishedProbandAmount
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTestResultsStatusMutationVariables,
  APITypes.UpdateTestResultsStatusMutation
>;
export const deleteTestResultsStatus = /* GraphQL */ `mutation DeleteTestResultsStatus(
  $input: DeleteTestResultsStatusInput!
  $condition: ModelTestResultsStatusConditionInput
) {
  deleteTestResultsStatus(input: $input, condition: $condition) {
    id
    status
    testType
    error
    statistics {
      processedResultRows
      exportedResults
      processedProbandAmount
      finishedProbandAmount
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTestResultsStatusMutationVariables,
  APITypes.DeleteTestResultsStatusMutation
>;
export const createIatTest = /* GraphQL */ `mutation CreateIatTest(
  $input: CreateIatTestInput!
  $condition: ModelIatTestConditionInput
) {
  createIatTest(input: $input, condition: $condition) {
    id
    name
    description
    language
    instructions {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    keyboard {
      left
      right
      confirm
      __typename
    }
    interTrialInterval
    counterbalancing
    probabilityWithoutReplacement
    style {
      maxWidth
      backgroundColour
      stimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      mobileStimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      optionsPosition
      optionsPositionMobile
      __typename
    }
    status
    previewCategories
    blocks {
      index
      type
      name
      amountTrials
      instructions {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      categoryLabelsInInstructions
      attributeCategoryVariant
      targetCategoryVariant
      tips {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      __typename
    }
    targetCategories {
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      categoryA {
        label {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      categoryB {
        label {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    categoryDivider {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    attributeCategories {
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      categoryA {
        label {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      categoryB {
        label {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    endScreen {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    startLabel
    nextLabel
    finishLabel
    feedback
    feedbackInterval
    owner
    editors
    viewers
    resultViewers
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIatTestMutationVariables,
  APITypes.CreateIatTestMutation
>;
export const updateIatTest = /* GraphQL */ `mutation UpdateIatTest(
  $input: UpdateIatTestInput!
  $condition: ModelIatTestConditionInput
) {
  updateIatTest(input: $input, condition: $condition) {
    id
    name
    description
    language
    instructions {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    keyboard {
      left
      right
      confirm
      __typename
    }
    interTrialInterval
    counterbalancing
    probabilityWithoutReplacement
    style {
      maxWidth
      backgroundColour
      stimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      mobileStimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      optionsPosition
      optionsPositionMobile
      __typename
    }
    status
    previewCategories
    blocks {
      index
      type
      name
      amountTrials
      instructions {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      categoryLabelsInInstructions
      attributeCategoryVariant
      targetCategoryVariant
      tips {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      __typename
    }
    targetCategories {
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      categoryA {
        label {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      categoryB {
        label {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    categoryDivider {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    attributeCategories {
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      categoryA {
        label {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      categoryB {
        label {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    endScreen {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    startLabel
    nextLabel
    finishLabel
    feedback
    feedbackInterval
    owner
    editors
    viewers
    resultViewers
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIatTestMutationVariables,
  APITypes.UpdateIatTestMutation
>;
export const deleteIatTest = /* GraphQL */ `mutation DeleteIatTest(
  $input: DeleteIatTestInput!
  $condition: ModelIatTestConditionInput
) {
  deleteIatTest(input: $input, condition: $condition) {
    id
    name
    description
    language
    instructions {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    keyboard {
      left
      right
      confirm
      __typename
    }
    interTrialInterval
    counterbalancing
    probabilityWithoutReplacement
    style {
      maxWidth
      backgroundColour
      stimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      mobileStimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      optionsPosition
      optionsPositionMobile
      __typename
    }
    status
    previewCategories
    blocks {
      index
      type
      name
      amountTrials
      instructions {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      categoryLabelsInInstructions
      attributeCategoryVariant
      targetCategoryVariant
      tips {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      __typename
    }
    targetCategories {
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      categoryA {
        label {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      categoryB {
        label {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    categoryDivider {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    attributeCategories {
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      categoryA {
        label {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      categoryB {
        label {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    endScreen {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    startLabel
    nextLabel
    finishLabel
    feedback
    feedbackInterval
    owner
    editors
    viewers
    resultViewers
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIatTestMutationVariables,
  APITypes.DeleteIatTestMutation
>;
export const createIatResult = /* GraphQL */ `mutation CreateIatResult(
  $input: CreateIatResultInput!
  $condition: ModelIatResultConditionInput
) {
  createIatResult(input: $input, condition: $condition) {
    id
    testId
    clientId
    survey {
      probandId
      surveyId
      surveyProbandId
      testProviderName
      __typename
    }
    timing {
      renderStartDelay
      renderEndDelay
      startTriggerDelay
      endTriggerDelay
      controlRaceDelay
      __typename
    }
    device {
      ua
      browser {
        name
        version
        major
        __typename
      }
      device {
        model
        type
        vendor
        __typename
      }
      engine {
        name
        version
        __typename
      }
      os {
        name
        version
        __typename
      }
      cpu {
        architecture
        __typename
      }
      __typename
    }
    blockIndex
    blockName
    blockType
    trialIndex
    actionResultName
    actionTarget {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    actionAttribute {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    counterBalancingGroup
    stimulus {
      categoryLabel {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      categoryType
      stimulus {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    pressedButton
    triggeredControl
    attributeLeft {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    attributeRight {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    targetLeft {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    targetRight {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIatResultMutationVariables,
  APITypes.CreateIatResultMutation
>;
export const updateIatResult = /* GraphQL */ `mutation UpdateIatResult(
  $input: UpdateIatResultInput!
  $condition: ModelIatResultConditionInput
) {
  updateIatResult(input: $input, condition: $condition) {
    id
    testId
    clientId
    survey {
      probandId
      surveyId
      surveyProbandId
      testProviderName
      __typename
    }
    timing {
      renderStartDelay
      renderEndDelay
      startTriggerDelay
      endTriggerDelay
      controlRaceDelay
      __typename
    }
    device {
      ua
      browser {
        name
        version
        major
        __typename
      }
      device {
        model
        type
        vendor
        __typename
      }
      engine {
        name
        version
        __typename
      }
      os {
        name
        version
        __typename
      }
      cpu {
        architecture
        __typename
      }
      __typename
    }
    blockIndex
    blockName
    blockType
    trialIndex
    actionResultName
    actionTarget {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    actionAttribute {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    counterBalancingGroup
    stimulus {
      categoryLabel {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      categoryType
      stimulus {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    pressedButton
    triggeredControl
    attributeLeft {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    attributeRight {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    targetLeft {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    targetRight {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIatResultMutationVariables,
  APITypes.UpdateIatResultMutation
>;
export const deleteIatResult = /* GraphQL */ `mutation DeleteIatResult(
  $input: DeleteIatResultInput!
  $condition: ModelIatResultConditionInput
) {
  deleteIatResult(input: $input, condition: $condition) {
    id
    testId
    clientId
    survey {
      probandId
      surveyId
      surveyProbandId
      testProviderName
      __typename
    }
    timing {
      renderStartDelay
      renderEndDelay
      startTriggerDelay
      endTriggerDelay
      controlRaceDelay
      __typename
    }
    device {
      ua
      browser {
        name
        version
        major
        __typename
      }
      device {
        model
        type
        vendor
        __typename
      }
      engine {
        name
        version
        __typename
      }
      os {
        name
        version
        __typename
      }
      cpu {
        architecture
        __typename
      }
      __typename
    }
    blockIndex
    blockName
    blockType
    trialIndex
    actionResultName
    actionTarget {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    actionAttribute {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    counterBalancingGroup
    stimulus {
      categoryLabel {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      categoryType
      stimulus {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    pressedButton
    triggeredControl
    attributeLeft {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    attributeRight {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    targetLeft {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    targetRight {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIatResultMutationVariables,
  APITypes.DeleteIatResultMutation
>;
export const createAmpTest = /* GraphQL */ `mutation CreateAmpTest(
  $input: CreateAmpTestInput!
  $condition: ModelAmpTestConditionInput
) {
  createAmpTest(input: $input, condition: $condition) {
    id
    name
    description
    language
    instructions {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    keyboard {
      left
      right
      confirm
      __typename
    }
    counterbalancing
    probabilityWithoutReplacement
    interTrialInterval
    style {
      maxWidth
      backgroundColour
      stimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      mobileStimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      optionsPosition
      optionsPositionMobile
      __typename
    }
    status
    endScreen {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    attributeCategories {
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      categoryA {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      categoryB {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    fixation {
      interval
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    primes {
      interval
      categories {
        label
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    targets {
      interval
      categories {
        label
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    mask {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    blankInterval
    blocks {
      index
      name
      type
      instructions {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      amountTrials
      attributeVariant
      tips {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      __typename
    }
    startLabel
    nextLabel
    finishLabel
    owner
    editors
    viewers
    resultViewers
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAmpTestMutationVariables,
  APITypes.CreateAmpTestMutation
>;
export const updateAmpTest = /* GraphQL */ `mutation UpdateAmpTest(
  $input: UpdateAmpTestInput!
  $condition: ModelAmpTestConditionInput
) {
  updateAmpTest(input: $input, condition: $condition) {
    id
    name
    description
    language
    instructions {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    keyboard {
      left
      right
      confirm
      __typename
    }
    counterbalancing
    probabilityWithoutReplacement
    interTrialInterval
    style {
      maxWidth
      backgroundColour
      stimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      mobileStimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      optionsPosition
      optionsPositionMobile
      __typename
    }
    status
    endScreen {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    attributeCategories {
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      categoryA {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      categoryB {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    fixation {
      interval
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    primes {
      interval
      categories {
        label
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    targets {
      interval
      categories {
        label
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    mask {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    blankInterval
    blocks {
      index
      name
      type
      instructions {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      amountTrials
      attributeVariant
      tips {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      __typename
    }
    startLabel
    nextLabel
    finishLabel
    owner
    editors
    viewers
    resultViewers
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAmpTestMutationVariables,
  APITypes.UpdateAmpTestMutation
>;
export const deleteAmpTest = /* GraphQL */ `mutation DeleteAmpTest(
  $input: DeleteAmpTestInput!
  $condition: ModelAmpTestConditionInput
) {
  deleteAmpTest(input: $input, condition: $condition) {
    id
    name
    description
    language
    instructions {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    keyboard {
      left
      right
      confirm
      __typename
    }
    counterbalancing
    probabilityWithoutReplacement
    interTrialInterval
    style {
      maxWidth
      backgroundColour
      stimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      mobileStimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      optionsPosition
      optionsPositionMobile
      __typename
    }
    status
    endScreen {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    attributeCategories {
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      categoryA {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      categoryB {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    fixation {
      interval
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    primes {
      interval
      categories {
        label
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    targets {
      interval
      categories {
        label
        practiceStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        testStimuliPool {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    mask {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    blankInterval
    blocks {
      index
      name
      type
      instructions {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      amountTrials
      attributeVariant
      tips {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      __typename
    }
    startLabel
    nextLabel
    finishLabel
    owner
    editors
    viewers
    resultViewers
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAmpTestMutationVariables,
  APITypes.DeleteAmpTestMutation
>;
export const createAmpResult = /* GraphQL */ `mutation CreateAmpResult(
  $input: CreateAmpResultInput!
  $condition: ModelAmpResultConditionInput
) {
  createAmpResult(input: $input, condition: $condition) {
    id
    testId
    clientId
    survey {
      probandId
      surveyId
      surveyProbandId
      testProviderName
      __typename
    }
    timing {
      renderStartDelay
      renderEndDelay
      startTriggerDelay
      endTriggerDelay
      controlRaceDelay
      __typename
    }
    device {
      ua
      browser {
        name
        version
        major
        __typename
      }
      device {
        model
        type
        vendor
        __typename
      }
      engine {
        name
        version
        __typename
      }
      os {
        name
        version
        __typename
      }
      cpu {
        architecture
        __typename
      }
      __typename
    }
    blockIndex
    blockName
    blockType
    trialIndex
    actionResultName
    counterBalancingGroup
    pressedButton
    triggeredControl
    attributeLeftStimulus {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    attributeRightStimulus {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    actionResultStimulus {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    attributeLeft
    attributeRight
    prime {
      label
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    target {
      label
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAmpResultMutationVariables,
  APITypes.CreateAmpResultMutation
>;
export const updateAmpResult = /* GraphQL */ `mutation UpdateAmpResult(
  $input: UpdateAmpResultInput!
  $condition: ModelAmpResultConditionInput
) {
  updateAmpResult(input: $input, condition: $condition) {
    id
    testId
    clientId
    survey {
      probandId
      surveyId
      surveyProbandId
      testProviderName
      __typename
    }
    timing {
      renderStartDelay
      renderEndDelay
      startTriggerDelay
      endTriggerDelay
      controlRaceDelay
      __typename
    }
    device {
      ua
      browser {
        name
        version
        major
        __typename
      }
      device {
        model
        type
        vendor
        __typename
      }
      engine {
        name
        version
        __typename
      }
      os {
        name
        version
        __typename
      }
      cpu {
        architecture
        __typename
      }
      __typename
    }
    blockIndex
    blockName
    blockType
    trialIndex
    actionResultName
    counterBalancingGroup
    pressedButton
    triggeredControl
    attributeLeftStimulus {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    attributeRightStimulus {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    actionResultStimulus {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    attributeLeft
    attributeRight
    prime {
      label
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    target {
      label
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAmpResultMutationVariables,
  APITypes.UpdateAmpResultMutation
>;
export const deleteAmpResult = /* GraphQL */ `mutation DeleteAmpResult(
  $input: DeleteAmpResultInput!
  $condition: ModelAmpResultConditionInput
) {
  deleteAmpResult(input: $input, condition: $condition) {
    id
    testId
    clientId
    survey {
      probandId
      surveyId
      surveyProbandId
      testProviderName
      __typename
    }
    timing {
      renderStartDelay
      renderEndDelay
      startTriggerDelay
      endTriggerDelay
      controlRaceDelay
      __typename
    }
    device {
      ua
      browser {
        name
        version
        major
        __typename
      }
      device {
        model
        type
        vendor
        __typename
      }
      engine {
        name
        version
        __typename
      }
      os {
        name
        version
        __typename
      }
      cpu {
        architecture
        __typename
      }
      __typename
    }
    blockIndex
    blockName
    blockType
    trialIndex
    actionResultName
    counterBalancingGroup
    pressedButton
    triggeredControl
    attributeLeftStimulus {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    attributeRightStimulus {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    actionResultStimulus {
      id
      tags {
        id
        name
        __typename
      }
      scope
      text
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      originalFileName
      __typename
    }
    attributeLeft
    attributeRight
    prime {
      label
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    target {
      label
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAmpResultMutationVariables,
  APITypes.DeleteAmpResultMutation
>;
export const createPodtTest = /* GraphQL */ `mutation CreatePodtTest(
  $input: CreatePodtTestInput!
  $condition: ModelPodtTestConditionInput
) {
  createPodtTest(input: $input, condition: $condition) {
    id
    name
    description
    language
    instructions {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    keyboard {
      left
      right
      confirm
      __typename
    }
    counterbalancing
    probabilityWithoutReplacement
    interTrialInterval
    style {
      maxWidth
      backgroundColour
      stimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      mobileStimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      optionsPosition
      optionsPositionMobile
      __typename
    }
    status
    endScreen {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    response {
      timeout
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      active {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      passive {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    scenes {
      maxAmountScenesPerTrial
      scenes {
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        boxes {
          top
          left
          width
          height
          __typename
        }
        __typename
      }
      intervalConfig {
        min
        max
        stepSize
        __typename
      }
      __typename
    }
    targets {
      label
      practiceStimuliPool {
        dangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        nonDangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      testStimuliPool {
        dangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        nonDangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    fixation {
      interval
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    trialFeedback {
      correctRejectionText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      hitText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      missText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      falseAlarmText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      noResponseText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      pointsFontsize
      __typename
    }
    blocks {
      index
      name
      type
      amountTrials
      instructions {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      preparationScreen {
        interval
        screen {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        __typename
      }
      tips {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      __typename
    }
    showCategoriesAfterInstructions
    startLabel
    nextLabel
    finishLabel
    owner
    editors
    viewers
    resultViewers
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePodtTestMutationVariables,
  APITypes.CreatePodtTestMutation
>;
export const updatePodtTest = /* GraphQL */ `mutation UpdatePodtTest(
  $input: UpdatePodtTestInput!
  $condition: ModelPodtTestConditionInput
) {
  updatePodtTest(input: $input, condition: $condition) {
    id
    name
    description
    language
    instructions {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    keyboard {
      left
      right
      confirm
      __typename
    }
    counterbalancing
    probabilityWithoutReplacement
    interTrialInterval
    style {
      maxWidth
      backgroundColour
      stimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      mobileStimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      optionsPosition
      optionsPositionMobile
      __typename
    }
    status
    endScreen {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    response {
      timeout
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      active {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      passive {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    scenes {
      maxAmountScenesPerTrial
      scenes {
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        boxes {
          top
          left
          width
          height
          __typename
        }
        __typename
      }
      intervalConfig {
        min
        max
        stepSize
        __typename
      }
      __typename
    }
    targets {
      label
      practiceStimuliPool {
        dangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        nonDangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      testStimuliPool {
        dangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        nonDangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    fixation {
      interval
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    trialFeedback {
      correctRejectionText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      hitText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      missText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      falseAlarmText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      noResponseText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      pointsFontsize
      __typename
    }
    blocks {
      index
      name
      type
      amountTrials
      instructions {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      preparationScreen {
        interval
        screen {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        __typename
      }
      tips {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      __typename
    }
    showCategoriesAfterInstructions
    startLabel
    nextLabel
    finishLabel
    owner
    editors
    viewers
    resultViewers
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePodtTestMutationVariables,
  APITypes.UpdatePodtTestMutation
>;
export const deletePodtTest = /* GraphQL */ `mutation DeletePodtTest(
  $input: DeletePodtTestInput!
  $condition: ModelPodtTestConditionInput
) {
  deletePodtTest(input: $input, condition: $condition) {
    id
    name
    description
    language
    instructions {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    keyboard {
      left
      right
      confirm
      __typename
    }
    counterbalancing
    probabilityWithoutReplacement
    interTrialInterval
    style {
      maxWidth
      backgroundColour
      stimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      mobileStimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      optionsPosition
      optionsPositionMobile
      __typename
    }
    status
    endScreen {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    response {
      timeout
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      active {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      passive {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    scenes {
      maxAmountScenesPerTrial
      scenes {
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        boxes {
          top
          left
          width
          height
          __typename
        }
        __typename
      }
      intervalConfig {
        min
        max
        stepSize
        __typename
      }
      __typename
    }
    targets {
      label
      practiceStimuliPool {
        dangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        nonDangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      testStimuliPool {
        dangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        nonDangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    fixation {
      interval
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    trialFeedback {
      correctRejectionText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      hitText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      missText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      falseAlarmText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      noResponseText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      pointsFontsize
      __typename
    }
    blocks {
      index
      name
      type
      amountTrials
      instructions {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      preparationScreen {
        interval
        screen {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        __typename
      }
      tips {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      __typename
    }
    showCategoriesAfterInstructions
    startLabel
    nextLabel
    finishLabel
    owner
    editors
    viewers
    resultViewers
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePodtTestMutationVariables,
  APITypes.DeletePodtTestMutation
>;
export const createPodtResult = /* GraphQL */ `mutation CreatePodtResult(
  $input: CreatePodtResultInput!
  $condition: ModelPodtResultConditionInput
) {
  createPodtResult(input: $input, condition: $condition) {
    id
    testId
    clientId
    survey {
      probandId
      surveyId
      surveyProbandId
      testProviderName
      __typename
    }
    timing {
      renderStartDelay
      renderEndDelay
      startTriggerDelay
      endTriggerDelay
      controlRaceDelay
      __typename
    }
    device {
      ua
      browser {
        name
        version
        major
        __typename
      }
      device {
        model
        type
        vendor
        __typename
      }
      engine {
        name
        version
        __typename
      }
      os {
        name
        version
        __typename
      }
      cpu {
        architecture
        __typename
      }
      __typename
    }
    blockIndex
    blockName
    blockType
    trialIndex
    actionResultName
    counterBalancingGroup
    earnedPoints
    reactionLeft
    reactionRight
    scenes {
      scene {
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        boxes {
          top
          left
          width
          height
          __typename
        }
        __typename
      }
      interval
      __typename
    }
    target {
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      category
      dangerous
      __typename
    }
    accumulatedPoints
    pressedButton
    triggeredControl
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePodtResultMutationVariables,
  APITypes.CreatePodtResultMutation
>;
export const updatePodtResult = /* GraphQL */ `mutation UpdatePodtResult(
  $input: UpdatePodtResultInput!
  $condition: ModelPodtResultConditionInput
) {
  updatePodtResult(input: $input, condition: $condition) {
    id
    testId
    clientId
    survey {
      probandId
      surveyId
      surveyProbandId
      testProviderName
      __typename
    }
    timing {
      renderStartDelay
      renderEndDelay
      startTriggerDelay
      endTriggerDelay
      controlRaceDelay
      __typename
    }
    device {
      ua
      browser {
        name
        version
        major
        __typename
      }
      device {
        model
        type
        vendor
        __typename
      }
      engine {
        name
        version
        __typename
      }
      os {
        name
        version
        __typename
      }
      cpu {
        architecture
        __typename
      }
      __typename
    }
    blockIndex
    blockName
    blockType
    trialIndex
    actionResultName
    counterBalancingGroup
    earnedPoints
    reactionLeft
    reactionRight
    scenes {
      scene {
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        boxes {
          top
          left
          width
          height
          __typename
        }
        __typename
      }
      interval
      __typename
    }
    target {
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      category
      dangerous
      __typename
    }
    accumulatedPoints
    pressedButton
    triggeredControl
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePodtResultMutationVariables,
  APITypes.UpdatePodtResultMutation
>;
export const deletePodtResult = /* GraphQL */ `mutation DeletePodtResult(
  $input: DeletePodtResultInput!
  $condition: ModelPodtResultConditionInput
) {
  deletePodtResult(input: $input, condition: $condition) {
    id
    testId
    clientId
    survey {
      probandId
      surveyId
      surveyProbandId
      testProviderName
      __typename
    }
    timing {
      renderStartDelay
      renderEndDelay
      startTriggerDelay
      endTriggerDelay
      controlRaceDelay
      __typename
    }
    device {
      ua
      browser {
        name
        version
        major
        __typename
      }
      device {
        model
        type
        vendor
        __typename
      }
      engine {
        name
        version
        __typename
      }
      os {
        name
        version
        __typename
      }
      cpu {
        architecture
        __typename
      }
      __typename
    }
    blockIndex
    blockName
    blockType
    trialIndex
    actionResultName
    counterBalancingGroup
    earnedPoints
    reactionLeft
    reactionRight
    scenes {
      scene {
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        boxes {
          top
          left
          width
          height
          __typename
        }
        __typename
      }
      interval
      __typename
    }
    target {
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      category
      dangerous
      __typename
    }
    accumulatedPoints
    pressedButton
    triggeredControl
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePodtResultMutationVariables,
  APITypes.DeletePodtResultMutation
>;
export const createPodtaTest = /* GraphQL */ `mutation CreatePodtaTest(
  $input: CreatePodtaTestInput!
  $condition: ModelPodtaTestConditionInput
) {
  createPodtaTest(input: $input, condition: $condition) {
    id
    name
    description
    language
    instructions {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    keyboard {
      left
      right
      confirm
      __typename
    }
    counterbalancing
    probabilityWithoutReplacement
    interTrialInterval
    style {
      maxWidth
      backgroundColour
      stimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      mobileStimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      optionsPosition
      optionsPositionMobile
      __typename
    }
    status
    endScreen {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    response {
      timeout
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      active {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      passive {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    scenes {
      maxAmountScenesPerTrial
      scenes {
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        boxes {
          top
          left
          width
          height
          position
          __typename
        }
        __typename
      }
      intervalConfig {
        min
        max
        stepSize
        __typename
      }
      __typename
    }
    targets {
      label
      practiceStimuliPool {
        dangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        nonDangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      testStimuliPool {
        dangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        nonDangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    fixation {
      interval
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    trialFeedback {
      correctRejectionText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      hitText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      missText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      falseAlarmText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      noResponseText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      pointsFontsize
      __typename
    }
    blocks {
      index
      name
      type
      amountTrials
      instructions {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      preparationScreen {
        interval
        screen {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        __typename
      }
      tips {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      __typename
    }
    showCategoriesAfterInstructions
    startLabel
    nextLabel
    finishLabel
    owner
    editors
    viewers
    resultViewers
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePodtaTestMutationVariables,
  APITypes.CreatePodtaTestMutation
>;
export const updatePodtaTest = /* GraphQL */ `mutation UpdatePodtaTest(
  $input: UpdatePodtaTestInput!
  $condition: ModelPodtaTestConditionInput
) {
  updatePodtaTest(input: $input, condition: $condition) {
    id
    name
    description
    language
    instructions {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    keyboard {
      left
      right
      confirm
      __typename
    }
    counterbalancing
    probabilityWithoutReplacement
    interTrialInterval
    style {
      maxWidth
      backgroundColour
      stimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      mobileStimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      optionsPosition
      optionsPositionMobile
      __typename
    }
    status
    endScreen {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    response {
      timeout
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      active {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      passive {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    scenes {
      maxAmountScenesPerTrial
      scenes {
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        boxes {
          top
          left
          width
          height
          position
          __typename
        }
        __typename
      }
      intervalConfig {
        min
        max
        stepSize
        __typename
      }
      __typename
    }
    targets {
      label
      practiceStimuliPool {
        dangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        nonDangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      testStimuliPool {
        dangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        nonDangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    fixation {
      interval
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    trialFeedback {
      correctRejectionText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      hitText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      missText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      falseAlarmText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      noResponseText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      pointsFontsize
      __typename
    }
    blocks {
      index
      name
      type
      amountTrials
      instructions {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      preparationScreen {
        interval
        screen {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        __typename
      }
      tips {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      __typename
    }
    showCategoriesAfterInstructions
    startLabel
    nextLabel
    finishLabel
    owner
    editors
    viewers
    resultViewers
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePodtaTestMutationVariables,
  APITypes.UpdatePodtaTestMutation
>;
export const deletePodtaTest = /* GraphQL */ `mutation DeletePodtaTest(
  $input: DeletePodtaTestInput!
  $condition: ModelPodtaTestConditionInput
) {
  deletePodtaTest(input: $input, condition: $condition) {
    id
    name
    description
    language
    instructions {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    keyboard {
      left
      right
      confirm
      __typename
    }
    counterbalancing
    probabilityWithoutReplacement
    interTrialInterval
    style {
      maxWidth
      backgroundColour
      stimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      mobileStimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      optionsPosition
      optionsPositionMobile
      __typename
    }
    status
    endScreen {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    response {
      timeout
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      active {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      passive {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    scenes {
      maxAmountScenesPerTrial
      scenes {
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        boxes {
          top
          left
          width
          height
          position
          __typename
        }
        __typename
      }
      intervalConfig {
        min
        max
        stepSize
        __typename
      }
      __typename
    }
    targets {
      label
      practiceStimuliPool {
        dangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        nonDangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      testStimuliPool {
        dangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        nonDangerousStimuli {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    fixation {
      interval
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      __typename
    }
    trialFeedback {
      correctRejectionText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      hitText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      missText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      falseAlarmText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      noResponseText {
        value
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        weight
        __typename
      }
      pointsFontsize
      __typename
    }
    blocks {
      index
      name
      type
      amountTrials
      instructions {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      preparationScreen {
        interval
        screen {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        __typename
      }
      tips {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      __typename
    }
    showCategoriesAfterInstructions
    startLabel
    nextLabel
    finishLabel
    owner
    editors
    viewers
    resultViewers
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePodtaTestMutationVariables,
  APITypes.DeletePodtaTestMutation
>;
export const createPodtaResult = /* GraphQL */ `mutation CreatePodtaResult(
  $input: CreatePodtaResultInput!
  $condition: ModelPodtaResultConditionInput
) {
  createPodtaResult(input: $input, condition: $condition) {
    id
    testId
    clientId
    survey {
      probandId
      surveyId
      surveyProbandId
      testProviderName
      __typename
    }
    timing {
      renderStartDelay
      renderEndDelay
      startTriggerDelay
      endTriggerDelay
      controlRaceDelay
      __typename
    }
    device {
      ua
      browser {
        name
        version
        major
        __typename
      }
      device {
        model
        type
        vendor
        __typename
      }
      engine {
        name
        version
        __typename
      }
      os {
        name
        version
        __typename
      }
      cpu {
        architecture
        __typename
      }
      __typename
    }
    blockIndex
    blockName
    blockType
    trialIndex
    actionResultName
    counterBalancingGroup
    earnedPoints
    reactionLeft
    reactionRight
    scenes {
      scene {
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        boxes {
          top
          left
          width
          height
          position
          __typename
        }
        __typename
      }
      interval
      __typename
    }
    target {
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      category
      dangerous
      __typename
    }
    accumulatedPoints
    pressedButton
    triggeredControl
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePodtaResultMutationVariables,
  APITypes.CreatePodtaResultMutation
>;
export const updatePodtaResult = /* GraphQL */ `mutation UpdatePodtaResult(
  $input: UpdatePodtaResultInput!
  $condition: ModelPodtaResultConditionInput
) {
  updatePodtaResult(input: $input, condition: $condition) {
    id
    testId
    clientId
    survey {
      probandId
      surveyId
      surveyProbandId
      testProviderName
      __typename
    }
    timing {
      renderStartDelay
      renderEndDelay
      startTriggerDelay
      endTriggerDelay
      controlRaceDelay
      __typename
    }
    device {
      ua
      browser {
        name
        version
        major
        __typename
      }
      device {
        model
        type
        vendor
        __typename
      }
      engine {
        name
        version
        __typename
      }
      os {
        name
        version
        __typename
      }
      cpu {
        architecture
        __typename
      }
      __typename
    }
    blockIndex
    blockName
    blockType
    trialIndex
    actionResultName
    counterBalancingGroup
    earnedPoints
    reactionLeft
    reactionRight
    scenes {
      scene {
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        boxes {
          top
          left
          width
          height
          position
          __typename
        }
        __typename
      }
      interval
      __typename
    }
    target {
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      category
      dangerous
      __typename
    }
    accumulatedPoints
    pressedButton
    triggeredControl
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePodtaResultMutationVariables,
  APITypes.UpdatePodtaResultMutation
>;
export const deletePodtaResult = /* GraphQL */ `mutation DeletePodtaResult(
  $input: DeletePodtaResultInput!
  $condition: ModelPodtaResultConditionInput
) {
  deletePodtaResult(input: $input, condition: $condition) {
    id
    testId
    clientId
    survey {
      probandId
      surveyId
      surveyProbandId
      testProviderName
      __typename
    }
    timing {
      renderStartDelay
      renderEndDelay
      startTriggerDelay
      endTriggerDelay
      controlRaceDelay
      __typename
    }
    device {
      ua
      browser {
        name
        version
        major
        __typename
      }
      device {
        model
        type
        vendor
        __typename
      }
      engine {
        name
        version
        __typename
      }
      os {
        name
        version
        __typename
      }
      cpu {
        architecture
        __typename
      }
      __typename
    }
    blockIndex
    blockName
    blockType
    trialIndex
    actionResultName
    counterBalancingGroup
    earnedPoints
    reactionLeft
    reactionRight
    scenes {
      scene {
        mediaItem {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        boxes {
          top
          left
          width
          height
          position
          __typename
        }
        __typename
      }
      interval
      __typename
    }
    target {
      mediaItem {
        id
        tags {
          id
          name
          __typename
        }
        scope
        text
        image {
          fullSize {
            width
            height
            key
            __typename
          }
          thumbNail {
            width
            height
            key
            __typename
          }
          __typename
        }
        originalFileName
        __typename
      }
      category
      dangerous
      __typename
    }
    accumulatedPoints
    pressedButton
    triggeredControl
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePodtaResultMutationVariables,
  APITypes.DeletePodtaResultMutation
>;
export const createWSWTest = /* GraphQL */ `mutation CreateWSWTest(
  $input: CreateWSWTestInput!
  $condition: ModelWSWTestConditionInput
) {
  createWSWTest(input: $input, condition: $condition) {
    id
    name
    language
    description
    instructions {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    style {
      maxWidth
      backgroundColour
      stimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      mobileStimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      optionsPosition
      optionsPositionMobile
      __typename
    }
    status
    endScreen {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    attributeCombinations {
      mainCategory
      additionalCategory
      criteriaCategory
      amount
      multiplicator
      __typename
    }
    mainAttribute {
      stimuliVariant
      type
      pickingStrategy
      categories {
        name
        mediaSnaps {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    criteriaAttribute {
      stimuliVariant
      type
      pickingStrategy
      categories {
        name
        mediaSnaps {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    additionalAttribute {
      stimuliVariant
      type
      pickingStrategy
      categories {
        name
        mediaSnaps {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    groupConstruction {
      instructionsScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      feedbackScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      fakeQuestions {
        randomizeSequence
        conditionalQuestion {
          questionText
          confirmText
          declineText
          answerType
          __typename
        }
        questionsAfter {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        questionsBefore {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        __typename
      }
      conditions {
        condition1
        condition2
        condition3
        __typename
      }
      groups {
        name
        color
        questionSet {
          randomizeSequence
          conditionalQuestion {
            questionText
            confirmText
            declineText
            answerType
            __typename
          }
          questionsAfter {
            noAnswer
            type
            questionText
            multiple {
              randomized
              options
              __typename
            }
            scale {
              invert
              min {
                name
                value
                __typename
              }
              max {
                name
                value
                __typename
              }
              __typename
            }
            mediaItemSnapshot {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            imageMultipleChoice {
              randomized
              options {
                id
                tags {
                  id
                  name
                  __typename
                }
                scope
                text
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                originalFileName
                __typename
              }
              __typename
            }
            stimulusTimeout
            __typename
          }
          questionsBefore {
            noAnswer
            type
            questionText
            multiple {
              randomized
              options
              __typename
            }
            scale {
              invert
              min {
                name
                value
                __typename
              }
              max {
                name
                value
                __typename
              }
              __typename
            }
            mediaItemSnapshot {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            imageMultipleChoice {
              randomized
              options {
                id
                tags {
                  id
                  name
                  __typename
                }
                scope
                text
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                originalFileName
                __typename
              }
              __typename
            }
            stimulusTimeout
            __typename
          }
          __typename
        }
        probability
        feedbackScreen {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        __typename
      }
      randomizeGroupColors
      __typename
    }
    learningPhase {
      instructionsScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      feedbackScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      questionsSet {
        randomizeSequence
        conditionalQuestion {
          questionText
          confirmText
          declineText
          answerType
          __typename
        }
        questionsAfter {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        questionsBefore {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        __typename
      }
      questionOffsetTimeout
      __typename
    }
    testPhase {
      instructionsScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      feedbackScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      questionsSet {
        randomizeSequence
        conditionalQuestion {
          questionText
          confirmText
          declineText
          answerType
          __typename
        }
        questionsAfter {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        questionsBefore {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        __typename
      }
      questionOffsetTimeout
      __typename
    }
    distractionPhase {
      instructionsScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      feedbackScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      questionsSet {
        randomizeSequence
        conditionalQuestion {
          questionText
          confirmText
          declineText
          answerType
          __typename
        }
        questionsAfter {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        questionsBefore {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        __typename
      }
      __typename
    }
    startLabel
    nextLabel
    finishLabel
    owner
    editors
    viewers
    resultViewers
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWSWTestMutationVariables,
  APITypes.CreateWSWTestMutation
>;
export const updateWSWTest = /* GraphQL */ `mutation UpdateWSWTest(
  $input: UpdateWSWTestInput!
  $condition: ModelWSWTestConditionInput
) {
  updateWSWTest(input: $input, condition: $condition) {
    id
    name
    language
    description
    instructions {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    style {
      maxWidth
      backgroundColour
      stimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      mobileStimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      optionsPosition
      optionsPositionMobile
      __typename
    }
    status
    endScreen {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    attributeCombinations {
      mainCategory
      additionalCategory
      criteriaCategory
      amount
      multiplicator
      __typename
    }
    mainAttribute {
      stimuliVariant
      type
      pickingStrategy
      categories {
        name
        mediaSnaps {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    criteriaAttribute {
      stimuliVariant
      type
      pickingStrategy
      categories {
        name
        mediaSnaps {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    additionalAttribute {
      stimuliVariant
      type
      pickingStrategy
      categories {
        name
        mediaSnaps {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    groupConstruction {
      instructionsScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      feedbackScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      fakeQuestions {
        randomizeSequence
        conditionalQuestion {
          questionText
          confirmText
          declineText
          answerType
          __typename
        }
        questionsAfter {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        questionsBefore {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        __typename
      }
      conditions {
        condition1
        condition2
        condition3
        __typename
      }
      groups {
        name
        color
        questionSet {
          randomizeSequence
          conditionalQuestion {
            questionText
            confirmText
            declineText
            answerType
            __typename
          }
          questionsAfter {
            noAnswer
            type
            questionText
            multiple {
              randomized
              options
              __typename
            }
            scale {
              invert
              min {
                name
                value
                __typename
              }
              max {
                name
                value
                __typename
              }
              __typename
            }
            mediaItemSnapshot {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            imageMultipleChoice {
              randomized
              options {
                id
                tags {
                  id
                  name
                  __typename
                }
                scope
                text
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                originalFileName
                __typename
              }
              __typename
            }
            stimulusTimeout
            __typename
          }
          questionsBefore {
            noAnswer
            type
            questionText
            multiple {
              randomized
              options
              __typename
            }
            scale {
              invert
              min {
                name
                value
                __typename
              }
              max {
                name
                value
                __typename
              }
              __typename
            }
            mediaItemSnapshot {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            imageMultipleChoice {
              randomized
              options {
                id
                tags {
                  id
                  name
                  __typename
                }
                scope
                text
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                originalFileName
                __typename
              }
              __typename
            }
            stimulusTimeout
            __typename
          }
          __typename
        }
        probability
        feedbackScreen {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        __typename
      }
      randomizeGroupColors
      __typename
    }
    learningPhase {
      instructionsScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      feedbackScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      questionsSet {
        randomizeSequence
        conditionalQuestion {
          questionText
          confirmText
          declineText
          answerType
          __typename
        }
        questionsAfter {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        questionsBefore {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        __typename
      }
      questionOffsetTimeout
      __typename
    }
    testPhase {
      instructionsScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      feedbackScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      questionsSet {
        randomizeSequence
        conditionalQuestion {
          questionText
          confirmText
          declineText
          answerType
          __typename
        }
        questionsAfter {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        questionsBefore {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        __typename
      }
      questionOffsetTimeout
      __typename
    }
    distractionPhase {
      instructionsScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      feedbackScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      questionsSet {
        randomizeSequence
        conditionalQuestion {
          questionText
          confirmText
          declineText
          answerType
          __typename
        }
        questionsAfter {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        questionsBefore {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        __typename
      }
      __typename
    }
    startLabel
    nextLabel
    finishLabel
    owner
    editors
    viewers
    resultViewers
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWSWTestMutationVariables,
  APITypes.UpdateWSWTestMutation
>;
export const deleteWSWTest = /* GraphQL */ `mutation DeleteWSWTest(
  $input: DeleteWSWTestInput!
  $condition: ModelWSWTestConditionInput
) {
  deleteWSWTest(input: $input, condition: $condition) {
    id
    name
    language
    description
    instructions {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    style {
      maxWidth
      backgroundColour
      stimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      mobileStimulusTextStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      optionsPosition
      optionsPositionMobile
      __typename
    }
    status
    endScreen {
      desktop
      mobile
      fontStyle {
        fontSize
        color
        alignment
        fontFace
        __typename
      }
      __typename
    }
    attributeCombinations {
      mainCategory
      additionalCategory
      criteriaCategory
      amount
      multiplicator
      __typename
    }
    mainAttribute {
      stimuliVariant
      type
      pickingStrategy
      categories {
        name
        mediaSnaps {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    criteriaAttribute {
      stimuliVariant
      type
      pickingStrategy
      categories {
        name
        mediaSnaps {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    additionalAttribute {
      stimuliVariant
      type
      pickingStrategy
      categories {
        name
        mediaSnaps {
          id
          tags {
            id
            name
            __typename
          }
          scope
          text
          image {
            fullSize {
              width
              height
              key
              __typename
            }
            thumbNail {
              width
              height
              key
              __typename
            }
            __typename
          }
          originalFileName
          __typename
        }
        __typename
      }
      __typename
    }
    groupConstruction {
      instructionsScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      feedbackScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      fakeQuestions {
        randomizeSequence
        conditionalQuestion {
          questionText
          confirmText
          declineText
          answerType
          __typename
        }
        questionsAfter {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        questionsBefore {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        __typename
      }
      conditions {
        condition1
        condition2
        condition3
        __typename
      }
      groups {
        name
        color
        questionSet {
          randomizeSequence
          conditionalQuestion {
            questionText
            confirmText
            declineText
            answerType
            __typename
          }
          questionsAfter {
            noAnswer
            type
            questionText
            multiple {
              randomized
              options
              __typename
            }
            scale {
              invert
              min {
                name
                value
                __typename
              }
              max {
                name
                value
                __typename
              }
              __typename
            }
            mediaItemSnapshot {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            imageMultipleChoice {
              randomized
              options {
                id
                tags {
                  id
                  name
                  __typename
                }
                scope
                text
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                originalFileName
                __typename
              }
              __typename
            }
            stimulusTimeout
            __typename
          }
          questionsBefore {
            noAnswer
            type
            questionText
            multiple {
              randomized
              options
              __typename
            }
            scale {
              invert
              min {
                name
                value
                __typename
              }
              max {
                name
                value
                __typename
              }
              __typename
            }
            mediaItemSnapshot {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            imageMultipleChoice {
              randomized
              options {
                id
                tags {
                  id
                  name
                  __typename
                }
                scope
                text
                image {
                  fullSize {
                    width
                    height
                    key
                    __typename
                  }
                  thumbNail {
                    width
                    height
                    key
                    __typename
                  }
                  __typename
                }
                originalFileName
                __typename
              }
              __typename
            }
            stimulusTimeout
            __typename
          }
          __typename
        }
        probability
        feedbackScreen {
          desktop
          mobile
          fontStyle {
            fontSize
            color
            alignment
            fontFace
            __typename
          }
          __typename
        }
        __typename
      }
      randomizeGroupColors
      __typename
    }
    learningPhase {
      instructionsScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      feedbackScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      questionsSet {
        randomizeSequence
        conditionalQuestion {
          questionText
          confirmText
          declineText
          answerType
          __typename
        }
        questionsAfter {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        questionsBefore {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        __typename
      }
      questionOffsetTimeout
      __typename
    }
    testPhase {
      instructionsScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      feedbackScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      questionsSet {
        randomizeSequence
        conditionalQuestion {
          questionText
          confirmText
          declineText
          answerType
          __typename
        }
        questionsAfter {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        questionsBefore {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        __typename
      }
      questionOffsetTimeout
      __typename
    }
    distractionPhase {
      instructionsScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      feedbackScreen {
        desktop
        mobile
        fontStyle {
          fontSize
          color
          alignment
          fontFace
          __typename
        }
        __typename
      }
      questionsSet {
        randomizeSequence
        conditionalQuestion {
          questionText
          confirmText
          declineText
          answerType
          __typename
        }
        questionsAfter {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        questionsBefore {
          noAnswer
          type
          questionText
          multiple {
            randomized
            options
            __typename
          }
          scale {
            invert
            min {
              name
              value
              __typename
            }
            max {
              name
              value
              __typename
            }
            __typename
          }
          mediaItemSnapshot {
            id
            tags {
              id
              name
              __typename
            }
            scope
            text
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            originalFileName
            __typename
          }
          imageMultipleChoice {
            randomized
            options {
              id
              tags {
                id
                name
                __typename
              }
              scope
              text
              image {
                fullSize {
                  width
                  height
                  key
                  __typename
                }
                thumbNail {
                  width
                  height
                  key
                  __typename
                }
                __typename
              }
              originalFileName
              __typename
            }
            __typename
          }
          stimulusTimeout
          __typename
        }
        __typename
      }
      __typename
    }
    startLabel
    nextLabel
    finishLabel
    owner
    editors
    viewers
    resultViewers
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWSWTestMutationVariables,
  APITypes.DeleteWSWTestMutation
>;
export const createWswResult = /* GraphQL */ `mutation CreateWswResult(
  $input: CreateWswResultInput!
  $condition: ModelWswResultConditionInput
) {
  createWswResult(input: $input, condition: $condition) {
    id
    testId
    clientId
    survey {
      probandId
      surveyId
      surveyProbandId
      testProviderName
      __typename
    }
    timing {
      renderStartDelay
      renderEndDelay
      startTriggerDelay
      endTriggerDelay
      controlRaceDelay
      __typename
    }
    device {
      ua
      browser {
        name
        version
        major
        __typename
      }
      device {
        model
        type
        vendor
        __typename
      }
      engine {
        name
        version
        __typename
      }
      os {
        name
        version
        __typename
      }
      cpu {
        architecture
        __typename
      }
      __typename
    }
    phase
    position
    trialIndex
    groupIndication {
      groupIndicatorShow
      groupIndicationCategory
      groupIndicationIndex
      groupIndicationColor
      __typename
    }
    questionType
    questionAnswer
    questionImageAnswerOriginalFileName
    noAnswer
    answerOptionIndex
    questionPosition
    questionPoolName
    questionSnapshot
    contentType
    resultStimulus {
      stimulusType
      stimulusId
      stimulusTextValue
      stimulusTags
      stimulusOriginalFilename
      __typename
    }
    attributeCombination {
      isLearningStimulus
      mainStimulus {
        stimulusType
        stimulusPoolCategory
        stimulusPoolIndex
        stimulusPoolPosition
        stimulusOriginalFilename
        type
        stimulusId
        stimulusTextValue
        stimulusTags
        __typename
      }
      criteriaStimulus {
        stimulusType
        stimulusPoolCategory
        stimulusPoolIndex
        stimulusPoolPosition
        stimulusOriginalFilename
        type
        stimulusId
        stimulusTextValue
        stimulusTags
        __typename
      }
      additionalStimulus {
        stimulusType
        stimulusPoolCategory
        stimulusPoolIndex
        stimulusPoolPosition
        stimulusOriginalFilename
        type
        stimulusId
        stimulusTextValue
        stimulusTags
        __typename
      }
      __typename
    }
    owner
    groupCondition
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWswResultMutationVariables,
  APITypes.CreateWswResultMutation
>;
export const updateWswResult = /* GraphQL */ `mutation UpdateWswResult(
  $input: UpdateWswResultInput!
  $condition: ModelWswResultConditionInput
) {
  updateWswResult(input: $input, condition: $condition) {
    id
    testId
    clientId
    survey {
      probandId
      surveyId
      surveyProbandId
      testProviderName
      __typename
    }
    timing {
      renderStartDelay
      renderEndDelay
      startTriggerDelay
      endTriggerDelay
      controlRaceDelay
      __typename
    }
    device {
      ua
      browser {
        name
        version
        major
        __typename
      }
      device {
        model
        type
        vendor
        __typename
      }
      engine {
        name
        version
        __typename
      }
      os {
        name
        version
        __typename
      }
      cpu {
        architecture
        __typename
      }
      __typename
    }
    phase
    position
    trialIndex
    groupIndication {
      groupIndicatorShow
      groupIndicationCategory
      groupIndicationIndex
      groupIndicationColor
      __typename
    }
    questionType
    questionAnswer
    questionImageAnswerOriginalFileName
    noAnswer
    answerOptionIndex
    questionPosition
    questionPoolName
    questionSnapshot
    contentType
    resultStimulus {
      stimulusType
      stimulusId
      stimulusTextValue
      stimulusTags
      stimulusOriginalFilename
      __typename
    }
    attributeCombination {
      isLearningStimulus
      mainStimulus {
        stimulusType
        stimulusPoolCategory
        stimulusPoolIndex
        stimulusPoolPosition
        stimulusOriginalFilename
        type
        stimulusId
        stimulusTextValue
        stimulusTags
        __typename
      }
      criteriaStimulus {
        stimulusType
        stimulusPoolCategory
        stimulusPoolIndex
        stimulusPoolPosition
        stimulusOriginalFilename
        type
        stimulusId
        stimulusTextValue
        stimulusTags
        __typename
      }
      additionalStimulus {
        stimulusType
        stimulusPoolCategory
        stimulusPoolIndex
        stimulusPoolPosition
        stimulusOriginalFilename
        type
        stimulusId
        stimulusTextValue
        stimulusTags
        __typename
      }
      __typename
    }
    owner
    groupCondition
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWswResultMutationVariables,
  APITypes.UpdateWswResultMutation
>;
export const deleteWswResult = /* GraphQL */ `mutation DeleteWswResult(
  $input: DeleteWswResultInput!
  $condition: ModelWswResultConditionInput
) {
  deleteWswResult(input: $input, condition: $condition) {
    id
    testId
    clientId
    survey {
      probandId
      surveyId
      surveyProbandId
      testProviderName
      __typename
    }
    timing {
      renderStartDelay
      renderEndDelay
      startTriggerDelay
      endTriggerDelay
      controlRaceDelay
      __typename
    }
    device {
      ua
      browser {
        name
        version
        major
        __typename
      }
      device {
        model
        type
        vendor
        __typename
      }
      engine {
        name
        version
        __typename
      }
      os {
        name
        version
        __typename
      }
      cpu {
        architecture
        __typename
      }
      __typename
    }
    phase
    position
    trialIndex
    groupIndication {
      groupIndicatorShow
      groupIndicationCategory
      groupIndicationIndex
      groupIndicationColor
      __typename
    }
    questionType
    questionAnswer
    questionImageAnswerOriginalFileName
    noAnswer
    answerOptionIndex
    questionPosition
    questionPoolName
    questionSnapshot
    contentType
    resultStimulus {
      stimulusType
      stimulusId
      stimulusTextValue
      stimulusTags
      stimulusOriginalFilename
      __typename
    }
    attributeCombination {
      isLearningStimulus
      mainStimulus {
        stimulusType
        stimulusPoolCategory
        stimulusPoolIndex
        stimulusPoolPosition
        stimulusOriginalFilename
        type
        stimulusId
        stimulusTextValue
        stimulusTags
        __typename
      }
      criteriaStimulus {
        stimulusType
        stimulusPoolCategory
        stimulusPoolIndex
        stimulusPoolPosition
        stimulusOriginalFilename
        type
        stimulusId
        stimulusTextValue
        stimulusTags
        __typename
      }
      additionalStimulus {
        stimulusType
        stimulusPoolCategory
        stimulusPoolIndex
        stimulusPoolPosition
        stimulusOriginalFilename
        type
        stimulusId
        stimulusTextValue
        stimulusTags
        __typename
      }
      __typename
    }
    owner
    groupCondition
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWswResultMutationVariables,
  APITypes.DeleteWswResultMutation
>;
export const createSurveyProvider = /* GraphQL */ `mutation CreateSurveyProvider(
  $input: CreateSurveyProviderInput!
  $condition: ModelSurveyProviderConditionInput
) {
  createSurveyProvider(input: $input, condition: $condition) {
    id
    slug
    name
    description
    snippetConfiguration {
      snippetType
      surveyIdReplacer
      probandIdReplacer
      __typename
    }
    setupDocumentation {
      steps {
        type
        textBeforeContent
        textAfterContent
        image {
          width
          height
          key
          __typename
        }
        __typename
      }
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSurveyProviderMutationVariables,
  APITypes.CreateSurveyProviderMutation
>;
export const updateSurveyProvider = /* GraphQL */ `mutation UpdateSurveyProvider(
  $input: UpdateSurveyProviderInput!
  $condition: ModelSurveyProviderConditionInput
) {
  updateSurveyProvider(input: $input, condition: $condition) {
    id
    slug
    name
    description
    snippetConfiguration {
      snippetType
      surveyIdReplacer
      probandIdReplacer
      __typename
    }
    setupDocumentation {
      steps {
        type
        textBeforeContent
        textAfterContent
        image {
          width
          height
          key
          __typename
        }
        __typename
      }
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSurveyProviderMutationVariables,
  APITypes.UpdateSurveyProviderMutation
>;
export const deleteSurveyProvider = /* GraphQL */ `mutation DeleteSurveyProvider(
  $input: DeleteSurveyProviderInput!
  $condition: ModelSurveyProviderConditionInput
) {
  deleteSurveyProvider(input: $input, condition: $condition) {
    id
    slug
    name
    description
    snippetConfiguration {
      snippetType
      surveyIdReplacer
      probandIdReplacer
      __typename
    }
    setupDocumentation {
      steps {
        type
        textBeforeContent
        textAfterContent
        image {
          width
          height
          key
          __typename
        }
        __typename
      }
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSurveyProviderMutationVariables,
  APITypes.DeleteSurveyProviderMutation
>;
export const createUserRegistration = /* GraphQL */ `mutation CreateUserRegistration(
  $input: CreateUserRegistrationInput!
  $condition: ModelUserRegistrationConditionInput
) {
  createUserRegistration(input: $input, condition: $condition) {
    id
    name
    email
    institution
    researchGoal
    additionalInformation
    confirmedUsagePolicy
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserRegistrationMutationVariables,
  APITypes.CreateUserRegistrationMutation
>;
export const updateUserRegistration = /* GraphQL */ `mutation UpdateUserRegistration(
  $input: UpdateUserRegistrationInput!
  $condition: ModelUserRegistrationConditionInput
) {
  updateUserRegistration(input: $input, condition: $condition) {
    id
    name
    email
    institution
    researchGoal
    additionalInformation
    confirmedUsagePolicy
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserRegistrationMutationVariables,
  APITypes.UpdateUserRegistrationMutation
>;
export const deleteUserRegistration = /* GraphQL */ `mutation DeleteUserRegistration(
  $input: DeleteUserRegistrationInput!
  $condition: ModelUserRegistrationConditionInput
) {
  deleteUserRegistration(input: $input, condition: $condition) {
    id
    name
    email
    institution
    researchGoal
    additionalInformation
    confirmedUsagePolicy
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserRegistrationMutationVariables,
  APITypes.DeleteUserRegistrationMutation
>;
export const createBroadcastMail = /* GraphQL */ `mutation CreateBroadcastMail(
  $input: CreateBroadcastMailInput!
  $condition: ModelBroadcastMailConditionInput
) {
  createBroadcastMail(input: $input, condition: $condition) {
    id
    subject
    sender
    content
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBroadcastMailMutationVariables,
  APITypes.CreateBroadcastMailMutation
>;
export const updateBroadcastMail = /* GraphQL */ `mutation UpdateBroadcastMail(
  $input: UpdateBroadcastMailInput!
  $condition: ModelBroadcastMailConditionInput
) {
  updateBroadcastMail(input: $input, condition: $condition) {
    id
    subject
    sender
    content
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBroadcastMailMutationVariables,
  APITypes.UpdateBroadcastMailMutation
>;
export const deleteBroadcastMail = /* GraphQL */ `mutation DeleteBroadcastMail(
  $input: DeleteBroadcastMailInput!
  $condition: ModelBroadcastMailConditionInput
) {
  deleteBroadcastMail(input: $input, condition: $condition) {
    id
    subject
    sender
    content
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBroadcastMailMutationVariables,
  APITypes.DeleteBroadcastMailMutation
>;
export const createMediaItemTags = /* GraphQL */ `mutation CreateMediaItemTags(
  $input: CreateMediaItemTagsInput!
  $condition: ModelMediaItemTagsConditionInput
) {
  createMediaItemTags(input: $input, condition: $condition) {
    id
    tagId
    mediaItemId
    tag {
      id
      name
      mediaItems {
        items {
          id
          tagId
          mediaItemId
          tag {
            id
            name
            mediaItems {
              items {
                id
                tagId
                mediaItemId
                tag {
                  id
                  name
                  mediaItems {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                mediaItem {
                  id
                  image {
                    fullSize {
                      width
                      height
                      key
                      __typename
                    }
                    thumbNail {
                      width
                      height
                      key
                      __typename
                    }
                    __typename
                  }
                  source
                  originalFileName
                  tags {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  tagsString
                  tagsIds
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          mediaItem {
            id
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            source
            originalFileName
            tags {
              items {
                id
                tagId
                mediaItemId
                tag {
                  id
                  name
                  mediaItems {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                mediaItem {
                  id
                  image {
                    fullSize {
                      width
                      height
                      key
                      __typename
                    }
                    thumbNail {
                      width
                      height
                      key
                      __typename
                    }
                    __typename
                  }
                  source
                  originalFileName
                  tags {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  tagsString
                  tagsIds
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tagsString
            tagsIds
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    mediaItem {
      id
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      source
      originalFileName
      tags {
        items {
          id
          tagId
          mediaItemId
          tag {
            id
            name
            mediaItems {
              items {
                id
                tagId
                mediaItemId
                tag {
                  id
                  name
                  mediaItems {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                mediaItem {
                  id
                  image {
                    fullSize {
                      width
                      height
                      key
                      __typename
                    }
                    thumbNail {
                      width
                      height
                      key
                      __typename
                    }
                    __typename
                  }
                  source
                  originalFileName
                  tags {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  tagsString
                  tagsIds
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          mediaItem {
            id
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            source
            originalFileName
            tags {
              items {
                id
                tagId
                mediaItemId
                tag {
                  id
                  name
                  mediaItems {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                mediaItem {
                  id
                  image {
                    fullSize {
                      width
                      height
                      key
                      __typename
                    }
                    thumbNail {
                      width
                      height
                      key
                      __typename
                    }
                    __typename
                  }
                  source
                  originalFileName
                  tags {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  tagsString
                  tagsIds
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tagsString
            tagsIds
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tagsString
      tagsIds
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMediaItemTagsMutationVariables,
  APITypes.CreateMediaItemTagsMutation
>;
export const updateMediaItemTags = /* GraphQL */ `mutation UpdateMediaItemTags(
  $input: UpdateMediaItemTagsInput!
  $condition: ModelMediaItemTagsConditionInput
) {
  updateMediaItemTags(input: $input, condition: $condition) {
    id
    tagId
    mediaItemId
    tag {
      id
      name
      mediaItems {
        items {
          id
          tagId
          mediaItemId
          tag {
            id
            name
            mediaItems {
              items {
                id
                tagId
                mediaItemId
                tag {
                  id
                  name
                  mediaItems {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                mediaItem {
                  id
                  image {
                    fullSize {
                      width
                      height
                      key
                      __typename
                    }
                    thumbNail {
                      width
                      height
                      key
                      __typename
                    }
                    __typename
                  }
                  source
                  originalFileName
                  tags {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  tagsString
                  tagsIds
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          mediaItem {
            id
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            source
            originalFileName
            tags {
              items {
                id
                tagId
                mediaItemId
                tag {
                  id
                  name
                  mediaItems {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                mediaItem {
                  id
                  image {
                    fullSize {
                      width
                      height
                      key
                      __typename
                    }
                    thumbNail {
                      width
                      height
                      key
                      __typename
                    }
                    __typename
                  }
                  source
                  originalFileName
                  tags {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  tagsString
                  tagsIds
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tagsString
            tagsIds
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    mediaItem {
      id
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      source
      originalFileName
      tags {
        items {
          id
          tagId
          mediaItemId
          tag {
            id
            name
            mediaItems {
              items {
                id
                tagId
                mediaItemId
                tag {
                  id
                  name
                  mediaItems {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                mediaItem {
                  id
                  image {
                    fullSize {
                      width
                      height
                      key
                      __typename
                    }
                    thumbNail {
                      width
                      height
                      key
                      __typename
                    }
                    __typename
                  }
                  source
                  originalFileName
                  tags {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  tagsString
                  tagsIds
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          mediaItem {
            id
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            source
            originalFileName
            tags {
              items {
                id
                tagId
                mediaItemId
                tag {
                  id
                  name
                  mediaItems {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                mediaItem {
                  id
                  image {
                    fullSize {
                      width
                      height
                      key
                      __typename
                    }
                    thumbNail {
                      width
                      height
                      key
                      __typename
                    }
                    __typename
                  }
                  source
                  originalFileName
                  tags {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  tagsString
                  tagsIds
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tagsString
            tagsIds
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tagsString
      tagsIds
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMediaItemTagsMutationVariables,
  APITypes.UpdateMediaItemTagsMutation
>;
export const deleteMediaItemTags = /* GraphQL */ `mutation DeleteMediaItemTags(
  $input: DeleteMediaItemTagsInput!
  $condition: ModelMediaItemTagsConditionInput
) {
  deleteMediaItemTags(input: $input, condition: $condition) {
    id
    tagId
    mediaItemId
    tag {
      id
      name
      mediaItems {
        items {
          id
          tagId
          mediaItemId
          tag {
            id
            name
            mediaItems {
              items {
                id
                tagId
                mediaItemId
                tag {
                  id
                  name
                  mediaItems {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                mediaItem {
                  id
                  image {
                    fullSize {
                      width
                      height
                      key
                      __typename
                    }
                    thumbNail {
                      width
                      height
                      key
                      __typename
                    }
                    __typename
                  }
                  source
                  originalFileName
                  tags {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  tagsString
                  tagsIds
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          mediaItem {
            id
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            source
            originalFileName
            tags {
              items {
                id
                tagId
                mediaItemId
                tag {
                  id
                  name
                  mediaItems {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                mediaItem {
                  id
                  image {
                    fullSize {
                      width
                      height
                      key
                      __typename
                    }
                    thumbNail {
                      width
                      height
                      key
                      __typename
                    }
                    __typename
                  }
                  source
                  originalFileName
                  tags {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  tagsString
                  tagsIds
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tagsString
            tagsIds
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    mediaItem {
      id
      image {
        fullSize {
          width
          height
          key
          __typename
        }
        thumbNail {
          width
          height
          key
          __typename
        }
        __typename
      }
      source
      originalFileName
      tags {
        items {
          id
          tagId
          mediaItemId
          tag {
            id
            name
            mediaItems {
              items {
                id
                tagId
                mediaItemId
                tag {
                  id
                  name
                  mediaItems {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                mediaItem {
                  id
                  image {
                    fullSize {
                      width
                      height
                      key
                      __typename
                    }
                    thumbNail {
                      width
                      height
                      key
                      __typename
                    }
                    __typename
                  }
                  source
                  originalFileName
                  tags {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  tagsString
                  tagsIds
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          mediaItem {
            id
            image {
              fullSize {
                width
                height
                key
                __typename
              }
              thumbNail {
                width
                height
                key
                __typename
              }
              __typename
            }
            source
            originalFileName
            tags {
              items {
                id
                tagId
                mediaItemId
                tag {
                  id
                  name
                  mediaItems {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                mediaItem {
                  id
                  image {
                    fullSize {
                      width
                      height
                      key
                      __typename
                    }
                    thumbNail {
                      width
                      height
                      key
                      __typename
                    }
                    __typename
                  }
                  source
                  originalFileName
                  tags {
                    items {
                      id
                      tagId
                      mediaItemId
                      tag {
                        id
                        name
                        mediaItems {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                      }
                      mediaItem {
                        id
                        image {
                          fullSize {
                            width
                            height
                            key
                            __typename
                          }
                          thumbNail {
                            width
                            height
                            key
                            __typename
                          }
                          __typename
                        }
                        source
                        originalFileName
                        tags {
                          items {
                            id
                            tagId
                            mediaItemId
                            tag {
                              id
                              name
                              mediaItems {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              createdAt
                              updatedAt
                              __typename
                            }
                            mediaItem {
                              id
                              image {
                                fullSize {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                thumbNail {
                                  width
                                  height
                                  key
                                  __typename
                                }
                                __typename
                              }
                              source
                              originalFileName
                              tags {
                                items {
                                  id
                                  tagId
                                  mediaItemId
                                  createdAt
                                  updatedAt
                                  __typename
                                }
                                nextToken
                                __typename
                              }
                              tagsString
                              tagsIds
                              createdAt
                              updatedAt
                              __typename
                            }
                            createdAt
                            updatedAt
                            __typename
                          }
                          nextToken
                          __typename
                        }
                        tagsString
                        tagsIds
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  tagsString
                  tagsIds
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            tagsString
            tagsIds
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tagsString
      tagsIds
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMediaItemTagsMutationVariables,
  APITypes.DeleteMediaItemTagsMutation
>;
export const registerUser = /* GraphQL */ `mutation RegisterUser(
  $name: String!
  $email: String!
  $institution: String!
  $additionalInformation: String
  $confirmedUsagePolicy: Boolean!
  $researchGoal: String!
) {
  registerUser(
    name: $name
    email: $email
    institution: $institution
    additionalInformation: $additionalInformation
    confirmedUsagePolicy: $confirmedUsagePolicy
    researchGoal: $researchGoal
  ) {
    body
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RegisterUserMutationVariables,
  APITypes.RegisterUserMutation
>;
export const confirmRegistration = /* GraphQL */ `mutation ConfirmRegistration(
  $registrationId: String!
  $groups: [String!]!
  $institution: String
  $additionalInformation: String
  $researchGoal: String
) {
  confirmRegistration(
    registrationId: $registrationId
    groups: $groups
    institution: $institution
    additionalInformation: $additionalInformation
    researchGoal: $researchGoal
  ) {
    body
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ConfirmRegistrationMutationVariables,
  APITypes.ConfirmRegistrationMutation
>;
export const denyRegistration = /* GraphQL */ `mutation DenyRegistration($registrationId: String!) {
  denyRegistration(registrationId: $registrationId) {
    body
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DenyRegistrationMutationVariables,
  APITypes.DenyRegistrationMutation
>;
export const mindSetLoggingAdapter = /* GraphQL */ `mutation MindSetLoggingAdapter(
  $severity: Severity!
  $name: String!
  $message: String!
  $context: String
) {
  mindSetLoggingAdapter(
    severity: $severity
    name: $name
    message: $message
    context: $context
  ) {
    body
    __typename
  }
}
` as GeneratedMutation<
  APITypes.MindSetLoggingAdapterMutationVariables,
  APITypes.MindSetLoggingAdapterMutation
>;
